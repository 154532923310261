import * as React from "react";
import Card from "material-ui/Card/Card";
import CircularProgress from "material-ui/CircularProgress";
import { HttpClient } from "../utils/HttpClient";
import { connect } from "react-redux";
import { setBreadcrumb, setProcessingTableState } from "../actions/Actions";
import { getBreadcrumbObject, setPageFromOffset, } from "../utils/DataHelpers";
import ProcessingTable from "../components/ProcessingComponents/ProcessingTable";
const ReactToastify = require("react-toastify");
class Processing extends React.Component {
    constructor(props) {
        super(props);
        const initialTableState = {
            rowSize: 20,
            count: 0,
            currentPage: 1,
            search: "",
            orderKey: "",
            order: "",
            filter: {
                phone: "",
                service: "",
                clientId: "",
            },
            data: [],
        };
        this.state = {
            loading: true,
            transactions: null,
            tableState: this.props.tableState || initialTableState,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Processing", "/processing")]));
    }
    render() {
        return (React.createElement("div", { className: "full-size-card-wrapper" },
            React.createElement(Card, { className: "card-full-size" }, this.renderProcessingTable())));
    }
    componentDidMount() {
        this.fetchProcessingData();
    }
    fetchProcessingData(currentPage = this.state.tableState.currentPage, rowSize = this.state.tableState.rowSize, search = this.state.tableState.search, orderKey = this.state.tableState.orderKey, order = this.state.tableState.order, filter = this.state.tableState.filter) {
        const query = {
            offset: (currentPage - 1) * rowSize,
            limit: rowSize,
            search,
            orderKey,
            order,
            phone: filter.phone,
            services: filter.service,
            clientId: filter.clientId,
        };
        HttpClient.getJSON(`${this.props.baseUrl}/processing/list`, query)
            .then((result) => {
            const tableState = {
                count: result.pagination.total,
                currentPage: setPageFromOffset(result.pagination.offset, result.pagination.length),
                rowSize,
                search,
                orderKey,
                order,
                filter,
                data: result.txs,
            };
            this.props.dispatch(setProcessingTableState(tableState));
            this.setState({ loading: false, tableState });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load data,reload Processing items! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderProcessingTable() {
        const { loading } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(ProcessingTable, { fetchData: this.fetchProcessingData.bind(this), tableState: this.props.tableState }));
        }
    }
}
const mapStateToProp = (state) => ({
    tableState: state.processingTableState,
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProp)(Processing);
