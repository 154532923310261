import * as React from "react";
import { formatCurrency } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import Card, { CardTitle } from "material-ui/Card";
class BusinessesGrid extends React.Component {
    render() {
        if (this.props.businesses.length === 0) {
            return (React.createElement("div", { className: "owner-business-grid" },
                React.createElement("p", null, "Business list is empty, so far.")));
        }
        else {
            return (React.createElement("div", { className: "owner-business-grid" }, this.props.businesses.map((business) => React.createElement(Link, { to: `/business/${business.id}/info`, key: business.id + "link" },
                React.createElement(Card, { className: "owner-business-card", key: business.id },
                    React.createElement(CardTitle, { title: business.title, subtitle: formatCurrency(business.balance) }))))));
        }
    }
}
export default BusinessesGrid;
