import { combineReducers } from "redux";
import { ADD_BUSINESS_INFO, ADD_EMAIL, ADD_USERS, APPEND_BREADCRUMB, DELETE_EMAIL, DELETE_USER, SET_BASE_URL, SET_BREADCRUMB, SET_BUYERS_TABLE_STATE, SET_CHOSEN_BUSINESS_OWNER, SET_CHOSEN_SELLER, SET_CHOSEN_USER, SET_PROCESSING_TABLE_STATE, SET_TOKEN, SET_TXS_TABLE_STATE, setProcessingTableState, UPDATE_TITLE, FIREBASE_EXAMPLES, } from "../actions/Actions";
import { isNullOrUndefined } from "util";
import { buyerProfileInfo } from "./buyerReducer";
import { commissionFormReducer } from "./CommissionReducer";
function users(state = null, action) {
    switch (action.type) {
        case ADD_USERS:
            return Object.assign({}, {
                users: action.users,
            });
        case DELETE_USER:
            return Object.assign({}, state, {
                users: state.users.filter((user) => user.phone !== action.phone),
            });
        default:
            return state;
    }
}
export function businessInfo(state = null, action) {
    switch (action.type) {
        case ADD_BUSINESS_INFO:
            return Object.assign({}, {
                title: action.title,
                emails: action.emails,
                id: action.id,
                idno: action.idno,
                ownerPhone: action.ownerPhone,
            });
        case ADD_EMAIL:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { emails: isNullOrUndefined(state.emails) ? [action.email] : [...state.emails, action.email] }));
        case DELETE_EMAIL:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { emails: state.emails.filter((email) => email !== action.email) }));
        case UPDATE_TITLE:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { title: action.title }));
        default:
            return state;
    }
}
export function txsTableState(state = null, action) {
    if (action.type === SET_TXS_TABLE_STATE) {
        return setTxTableState(action);
    }
    else {
        return state;
    }
}
export function processingTableState(state = null, action) {
    if (action.type === SET_PROCESSING_TABLE_STATE) {
        return setProcessingTableState(action);
    }
    else {
        return state;
    }
}
function buyersTableState(state = null, action) {
    if (action.type === SET_BUYERS_TABLE_STATE) {
        return setTableState(action);
    }
    else {
        return state;
    }
}
export function baseUrl(state = null, action) {
    if (action.type === SET_BASE_URL) {
        return action.url;
    }
    else {
        return state;
    }
}
export function firebaseConfiguration(state = {}, action) {
    if (action.type === FIREBASE_EXAMPLES) {
        return action.firebaseConfig;
    }
    else {
        return state;
    }
}
function chosenUser(state = null, action) {
    if (action.type === SET_CHOSEN_USER) {
        return Object.assign({}, {
            phone: action.phone,
            roles: action.roles,
        });
    }
    else {
        return state;
    }
}
function chosenOwner(state = null, action) {
    if (action.type === SET_CHOSEN_BUSINESS_OWNER) {
        return Object.assign({}, {
            phone: action.phone,
            name: action.name,
            surname: action.surname,
        });
    }
    else {
        return state;
    }
}
export function currentBreadcrumbs(state = [], action) {
    switch (action.type) {
        case SET_BREADCRUMB:
            return action.breadcrumbs;
        case APPEND_BREADCRUMB:
            return state.concat(action.breadcrumbs);
        default:
            return state;
    }
}
export function chosenSeller(state = null, action) {
    if (action.type === SET_CHOSEN_SELLER) {
        return action.phone;
    }
    else {
        return state;
    }
}
function setTxTableState(action) {
    return Object.assign({}, {
        rowSize: action.rowSize,
        count: action.count,
        currentPage: action.currentPage,
        search: action.search,
        orderKey: action.orderKey,
        order: action.order,
        filter: action.filter,
        data: action.data,
    });
}
function setTableState(action) {
    return Object.assign({}, {
        rowSize: action.rowSize,
        count: action.count,
        currentPage: action.currentPage,
        search: action.search,
        orderKey: action.orderKey,
        order: action.order,
        data: action.data,
    });
}
export function token(state = null, action) {
    if (action.type === SET_TOKEN) {
        return action.token;
    }
    else {
        return state;
    }
}
const reducer = combineReducers({
    businessInfo,
    users,
    buyerProfileInfo,
    baseUrl,
    chosenUser,
    txsTableState,
    processingTableState,
    buyersTableState,
    chosenOwner,
    currentBreadcrumbs,
    chosenSeller,
    token,
    firebaseConfiguration,
    commissionFormState: commissionFormReducer,
});
export default reducer;
