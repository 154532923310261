import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class UnblockBuyerDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.dialogBtns = [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: () => this.props.toggleDialog() }),
            React.createElement(FlatButton, { label: "Unblock", primary: true, onClick: this.props.onUnblockBtnClick.bind(this) }),
        ];
    }
    render() {
        return (React.createElement(Dialog, { title: "Unblock Wallet", actions: this.dialogBtns, modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) }, "Are you sure you want to unblock current wallet?"));
    }
}
export default UnblockBuyerDialog;
