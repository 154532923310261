import * as React from "react";
import Card, { CardHeader, CardText } from "material-ui/Card";
import { Link } from "react-router-dom";
const SellerNotFount = (props) => (React.createElement(Card, { className: "buyer-not-found-card" },
    React.createElement(CardHeader, { title: `Seller not found`, subtitle: `Error 404` }),
    React.createElement(CardText, null,
        "Seller with ",
        props.phone,
        " phone number doesn't exist. Click ",
        React.createElement(Link, { to: "/buyers" }, "Here"),
        " to return, ore use \"Go Back One Page\" button in your browser.")));
export default SellerNotFount;
