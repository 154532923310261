import * as React from "react";
import List from "material-ui/List/List";
import ListItem from "material-ui/List/ListItem";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import IconButton from "material-ui/IconButton";
import Subheader from "material-ui/Subheader";
class EmailList extends React.Component {
    render() {
        return this.renderList();
    }
    iconRightMenu(value) {
        return (React.createElement(IconButton, { onClick: () => this.props.deleteItem(value), tooltipPosition: "bottom-left" },
            React.createElement(DeleteIcon, null)));
    }
    renderList() {
        if (this.props.emails) {
            return (React.createElement(List, null,
                React.createElement(Subheader, null, "Emails:"),
                this.props.emails.map((value) => React.createElement(ListItem, { key: value, value: value, rightIconButton: this.iconRightMenu(value), primaryText: value }))));
        }
        else {
            return React.createElement(Subheader, null, "Emails:");
        }
    }
}
export default EmailList;
