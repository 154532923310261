import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl, setPageFromOffset } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { Card } from "material-ui/Card";
import { HttpClient } from "../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import { Tabs, Tab, IconButton, Dialog, FlatButton } from "material-ui";
import Remove from "material-ui/svg-icons/content/remove";
import { white } from "material-ui/styles/colors";
import CashoutBusinessArchiveTable from "../components/CashoutBusiness/CashoutBusinessArchiveTable";
import CashoutBusinessOpenList from "../components/CashoutBusiness/CashoutBusinessOpenList";
import * as moment from "moment";
import CashoutBusinessDialog from "../components/CashoutBusiness/CashoutBusinessDialog";
import CashoutBusinessDetails from "../components/CashoutBusiness/CashoutBusinessDetails";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Open"] = 0] = "Open";
    TabNames[TabNames["Archive"] = 1] = "Archive";
})(TabNames || (TabNames = {}));
const emptyWithdrawal = {
    id: "",
    whenCreated: 0,
    whenFinished: 0,
    status: "",
    operator: "",
    companyId: "",
    companyTitle: "",
    amount: "",
    orderNo: 0,
    percent: "",
    filter: {
        searchName: "",
        idno: "",
        start: moment().startOf("month").toDate(),
        end: moment().endOf("day").toDate(),
    },
    serviceProvider: "",
    images: [],
    notes: "",
};
class WithdrawalRequests extends React.Component {
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            const newArray = this.state.files.concat(files);
            this.setStateFiles(newArray);
        };
        this.setStateFiles = (files) => {
            this.setState((state) => ({
                files,
                errors: state.errors && state.errors.filter((e) => e.field !== "filesUpload"),
            }));
        };
        this.handleKeyPress = (event) => {
            if (event.key === "Enter") {
                this.fetchServiceProvider();
            }
        };
        this.state = {
            loading: false,
            cashoutBusiness: [],
            selectedTab: TabNames.Open,
            items: [],
            rowSize: 20,
            count: 0,
            currentPage: 1,
            currentBusiness: Object.assign({}, emptyWithdrawal),
            errors: null,
            isSaving: false,
            toggleDialog: false,
            detailsDialog: false,
            toggleDetailsDialog: false,
            imageIndex: 0,
            files: [],
            services: [],
            details: Object.assign({}, emptyWithdrawal),
            currentOrganization: {},
            imagesLightboxOpened: false,
            showFileError: null,
            orderKey: "",
            order: "",
            filter: {
                searchName: "",
                idno: "",
                start: moment().startOf("month").toDate(),
                end: moment().endOf("day").toDate(),
            },
            dialogFilter: {
                idno: "",
                name: "",
                account: "",
            },
            toggleDeleteDialog: false,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Cashout company", "/cashout-company")]));
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            React.createElement(Card, { className: "card-full-size" },
                this.renderBusinessDialog(),
                this.renderDataTable(),
                this.renderDeleteBusinessDialog(),
                this.cashoutBusinessDetails())));
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/withdrawal/company/list`).then((response) => {
            this.setState({
                loading: false,
                cashoutBusiness: response && response.body || [],
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    handleChangeFilter(value, name) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { filter: Object.assign(Object.assign({}, state.filter), { [name]: value }), errors: state.errors && state.errors.filter((e) => e.field !== name) })));
    }
    fetchBusiness(currentPage = this.state.currentPage, rowSize = this.state.rowSize, orderKey = this.state.orderKey, order = this.state.order, filter = this.state.filter) {
        if (orderKey === "companyName") {
            orderKey = "name";
        }
        else if (orderKey === "percent") {
            orderKey = "comm_percent";
        }
        else if (orderKey === "whenFinished") {
            orderKey = "when_finished";
        }
        this.setState({
            loading: false,
        });
        const query = {
            offset: (currentPage - 1) * rowSize,
            limit: rowSize,
            orderKey,
            order,
            searchName: filter.searchName,
            idno: filter.idno,
            startTime: filter.start ? moment(filter.start).format("x") : "",
            endTime: filter.end ? moment(filter.end).format("x") : "",
        };
        HttpClient.getJSON(`${this.props.baseUrl}/withdrawal/company/archive/list`, query)
            .then((response) => {
            if (response) {
                this.setState({
                    loading: false,
                    items: response && response.txs || [],
                    count: response && response.pagination.total || 0,
                    currentPage: setPageFromOffset(response && response.pagination.offset, response && response.pagination.length),
                    rowSize,
                    orderKey,
                    order,
                });
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading data! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    closeBusinessDialog() {
        this.setState({
            toggleDialog: false,
            currentBusiness: null,
            isSaving: false,
            errors: null,
            files: [],
        });
    }
    clearFilterValue() {
        this.setState({
            dialogFilter: {
                name: "",
                idno: "",
                account: "",
            },
        });
    }
    getSelectedService() {
        let selectedService = {};
        (this.state.services || []).forEach(item => {
            if (item.businessId === this.state.details.companyId) {
                selectedService = item;
            }
        });
        return selectedService;
    }
    validateBusiness() {
        const result = [];
        const { details, files } = this.state;
        const currentBusiness = details;
        const selectedItems = this.getSelectedService();
        const calculateWithdrawalPercent = Number(details && Number(details.amount) / 100) * Number(details && details.percent);
        const totalWithdrawal = Number(details && details.amount) + Number(calculateWithdrawalPercent);
        if (!currentBusiness || !currentBusiness.amount) {
            result.push({
                field: "amount",
                text: "Amount must not be empty!",
            });
        }
        if (currentBusiness && currentBusiness.amount && Number(currentBusiness.amount) <= 0) {
            result.push({
                field: "amount",
                text: "Amount can not be 0!",
            });
        }
        if (totalWithdrawal > Number(selectedItems.balance)) {
            result.push({
                field: "withdrawal",
            });
        }
        if (currentBusiness && Number(currentBusiness.amount) > Number(selectedItems.balance)) {
            result.push({
                field: "amount",
                text: "Amount is higher than balance",
            });
        }
        if (!currentBusiness || !currentBusiness.percent) {
            result.push({
                field: "percent",
                text: "Commission must not be empty!",
            });
        }
        if (!currentBusiness || !currentBusiness.notes) {
            result.push({
                field: "notes",
                text: "Comment must not be empty!",
            });
        }
        if (currentBusiness && currentBusiness.percent && Number(currentBusiness.percent) > 100) {
            result.push({
                field: "percent",
                text: "Percent can not be higher than 100",
            });
        }
        if (currentBusiness && currentBusiness.percent && Number(currentBusiness.percent) < 0) {
            result.push({
                field: "percent",
                text: "Percent can not be smaller than 0",
            });
        }
        if (!currentBusiness || !currentBusiness.companyId) {
            result.push({
                field: "businessId",
                text: "Organization must not be empty!",
            });
        }
        if (!currentBusiness || Number(currentBusiness.balance) < Number(currentBusiness.amount)) {
            result.push({
                field: "amount",
                text: "Insufficient resources!",
            });
        }
        if (files.length > 3) {
            result.push({
                field: "filesUpload",
                text: "Only 3 files is allowed",
            });
        }
        return result;
    }
    addBusiness() {
        const errs = this.validateBusiness();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        const { details, files } = this.state;
        const currentBusiness = details;
        if (currentBusiness && files.length <= 3) {
            const multiPartData = new FormData();
            for (let i = 0; i < files.length; i++) {
                multiPartData.append(`file${i}`, files[i]);
            }
            this.setState({
                isSaving: true,
            });
            const isUpdate = currentBusiness && currentBusiness.id ? `${currentBusiness.id}/update` : "create";
            const defaultErrorMessage = "Could not make changes!";
            HttpClient.postMultiPart(`${this.props.baseUrl}/withdrawal/company/${isUpdate}?amount=${currentBusiness.amount}&companyId=${currentBusiness.companyId}&notes=${currentBusiness.notes}&percent=${currentBusiness.percent}`, multiPartData)
                .then((response) => {
                const resp = JSON.parse(response);
                switch (resp.status.code) {
                    case "ok":
                        ReactToastify.toast.info(currentBusiness.id
                            ? "Withdrawal was update successfully!"
                            : "Withdrawal was added successfully!");
                        this.closeBusinessDialog();
                        this.fetchData();
                        this.setState({
                            isSaving: false,
                        });
                        break;
                    case "not_found_request":
                    case "already_confirmed":
                    case "not_found_company":
                        ReactToastify.toast.info(resp.status.message || defaultErrorMessage);
                        break;
                    default:
                        ReactToastify.toast.info(defaultErrorMessage);
                        break;
                }
            })
                .catch((err) => {
                this.setState({
                    isSaving: false,
                });
                ReactToastify.toast.error("Could not add withdrawal! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    removeByIndex(fileIndex) {
        const files = this.state.files;
        if (files && files.length >= fileIndex + 1) {
            files.splice(fileIndex, 1);
            this.setStateFiles(files);
        }
    }
    getOperatorTileIcon(fileIndex) {
        return (React.createElement(IconButton, { onClick: () => this.removeByIndex(fileIndex), key: fileIndex },
            React.createElement(Remove, { color: white })));
    }
    getBusinessDetails() {
        const defaultMessage = "Error loading data!";
        const { currentBusiness } = this.state;
        if (!currentBusiness.id) {
            this.setState({
                details: Object.assign({}, emptyWithdrawal),
            });
            return;
        }
        HttpClient.getJSON(`${this.props.baseUrl}/withdrawal/company/${currentBusiness.id}/detail`)
            .then((response) => {
            if (response && response.status.code === "ok") {
                this.setState({
                    details: response.body,
                });
            }
            else {
                this.setState({
                    toggleDetailsDialog: false,
                    currentBusiness: null,
                });
                ReactToastify.toast.error(response && response.status && response.status.message || defaultMessage);
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading data! Status: ", +JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    fetchServiceProvider(name = this.state.dialogFilter.name, idno = this.state.dialogFilter.idno, account = this.state.dialogFilter.account) {
        const defaultMessage = "Error loading data!";
        this.setState({
            loading: false,
        });
        HttpClient.getJSON(`${this.props.baseUrl}/withdrawal/company/providers`, Object.assign(Object.assign(Object.assign({}, name ? { name } : ""), idno ? { idno } : ""), account ? { account } : ""))
            .then((response) => {
            if (response && response.body && response.status.code === "ok") {
                this.setState({
                    loading: false,
                    services: response && response.body || [],
                });
            }
            else {
                this.setState({
                    loading: false,
                    toggleDialog: false,
                    currentBusiness: null,
                });
                ReactToastify.toast.error(response && response.status && response.status.message || defaultMessage);
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading service provider! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    handleInputChange(value, name) {
        const filterErrors = this.state.errors && this.state.errors.filter((e) => e.field !== name);
        this.setState((state) => (Object.assign(Object.assign({}, state), { details: Object.assign(Object.assign({}, state.details), { [name]: value }), errors: filterErrors })));
    }
    handleInputFilterChange(value, name) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { dialogFilter: Object.assign(Object.assign({}, state.dialogFilter), { [name]: value }) })));
    }
    handleChangeNumericInputs(value, name) {
        const re = /^([0-9]\d{0,12})([.]?\d{0,4})$/;
        if (re.test(value) || value === "") {
            this.setState((state) => (Object.assign(Object.assign({}, state), { details: Object.assign(Object.assign({}, state.details), { [name]: value }), errors: state.errors && state.errors.filter((e) => e.field !== name) })));
        }
    }
    ;
    handleStartDateChange(date) {
        const { currentPage, rowSize, order, orderKey, filter } = this.state;
        const newFilter = Object.assign(Object.assign({}, filter), { start: date ? moment(date).startOf("day").toDate() : null });
        this.setState((state) => (Object.assign(Object.assign({}, state), { filter: newFilter })));
        this.fetchBusiness(currentPage, rowSize, orderKey, order, newFilter);
    }
    handleEndDateChange(date) {
        const { currentPage, rowSize, order, orderKey, filter } = this.state;
        const newFilter = Object.assign(Object.assign({}, filter), { end: date ? moment(date).endOf("day").toDate() : null });
        this.setState((state) => (Object.assign(Object.assign({}, state), { filter: newFilter })));
        this.fetchBusiness(currentPage, rowSize, orderKey, order, newFilter);
    }
    renderTabs() {
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "250px" } },
                React.createElement(Tab, { label: "Open", value: TabNames.Open }),
                React.createElement(Tab, { label: "Archive", value: TabNames.Archive }))));
    }
    changeGridTileState(opened, index) {
        this.setState({ imagesLightboxOpened: opened, imageIndex: index });
    }
    renderDataTable() {
        const { selectedTab, loading, filter } = this.state;
        const { roles } = this.props;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else if (selectedTab === TabNames.Open) {
            return (React.createElement(CashoutBusinessOpenList, { openNewRequestDialog: this.toggleCashoutBusinessDialog.bind(this), data: this.state.cashoutBusiness, fetchData: this.fetchData.bind(this), toggleCashoutBusinessDialog: this.toggleCashoutBusinessDialog.bind(this), toggleDeleteBusinessDialog: this.toggleDeleteBusinessDialog.bind(this), emptyCashout: emptyWithdrawal, roles: roles }));
        }
        else if (selectedTab === TabNames.Archive) {
            return (React.createElement(CashoutBusinessArchiveTable, { reloadData: this.fetchBusiness.bind(this), data: this.state.items, rowSize: this.state.rowSize, count: this.state.count, currentPage: this.state.currentPage, orderKey: this.state.orderKey, order: this.state.order, handleChangeFilter: this.handleChangeFilter.bind(this), toggleDetailsDialog: this.toggleDetailsDialog.bind(this), handleStartDateChange: this.handleStartDateChange.bind(this), handleEndDateChange: this.handleEndDateChange.bind(this), filter: filter }));
        }
    }
    renderBusinessDialog() {
        const { toggleDialog, isSaving, errors, dialogFilter, currentBusiness, details } = this.state;
        const calculateWithdrawalPercent = Number(details && Number(details.amount) / 100) * Number(details && details.percent);
        const totalWithdrawal = Number(details && details.amount) + Number(calculateWithdrawalPercent);
        const selectedItems = this.getSelectedService();
        return toggleDialog && (React.createElement(CashoutBusinessDialog, { open: toggleDialog, currentBusiness: currentBusiness, dialogTitle: currentBusiness && currentBusiness.id ? "Edit withdrawal" : "Create withdrawal", handleInputChange: this.handleInputChange.bind(this), isSaving: isSaving, onOkClick: this.addBusiness.bind(this), onCancelClick: this.closeBusinessDialog.bind(this), errors: errors, baseUrl: this.props.baseUrl, fetchServiceProvider: this.fetchServiceProvider.bind(this), files: this.state.files, onDrop: this.onDrop.bind(this), getOperatorTileIcon: this.getOperatorTileIcon.bind(this), changeGridTileState: this.changeGridTileState.bind(this), services: this.state.services, fetchData: this.fetchData.bind(this), handleChangeNumericInputs: this.handleChangeNumericInputs.bind(this), dialogFilter: dialogFilter, handleInputFilterChange: this.handleInputFilterChange.bind(this), handleKeyPress: this.handleKeyPress.bind(this), clearFilterValue: this.clearFilterValue.bind(this), removeByIndex: this.removeByIndex.bind(this), totalWithdrawal: totalWithdrawal, getBusinessDetails: this.getBusinessDetails.bind(this), details: this.state.details, deleteSelectedFile: this.deleteSelectedFile.bind(this), selectedService: selectedItems }));
    }
    cashoutBusinessDetails() {
        const { currentBusiness, toggleDetailsDialog } = this.state;
        return toggleDetailsDialog && (React.createElement(CashoutBusinessDetails, { open: toggleDetailsDialog, currentBusiness: currentBusiness, onCancelClick: this.toggleDetailsDialog.bind(this), baseUrl: this.props.baseUrl, getBusinessDetails: this.getBusinessDetails.bind(this), details: this.state.details, isSaving: this.state.isSaving }));
    }
    toggleCashoutBusinessDialog(currentBusiness) {
        this.setState(state => ({
            currentBusiness: Object.assign({}, currentBusiness),
            toggleDialog: !state.toggleDialog,
        }));
    }
    toggleDeleteBusinessDialog(currentBusiness) {
        this.setState(state => ({
            toggleDeleteDialog: !state.toggleDeleteDialog,
            currentBusiness: (currentBusiness && Object.assign({}, currentBusiness)),
        }));
    }
    toggleDetailsDialog(currentBusiness) {
        this.setState(state => ({
            toggleDetailsDialog: !state.toggleDetailsDialog,
            currentBusiness: Object.assign({}, currentBusiness),
        }));
    }
    deleteSelectedFile(imgName) {
        const defaultErrorMessage = "Could not delete file!";
        const { currentBusiness } = this.state;
        const fileName = imgName;
        if (currentBusiness) {
            HttpClient.delete(`${this.props.baseUrl}/withdrawal/company/${this.state.currentBusiness.id}/doc?fileName=${fileName}`)
                .then((response) => {
                const resp = JSON.parse(response);
                switch (resp.status.code) {
                    case "ok":
                        ReactToastify.toast.info("File was deleted successfully!");
                        this.getBusinessDetails();
                        break;
                    case "not_found_request":
                    case "already_confirmed":
                    case "not_found_company":
                    case "not_found_operator":
                        ReactToastify.toast.info(resp.status.message || defaultErrorMessage);
                        break;
                    default:
                        ReactToastify.toast.info(defaultErrorMessage);
                        break;
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Could not delete file! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    deleteSelectedBusiness() {
        const defaultErrorMessage = "Could not delete business!";
        const { currentBusiness } = this.state;
        if (currentBusiness) {
            HttpClient.post(`${this.props.baseUrl}/withdrawal/company/${this.state.currentBusiness.id}/rejected/finish`)
                .then((response) => {
                const resp = JSON.parse(response);
                switch (resp.status.code) {
                    case "ok":
                        ReactToastify.toast.info("Company was deleted successfully!");
                        this.fetchData();
                        this.toggleDeleteBusinessDialog(null);
                        break;
                    case "not_found_request":
                    case "insufficient_funds":
                    case "already_confirmed":
                    case "not_found_operator":
                        ReactToastify.toast.info(resp.status.message || defaultErrorMessage);
                        break;
                    default:
                        ReactToastify.toast.info(defaultErrorMessage);
                        break;
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Could not delete business! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    renderDeleteBusinessDialog() {
        const companyName = this.state.currentBusiness && this.state.currentBusiness.companyName;
        return (React.createElement(Dialog, { title: companyName ? "Delete company with name " + companyName + "?" : "Delete chosen company ?", actions: [
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: () => this.toggleDeleteBusinessDialog(null), disabled: this.state.isSaving }),
                React.createElement(FlatButton, { label: "OK", onClick: this.deleteSelectedBusiness.bind(this), disabled: this.state.isSaving }),
            ], modal: true, open: this.state.toggleDeleteDialog, onRequestClose: () => this.toggleDeleteBusinessDialog(null) }));
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(WithdrawalRequests));
