import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { CircularProgress } from "material-ui";
import { capitalizeFirstLetter, formatTime, getInfoTableRow, } from "../../utils/DataHelpers";
import Table, { TableBody } from "material-ui/Table";
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from "@material-ui/core";
class ArchiveUsersDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const readOnly = this.props.currentArchive && this.props.currentArchive.readOnly;
        const dialogTitle = readOnly ?
            "Details" :
            this.props.dialogTitle;
        return (React.createElement(Dialog, { title: dialogTitle, actions: this.renderDialogActions, modal: true, open: this.props.open, onRequestClose: this.props.onCancelClick.bind(this) },
            !readOnly && this.renderForm(),
            readOnly && this.renderArchiveInfo()));
    }
    get renderDialogActions() {
        const readOnly = this.props.currentArchive && this.props.currentArchive.readOnly;
        if (readOnly) {
            return [
                React.createElement(FlatButton, { label: "Close", primary: true, onClick: this.props.onCancelClick.bind(this), disabled: this.props.isSaving }),
            ];
        }
        return [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.onCancelClick.bind(this), disabled: this.props.isSaving }),
            React.createElement(FlatButton, { label: "Ok", primary: true, onClick: this.props.onOkClick, disabled: this.props.isSaving }),
        ];
    }
    renderForm() {
        const { currentArchive } = this.props;
        if (this.props.isSaving) {
            return (React.createElement(CircularProgress, { size: 50, style: { left: "50%" } }));
        }
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
            React.createElement(FormControl, { component: "fieldset" },
                React.createElement(FormLabel, { style: {
                        color: "rgba(0, 0, 0, 0.6)", fontSize: 14,
                        marginTop: 15,
                        marginBottom: 15,
                    } }, "Inactive days"),
                React.createElement(RadioGroup, { "aria-label": "gender", name: "gender1", style: { display: "flex", flexDirection: "row" }, value: currentArchive && currentArchive.inactivityDays, onChange: (e) => this.props.handleInputChange(e.target.value, "inactivityDays") },
                    React.createElement(FormControlLabel, { style: { color: "#000" }, value: "30", control: React.createElement(Radio, { className: "radio-button-control" }), label: "30 days" }),
                    React.createElement(FormControlLabel, { style: { color: "#000" }, value: "60", control: React.createElement(Radio, { className: "radio-button-control" }), label: "60 days" }),
                    React.createElement(FormControlLabel, { style: { color: "#000" }, value: "90", control: React.createElement(Radio, { className: "radio-button-control" }), label: "90 days" })))));
    }
    renderArchiveInfo() {
        const { currentArchive } = this.props;
        return (React.createElement(Table, { selectable: false },
            React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false, className: "border-none" },
                getInfoTableRow("Created by", currentArchive.createdBy, "table-column-multiline"),
                getInfoTableRow("Created", formatTime(currentArchive.created), "table-column-multiline"),
                getInfoTableRow("Inactivity days", currentArchive.inactivityDays, "table-column-multiline"),
                getInfoTableRow("Count", currentArchive.count, "table-column-multiline"),
                getInfoTableRow("Finished", formatTime(currentArchive.finished), "table-column-multiline"),
                getInfoTableRow("Result", capitalizeFirstLetter(currentArchive.result), "table-column-multiline"),
                getInfoTableRow("Finish count", currentArchive.finishCount, "table-column-multiline"),
                getInfoTableRow("Finished By", currentArchive.finishedBy, "table-column-multiline"))));
    }
}
export default ArchiveUsersDialog;
