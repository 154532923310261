import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { HttpClient } from "../../utils/HttpClient";
import { appendBreadcrumb } from "../../actions/Actions";
import CircularProgress from "material-ui/CircularProgress";
import Message from "./Message";
import { Card, CardText, CardActions } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import UploadIcon from "material-ui/svg-icons/editor/publish";
import { Typography, Grid } from "@material-ui/core";
import ConfirmDialog from "./ConfirmDialog";
import ErrorListDialog from "./ErrorListDialog";
import Toggle from "material-ui/Toggle";
import { withRouter } from '../../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class NotificationInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            messages: [
                { lang: "ro", body: "", title: "" },
                { lang: "en", body: "", title: "" },
                { lang: "ru", body: "", title: "" },
            ],
            phones: [],
            userCount: 0,
            confirmDialogOpen: false,
            errorPhones: [],
            savedId: null,
            isAllUsers: false,
        };
        const currId = this.props.match.params.id;
        if (currId === "new") {
            this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("New message", "/notifications/" + currId)]));
        }
    }
    componentDidMount() {
        const currId = this.props.match.params.id;
        if (currId !== "new") {
            this.fetchNotificationInfo();
        }
    }
    render() {
        const { loading, messages, isAllUsers } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(Card, { className: "card-998" },
                React.createElement(CardText, null,
                    React.createElement(Typography, null, "Upload user list"),
                    React.createElement(Grid, { container: true, justify: "flex-start", direction: "row", alignItems: "center" },
                        React.createElement(Grid, { item: true, md: 10 },
                            React.createElement(RaisedButton, { label: "Upload file", className: "info-card-raisedbutton", style: { marginLeft: 0 }, icon: React.createElement(UploadIcon, null), primary: true, containerElement: "label", disabled: isAllUsers },
                                React.createElement("input", { id: "file-upload", type: "file", style: { display: "none" }, onChange: this.uploadUserPhones.bind(this) })),
                            React.createElement("span", { style: { marginLeft: 10 } }, this.state.phones.length > 0 ? "Read " + this.state.phones.length + " phones" : ""),
                            React.createElement("span", { style: { marginLeft: 10 } }, this.state.userCount > 0 ? "Uploaded " + this.state.userCount + " user phones" : "")),
                        React.createElement(Grid, { item: true, md: 2 },
                            React.createElement(Toggle, { label: "All users", labelStyle: { fontSize: 16, paddingLeft: 15 }, toggled: isAllUsers, onToggle: this.toggleAllUsers.bind(this) }))),
                    React.createElement(Message, { message: messages[0], handleChange: this.handleTextChange.bind(this) }),
                    React.createElement(Message, { message: messages[2], handleChange: this.handleTextChange.bind(this) }),
                    React.createElement(Message, { message: messages[1], handleChange: this.handleTextChange.bind(this) })),
                React.createElement(CardActions, { className: "info-page-cardactions" },
                    React.createElement(RaisedButton, { label: "Discard", className: "info-card-raisedbutton", onClick: this.isUpdated() ? this.toggleConfirmDialog.bind(this) : this.goToNotificationsList.bind(this) }),
                    React.createElement(RaisedButton, { label: this.isUpdated() ? "Save" : "Next", secondary: true, className: "info-card-raisedbutton", disabled: this.isSaveBtnDisabled(), onClick: this.isUpdated() ? this.saveNotification.bind(this) : () => this.goToSummary(this.props.match.params.id) })),
                React.createElement(ConfirmDialog, { opened: this.state.confirmDialogOpen, onCancelClick: this.toggleConfirmDialog.bind(this), onOkClick: this.goToNotificationsList.bind(this), title: "Discard changes?", content: `All unsaved data will be lost`, isActioning: false }),
                React.createElement(ErrorListDialog, { opened: this.state.errorPhones.length > 0, onOkClick: this.closeErrorDialog.bind(this), title: this.state.savedId ? "Errors in loading user phones" : "Errors in reading phones", content: this.state.errorPhones })));
        }
    }
    toggleAllUsers() {
        this.setState((state) => ({
            isAllUsers: !state.isAllUsers,
        }));
    }
    closeErrorDialog() {
        if (this.state.savedId) {
            this.goToSummary(this.state.savedId);
        }
        else {
            this.setState({
                errorPhones: [],
            });
        }
    }
    toggleConfirmDialog() {
        this.setState((state) => ({
            confirmDialogOpen: !state.confirmDialogOpen,
        }));
    }
    goToNotificationsList() {
        this.props.history("/notifications");
    }
    goToSummary(id) {
        this.props.history(`/notifications/${id}/summary`);
    }
    isSaveBtnDisabled() {
        const { messages } = this.state;
        const messageRo = messages[0];
        return !messageRo.title ||
            messageRo.title === "" ||
            !messageRo.body ||
            messageRo.body === "" ||
            messageRo.body.replace(/<(.|\n)*?>/g, "").trim().length === 0;
    }
    saveNotification() {
        this.setState({
            loading: true,
        });
        const currId = this.props.match.params.id;
        const { messages } = this.state;
        let url = "admin/notification/create";
        if (currId !== "new") {
            url = "admin/notification/change/" + currId;
        }
        const postData = JSON.stringify({
            title_ro: messages[0].title,
            title_en: messages[1].title,
            title_ru: messages[2].title,
            body_ro: messages[0].body,
            body_en: messages[1].body,
            body_ru: messages[2].body,
            phones: this.state.phones,
            allUsers: this.state.isAllUsers,
        });
        HttpClient.post(url, postData)
            .then((result) => {
            const resultJson = JSON.parse(result);
            const errorsObj = resultJson.error_phones;
            const errorsArr = [];
            for (const key in errorsObj) {
                if (errorsObj.hasOwnProperty(key)) {
                    errorsArr.push("" + key + ": " + errorsObj[key]);
                }
            }
            if (errorsArr.length === 0) {
                this.goToSummary(resultJson.id);
            }
            else {
                this.setState({
                    loading: false,
                    errorPhones: errorsArr,
                    savedId: resultJson.id,
                });
            }
        })
            .catch((err) => {
            this.setState({
                loading: false,
            });
            ReactToastify.toast.error("Can't save message. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.data));
        });
    }
    fetchNotificationInfo() {
        this.setState({
            loading: true,
        });
        const { match: { params: { id } }, baseUrl, dispatch } = this.props;
        HttpClient.getJSON(`${baseUrl}/admin/notification/load/${id}`)
            .then((result) => {
            const fetchedMessages = [
                { lang: "ro", title: result.title_ro, body: result.body_ro },
                { lang: "en", title: result.title_en, body: result.body_en },
                { lang: "ru", title: result.title_ru, body: result.body_ru },
            ];
            this.fetchedMessages = [...fetchedMessages];
            this.setState({
                loading: false,
                messages: fetchedMessages,
                userCount: result.userCount,
            });
            dispatch(appendBreadcrumb([getBreadcrumbObject(result.title_ro, "/notifications/" + id)]));
            if (result.status !== "CREATED") {
                this.goToSummary(id);
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load notification data. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
            this.setState({
                loading: false,
            });
        });
    }
    handleTextChange(lang, name, value) {
        const newMessages = this.state.messages;
        switch (lang) {
            case "ru":
                newMessages[2] = Object.assign(Object.assign({}, newMessages[2]), { [name]: value });
                break;
            case "en":
                newMessages[1] = Object.assign(Object.assign({}, newMessages[1]), { [name]: value });
                break;
            default:
                newMessages[0] = Object.assign(Object.assign({}, newMessages[0]), { [name]: value });
        }
        this.setState({
            messages: newMessages,
        });
    }
    uploadUserPhones(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result.toString();
            const lines = result.split(/[\s;,]+/).filter((phone) => phone !== "");
            const resultPhones = [];
            const errors = [];
            for (const i in lines) {
                if (i) {
                    const currLine = lines[i];
                    if (!/^\d+$/.test(currLine)) {
                        errors.push("Line-" + i + ": " + currLine + ": number should contain digits only!");
                    }
                    else {
                        resultPhones.push(currLine);
                    }
                }
            }
            this.setState({
                phones: resultPhones,
                errorPhones: errors,
            });
        };
        if (file) {
            reader.readAsText(file);
        }
    }
    isUpdated() {
        if (!this.fetchedMessages) {
            return true;
        }
        const { messages, phones, isAllUsers } = this.state;
        const fetchedMessages = this.fetchedMessages;
        return messages[0].title !== fetchedMessages[0].title ||
            messages[1].title !== fetchedMessages[1].title ||
            messages[2].title !== fetchedMessages[2].title ||
            messages[0].body !== fetchedMessages[0].body ||
            messages[1].body !== fetchedMessages[1].body ||
            messages[2].body !== fetchedMessages[2].body ||
            phones.length !== 0 || isAllUsers;
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(NotificationInfo));
