import * as React from "react";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, } from "../../utils/DataHelpers";
import { Card, CardActions, CardText } from "material-ui/Card";
import Table, { TableBody } from "material-ui/Table";
import { Divider } from "material-ui";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../../styles/Processing.css";
class ProcessingDetails extends React.Component {
    render() {
        const { amount, clientId, lComission, hComission, lastTried, businessTitle, nextTry, txnId, service, repetitions, wallet, business, serviceUuid, } = this.props.data;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false, className: "border-none" },
                        getInfoTableRowWithLink("Wallet", formatPhone(wallet), `/buyer/${wallet}/info`),
                        getInfoTableRow("Last tried", formatTime(lastTried)),
                        getInfoTableRow("Next try", formatTime(nextTry)),
                        getInfoTableRow("Repeats", repetitions))),
                React.createElement(Divider, null),
                React.createElement(Table, { selectable: false, className: "border-none" },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Amount", formatCurrency(amount)),
                        hComission &&
                            getInfoTableRow("High commission", formatCurrency(hComission)),
                        lComission &&
                            getInfoTableRow("Low commission", formatCurrency(lComission)),
                        getInfoTableRowWithLink("Business", businessTitle, `/business/${business}/info`),
                        getInfoTableRowWithLink("Service", service, `/services/${serviceUuid}/info`),
                        getInfoTableRow("Client ID", clientId),
                        getInfoTableRow("Txn ID", txnId)))),
            this.renderCardActions()));
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" }, this.renderCloseButton()));
    }
    renderCloseButton() {
        return (React.createElement(Link, { to: `/processing` },
            React.createElement(Button, { className: "bg-o mb-10", variant: "outlined" }, "Back")));
    }
}
export default ProcessingDetails;
