import * as React from "react";
import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import * as moment from "moment";
import { maxInputLength } from "../../variables/Common";
import { InlineDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { BuyerDocTypes } from "../../interfaces/Buyer";
class BuyerPassportDialog extends React.Component {
    get renderDialogActions() {
        const { passportSeries, issueDate, expDate, issuingAuthority, docType } = this.state;
        return [
            React.createElement("div", { id: "buyer-passport-dialog" },
                React.createElement(FlatButton, { label: "Close", primary: true, onClick: this.props.toggleDialog.bind(this) }),
                React.createElement(FlatButton, { label: "Submit", primary: true, onClick: () => this.props.onUpdateClick(passportSeries, issueDate, expDate, issuingAuthority, docType), disabled: this.isButtonDisabled() }))
        ];
    }
    constructor(props) {
        super(props);
        const { expDate, passportSeries, issueDate, issuingAuthority, docType } = this.props;
        this.state = {
            expDate: expDate,
            passportSeries: passportSeries || "",
            issueDate: issueDate,
            issuingAuthority: issuingAuthority || "",
            passportSeriesError: "",
            docType: docType,
        };
    }
    render() {
        const { passportSeries, expDate, issueDate, issuingAuthority, passportSeriesError, docType } = this.state;
        return (React.createElement(Dialog, { title: "Passport Details", actions: this.renderDialogActions, modal: false, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) },
            React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils, locale: moment.locale(), moment: moment },
                React.createElement("div", { className: "commission-form-row", key: "first-row" },
                    React.createElement(SelectField, { id: "buyer-doc-type", hintText: "Select document type", onChange: (e, i, v) => this.handleDocTypeChange(e, i, v), value: docType, floatingLabelText: "Document type", style: { minWidth: 400 }, errorText: (!docType && docType !== 0) ? "Document Type must not be empty!" : "" }, this.renderMenuItems()),
                    React.createElement(TextField, { name: "passportSeries", id: "passportSeries", value: passportSeries, floatingLabelText: "Passport series and number", onChange: (e) => this.handlePassportSeriesChange(e.target.value), errorText: passportSeriesError })),
                React.createElement("div", { className: "commission-form-row", key: "second-row" },
                    React.createElement(InlineDatePicker, { format: "DD.MM.YYYY", id: "issuepassportdate", autoOk: true, disableFuture: true, keyboard: true, clearable: true, KeyboardButtonProps: {
                            disabled: true,
                        }, style: { marginTop: 16 }, label: "Passport date of issue:", mask: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/], onChange: this.handleIssueDateChange.bind(this), value: issueDate || null }),
                    React.createElement(InlineDatePicker, { format: "DD.MM.YYYY", id: "passportExpirationDate", autoOk: true, disablePast: true, keyboard: true, clearable: true, KeyboardButtonProps: {
                            disabled: true,
                        }, style: { marginTop: 16 }, label: "Passport expiration date:", mask: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/], onChange: this.handleExpirationDateChange.bind(this), value: expDate || null })),
                React.createElement(TextField, { fullWidth: true, id: "issuingAuthority", name: "issuingAuthority", value: issuingAuthority, floatingLabelText: "Passport issuing authority", onChange: (e) => this.setState({ issuingAuthority: e.target.value }) }))));
    }
    renderMenuItems() {
        const result = [];
        for (const [typeKey, typeDecr] of BuyerDocTypes()) {
            result.push(React.createElement(MenuItem, { key: typeKey, insetChildren: true, value: typeKey, primaryText: typeDecr, innerDivStyle: { paddingLeft: 10 } }));
        }
        return result;
    }
    handlePassportSeriesChange(passportSeries) {
        this.setState({
            passportSeries,
            passportSeriesError: passportSeries.length > maxInputLength
                ? "Message too long"
                : "",
        });
    }
    handleDocTypeChange(event, index, value) {
        this.setState({
            docType: value,
        });
    }
    handleExpirationDateChange(date) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { expDate: date })));
    }
    handleIssueDateChange(date) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { issueDate: date })));
    }
    isButtonDisabled() {
        const { expDate, passportSeries, issueDate, issuingAuthority, docType } = this.state;
        if (!docType && docType !== 0) {
            return true;
        }
        const previous = this.props;
        const hasExpDateChanged = expDate !== previous.expDate;
        const hasPassportSeriesChanged = passportSeries !== previous.passportSeries && passportSeries;
        const hasIssuingAuthorityChanged = issuingAuthority !== previous.issuingAuthority && issuingAuthority;
        const hasIssueDateChanged = issueDate !== previous.issueDate;
        const hasDocTypeChanged = docType !== previous.docType;
        return !(hasExpDateChanged || hasPassportSeriesChanged || hasIssueDateChanged || hasIssuingAuthorityChanged || hasDocTypeChanged);
    }
}
export default BuyerPassportDialog;
