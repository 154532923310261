export var TxType;
(function (TxType) {
    TxType["SPEND"] = "spend";
    TxType["SUPPLY"] = "supply";
    TxType["P2P"] = "p2p";
    TxType["WITHDRAW"] = "withdraw";
    TxType["CBC"] = "CBC";
})(TxType || (TxType = {}));
export var TxState;
(function (TxState) {
    TxState["EXECUTED"] = "executed";
    TxState["REVERTED"] = "reverted";
    TxState["FAILED"] = "failed";
})(TxState || (TxState = {}));
