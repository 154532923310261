import * as React from "react";
import { formatAmount, formatTime, getName, getTableColumnAction, getTableColumnItem, } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import SmartDataTable from "../SmartDataTable";
import RaisedButton from "material-ui/RaisedButton";
import { IconMenu, MenuItem } from "material-ui";
import More from "material-ui/svg-icons/navigation/more-vert";
import Delete from "material-ui/svg-icons/action/delete";
import Edit from "material-ui/svg-icons/editor/mode-edit";
class CashoutBusinessOpenList extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("companyName", "Organization", organization => organization, false),
            getTableColumnItem("amount", "Amount", (amount) => formatAmount(amount) + " MDL", false),
            getTableColumnItem("percent", "Commission", percent => (percent ? percent + " %" : ""), false),
            getTableColumnItem("operator", "Operator", (operator, all) => this.formatNameAndSurname(all), false),
            getTableColumnItem("whenCreated", "When created", (date) => formatTime(date), false),
            getTableColumnAction("id", (name, archiveInfo) => (this.checkRoles() && this.renderIconMenu(archiveInfo))),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons(this.props.emptyCashout), showHeaderToolbarFilterIcon: false }));
    }
    componentDidMount() {
        this.props.fetchData();
    }
    formatNameAndSurname(all) {
        const { operator } = all;
        const result = (operator || "");
        return getName(result);
    }
    checkRoles() {
        const { roles } = this.props;
        return (roles.indexOf("Admin") !== -1 || roles.indexOf("Support") !== -1);
    }
    getToolbarIcons(emptyCashout) {
        return [
            React.createElement("div", { className: "add-btn-wrapper" }, this.checkRoles() && React.createElement(RaisedButton, { label: "New withdrawal", onClick: () => this.props.openNewRequestDialog(emptyCashout), primary: true })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
    renderIconMenu(items) {
        return (React.createElement(IconMenu, { iconButtonElement: React.createElement(IconButton, { id: "more-button" },
                React.createElement(More, null)) },
            React.createElement(MenuItem, { leftIcon: React.createElement(Edit, null), primaryText: "Edit", onClick: () => this.props.openNewRequestDialog(items) }),
            React.createElement(MenuItem, { leftIcon: React.createElement(Delete, null), primaryText: "Delete", onClick: () => this.props.toggleDeleteBusinessDialog(items) })));
    }
}
export default CashoutBusinessOpenList;
