import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl, } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { Card } from "material-ui/Card";
import { HttpClient } from "../utils/HttpClient";
import { Tabs, Tab } from "material-ui";
import CashInBusinessArchiveTable from "../components/CashInRequests/CashInBusinessArchiveTable";
import CashInBusinessOpenList from "../components/CashInRequests/CashInBusinessOpenList";
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Active"] = 0] = "Active";
    TabNames[TabNames["Archive"] = 1] = "Archive";
})(TabNames || (TabNames = {}));
class CashInRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cashinRequests: [],
            selectedTab: TabNames.Active,
            isLoading: false,
            archiveFilter: {
                company: "",
                amount: "",
                status: "any",
            },
            rowSize: 20,
            count: 0,
            currentPage: 1,
            orderKey: "",
            order: "",
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("CashIn requests", "/cash-in-requests")]));
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            React.createElement(Card, { className: "card-full-size cash-in-content" }, this.renderDataTable())));
    }
    fetchActiveCashin() {
        const { baseUrl } = this.props;
        this.setState({ isLoading: true });
        const defaultErrorMessage = "Error while receiving active cashin requests!";
        HttpClient.getJSON(`${baseUrl}/cashin/company/requests/active`)
            .then((response) => {
            if (response && response.status && response.status.code === "ok") {
                this.setState({ cashinRequests: response.body || [], isLoading: false });
            }
            else {
                ReactToastify.toast.info(defaultErrorMessage);
                this.setState({ isLoading: false });
            }
        }).catch((err) => {
            ReactToastify.toast.error(defaultErrorMessage + " Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({ isLoading: false });
        });
    }
    fetchArchiveCashin() {
        const { baseUrl } = this.props;
        const { archiveFilter: { company, amount, status }, currentPage, order, orderKey, rowSize } = this.state;
        const query = {
            search: company,
            status: status,
        };
        this.setState({ isLoading: true });
        const defaultErrorMessage = "Error while receiving archive cashin requests!";
        HttpClient.getJSON(`${baseUrl}/cashin/company/requests/archive`, query)
            .then((response) => {
            if (response && response.status && response.status.code === "ok") {
                this.setState({ cashinRequests: response.body || [], isLoading: false });
            }
            else {
                ReactToastify.toast.info(defaultErrorMessage);
                this.setState({ isLoading: false });
            }
        }).catch((err) => {
            ReactToastify.toast.error(defaultErrorMessage + " Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({ isLoading: false });
        });
    }
    handleChangeFilter(name, value) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { archiveFilter: Object.assign(Object.assign({}, state.archiveFilter), { [name]: value }) })));
    }
    handleChangeTableOptions(name, value) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { [name]: value })), () => {
            this.fetchArchiveCashin();
        });
    }
    renderTabs() {
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "250px" } },
                React.createElement(Tab, { label: "Active", value: TabNames.Active }),
                React.createElement(Tab, { label: "Archive", value: TabNames.Archive }))));
    }
    renderDataTable() {
        const { selectedTab, isLoading, cashinRequests, archiveFilter, rowSize, count, currentPage, orderKey, order } = this.state;
        const { roles, baseUrl } = this.props;
        if (selectedTab === TabNames.Active) {
            return (React.createElement(CashInBusinessOpenList, { data: cashinRequests, fetchData: this.fetchActiveCashin.bind(this), roles: roles, baseUrl: baseUrl, isLoading: isLoading }));
        }
        else if (selectedTab === TabNames.Archive) {
            return (React.createElement(CashInBusinessArchiveTable, { data: cashinRequests, roles: roles, filter: archiveFilter, fetchData: this.fetchArchiveCashin.bind(this), baseUrl: baseUrl, isLoading: isLoading, rowSize: this.state.rowSize, count: this.state.count, currentPage: this.state.currentPage, orderKey: this.state.orderKey, order: this.state.order, handleChangeFilter: this.handleChangeFilter.bind(this), handleChangeTableOptions: this.handleChangeTableOptions.bind(this) }));
        }
    }
}
export default connect(mapStateToPropsBaseUrl)(CashInRequests);
