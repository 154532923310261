import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
class BlockBuyerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            reasonMsg: "",
        };
        this.state = this.initialState;
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.opened) {
            this.setState(this.initialState);
        }
    }
    render() {
        return (React.createElement(Dialog, { title: "Block Wallet", actions: this.getDialogActions(), modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) },
            "Are you sure you want to block current wallet?",
            React.createElement(TextField, { id: "reason-message", fullWidth: true, onChange: (e) => this.setState({ reasonMsg: e.target.value }), floatingLabelText: "Enter reason message", hintText: "Example: Client initiative" })));
    }
    getDialogActions() {
        return [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: () => this.props.toggleDialog() }),
            React.createElement(FlatButton, { label: "Block", primary: true, onClick: () => this.props.onBlockBtnClick(this.state.reasonMsg), disabled: this.getBlockBtnState() }),
        ];
    }
    getBlockBtnState() {
        return !this.state.reasonMsg;
    }
}
export default BlockBuyerDialog;
