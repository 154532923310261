import * as React from "react";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { isNullOrUndefined } from "util";
import { POSType } from "../../variables/Common";
class POSTypeSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.posTypes = [
            POSType.DEFAULT,
            POSType.TRANSPORT,
        ];
        this.state = {
            posType: this.getPosType(),
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.posType !== nextProps.type) {
            this.setState({ posType: nextProps.type });
        }
    }
    render() {
        return (React.createElement(SelectField, { id: "select-pos-type", floatingLabelText: "POS Type", className: this.props.className, hintText: "Select a type", value: this.state.posType, multiple: false, onChange: (e, i, v) => this.handleTypeChange(v) },
            React.createElement(MenuItem, { value: this.posTypes[0], primaryText: "Default" }),
            React.createElement(MenuItem, { value: this.posTypes[1], primaryText: "Public Transport" })));
    }
    handleTypeChange(value) {
        this.setState({ posType: value });
        this.props.setPosType(value);
    }
    getPosType() {
        return isNullOrUndefined(this.props.type) ? POSType.DEFAULT : this.props.type;
    }
}
export default POSTypeSelectField;
