import * as React from "react";
import { HttpClient } from "../../utils/HttpClient";
import ClearIcon from "material-ui/svg-icons/navigation/close";
import { GridTile } from "material-ui";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { base64ToArrayBuffer, createAndDownloadBlobFile, fromParamsUrlList } from "./CashinUtils";
const ReactToastify = require("react-toastify");
class CashinFileLoader extends React.Component {
    constructor(props) {
        super(props);
        this.fetchFilesList = () => {
            const { baseUrl, fileIdList } = this.props;
            if (!fileIdList || !fileIdList.length) {
                return;
            }
            const urlParams = fromParamsUrlList("id", fileIdList);
            this.setState({
                isLoading: true,
            });
            const defaultErrorMessage = "Error while getting the list files for cashin";
            HttpClient.getJSON(`${baseUrl}/files/list?${urlParams}`).then((response) => {
                const status = response && response.status;
                if (status && status.code === "ok") {
                    this.setState({ fileList: response.body || [] });
                }
                else {
                    ReactToastify.toast.info(defaultErrorMessage);
                }
                this.setState({
                    isLoading: false,
                });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isLoading: false,
                });
            });
        };
        this.uploadFile = (file) => {
            const { baseUrl, addFileId } = this.props;
            if (!file) {
                return;
            }
            const query = {
                name: file.name,
                type: file.type,
            };
            const multiPartData = new FormData();
            multiPartData.append("upload", file);
            this.setState({
                isUploading: true,
            });
            const defaultErrorMessage = `Error while upload file ${query.name} for cashin`;
            HttpClient.postMultiPart(`${baseUrl}/files/upload?name=${query.name}&type=${query.type}&note=cashin_request`, multiPartData).then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    this.setState({
                        fileList: [
                            ...this.state.fileList,
                            resp.body,
                        ],
                    });
                    addFileId(resp.body && resp.body.id);
                }
                else {
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
                this.setState({
                    isUploading: false,
                });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isUploading: false,
                });
            });
        };
        this.downloadFile = (file) => {
            const { baseUrl } = this.props;
            if (!file) {
                return;
            }
            this.setState({
                isDownloading: true,
                selectedId: file.id,
            });
            HttpClient.getByType(`${baseUrl}${file.url}`).then((response) => {
                if (!response) {
                    ReactToastify.toast.error(`Failed to download file ${file.label}`);
                }
                createAndDownloadBlobFile(base64ToArrayBuffer(response), file.label, file.type);
                this.setState({
                    isDownloading: false,
                    selectedId: "",
                });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isUploading: false,
                });
            });
        };
        this.removeFileById = (id) => {
            const { baseUrl, deleteFile } = this.props;
            this.setState({
                isDeleting: true,
                selectedId: id,
            });
            const fileList = [...this.state.fileList];
            const defaultErrorMessage = `Error while deleting file for cashin`;
            HttpClient.post(`${baseUrl}/files/${id}/delete`).then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    deleteFile(id);
                    ReactToastify.toast.success("File is deleted");
                }
                else {
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
                this.setState({
                    isDeleting: false,
                });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isDeleting: false,
                });
            });
        };
        this.onDrop = (files) => {
            if (files && files.length) {
                files.forEach((file) => this.uploadFile(file));
            }
        };
        this.handlePaste = (e) => {
            const file = e.clipboardData && e.clipboardData.items && e.clipboardData.items[0].getAsFile();
        };
        this.state = {
            selectedId: "",
            fileList: [],
            isLoading: false,
            isDeleting: false,
            isUploading: false,
            isDownloading: false,
        };
    }
    componentDidMount() {
        this.fetchFilesList();
    }
    componentWillReceiveProps(nextProps) {
        if ((this.state.fileList && (this.state.fileList || []).length) !== nextProps.fileIdList.length) {
            this.fetchFilesList();
        }
    }
    renderViewFileList() {
        const { isEditable, baseUrl } = this.props;
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            return (fileList.map((file, i) => {
                return (React.createElement("div", { style: { backgroundColor: "#f5f5f5", marginBottom: 10 } },
                    React.createElement("ul", { style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: 0,
                            padding: 10,
                        } },
                        React.createElement("div", { style: { display: "flex" } },
                            React.createElement("a", { style: { color: "#0921CB", fontWeight: 500 }, target: "_blank", href: `${baseUrl + file.url}` },
                                file && file.label,
                                " "),
                            React.createElement("div", { style: { color: "#7a7a7a", marginLeft: 10 } },
                                "(",
                                (file.size / 1024).toFixed(2),
                                " KB)")),
                        isEditable && React.createElement(ClearIcon, { onClick: () => this.removeFileById(file.id), key: i, style: { color: "#7a7a7a", cursor: "pointer" } }))));
            }));
        }
        else {
            return (React.createElement(GridTile, null));
        }
    }
    renderDropZone() {
        return (React.createElement("div", { onPaste: (e) => this.handlePaste(e), style: { display: "flex", flexWrap: "nowrap", width: "100%" } },
            React.createElement(Dropzone, { onDrop: this.onDrop.bind(this), disableClick: true, style: {
                    position: "relative",
                    width: "100%",
                    height: 110,
                    borderWidth: 2,
                    borderColor: "rgb(102, 102, 102)",
                    borderStyle: "dashed",
                    borderRadius: 5,
                } }, ({ open }) => (React.createElement("div", { style: { textAlign: "center" } },
                React.createElement("p", { style: { fontSize: 14 } }, "Drop file here "),
                React.createElement("p", { style: { fontSize: 14 } }, "Or"),
                React.createElement("button", { type: "button", onClick: () => open(), style: { fontSize: 14 } }, "Open File Dialog"))))));
    }
    render() {
        const { isEditable } = this.props;
        return (React.createElement(React.Fragment, null,
            this.renderViewFileList(),
            isEditable && this.renderDropZone()));
    }
}
export default connect(mapStateToPropsBaseUrl)(CashinFileLoader);
