import * as React from "react";
import { formatPhone, formatTime, getName, getTableColumnItem } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import SmartDataTable from "../SmartDataTable";
import Card from "material-ui/Card/Card";
import AddNewSellerDialog from "./AddNewSellerDialog";
import More from "material-ui/svg-icons/navigation/more-vert";
import Delete from "material-ui/svg-icons/action/delete";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";
import { setChosenSeller } from "../../actions/Actions";
import DeleteSellerDialog from "./UnlinkSellerDialog";
import RaisedButton from "material-ui/RaisedButton";
import { HttpClient } from "../../utils/HttpClient";
import { Link } from "react-router-dom";
const ReactToastify = require("react-toastify");
class POSSellers extends React.Component {
    constructor(props) {
        super(props);
        this.sellersTableColumns = [
            getTableColumnItem("phone", "ID", (phone) => React.createElement(Link, { to: `/seller/${phone}/info` }, formatPhone(phone))),
            getTableColumnItem("name", "Name", (name) => getName(name)),
            getTableColumnItem("lastSeen", "Last Seen", (time) => formatTime(time)),
            getTableColumnItem("created", "Created", (time) => formatTime(time)),
        ];
        this.initialState = {
            addDialogOpened: false,
            sellerPhone: "",
            errorText: "",
            unlinkDialogOpened: false,
        };
        this.state = this.initialState;
    }
    get renderIcons() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { onClick: this.toggleAddNewSellerDialog(), label: "ADD SELLER", primary: true })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(AddNewSellerDialog, { opened: this.state.addDialogOpened, toggleDialog: this.toggleAddNewSellerDialog(), onAddBtnClick: this.addNewSeller.bind(this) }),
            React.createElement(DeleteSellerDialog, { opened: this.state.unlinkDialogOpened, toggleDialog: this.toggleUnlinkSellerDialog(), unlinkSeller: this.unlinkSeller.bind(this) }),
            React.createElement(Card, { className: "card-998" }, this.renderSellersTable())));
    }
    unlinkSeller() {
        HttpClient.delete(`${this.props.baseUrl}/business/${this.props.match.params.id}` +
            `/pos/${this.props.match.params.externalId}/sellers/${this.props.sellerPhone}`)
            .then((result) => {
            ReactToastify.toast.info("Seller was successfully unlinked!");
            this.props.fetchData();
        })
            .catch((err) => {
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("Can't unlink seller. Invalid phone / POS ID");
                    break;
                case 422:
                    ReactToastify.toast.error("Can't unlink seller. Seller and POS have no link");
                    break;
                default:
                    ReactToastify.toast.error("Can't unlink seller.");
                    break;
            }
        });
    }
    addNewSeller(phone, name) {
        const data = JSON.stringify({ phone, name });
        this.setState({ addDialogOpened: false });
        HttpClient
            .post(`${this.props.baseUrl}/business/${this.props.match.params.id}/pos/${this.props.match.params.externalId}` +
            `/sellers`, data)
            .then((result) => {
            ReactToastify.toast.success("New seller was successfully added!");
            this.props.fetchData();
        })
            .catch((err) => {
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("POS or Business doesn't exist.");
                    break;
                case 409:
                    ReactToastify.toast.error("Seller already exists.");
                    break;
                default:
                    ReactToastify.toast.error("Smth went wrong! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
    toggleAddNewSellerDialog() {
        return () => this.setState({ addDialogOpened: !this.state.addDialogOpened });
    }
    toggleUnlinkSellerDialog() {
        return () => this.setState({ unlinkDialogOpened: !this.state.unlinkDialogOpened });
    }
    renderSellersTable() {
        return (React.createElement(SmartDataTable, { data: this.props.sellersData, columns: this.sellersTableColumns, toolbarIconRight: this.renderIcons }));
    }
    renderIconMenu(phone) {
        return (React.createElement(IconMenu, { iconButtonElement: React.createElement(IconButton, null,
                React.createElement(More, null)) },
            React.createElement(MenuItem, { leftIcon: React.createElement(Delete, null), primaryText: "Delete", onClick: () => {
                    this.setState({ unlinkDialogOpened: true });
                    this.props.dispatch(setChosenSeller(phone));
                } })));
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    sellerPhone: state.chosenSeller,
});
export default connect(mapStateToProps)(POSSellers);
