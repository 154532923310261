import * as React from "react";
import Card from "material-ui/Card/Card";
import SmartDataTable from "../SmartDataTable";
import { formatPhone, formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
export default class POSShifts extends React.Component {
    constructor() {
        super(...arguments);
        this.shiftsTableColumns = [
            getTableColumnItem("started", "Started", (time) => formatTime(time)),
            getTableColumnItem("stopped", "Stopped", (time) => formatTime(time)),
            getTableColumnItem("seller", "Seller", (seller) => formatPhone(seller)),
            getTableColumnItem("balance", "Balance", (balance) => React.createElement("span", null, balance + " MDL")),
            getTableColumnItem("status", "Status"),
        ];
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Card, { className: "card-full-size" }, this.renderShiftsDataTable())));
    }
    renderShiftsDataTable() {
        return (React.createElement(SmartDataTable, { data: this.props.shiftsData, columns: this.shiftsTableColumns, toolbarIconRight: this.renderUpdateIcon }));
    }
    get renderUpdateIcon() {
        return (React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
            React.createElement(Refresh, null)));
    }
}
