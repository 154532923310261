import * as React from "react";
import CircularProgress from "material-ui/CircularProgress";
import { Card, CardActions, CardText, CardTitle } from "material-ui";
import Toggle from "material-ui/Toggle";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import TimePicker from "material-ui/TimePicker";
import Clear from "material-ui/svg-icons/content/clear";
import IconButton from "material-ui/IconButton";
import * as moment from "moment";
import * as lodash from "lodash";
import { HttpClient } from "../../utils/HttpClient";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import { DataValidator } from "../../utils/DataValidator";
import { isNullOrUndefined } from "util";
import { Typography } from "@material-ui/core";
const ReactToastify = require("react-toastify");
class Maintenance extends React.Component {
    constructor(props) {
        super(props);
        this.fetchedOptions = {
            active: false,
            automatically: false,
            duration: "",
            startTime: "",
            startIn: "",
            serverTime: ""
        };
        this.state = Object.assign({ loading: true, durationError: "" }, this.fetchedOptions);
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement("div", null,
                this.renderManualCard(),
                this.renderAutoCard()));
        }
    }
    fetchData() {
        this.fetchedOptions = {
            active: false,
            automatically: false,
            duration: "",
            startTime: "",
            startIn: "",
            serverTime: ""
        };
        HttpClient.getJSON(`${this.props.baseUrl}/params/maintenance`).then((result) => {
            if (!lodash.isEmpty(result)) {
                Object.assign(this.fetchedOptions, result, {
                    active: !!result.active,
                    automatically: !!result.automatically,
                });
            }
            this.setState(Object.assign({ loading: false, active: false }, this.fetchedOptions));
        }).catch((err) => {
            ReactToastify.toast.error("Can't load maintenance mode options! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    setOptions() {
        const { automatically, duration, startTime } = this.state;
        const putData = JSON.stringify({
            automatically,
            duration: automatically ? Number(duration) : Number(this.fetchedOptions.duration),
            startTime: automatically ? Number(startTime || 0) : Number(this.fetchedOptions.startTime),
        });
        HttpClient.putWithData(`${this.props.baseUrl}/params/maintenance`, putData).then(() => {
            ReactToastify.toast.info("New maintenance mode options were successfully saved!");
            this.fetchData();
        }).catch((err) => {
            ReactToastify.toast.error("Can't save maintenance mode options! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    startMaintenanceMode() {
        HttpClient.patchWithData(`${this.props.baseUrl}/params/maintenance/start`).then(() => {
            ReactToastify.toast.info("Maintenance mode was successfully started!");
            this.fetchData();
        }).catch((err) => {
            ReactToastify.toast.error("Can't start maintenance mode! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    finishMaintenanceMode() {
        HttpClient.patchWithData(`${this.props.baseUrl}/params/maintenance/finish`).then(() => {
            ReactToastify.toast.info("Maintenance mode was successfully finished!");
            this.fetchData();
        }).catch((err) => {
            ReactToastify.toast.error("Can't finish maintenance mode! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderManualCard() {
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardTitle, { title: "Maintenance mode", subtitle: React.createElement("span", null,
                    "Currently maintenance mode is ",
                    this.renderActiveStatus(),
                    ".") }),
            React.createElement(CardActions, { className: "info-page-cardactions" }, this.renderActionBtns())));
    }
    renderActionBtns() {
        if (this.state.active) {
            return (React.createElement(RaisedButton, { secondary: true, label: "turn off", onClick: () => this.finishMaintenanceMode() }));
        }
        else {
            return (React.createElement(RaisedButton, { secondary: true, label: "turn on", onClick: () => this.startMaintenanceMode() }));
        }
    }
    renderActiveStatus() {
        return this.state.active ? React.createElement("span", { className: "qiwi-secondary-color" }, "active") :
            React.createElement("span", { className: "qiwi-primary-color" }, "inactive");
    }
    renderAutoCard() {
        const { automatically, duration, durationError, serverTime, active, startIn } = this.state;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardTitle, { title: "Automatic maintenance mode" }),
            React.createElement(CardText, null,
                "Maintenance mode may be set automatically according to given start time and duration.",
                React.createElement(Toggle, { label: "Start maintenance mode automatically at the specified time", toggled: automatically, style: { marginTop: "20px" }, onToggle: () => this.setState({ automatically: !this.state.automatically }) }),
                React.createElement("span", { className: "maintenance-mode-note" }, "Note: Manually started maintenance mode wouldn't be stopped automatically!"),
                serverTime && React.createElement(Typography, { variant: "body2" },
                    "Server time: ",
                    serverTime),
                !active && React.createElement(Typography, { variant: "body2" },
                    "Maintenance mode will start in:",
                    moment.utc(startIn * 1000).format('HH:mm:ss'))),
            React.createElement(CardText, { className: "maintenance-card-auto-options" },
                React.createElement(TextField, { id: "duration-maintenance", floatingLabelText: "Duration", hintText: "Value in minutes", value: duration, errorText: durationError, onChange: (e) => this.handleDurationChange(e.target.value), disabled: !automatically }),
                this.renderStartTimePicker()),
            React.createElement(CardActions, { className: "info-page-cardactions" },
                React.createElement(RaisedButton, { primary: true, label: "reset", onClick: () => this.handleResetBtnClick(), disabled: this.handleResetBtnState() }),
                React.createElement(RaisedButton, { primary: true, disabled: this.handleSaveBtnState(), label: "save", onClick: () => this.handleSaveBtnClick() }))));
    }
    renderStartTimePicker() {
        const { automatically } = this.state;
        return (React.createElement("div", { className: "timepicker-wrapper" },
            React.createElement(TimePicker, { id: "start-time-picker", hintText: "Start time", format: "24hr", autoOk: true, floatingLabelText: "Start time", value: this.getDateFromTimestamp(), onChange: (e, date) => this.handleChangeTimePicker(date, false), disabled: !automatically }),
            React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => this.handleChangeTimePicker(null, true), disabled: !automatically },
                React.createElement(Clear, null))));
    }
    handleChangeTimePicker(date, reset) {
        const formatTime = moment(date).format("HH:mm:ss");
        const secondsTime = moment.duration(formatTime).asSeconds();
        const startTime = reset ? undefined : secondsTime;
        this.setState({ startTime });
    }
    handleDurationChange(duration) {
        DataValidator.numberValidator(duration).then((durationError) => {
            this.setState({ duration, durationError });
        }).catch((durationError) => {
            this.setState({ duration, durationError });
        });
    }
    handleResetBtnClick() {
        this.setState(Object.assign(Object.assign({}, this.fetchedOptions), { durationError: "" }));
    }
    handleSaveBtnClick() {
        const { startTime, automatically, duration } = this.state;
        if (startTime !== this.fetchedOptions.startTime
            || automatically !== this.fetchedOptions.automatically
            || duration.toString() !== this.fetchedOptions.duration.toString()) {
            this.setOptions();
        }
    }
    handleSaveBtnState() {
        const { durationError, automatically, duration, startTime } = this.state;
        if (automatically && (duration.toString() === "" || isNullOrUndefined(startTime) || durationError !== "")) {
            return true;
        }
        else {
            return this.handleResetBtnState();
        }
    }
    handleResetBtnState() {
        const { startTime, automatically, active, duration } = this.state;
        return !(startTime !== this.fetchedOptions.startTime
            || automatically !== this.fetchedOptions.automatically
            || duration.toString() !== this.fetchedOptions.duration.toString());
    }
    getDateFromTimestamp() {
        const { startTime } = this.state;
        const seconds = moment().startOf("day").add(startTime, "seconds").valueOf();
        if (seconds) {
            return new Date(Number(seconds));
        }
        else {
            return undefined;
        }
    }
}
export default connect(mapStateToPropsBaseUrl)(Maintenance);
