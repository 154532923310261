import * as React from "react";
import { formatCurrency, formatTime, getName, getTableColumnItem } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import * as lodash from "lodash";
class CommissionsTable extends React.Component {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("businessTitle", "Business", (p, all) => React.createElement(Link, { to: `/business/${all.businessId}/info/commission` }, getName(all.businessTitle))),
            getTableColumnItem("hiFixed", "High Commission", (p, all) => this.getHiCommissionRow(all), false),
            getTableColumnItem("loFixed", "Low Commission", (p, all) => this.getLowCommissionRow(all), false),
            getTableColumnItem("validFrom", "Valid From", (date) => this.renderValidDate(date)),
            getTableColumnItem("validUntil", "Valid Until", (date) => this.renderValidDate(date)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons() }));
    }
    getHiCommissionRow(commission) {
        if (commission.hiThreshold) {
            return this.getRowWithThreshold(commission.hiAmountBefore, commission.hiAmountAfter, commission.hiThreshold);
        }
        else {
            return this.displayCommissionPart(commission.hiAmountBefore);
        }
    }
    getLowCommissionRow(commission) {
        if (commission.loThreshold) {
            return this.getRowWithThreshold(commission.loAmountBefore, commission.loAmountAfter, commission.loThreshold);
        }
        else {
            return this.displayCommissionPart(commission.loAmountBefore);
        }
    }
    getRowWithThreshold(before, after, threshold) {
        return `${this.displayCommissionPart(before)} < ${formatCurrency(threshold)} < ${this.displayCommissionPart(after)}`;
    }
    displayCommissionPart(part) {
        if (lodash.isEmpty(part) || !part) {
            return "Is not specified";
        }
        else if (part.fixed) {
            return String(part.fixed);
        }
        else {
            return this.displayPercent(part.percent, part.percentDown, part.percentUp);
        }
    }
    displayPercent(percent, down, up) {
        return `${percent}% (
            Min: ${down ? down : ""},
            Max: ${up ? up : ""} )`;
    }
    renderValidDate(date) {
        if (date) {
            return formatTime(date);
        }
        else {
            return "Unlimited";
        }
    }
    getToolbarIcons() {
        return [
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default CommissionsTable;
