import * as React from "react";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import * as lodash from "lodash";
import IconButton from "material-ui/IconButton";
import DataTables from 'material-ui-datatables';
import { formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import { HttpClient } from "../../utils/HttpClient";
const ReactToastify = require("react-toastify");
import CircularProgress from "material-ui/CircularProgress";
class Active extends React.Component {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("id", "ID", (id, all) => React.createElement(Link, { to: `/cbc/${all.id}` }, id)),
            getTableColumnItem("phone", "Telefon"),
            getTableColumnItem("amount", "Suma", (amount) => `${amount} MDL`),
            getTableColumnItem("createdAt", "Creat"),
        ];
        this.componentDidMount = () => {
            this.fetchData();
        };
        this.fetchData = () => {
            this.setState({
                loading: true
            });
            const { baseUrl } = this.props;
            HttpClient.getJSON(`${baseUrl}/cbc/active`).then((result) => {
                if (!lodash.isEmpty(result)) {
                    const changedDate = result.map(item => {
                        return Object.assign(Object.assign({}, item), { createdAt: formatTime(new Date(item.createdAt).getTime()) });
                    });
                    this.setState({
                        data: changedDate
                    });
                }
                this.setState({ loading: false, contentLoading: false });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load system CBC Archive! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({ loading: false, contentLoading: false });
            });
        };
        this.state = {
            data: [],
            loading: true,
            contentLoading: false
        };
    }
    render() {
        const { data, loading } = this.state;
        return (React.createElement("div", null, loading ? (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                top: "50%",
                left: "50%",
                position: "absolute",
                marginLeft: "-60px",
                marginTop: "-60px",
            } })) : (React.createElement(React.Fragment, null,
            React.createElement(IconButton, { onClick: this.fetchData, style: {
                    display: "flex",
                    marginLeft: "auto",
                    marginTop: 15,
                    marginRight: 15
                } },
                React.createElement(Refresh, null)),
            React.createElement(DataTables, { height: 'auto', selectable: false, showRowHover: true, columns: this.tableColumns, data: data, showCheckboxes: false, showFooterToolbar: false })))));
    }
}
export default Active;
