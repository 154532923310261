import * as React from "react";
import { connect } from "react-redux";
import { appendBreadcrumb } from "../../actions/Actions";
import { downloadReport, getBreadcrumbObject, getStartOfDayMillis } from "../../utils/DataHelpers";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import * as moment from "moment";
import { HttpClient } from "../../utils/HttpClient";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { DatePicker } from "material-ui";
const ReactToastify = require("react-toastify");
class CnaSuspiciousTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            date: null,
            fileName: "",
            reportFormat: "csv"
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: 'CSV', fileExt: 'csv' },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("CNA SPCSB", "/reports/cna/info")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        this.setState({ loading: true, generated: false });
        const query = {
            date: getStartOfDayMillis(this.state.date),
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/cna`, query)
            .then((result) => {
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({ loading: false, generated: true, fileName: result.fileName });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create SPCSB report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    renderActionCard() {
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "CNA SPCSB report" }),
            React.createElement(CardText, null,
                "Generate \u0421NA SPCSB report where will be displayed summary about suspicious operations.",
                React.createElement(DatePicker, { id: "selected-date", maxDate: moment().toDate(), formatDate: (date) => moment(date).format("DD.MM.YYYY"), autoOk: true, container: "inline", disabled: this.state.loading, floatingLabelText: "Selected date:", onChange: this.handleDateChange.bind(this), value: this.state.date }),
                React.createElement(SelectField, { id: 'report-format', hintText: "Select report format", style: { position: 'absolute' }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: 'Report format' }, this.renderMenuItems())),
            this.renderLoading(),
            this.renderCardActions()));
    }
    handleDateChange(event, date) {
        this.setState({
            date: date
        });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value, fileName: null });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: !this.state.date || this.state.loading })));
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(CnaSuspiciousTransactions);
