import * as React from "react";
import { Area, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from "recharts";
import { cyan500 } from "material-ui/styles/colors";
class NewBuyersChart extends React.Component {
    render() {
        return (React.createElement(ComposedChart, { margin: { top: 10, right: 30, left: 0, bottom: 0 }, width: 900, height: 250, data: this.props.data },
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "colorUv", x1: "0", y1: "0", x2: "0", y2: "1" },
                    React.createElement("stop", { offset: "5%", stopColor: cyan500, stopOpacity: 0.8 }),
                    React.createElement("stop", { offset: "95%", stopColor: cyan500, stopOpacity: 0 }))),
            React.createElement(Legend, null),
            React.createElement(XAxis, { dataKey: "name" }),
            React.createElement(YAxis, { yAxisId: 0, orientation: "left", dataKey: "new", type: "number" }),
            React.createElement(YAxis, { yAxisId: 1, orientation: "right", dataKey: "total", type: "number" }),
            React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
            React.createElement(Tooltip, null),
            React.createElement(Line, { name: "Total wallets count", yAxisId: 1, dot: false, type: "monotone", dataKey: "total", stroke: "#ff7300" }),
            React.createElement(Area, { name: "New wallets per day", yAxisId: 0, type: "monotone", dataKey: "new", stroke: cyan500, fillOpacity: 1, fill: "url(#colorUv)" })));
    }
}
export default NewBuyersChart;
