import * as React from "react";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import { formatAmount, formatPhone, formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import ClearIcon from "material-ui/svg-icons/navigation/close";
const DataTable = require("material-ui-datatables");
class ProcessingTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("wallet", "Wallet", (wallet, all) => React.createElement(Link, { to: `/processing/detail/${all.id}` }, formatPhone(wallet))),
            getTableColumnItem("created", "Created", (created) => formatTime(created)),
            getTableColumnItem("amount", "Amount", (amount) => formatAmount(amount)),
            getTableColumnItem("service", "Service"),
            getTableColumnItem("clientId", "Client ID"),
            getTableColumnItem("repetitions", "Repetitions"),
        ];
        this.handleChangeFilter = (name) => (event) => {
            const newFilter = Object.assign(Object.assign({}, this.state.filter), { [name]: event.target.value });
            this.setState({
                filter: newFilter,
            });
        };
        this.handleKeyPress = (event) => {
            if (event.key === "Enter") {
                this.props.fetchData(this.props.tableState.currentPage, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.state.filter);
            }
        };
        this.clearValue = () => {
            this.setState({
                filter: {
                    phone: "",
                    service: "",
                    clientId: "",
                },
            });
            this.props.fetchData();
        };
        this.sanitize = (text) => {
            return text.toLowerCase().trim();
        };
        this.state = {
            filter: {
                phone: this.props.tableState.filter.phone,
                service: this.props.tableState.filter.service,
                clientId: this.props.tableState.filter.clientId,
            },
            errorText: "",
        };
    }
    get renderUpdateIcon() {
        return [
            React.createElement(React.Fragment, null,
                React.createElement("div", { style: {
                        display: "flex",
                        width: 700,
                        flexDirection: "row",
                    } },
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "filterByPhone", label: "Filter by Phone", style: { marginLeft: "40px", marginRight: "20px" }, value: this.state.filter.phone.replace(/\D+/g, ""), onChange: this.handleChangeFilter("phone"), disabled: this.state.filter.clientId ||
                                this.state.filter.service, onKeyPress: this.handleKeyPress, InputProps: {
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, this.state.filter.phone &&
                                    React.createElement(ClearIcon, { style: { cursor: "pointer" }, onClick: () => this.clearValue() })),
                            } })),
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "filterByService", label: "Filter by Service", style: { marginRight: "20px" }, value: this.state.filter.service, onChange: this.handleChangeFilter("service"), disabled: this.state.filter.clientId ||
                                this.state.filter.phone, onKeyPress: this.handleKeyPress, InputProps: {
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, this.state.filter.service &&
                                    React.createElement(ClearIcon, { style: { cursor: "pointer" }, onClick: () => this.clearValue() })),
                            } })),
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "filterByClientID", label: "Filter by Client ID", style: { marginRight: "20px" }, value: this.state.filter.clientId, onChange: this.handleChangeFilter("clientId"), disabled: this.state.filter.phone ||
                                this.state.filter.service, onKeyPress: this.handleKeyPress, InputProps: {
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, this.state.filter.clientId &&
                                    React.createElement(ClearIcon, { style: { cursor: "pointer" }, onClick: () => this.clearValue() })),
                            } })))),
            React.createElement(IconButton, { onClick: () => this.props.fetchData() },
                React.createElement(Refresh, null)),
        ];
    }
    render() {
        return (this.renderTable());
    }
    renderTable() {
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.tableColumns, selectable: false, data: this.props.tableState.data, showCheckboxes: false, showHeaderToolbar: true, showHeaderToolbarFilterIcon: false, rowSizeList: [5, 10, 20, 40], rowSize: this.props.tableState.rowSize, count: this.props.tableState.count, page: this.props.tableState.currentPage, tableRowStyle: { cursor: "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleSearchValueChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.renderUpdateIcon }));
    }
    handleNextPageClick() {
        const page = this.props.tableState.currentPage + 1;
        this.props.fetchData(page, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.state.filter);
    }
    handlePreviousPageClick() {
        const page = this.props.tableState.currentPage - 1;
        this.props.fetchData(page, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.state.filter);
    }
    handleSearchValueChange(search) {
        this.props.fetchData(1, this.props.tableState.rowSize, this.sanitize(search), this.props.tableState.orderKey, this.props.tableState.order, this.state.filter);
    }
    handleSortOrderChange(key, order) {
        this.props.fetchData(this.props.tableState.currentPage, this.props.tableState.rowSize, this.props.tableState.search, key, order, this.state.filter);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.props.tableState.currentPage;
        if ((page - 1) * rowSize > this.props.tableState.count) {
            page = 1;
        }
        this.props.fetchData(page, rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.state.filter);
    }
}
export default ProcessingTable;
