import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { DataValidator } from "../../utils/DataValidator";
class AddOwnerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            name: "",
            surname: "",
            phone: "",
            errorText: "",
        };
        this.state = this.initialState;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.opened && prevProps.opened) {
            this.setState(this.initialState);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.phoneAlreadyExists && this.state.phone) {
            this.setState({ errorText: "Phone number already exists" });
        }
    }
    render() {
        return (React.createElement(Dialog, { title: "Add New Business Owner", open: this.props.opened, modal: true, actions: this.renderDialogActions, onRequestClose: this.props.toggleDialog }, this.renderInputs()));
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "image-dialog" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleDialog }),
                React.createElement(FlatButton, { label: "Accept", primary: true, onClick: this.handleAcceptButtonState ?
                        () => false
                        : () => this.props.onAddBtnClick(this.state.name, this.state.surname, this.state.phone), disabled: this.handleAcceptButtonState }))
        ];
    }
    get handleAcceptButtonState() {
        return !(this.state.phone && this.state.name && this.state.surname && !this.state.errorText);
    }
    renderInputs() {
        return (React.createElement("div", null,
            React.createElement(TextField, { autoFocus: true, floatingLabelText: "Enter name", fullWidth: true, onChange: (e) => this.setState({ name: e.target.value }) }),
            React.createElement(TextField, { floatingLabelText: "Enter surname", fullWidth: true, onChange: (e) => this.setState({ surname: e.target.value }) }),
            React.createElement(TextField, { floatingLabelText: "Enter phone", fullWidth: true, hintText: "373 XX XXX XXX", errorText: this.state.errorText, onChange: (e) => this.handleUpdateInput(e) })));
    }
    handleUpdateInput(e) {
        DataValidator.phoneNumberValidator(e.target.value).then((result) => {
            this.setState({ phone: result, errorText: "" });
        }).catch((err) => {
            this.setState({ phone: "", errorText: err });
        });
    }
}
export default AddOwnerDialog;
