import * as React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatTime } from "../../utils/DataHelpers";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "material-ui/svg-icons/action/delete";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class BuyerNotes extends React.Component {
    constructor(props) {
        super(props);
        this.confirmDialogActions = [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: () => this.toggleConfirmDialog(null) }),
            React.createElement(FlatButton, { label: "OK", primary: true, onClick: () => this.props.deleteNote(this.state.deletedId) })
        ];
        this.state = {
            confirmDialogOpen: false,
            deletedId: null
        };
    }
    renderConfirmDialog() {
        return (React.createElement(Dialog, { title: "Delete note?", modal: true, open: this.state.confirmDialogOpen, actions: this.confirmDialogActions }));
    }
    toggleConfirmDialog(noteId) {
        this.setState(state => ({
            deletedId: noteId,
            confirmDialogOpen: !state.confirmDialogOpen
        }));
    }
    ;
    render() {
        const notes = this.props.notes || [];
        return (React.createElement("div", null,
            notes.length > 0 &&
                React.createElement(Table, { size: "small" },
                    React.createElement(TableBody, null, notes.map(note => (React.createElement(TableRow, { key: note.id },
                        React.createElement(TableCell, { style: { padding: 2 } },
                            React.createElement("i", null, note.noteText)),
                        React.createElement(TableCell, { style: { width: 140 } }, formatTime(note.whenCreated)),
                        React.createElement(TableCell, { style: { width: 20, padding: 2 } },
                            React.createElement(IconButton, { style: { margin: 0, padding: 0 }, onClick: () => this.toggleConfirmDialog(note.id) },
                                React.createElement(DeleteIcon, { fontSize: "small", color: "grey" })))))))),
            this.renderConfirmDialog()));
    }
}
export default BuyerNotes;
