import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import Card from "material-ui/Card/Card";
import Tabs, { Tab } from "material-ui/Tabs";
import TabPanel from '../components/CashByCode/TabPanel';
import { TabNames } from '../interfaces/CashByCode';
import Archive from '../components/CashByCode/Archive';
import '../styles/CashByCode.css';
import Active from '../components/CashByCode/Active';
class CashByCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedTab: TabNames.Active
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("CashByCode", "/cbc")]));
    }
    render() {
        const { baseUrl } = this.props;
        const { selectedTab } = this.state;
        return (React.createElement("div", null,
            React.createElement("div", { className: "tabs-wrapper" },
                React.createElement(Tabs, { value: selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "250px" } },
                    React.createElement(Tab, { label: "Active", value: TabNames.Active }),
                    React.createElement(Tab, { label: "Archive", value: TabNames.Archive }))),
            React.createElement(Card, { className: "card-998" },
                React.createElement(TabPanel, { value: selectedTab, index: 0 },
                    React.createElement(Active, Object.assign({}, { baseUrl })))),
            React.createElement(Card, { className: "card-full-size" },
                React.createElement(TabPanel, { value: selectedTab, index: 1 },
                    React.createElement(Archive, Object.assign({}, { baseUrl }))))));
    }
}
export default connect(mapStateToPropsBaseUrl)(CashByCode);
