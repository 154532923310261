import * as $ from "jquery";
export class AuthService {
    static getToken(url, callback, errCallback, token) {
        $.ajax(url, {
            type: "GET",
            headers: { Authorization: token },
            success: (data) => {
                callback(data);
            },
            error: (data) => {
                errCallback(data);
            },
        });
    }
}
