import * as React from "react";
import { connect } from "react-redux";
import { HttpClient } from "../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import P2PTx from "../components/TransactionsComponents/P2PTx";
import SupplyTx from "../components/TransactionsComponents/SupplyTx";
import CBCTx from "../components/TransactionsComponents/CBCTx";
import SpendTx from "../components/TransactionsComponents/SpendTx";
import { appendBreadcrumb } from "../actions/Actions";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import WithdrawalTx from "../components/TransactionsComponents/WithdrawalTx";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class TransactionInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            txInfoData: null,
        };
        this.props.dispatch(appendBreadcrumb([
            getBreadcrumbObject(`${this.props.match.params.txId}`, `/transactions/${this.props.match.params.type}/${this.props.match.params.txId}/info`),
        ]));
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        return (React.createElement("div", null, this.renderTxInfo()));
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/tx/${this.props.match.params.type}/${this.props.match.params.txId}`)
            .then((result) => {
            this.setState({ loading: false, txInfoData: result });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load txs detailed data. Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    revertTransactions() {
        const data = this.state.txInfoData;
        const defaultErrorMessage = "Could not make changes!";
        HttpClient
            .patch(`${this.props.baseUrl}/tx/${data.txnId}/toPending`)
            .then((response) => {
            const result = JSON.parse(response);
            switch (result.status.code) {
                case "ok":
                    ReactToastify.toast.info("Transaction was successfully reverted!");
                    this.fetchData();
                    break;
                case "tx_not_found":
                case "not_found_operator":
                case "backend_error":
                    ReactToastify.toast.info(result.status.message || defaultErrorMessage);
                    break;
                default:
                    ReactToastify.toast.info(defaultErrorMessage);
                    break;
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderTxInfo() {
        const { loading, txInfoData } = this.state;
        const { roles } = this.props;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        switch (this.props.match.params.type) {
            case "supply":
                return (React.createElement(SupplyTx, { txData: txInfoData }));
            case "spend":
                return (React.createElement(SpendTx, { txData: txInfoData, revertTransaction: this.revertTransactions.bind(this), roles: roles }));
            case "p2p":
                return (React.createElement(P2PTx, { txData: txInfoData }));
            case "withdrawal":
                return (React.createElement(WithdrawalTx, { txData: txInfoData }));
            case "cbc":
                return (React.createElement(CBCTx, { txData: txInfoData }));
            default:
                return React.createElement("p", null, "Unknown transaction type!");
        }
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
});
export default withRouter(connect(mapStateToProps)(TransactionInfoPage));
