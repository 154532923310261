import * as React from "react";
import { WithdrawalStatus } from "../../interfaces/WithdrawalRequest";
import { Card, CardActions, CardText } from "material-ui/Card";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, getName, getWithdrawStatusCell, } from "../../utils/DataHelpers";
import Table, { TableBody } from "material-ui/Table";
import AcceptWithdrawalDialog from "./AcceptWithdrawalDialog";
import RaisedButton from "material-ui/RaisedButton";
import RejectWithdrawalDialog from "./RejectWithdrawalDialog";
import TextField from "material-ui/TextField";
import Download from "material-ui/svg-icons/file/cloud-download";
import CircularProgress from "material-ui/CircularProgress";
class WithdrawalRequestProfile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            acceptDialogOpened: false,
            rejectDialogOpened: false,
            requestNumber: this.props.data.requestNumber || "",
        };
    }
    render() {
        const { acceptDialogOpened, rejectDialogOpened } = this.state;
        const { data, onAcceptButtonClick, onRejectButtonClick } = this.props;
        return (React.createElement("div", null,
            React.createElement(AcceptWithdrawalDialog, { opened: acceptDialogOpened, toggleDialog: this.toggleAcceptDialog(), onAcceptBtnClick: () => onAcceptButtonClick(), amount: data.amount }),
            React.createElement(RejectWithdrawalDialog, { opened: rejectDialogOpened, toggleDialog: this.toggleRejectDialog(), onRejectBtnClick: () => onRejectButtonClick() }),
            this.renderProfileCard()));
    }
    renderProfileCard() {
        const { walletPhone, amount, operatorPhone, status, userName, userSurname, whenCreated, whenFinished, chargedAmount } = this.props.data;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRowWithLink("Wallet", formatPhone(walletPhone), `/buyer/${walletPhone}/info`),
                        getInfoTableRow("When created", formatTime(whenCreated)),
                        getInfoTableRow("Amount", formatCurrency(amount)),
                        getInfoTableRow("Charged amount", formatCurrency(chargedAmount)),
                        getInfoTableRow("Commission", formatCurrency(chargedAmount - amount)),
                        getInfoTableRow("First name", getName(userName)),
                        getInfoTableRow("Last name", getName(userSurname)),
                        getInfoTableRow("Status", getWithdrawStatusCell(status)),
                        whenFinished &&
                            getInfoTableRow("When finished", formatTime(whenFinished)),
                        operatorPhone &&
                            getInfoTableRow("Operator phone", formatPhone(operatorPhone)),
                        getInfoTableRow("Request number", this.getRequestNumberEntry()),
                        getInfoTableRow("Generate letter of request", this.renderGenerateRequestButton()),
                        getInfoTableRow("Generate cash warrant", this.renderGenerateWarrantButton())))),
            this.renderCardActions()));
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            this.renderUpdateButton(),
            this.renderRejectButton(),
            this.renderAcceptButton()));
    }
    renderAcceptButton() {
        const { status } = this.props.data;
        if (status === WithdrawalStatus.PENDING) {
            return (React.createElement(RaisedButton, { secondary: true, label: "Accept", className: "info-card-raisedbutton", onClick: this.toggleAcceptDialog() }));
        }
        else {
            return null;
        }
    }
    renderRejectButton() {
        const { status } = this.props.data;
        if (status === WithdrawalStatus.PENDING) {
            return (React.createElement(RaisedButton, { primary: true, label: "Reject", className: "info-card-raisedbutton", onClick: this.toggleRejectDialog() }));
        }
        else {
            return null;
        }
    }
    getRequestNumberEntry() {
        const { requestNumber } = this.state;
        return (React.createElement(TextField, { hintText: "Enter request number", value: requestNumber, onChange: (e) => this.setState({ requestNumber: e.target.value }) }));
    }
    renderUpdateButton() {
        return (React.createElement(RaisedButton, { primary: true, label: "Update", className: "info-card-raisedbutton", onClick: () => this.props.onUpdateButtonClick(this.state.requestNumber), disabled: this.isUpdateButtonDisabled() }));
    }
    renderGenerateRequestButton() {
        const { loadingRequest } = this.props;
        return (React.createElement("div", { className: "progress-button-wrapper" },
            React.createElement(RaisedButton, { primary: true, label: "request", disabled: loadingRequest, icon: React.createElement(Download, null), onClick: () => this.props.onDownloadRequestClick() }),
            loadingRequest && React.createElement(CircularProgress, { size: 24, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: -12,
                    marginTop: -12,
                } })));
    }
    renderGenerateWarrantButton() {
        const { loadingRequest } = this.props;
        return (React.createElement("div", { className: "progress-button-wrapper" },
            React.createElement(RaisedButton, { primary: true, label: "warrant", disabled: loadingRequest, icon: React.createElement(Download, null), onClick: () => this.props.onDownloadWarrantClick() }),
            loadingRequest && React.createElement(CircularProgress, { size: 24, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: -12,
                    marginTop: -12,
                } })));
    }
    isUpdateButtonDisabled() {
        const { requestNumber } = this.state;
        return !requestNumber || requestNumber === this.props.data.requestNumber;
    }
    toggleAcceptDialog() {
        return () => this.setState({ acceptDialogOpened: !this.state.acceptDialogOpened });
    }
    toggleRejectDialog() {
        return () => this.setState({ rejectDialogOpened: !this.state.rejectDialogOpened });
    }
}
export default WithdrawalRequestProfile;
