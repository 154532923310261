import * as React from "react";
import { ServiceRiskGroup } from "../../interfaces/Services";
import { defaultLogoUrl } from "../../variables/Common";
import Card, { CardActions, CardHeader, CardText, CardTitle } from "material-ui/Card";
import { Table, TableBody } from "material-ui/Table";
import { getInfoTableRow } from "../../utils/DataHelpers";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import AddPhoto from "material-ui/svg-icons/image/add-a-photo";
import ChangeImageDialog from "./ChangeImageDialog";
import * as lodash from "lodash";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
class ServiceProfile extends React.Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            name: this.props.service.serviceName,
            imageUrl: this.props.service.imageUrl,
            changeImageDialogOpened: false,
            errorText: "",
            riskGroup: this.props.service.riskGroup
        };
        this.state = this.defaultState;
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(ChangeImageDialog, { opened: this.state.changeImageDialogOpened, toggleDialog: this.toggleChangeImageDialog(), onOpenBtnClick: (imageUrl) => this.setState({ imageUrl, changeImageDialogOpened: false }) }),
            this.renderProfileCard()));
    }
    componentWillReceiveProps(nextProps) {
        if (!lodash.isEqual(this.props.service, nextProps.service)) {
            this.defaultState.name = nextProps.service.serviceName;
            this.defaultState.riskGroup = nextProps.service.riskGroup;
            this.defaultState.imageUrl = nextProps.service.imageUrl;
            this.setState(this.defaultState);
        }
    }
    renderProfileCard() {
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardHeader, { title: this.props.service.serviceName, avatar: React.createElement("img", { className: "table-image", src: this.props.service.imageUrl ? this.props.service.imageUrl : defaultLogoUrl, onError: (e) => e.target.src = defaultLogoUrl }) }),
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Provider", this.props.service.providerName),
                        getInfoTableRow("Provider ID", this.props.service.providerId),
                        getInfoTableRow("Service ID", this.props.service.serviceId)))),
            React.createElement(CardTitle, { subtitle: "Edit Profile" }),
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Image", this.getChangeLogoBtn()),
                        getInfoTableRow("Name", this.renderNameTextField()),
                        getInfoTableRow("Risk level", this.renderRiskLevelSelect())))),
            this.renderCardActions()));
    }
    getChangeLogoBtn() {
        return (React.createElement("div", null,
            React.createElement(RaisedButton, { icon: React.createElement(AddPhoto, null), primary: true, label: "change logo", onClick: this.toggleChangeImageDialog() }),
            this.renderChosenLogo()));
    }
    renderNameTextField() {
        return (React.createElement(TextField, { id: "service-name-input", floatingLabelText: "Service name", hintText: "Enter service name", errorText: this.state.errorText, value: this.state.name, onChange: (e) => this.handleNameChange(e.target.value) }));
    }
    renderRiskLevelSelect() {
        return (React.createElement(SelectField, { id: 'risk-level', hintText: "Select a risk level", onChange: (e, i, v) => this.handleRiskGroupChange(e, i, v), value: this.state.riskGroup, floatingLabelText: 'Risk level' }, this.renderMenuItems()));
    }
    handleRiskGroupChange(event, index, value) {
        this.setState({ riskGroup: value });
    }
    renderMenuItems() {
        const riskGroups = [
            ServiceRiskGroup.MINOR,
            ServiceRiskGroup.SMALL,
            ServiceRiskGroup.MIDDLE,
            ServiceRiskGroup.HIGH,
            ServiceRiskGroup.VERY_HIGH
        ];
        return riskGroups.map((riskGroup) => React.createElement(MenuItem, { key: riskGroup, insetChildren: true, value: riskGroup, primaryText: ServiceRiskGroup[riskGroup] }));
    }
    handleNameChange(name) {
        this.setState({
            name,
            errorText: name ? "" : "Value can not be empty!",
        });
    }
    renderChosenLogo() {
        if (this.state.imageUrl) {
            return (React.createElement("img", { src: this.state.imageUrl, className: "chosen-logo table-image" }));
        }
        else {
            return null;
        }
    }
    toggleChangeImageDialog() {
        return () => this.setState({ changeImageDialogOpened: !this.state.changeImageDialogOpened });
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, label: "RESET", className: "info-card-raisedbutton", disabled: this.getResetBtnState(), onClick: () => this.setState(this.defaultState) }),
            React.createElement(RaisedButton, { secondary: true, label: "UPDATE", className: "info-card-raisedbutton", disabled: this.getUpdateBtnState(), onClick: () => this.props.onUpdateClick({
                    serviceName: this.state.name !== this.props.service.serviceName ? this.state.name : undefined,
                    imageUrl: this.state.imageUrl,
                    riskGroup: this.state.riskGroup !== this.props.service.riskGroup ? this.state.riskGroup : ServiceRiskGroup.MINOR
                }) })));
    }
    getResetBtnState() {
        return !(this.state.name !== this.props.service.serviceName ||
            this.state.riskGroup !== this.props.service.riskGroup ||
            (this.state.imageUrl !== this.props.service.imageUrl));
    }
    getUpdateBtnState() {
        if (this.state.errorText) {
            return true;
        }
        else {
            return this.getResetBtnState();
        }
    }
}
export default ServiceProfile;
