import * as React from "react";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import CircularProgress from "material-ui/CircularProgress";
import Card from "material-ui/Card/Card";
import { getOffset, getQuery, getTableState } from "../../utils/DataTablesHelepers";
import { HttpClient } from "../../utils/HttpClient";
import { formatCurrency, formatTime, getTableColumnItem, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const DataTable = require("material-ui-datatables");
const ReactToastify = require("react-toastify");
class BuyerWithdrawalTransactions extends React.PureComponent {
    constructor(props) {
        super(props);
        this.withdrawalsColumns = [
            getTableColumnItem("whenCreated", "When", (time, all) => React.createElement(Link, { to: `/transactions/withdrawal/${all.id}/info` }, formatTime(time))),
            getTableColumnItem("amount", "Amount", (amount) => formatCurrency(amount)),
            getTableColumnItem("requestNumber", "Request number"),
        ];
        this.state = {
            loading: true,
            tableState: null
        };
    }
    render() {
        return (React.createElement(Card, { className: "card-full-size" }, this.renderTable()));
    }
    componentDidMount() {
        this.fetchBuyerWithdrawalTxes();
    }
    fetchBuyerWithdrawalTxes(currentPage = 1, rowSize = 20, search = "", orderKey = "", order = "") {
        const query = getQuery(getOffset(currentPage, rowSize), rowSize, search, orderKey, order);
        HttpClient.getJSON(`${this.props.baseUrl}/tx/list/${this.props.id}/withdrawal`, query)
            .then((result) => {
            this.setState({
                loading: false,
                tableState: getTableState(result, rowSize, search, orderKey, order, "txs")
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load buyer withdrawals. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    get renderIcons() {
        return (React.createElement(IconButton, { onClick: () => this.fetchBuyerWithdrawalTxes() },
            React.createElement(Refresh, null)));
    }
    renderTable() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.withdrawalsColumns, data: this.state.tableState.data, showCheckboxes: false, showHeaderToolbar: true, tableRowStyle: { cursor: "pointer" }, selectable: true, rowSizeList: [5, 10, 20, 40], rowSize: this.state.tableState.rowSize, count: this.state.tableState.count, page: this.state.tableState.currentPage, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleSearchValueChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.renderIcons }));
        }
    }
    handleNextPageClick() {
        const page = this.state.tableState.currentPage + 1;
        this.fetchBuyerWithdrawalTxes(page, this.state.tableState.rowSize, this.state.tableState.search, this.state.tableState.orderKey, this.state.tableState.order);
    }
    handlePreviousPageClick() {
        const page = this.state.tableState.currentPage - 1;
        this.fetchBuyerWithdrawalTxes(page, this.state.tableState.rowSize, this.state.tableState.search, this.state.tableState.orderKey, this.state.tableState.order);
    }
    handleSearchValueChange(search) {
        this.fetchBuyerWithdrawalTxes(1, this.state.tableState.rowSize, search, this.state.tableState.orderKey, this.state.tableState.order);
    }
    handleSortOrderChange(key, order) {
        this.fetchBuyerWithdrawalTxes(this.state.tableState.currentPage, this.state.tableState.rowSize, this.state.tableState.search, key.toLowerCase(), order);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.state.tableState.currentPage;
        if ((page - 1) * rowSize > this.state.tableState.count) {
            page = 1;
        }
        this.fetchBuyerWithdrawalTxes(page, rowSize, this.state.tableState.search, this.state.tableState.orderKey, this.state.tableState.order);
    }
}
export default connect(mapStateToPropsBaseUrl)(BuyerWithdrawalTransactions);
