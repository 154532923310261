import * as React from "react";
import { connect } from "react-redux";
import { appendBreadcrumb } from "../../actions/Actions";
import { downloadReport, getBreadcrumbObject } from "../../utils/DataHelpers";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import { HttpClient } from "../../utils/HttpClient";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import * as moment from "moment";
const ReactToastify = require("react-toastify");
class FiscAnnualTurnover extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            year: "",
            errorText: null,
            reportFormat: "xlsx",
        };
        this.validateInput = (year) => {
            if (year === "") {
                return "";
            }
            if (!/\d{4}/.test(year)) {
                return "invalid year value";
            }
            const currYear = moment().year();
            const minYear = currYear - 10;
            if (Number(year) > currYear) {
                return "max year value: " + currYear;
            }
            if (Number(year) < minYear) {
                return "min year value: " + minYear;
            }
            return "";
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: 'MS Excel', fileExt: 'xlsx' },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("Fisc: Moneda Electronica", "/reports/fisc/annual")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        this.setState({ loading: true, generated: false });
        const query = {
            year: this.state.year,
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/fiscAnnual`, query)
            .then((result) => {
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({ loading: false, generated: true });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create FISC annual report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    handleInputChange(year) {
        this.setState({
            year: year,
            errorText: this.validateInput(year),
        });
    }
    renderActionCard() {
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "FISC Annual Report" }),
            React.createElement(CardText, null,
                "Generate annual wallets turnover report - \"Fisc: Moneda Electronica\".",
                React.createElement("br", null),
                React.createElement(TextField, { id: "selected-date", hintText: "Year value in 4-digits format: YYYY", onChange: (e) => this.handleInputChange(e.target.value), errorText: this.state.errorText, disabled: this.state.loading, errorStyle: { position: "absolute", bottom: "-0.9rem" } }),
                React.createElement("br", null),
                React.createElement(SelectField, { id: 'report-format', hintText: "Select report format", style: { position: 'absolute' }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: 'Report format' }, this.renderMenuItems())),
            this.renderLoading(),
            this.renderCardActions()));
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: this.isButtonDisabled() || this.state.loading })));
    }
    isButtonDisabled() {
        const { year, errorText } = this.state;
        return year === "" || errorText !== "";
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(FiscAnnualTurnover);
