import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { CircularProgress, GridTile, IconButton } from "material-ui";
import { TextField, Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { HttpClient } from "../../utils/HttpClient";
import { formatCurrency, getTableColumnItem } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import SearchIcon from "material-ui/svg-icons/action/search";
import ClearIcon from "material-ui/svg-icons/navigation/close";
import { InputAdornment } from "@material-ui/core";
const ReactToastify = require("react-toastify");
class CashoutBusinessDialog extends React.Component {
    constructor(props) {
        super(props);
        this.selectOrganizationTable = [
            getTableColumnItem("businessName", "Organization", (businessName) => (businessName), false),
            getTableColumnItem("businessIdno", "IDNO", (idno) => (idno), false),
            getTableColumnItem("businessAccount", "Client ID", (clientId) => (clientId), false),
        ];
        this.state = {
            operatorImagePreview: "",
            operatorImageFile: null,
            imagesLightboxOpened: false,
            loading: false,
            isSaving: false,
            type: "",
            isSelected: false,
        };
    }
    get renderDialogActions() {
        const { currentBusiness } = this.props;
        return [
            React.createElement("div", { id: "cashout-decision", style: { display: "flex", justifyContent: "space-between" } },
                currentBusiness.id && React.createElement("div", { style: { display: "flex" } },
                    React.createElement(FlatButton, { label: "Approve", primary: true, onClick: () => this.addBusinessActions("accepted"), disabled: this.props.isSaving }),
                    React.createElement(FlatButton, { label: "Reject", primary: true, onClick: () => this.addBusinessActions("rejected"), disabled: this.props.isSaving })),
                React.createElement("div", { style: { width: "100%" } },
                    React.createElement(FlatButton, { label: "Discard", primary: true, onClick: this.props.onCancelClick.bind(this), disabled: this.props.isSaving || !this.props.files }),
                    React.createElement(FlatButton, { label: "Save", primary: true, onClick: this.props.onOkClick, disabled: this.props.isSaving }))),
        ];
    }
    get isSelectedActions() {
        return [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onFocus: this.props.clearFilterValue, onClick: this.toggleSelectedProvider() }),
        ];
    }
    componentDidMount() {
        this.props.fetchServiceProvider();
        this.props.getBusinessDetails();
    }
    renderSelectedOptions() {
        return (React.createElement(SmartDataTable, { data: this.props.services, columns: this.selectOrganizationTable, showFooterToolbar: false, isCursorPointer: true, showHeaderToolbarFilterIcon: false, onRowSelection: (row) => this.changeServiceSelected(row) }));
    }
    render() {
        const dialogTitle = this.props.dialogTitle;
        const { isSelected } = this.state;
        return (React.createElement(Dialog, { title: isSelected ? "Select organization" : dialogTitle, actions: !isSelected ? this.renderDialogActions : this.isSelectedActions, contentStyle: { maxWidth: !isSelected ? "500px" : "700px" }, bodyStyle: { border: "none" }, modal: true, open: this.props.open, autoScrollBodyContent: true, onRequestClose: this.props.onCancelClick.bind(this) },
            this.renderForm(),
            isSelected && this.renderSelectedOptions()));
    }
    changeServiceSelected(row) {
        const companyId = this.props.services[row] ? this.props.services[row].businessId : null;
        this.props.handleInputChange(companyId, "companyId");
        this.setState({
            isSelected: false,
        });
    }
    addBusinessActions(type) {
        const defaultErrorMessage = "Could not make changes!";
        const { currentBusiness } = this.props;
        this.setState({
            isSaving: true,
        });
        HttpClient.post(`${this.props.baseUrl}/withdrawal/company/${currentBusiness.id}/${type}/finish`)
            .then((response) => {
            const resp = JSON.parse(response);
            switch (resp.status.code) {
                case "ok":
                    (type === "accepted"
                        ? ReactToastify.toast.info("Withdrawal was approved successfully!")
                        : ReactToastify.toast.info("Withdrawal was rejected successfully!"));
                    this.props.onCancelClick();
                    this.props.fetchData();
                    this.setState({
                        isSaving: false,
                    });
                    break;
                case "not_found_request":
                case "insufficient_funds":
                case "already_confirmed":
                case "qiwi_side_error":
                case "not_found_operator":
                    ReactToastify.toast.info(resp.status.message || defaultErrorMessage);
                    this.props.onCancelClick();
                    this.props.fetchData();
                    this.setState({
                        isSaving: false,
                    });
                    break;
                default:
                    ReactToastify.toast.info(defaultErrorMessage);
                    break;
            }
        })
            .catch((err) => {
            this.setState({
                isSaving: false,
            });
            ReactToastify.toast.error(defaultErrorMessage + " Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    findErrorText(field) {
        const { errors } = this.props;
        if (errors) {
            const error = errors.find((e) => e.field === field);
            return error ? error.text : "";
        }
        return "";
    }
    ifExistsErrors(field) {
        const { errors } = this.props;
        if (!errors) {
            return false;
        }
        const error = errors.find((e) => e.field === field);
        return !!error;
    }
    renderOperatorGridTile() {
        const { files } = this.props;
        if (files || files.length) {
            return (files.map((file, i) => {
                return (React.createElement("div", { style: { width: 435, backgroundColor: "#f5f5f5", marginTop: 10 } },
                    React.createElement("ul", { style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: 0,
                            padding: 10,
                        } },
                        React.createElement("div", { style: { display: "flex" } },
                            React.createElement("a", { style: { color: "#0921CB", fontWeight: 500 }, href: file.preview, target: "_blank" },
                                file && file.name,
                                " "),
                            React.createElement("div", { style: { color: "#7a7a7a", marginLeft: 10 } },
                                "(",
                                (file.size / 1024).toFixed(2),
                                " KB)")),
                        React.createElement(ClearIcon, { onClick: () => this.props.removeByIndex(i), key: i, style: { color: "#7a7a7a", cursor: "pointer" } }))));
            }));
        }
        else {
            return (React.createElement(GridTile, null));
        }
    }
    toggleSelectedProvider() {
        return () => this.setState({ isSelected: !this.state.isSelected });
    }
    renderForm() {
        const { isSaving, dialogFilter, totalWithdrawal, details } = this.props;
        const { isSelected } = this.state;
        if (isSaving) {
            return (React.createElement(CircularProgress, { size: 50, style: { left: "50%" } }));
        }
        const selectedService = this.props.selectedService;
        return (React.createElement(React.Fragment, null,
            isSelected && React.createElement("div", null,
                React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                    React.createElement(TextField, { id: "filterByOrganization", style: { width: "35%", marginRight: 30 }, label: "Filter by organization", onChange: (e) => this.props.handleInputFilterChange(e.target.value, "name"), value: dialogFilter && dialogFilter.name, onKeyPress: this.props.handleKeyPress, disabled: isSaving }),
                    React.createElement(TextField, { id: "filter-by-idno", style: { width: "35%", marginRight: 30 }, label: "Filter by IDNO", onChange: (e) => this.props.handleInputFilterChange(e.target.value, "idno"), value: dialogFilter && dialogFilter.idno, onKeyPress: this.props.handleKeyPress, disabled: isSaving }),
                    React.createElement(TextField, { id: "filter-by-client", style: { width: "35%" }, label: "Filter by Client ID", onChange: (e) => this.props.handleInputFilterChange(e.target.value, "account"), value: dialogFilter && dialogFilter.account, onKeyPress: this.props.handleKeyPress, disabled: isSaving }))),
            !isSelected && React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement("div", { style: { marginTop: 15 } },
                    React.createElement(TextField, { id: "select-orgranization", required: true, disabled: isSaving, style: { width: "100%" }, label: "Select organization", onFocus: this.toggleSelectedProvider(), helperText: this.findErrorText("companyId"), error: this.ifExistsErrors("companyId"), value: selectedService && selectedService.businessName || "", InputProps: {
                            endAdornment: React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { onFocus: this.toggleSelectedProvider() },
                                    React.createElement(SearchIcon, null))),
                        } })),
                React.createElement("div", { style: { display: "flex", flexDirection: "row", marginTop: 15 } },
                    React.createElement(TextField, { id: "amount-input", required: true, style: { width: "40%" }, helperText: this.findErrorText("amount"), error: this.ifExistsErrors("amount"), label: "Amount", onChange: (e) => this.props.handleChangeNumericInputs(e.target.value, "amount"), value: details && details.amount, disabled: isSaving }),
                    React.createElement("div", null,
                        React.createElement(TextField, { id: "percent-input", required: true, style: { width: "86%", marginLeft: 15 }, helperText: this.findErrorText("percent"), error: this.ifExistsErrors("percent"), label: "Commission", onChange: (e) => this.props.handleChangeNumericInputs(e.target.value, "percent"), value: details && details.percent, disabled: isSaving, inputProps: { maxLength: 12 }, InputProps: {
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, "%"),
                            } }))),
                React.createElement("div", { style: {
                        display: "flex",
                        flexDirection: "row",
                        maxWidth: "66%",
                        justifyContent: "space-between",
                        marginTop: 10,
                    } },
                    React.createElement("div", null,
                        React.createElement("p", { style: {
                                fontSize: 12,
                                fontWeight: 300,
                                color: "#b2b2b2",
                            } }, "Total withdrawal"),
                        React.createElement("div", { style: { color: "#000" } }, formatCurrency(totalWithdrawal ? totalWithdrawal : 0)),
                        this.ifExistsErrors("withdrawal") &&
                            React.createElement(Typography, { style: { fontSize: 12, color: "#f44336" } }, "Amount is higher than balance")),
                    React.createElement("div", null,
                        React.createElement("p", { style: {
                                fontSize: 12,
                                fontWeight: 300,
                                color: "#b2b2b2",
                            } }, "Customer balance"),
                        React.createElement("div", { style: { color: "#000000" } }, formatCurrency(selectedService && selectedService.balance || 0)))),
                React.createElement("div", { style: { display: "flex", flexDirection: "row", marginTop: 15 } },
                    React.createElement(TextField, { id: "comment-input", label: "Comment", required: true, style: { width: "100%" }, onChange: (e) => this.props.handleInputChange(e.target.value, "notes"), value: details && details.notes, helperText: this.findErrorText("notes"), error: this.ifExistsErrors("notes"), disabled: isSaving, multiline: true, rows: 6, rowsMax: 6, InputLabelProps: {
                            shrink: true,
                        } })),
                React.createElement("div", { className: "images-list-root-withdrawal", style: { marginTop: 20, flexDirection: "column" } }, this.renderDropZone()),
                this.renderOperatorGridTile(),
                (details && details.images || []).map((el, index) => {
                    const lastIndexOf = el.lastIndexOf("/");
                    const imgName = (lastIndexOf === -1 ? "Untitled" : el.slice(lastIndexOf + 1));
                    return (React.createElement("div", null, imgName && React.createElement("div", { key: index, style: {
                            width: "98%",
                            backgroundColor: "#f5f5f5",
                            marginTop: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0px 5px",
                        } },
                        React.createElement("ul", { style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: 0,
                                padding: 10,
                            } },
                            React.createElement("div", null,
                                React.createElement("a", { style: { color: "#0921CB", fontWeight: 500, cursor: "pointer" }, href: details.images[index], target: "_blank" },
                                    imgName,
                                    " "))),
                        imgName && React.createElement(ClearIcon, { onClick: () => this.props.deleteSelectedFile(imgName), style: { color: "#7a7a7a", cursor: "pointer" } }))));
                }))));
    }
    handlePaste(e) {
        const data = e.clipboardData.items[0].getAsFile();
        if (data && data.type.split("/")[0] === "image") {
            this.setState({
                operatorImageFile: data,
                operatorImagePreview: window.URL.createObjectURL(data),
            });
        }
    }
    renderDropZone() {
        return (React.createElement("div", { onPaste: (e) => this.handlePaste(e), style: { display: "flex", flexWrap: "nowrap", width: "100%" } },
            React.createElement(Dropzone, { onDrop: this.props.onDrop, disableClick: true, style: {
                    position: "relative",
                    width: "100%",
                    height: 110,
                    borderWidth: 2,
                    borderColor: "rgb(102, 102, 102)",
                    borderStyle: "dashed",
                    borderRadius: 5,
                } }, ({ open }) => (React.createElement("div", { style: { textAlign: "center" } },
                React.createElement("p", { style: { fontSize: 14 } }, "Drop file here "),
                React.createElement("p", { style: { fontSize: 14 } }, "Or"),
                React.createElement("button", { type: "button", onClick: () => open(), style: { fontSize: 14 } }, "Open File Dialog"))))));
    }
}
export default CashoutBusinessDialog;
