import * as firebase from "firebase";
const firebaseui = require("firebaseui");
export const ADD_EMAIL = "ADD_EMAIL";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const ADD_BUSINESS_INFO = "ADD_BUSINESS_INFO";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const ADD_USERS = "ADD_USERS";
export const DELETE_USER = "DELETE_USER";
export const SET_BUYER_INFO = "SET_BUYER_INFO";
export const SET_BASE_URL = "SET_BASE_URL";
export const SET_CHOSEN_USER = "SET_CHOSEN_USER";
export const SET_TXS_TABLE_STATE = "SET_TXS_TABLE_STATE";
export const SET_BUYERS_TABLE_STATE = "SET_BUYERS_TABLE_STATE";
export const SET_CHOSEN_BUSINESS_OWNER = "SET_SCHOSEN_BUSINESS_OWNER";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_CHOSEN_SELLER = "SET_CHOSEN_SELLER";
export const APPEND_BREADCRUMB = "APPEND_BREADCRUMB";
export const SET_TOKEN = "SET_TOKEN";
export const SET_PROCESSING_TABLE_STATE = "SET_PROCESSING_TABLE_STATE";
export const FIREBASE_EXAMPLES = "FIREBASE_EXAMPLES";
export function addUsers(data) {
    return {
        type: ADD_USERS,
        users: data.users,
    };
}
export function deleteUser(phone) {
    return {
        type: DELETE_USER,
        phone,
    };
}
export function addEmail(email) {
    return {
        type: ADD_EMAIL,
        email,
    };
}
export function deleteEmail(email) {
    return {
        type: DELETE_EMAIL,
        email,
    };
}
export function addBusinessInfo(info) {
    return {
        type: ADD_BUSINESS_INFO,
        title: info.business.title,
        emails: info.business.emails,
        id: info.business.id,
        idno: info.business.idno,
        ownerPhone: info.business.ownerPhone,
    };
}
export function updateTitle(title) {
    return {
        type: UPDATE_TITLE,
        title,
    };
}
export function setTxsTableState(table) {
    return {
        type: SET_TXS_TABLE_STATE,
        rowSize: table.rowSize,
        count: table.count,
        currentPage: table.currentPage,
        search: table.search,
        orderKey: table.orderKey,
        order: table.order,
        filter: table.filter,
        data: table.data,
    };
}
export function setProcessingTableState(table) {
    return {
        type: SET_PROCESSING_TABLE_STATE,
        rowSize: table.rowSize,
        count: table.count,
        currentPage: table.currentPage,
        search: table.search,
        orderKey: table.orderKey,
        order: table.order,
        filter: table.filter,
        data: table.data,
    };
}
export function setBuyerInfo(buyer) {
    return {
        type: SET_BUYER_INFO,
        phone: buyer.phone,
        photoUrl: buyer.photoUrl,
        balance: buyer.balance,
        created: buyer.created,
        accessed: buyer.accessed,
        name: buyer.name,
        surname: buyer.surname,
        birthday: buyer.birthday,
        idnp: buyer.idnp,
        address: buyer.address,
        passportSeries: buyer.passportSeries,
        expDate: buyer.expDate,
        blocked: buyer.blocked,
        buyerType: buyer.type,
        formNumber: buyer.formNumber,
        issueDate: buyer.issueDate,
        issuingAuthority: buyer.issuingAuthority,
        docType: buyer.docType,
    };
}
export function setBaseUrl(url) {
    return {
        type: SET_BASE_URL,
        url,
    };
}
export function setFirebaseConfiguration(configs) {
    const uiConfig = {
        signInOptions: [
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                    type: "image",
                    size: "normal",
                    badge: "bottomleft",
                },
                defaultCountry: "MD",
            },
        ],
        callbacks: {
            signInSuccess: () => false,
        },
    };
    const fb = firebase.initializeApp(configs);
    const fbUi = new firebaseui.auth.AuthUI(fb.auth());
    const firebaseConfig = { fb, fbUi, uiConfig };
    return {
        type: FIREBASE_EXAMPLES,
        firebaseConfig
    };
}
export function setChosenUser(user) {
    return {
        type: SET_CHOSEN_USER,
        phone: user.phone,
        roles: user.roles,
    };
}
export function setBuyersTableState(table) {
    return {
        type: SET_BUYERS_TABLE_STATE,
        rowSize: table.rowSize,
        count: table.count,
        currentPage: table.currentPage,
        search: table.search,
        orderKey: table.orderKey,
        order: table.order,
        data: table.data,
    };
}
export function setBreadcrumb(breadcrumbs) {
    return {
        type: SET_BREADCRUMB,
        breadcrumbs,
    };
}
export function setChosenSeller(phone) {
    return {
        type: SET_CHOSEN_SELLER,
        phone,
    };
}
export function appendBreadcrumb(breadcrumbs) {
    return {
        type: APPEND_BREADCRUMB,
        breadcrumbs,
    };
}
export function setToken(token) {
    return {
        type: SET_TOKEN,
        token,
    };
}
