import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import * as isEmail from "validator/lib/isEmail";
import { DataValidator } from "../../utils/DataValidator";
import AutoComplete from "material-ui/AutoComplete";
import { HttpClient } from "../../utils/HttpClient";
import { connect } from "react-redux";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import CircularProgress from "material-ui/CircularProgress";
const ReactToastify = require("react-toastify");
class AddBusinessDialog extends React.Component {
    constructor(props) {
        super(props);
        this.dataSource = [];
        this.initialState = {
            title: "",
            emails: [""],
            emailsCount: 1,
            id: "",
            emailsErrorText: [],
            acceptBtnDisabled: true,
            ownerPhone: "",
            ownerErrorText: "",
            searchText: "",
            owners: [],
            loading: true,
        };
        this.state = this.initialState;
    }
    render() {
        return (React.createElement(Dialog, { title: "Add New Business", actions: this.renderDialogActions, modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) }, this.renderForm()));
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.opened) {
            this.setState(this.initialState);
        }
        else {
            this.fetchOwnersData();
        }
    }
    fetchOwnersData() {
        HttpClient.getJSON(`${this.props.baseUrl}/owner/list`).then((result) => {
            this.setState({ owners: result.owners, loading: false });
            this.dataSource = result.owners.map((owner) => owner.phone);
        }).catch((err) => {
            this.props.toggleDialog();
            ReactToastify.toast.error("Can't load form data, try one more time. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "business-dialog-actions" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: () => this.props.toggleDialog() }),
                React.createElement(FlatButton, { label: "Accept", primary: true, onClick: !(this.state.acceptBtnDisabled || this.handleAcceptButtonState)
                        ? () => this.props.onAddBtnClick(this.state.title, this.state.emails, this.state.id, this.state.ownerPhone)
                        : () => false, disabled: this.state.acceptBtnDisabled || this.handleAcceptButtonState }))
        ];
    }
    renderForm() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 40, thickness: 5, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-20px",
                    marginTop: "-20px",
                } }));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(TextField, { id: "title-input", autoFocus: true, floatingLabelText: "Enter title", fullWidth: true, value: this.state.title, onChange: (e) => this.handleUpdateTitleInput(e) }),
                " ",
                React.createElement("br", null),
                React.createElement(TextField, { id: "id-input", floatingLabelText: "Enter id", fullWidth: true, value: this.state.id, onChange: (e) => this.handleUpdateIdInput(e) }),
                " ",
                React.createElement("br", null),
                React.createElement(AutoComplete, { id: "owner-phone-number", floatingLabelText: "Owner phone number", errorText: this.state.ownerErrorText, hintText: "Type national number for hint", dataSource: this.dataSource, maxSearchResults: 5, searchText: this.state.searchText, onUpdateInput: (value) => this.handleUpdateOwnerInput(value), filter: (searchText, key) => (key.indexOf(searchText) !== -1), openOnFocus: true }),
                React.createElement("span", { className: "add-business-dialog-span" }, this.getOwnerName()),
                this.renderEmailsTextFields()));
        }
    }
    handleUpdateTitleInput(e) {
        this.setState({ title: e.target.value });
    }
    handleUpdateIdInput(e) {
        this.setState({ id: e.target.value });
    }
    handleUpdateOwnerInput(number) {
        DataValidator.phoneNumberValidator(number).then((result) => {
            this.setState({ ownerPhone: result, ownerErrorText: "", searchText: number });
        }).catch((err) => {
            this.setState({ ownerPhone: "", ownerErrorText: err, searchText: number });
        });
    }
    handleUpdateEmailInput(value, itemNumber) {
        const emails = this.state.emails.slice();
        emails[itemNumber] = value;
        const emailsErrorText = this.state.emailsErrorText.slice();
        if (isEmail(value)) {
            emailsErrorText[itemNumber] = "";
            this.setState({
                emailsErrorText,
                emails,
                acceptBtnDisabled: false,
            });
        }
        else {
            emailsErrorText[itemNumber] = "Please input a valid email";
            this.setState({
                emailsErrorText,
                emails,
                acceptBtnDisabled: true,
            });
        }
    }
    get handleAcceptButtonState() {
        return !(this.state.title && this.state.id && this.state.ownerErrorText === "");
    }
    renderEmailsTextFields() {
        const textFields = [];
        for (let i = 0; i < this.state.emailsCount; i++) {
            if (i === this.state.emailsCount - 1) {
                textFields.push(React.createElement("div", { key: i + "lastdiv" },
                    React.createElement(TextField, { floatingLabelText: "Enter email", key: i, value: this.state.emails[i], onChange: (e) => this.handleUpdateEmailInput(e.target.value, i), errorText: this.state.emailsErrorText[i] }),
                    React.createElement(FlatButton, { label: "one more", key: "flatbutton" + i, primary: true, onClick: () => this.setState({ emailsCount: this.state.emailsCount + 1 }) })));
            }
            else {
                textFields.push(React.createElement("div", { key: i + "textfielddiv" },
                    React.createElement(TextField, { key: i + i, floatingLabelText: "Enter email", value: this.state.emails[i], onChange: (e) => this.handleUpdateEmailInput(e.target.value, i), errorText: this.state.emailsErrorText[i] }),
                    React.createElement("br", null)));
            }
        }
        return textFields;
    }
    getOwnerName() {
        let result;
        if (this.state.ownerPhone) {
            this.state.owners.filter((owner) => {
                if (owner.phone === this.state.ownerPhone) {
                    result = owner.name + " " + owner.surname;
                }
            });
        }
        else {
            result = "";
        }
        return result;
    }
}
export default connect(mapStateToPropsBaseUrl)(AddBusinessDialog);
