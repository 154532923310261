import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import * as lodash from "lodash";
import { HttpClient } from "../utils/HttpClient";
import Card from "material-ui/Card/Card";
import RequestsTable from "../components/UpgradeRequestsComponents/RequestsTable";
import CircularProgress from "material-ui/CircularProgress";
import Tabs, { Tab } from "material-ui/Tabs";
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Pending"] = 0] = "Pending";
    TabNames[TabNames["Resolved"] = 1] = "Resolved";
})(TabNames || (TabNames = {}));
class UpgradeRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            upgradeRequestsForResolved: [],
            upgradeRequestsForPending: [],
            selectedTab: TabNames.Pending,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Upgrade requests", "/upgrades")]));
    }
    componentDidMount() {
        this.fetchData("pending");
    }
    componentDidUpdate(prevProps, prevState) {
        const { selectedTab } = this.state;
        if (prevState.selectedTab !== selectedTab) {
            this.fetchData(selectedTab === TabNames.Pending
                ? "pending"
                : "resolved");
        }
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            React.createElement(Card, { className: "card-full-size" }, this.renderDataTable())));
    }
    fetchData(endpoint) {
        if (endpoint === "pending") {
            this.setState({
                loading: true,
                upgradeRequestsForPending: [],
            });
        }
        else {
            this.setState({
                loading: true,
                upgradeRequestsForResolved: [],
            });
        }
        HttpClient.getJSON(`${this.props.baseUrl}/wallet/upgrade/${endpoint}`).then((result) => {
            if (endpoint === "pending") {
                this.setState({
                    loading: false,
                    upgradeRequestsForPending: lodash.isEmpty(result) ? [] : result.requests,
                });
            }
            else {
                this.setState({
                    loading: false,
                    upgradeRequestsForResolved: lodash.isEmpty(result) ? [] : result.requests,
                });
            }
        }).catch((err) => {
            ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({ loading: false });
        });
    }
    renderDataTable() {
        const { selectedTab, loading, upgradeRequestsForPending, upgradeRequestsForResolved } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else if (selectedTab === TabNames.Pending) {
            return (React.createElement(RequestsTable, { data: upgradeRequestsForPending, fetchData: () => this.fetchData("pending") }));
        }
        else if (selectedTab === TabNames.Resolved) {
            return (React.createElement(RequestsTable, { data: upgradeRequestsForResolved, fetchData: () => this.fetchData("resolved") }));
        }
    }
    renderTabs() {
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "250px" } },
                React.createElement(Tab, { label: "Pending", value: TabNames.Pending }),
                React.createElement(Tab, { label: "Resolved", value: TabNames.Resolved }))));
    }
}
export default connect(mapStateToPropsBaseUrl)(UpgradeRequests);
