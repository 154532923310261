import * as React from "react";
import { connect } from "react-redux";
import { downloadReport, getBreadcrumbObject, getEndOfDayMillis, getStartOfDayMillis } from "../../utils/DataHelpers";
import { appendBreadcrumb } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import CardActions from "material-ui/Card/CardActions";
import { Card, CardHeader, CardText } from "material-ui/Card";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DateRangePicker from "../DateRangePicker";
const ReactToastify = require("react-toastify");
class BNMReport extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            startDate: null,
            endDate: null,
            fileName: "",
            reportFormat: "xlsx",
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: "MS Excel", fileExt: "xlsx" },
            ];
            return reportFormats.map(({ fileExt, name }) => React.createElement(MenuItem, { key: fileExt, insetChildren: true, value: fileExt, primaryText: name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("BNM Report", "/reports/generate/bnm")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        this.setState({ loading: true, generated: false });
        const query = {
            dateFrom: getStartOfDayMillis(this.state.startDate),
            dateTo: getEndOfDayMillis(this.state.endDate),
            reportFormat: this.state.reportFormat,
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/bnm`, query)
            .then((result) => {
            if (!result.fileName) {
                this.setState({ loading: false, generated: false }),
                    ReactToastify.toast.info("No data for this period.");
                return;
            }
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({
                loading: false,
                generated: true,
                fileName: `Raport BNM ${result.fileName}`,
            });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create BNM report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        const { reportFormat } = this.state;
        const { token, baseUrl } = this.props;
        downloadReport(fileName, reportFormat, token, baseUrl);
    }
    renderCardActions() {
        const { loading } = this.state;
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: this.isButtonDisabled() || loading })));
    }
    renderActionCard() {
        const { startDate, endDate, loading } = this.state;
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "BNM Report" }),
            React.createElement(CardText, null,
                React.createElement(DateRangePicker, { startDate: startDate, endDate: endDate, disabled: loading, onStartDateChange: this.handleStartDateChange.bind(this), onEndDateChange: this.handleEndDateChange.bind(this) }),
                React.createElement(SelectField, { id: "report-format", hintText: "Select report format", style: { position: "absolute" }, onChange: (e, index, value) => this.handleReportFormatChange(e, index, value), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: "Report format" }, this.renderMenuItems())),
            this.renderLoading(),
            this.renderCardActions()));
    }
    handleStartDateChange(e, date) {
        this.setState({ startDate: date });
    }
    handleEndDateChange(e, date) {
        this.setState({ endDate: date });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value, fileName: null });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    isButtonDisabled() {
        const { startDate, endDate } = this.state;
        return !(startDate && endDate);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(BNMReport);
