import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { Card } from "material-ui/Card";
import * as lodash from "lodash";
import { HttpClient } from "../utils/HttpClient";
import WithdrawalRequestsTable from "../components/WithdrawalRequestsComponents/WithdrawalRequestsTable";
import CircularProgress from "material-ui/CircularProgress";
import AddWithdrawRequestDialog from "../components/WithdrawalRequestsComponents/AddWithdrawRequestDialog";
const ReactToastify = require("react-toastify");
class WithdrawalRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            withdrawalRequests: [],
            addWithdrawDialogOpened: false,
            items: [],
            rowSize: 20,
            count: 0,
            currentPage: 1,
            search: "",
            currentBusiness: null,
            errors: null,
            isSaving: false,
            toggleDialog: false,
            detailsDialog: false,
            toggleDetailsDialog: false,
            imageIndex: 0,
            files: [],
            services: [],
            imagesLightboxOpened: false,
            showFileError: null,
            orderKey: "",
            order: "",
            showDisabledButtons: false
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Withdrawal requests", "/withdrawals")]));
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Card, { className: "card-full-size" },
                this.renderDataTable(),
                this.renderAddWithdrawRequestDialog())));
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/withdrawal`).then((result) => {
            this.setState({
                loading: false,
                withdrawalRequests: lodash.isEmpty(result) ? [] : result.requests,
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    addNewWithdrawRequest(walletPhone, amount, requestNumber) {
        this.setState({ showDisabledButtons: true });
        const postData = JSON.stringify({
            walletPhone,
            amount: Number(amount),
            requestNumber,
        });
        HttpClient.post(`${this.props.baseUrl}/withdrawal`, postData)
            .then(() => {
            this.setState({ addWithdrawDialogOpened: false, showDisabledButtons: false });
            this.fetchData();
            ReactToastify.toast.success("Request was successfully added!");
        })
            .catch((err) => {
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("Couldn't add request! Wallet not found");
                    break;
                case 406:
                    ReactToastify.toast.error("Couldn't add request! Wallet has insufficient funds or poor type");
                    break;
                case 400:
                    ReactToastify.toast.error("Couldn't add request! Invalid amount");
                    break;
                default:
                    ReactToastify.toast.error("Couldn't add request! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
    renderAddWithdrawRequestDialog() {
        const { showDisabledButtons } = this.state;
        return (React.createElement(AddWithdrawRequestDialog, Object.assign({ opened: this.state.addWithdrawDialogOpened, onAddClick: this.addNewWithdrawRequest.bind(this) }, { showDisabledButtons }, { toggleDialog: this.toggleAddWithdrawRequestDialog() })));
    }
    renderDataTable() {
        const { loading } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(WithdrawalRequestsTable, { openNewRequestDialog: this.toggleAddWithdrawRequestDialog(), data: this.state.withdrawalRequests, fetchData: this.fetchData.bind(this) }));
        }
    }
    toggleAddWithdrawRequestDialog() {
        return () => this.setState((prevState) => ({ addWithdrawDialogOpened: !prevState.addWithdrawDialogOpened, showDisabledButtons: false }));
    }
}
export default connect(mapStateToPropsBaseUrl)(WithdrawalRequests);
