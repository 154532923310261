import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import AddUserDialog from "../components/UsersComponents/AddUserDialog";
import UsersTable from "../components/UsersComponents/UsersTable";
import { addUsers, deleteUser, setBreadcrumb } from "../actions/Actions";
import { connect } from "react-redux";
import { HttpClient } from "../utils/HttpClient";
import LinearProgress from "material-ui/LinearProgress";
import Card from "material-ui/Card/Card";
import EditUserDialog from "../components/UsersComponents/EditUserDialog";
import { getBreadcrumbObject } from "../utils/DataHelpers";
const ReactToastify = require("react-toastify");
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.deleteDialogActions = [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.toggleDeleteUserDialog() }),
            React.createElement(FlatButton, { label: "Delete", primary: true, onClick: () => this.deleteSelectedUser() }),
        ];
        this.state = {
            addUserDialogOpened: false,
            deleteUserDialogOpened: false,
            loading: true,
            editUserDialogOpened: false,
            user: {},
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Users", "/users")]));
    }
    render() {
        return (React.createElement("div", { className: "users-content" },
            React.createElement(Card, { className: "card-998" }, this.renderTable()),
            this.renderAddUserDialog(),
            this.renderDeleteDialog(),
            this.renderEditUserDialog()));
    }
    componentDidMount() {
        this.fetchUserData();
    }
    fetchUserData() {
        HttpClient.getJSON(`${this.props.baseUrl}/user/list`)
            .then((response) => {
            const data = {
                users: response.users,
            };
            this.props.dispatch(addUsers(data));
            this.setState({ loading: false });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load data,reload page! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    addNewUser(phone, roles) {
        this.setState({ addUserDialogOpened: false });
        const postData = JSON.stringify({ phone, roles: roles.join() });
        HttpClient.post(`${this.props.baseUrl}/user`, postData).then(() => {
            this.fetchUserData();
            ReactToastify.toast.success("User successfully added!");
        }).catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    updateSelectedUser(phone, roles) {
        this.setState({ editUserDialogOpened: false });
        const putData = JSON.stringify({ phone, roles: roles.join() });
        HttpClient.putWithData(`${this.props.baseUrl}/user/${this.props.chosenUser.phone}`, putData).then(() => {
            this.fetchUserData();
            ReactToastify.toast.info("User successfully updated!");
        }).catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    deleteSelectedUser() {
        this.setState({ deleteUserDialogOpened: false });
        HttpClient.delete(`${this.props.baseUrl}/user/${this.props.chosenUser.phone}`)
            .then(() => {
            this.props.dispatch(deleteUser(this.props.chosenUser.phone));
            ReactToastify.toast.info("User successfully deleted!");
        })
            .catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderDeleteDialog() {
        return (React.createElement(Dialog, { title: "Delete", actions: this.deleteDialogActions, modal: false, open: this.state.deleteUserDialogOpened, onRequestClose: this.toggleDeleteUserDialog() }, "Are you sure you want to delete chosen User?"));
    }
    renderTable() {
        if (this.state.loading) {
            return (React.createElement(LinearProgress, { mode: "indeterminate" }));
        }
        else {
            return (React.createElement(UsersTable, { users: this.props.users.users, fetchData: () => this.fetchUserData(), addNewUser: this.toggleAddUserDialog(), deleteUser: this.toggleDeleteUserDialog(), editUser: this.toggleEditUserDialog() }));
        }
    }
    renderAddUserDialog() {
        return (React.createElement(AddUserDialog, { opened: this.state.addUserDialogOpened, toggleModal: this.toggleAddUserDialog(), onAddBtnClick: this.addNewUser.bind(this) }));
    }
    renderEditUserDialog() {
        return (React.createElement(EditUserDialog, { opened: this.state.editUserDialogOpened, toggleModal: this.toggleEditUserDialog(), onUpdateBtnClick: this.updateSelectedUser.bind(this), user: this.props.chosenUser }));
    }
    toggleAddUserDialog() {
        return () => this.setState({ addUserDialogOpened: !this.state.addUserDialogOpened });
    }
    toggleDeleteUserDialog() {
        return () => this.setState({ deleteUserDialogOpened: !this.state.deleteUserDialogOpened });
    }
    toggleEditUserDialog() {
        return () => this.setState({
            editUserDialogOpened: !this.state.editUserDialogOpened,
            user: this.props.users,
        });
    }
}
const mapStateToProps = (state) => ({
    users: state.users,
    baseUrl: state.baseUrl,
    chosenUser: state.chosenUser,
});
export default connect(mapStateToProps)(Users);
