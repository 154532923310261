import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
class EditUserDialog extends React.Component {
    constructor(props) {
        super(props);
        this.roleTypes = [
            "Admin",
            "Support",
            "Manager",
            "Cashier",
            "Accountant"
        ];
        this.chosenUserInfo = {
            phone: "",
            roles: [],
        };
        this.state = this.chosenUserInfo;
    }
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user)) {
            this.chosenUserInfo.roles = nextProps.user.roles;
            this.chosenUserInfo.phone = nextProps.user.phone;
            this.setState(this.chosenUserInfo);
        }
    }
    render() {
        return (React.createElement(Dialog, { title: "Edit User", actions: this.renderDialogActions, modal: false, open: this.props.opened, onRequestClose: this.props.toggleModal.bind(this) }, this.renderForm()));
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "edit-user-dialog-actions" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleModal.bind(this) }),
                React.createElement(FlatButton, { label: "Update", primary: true, onClick: !this.handleButtonState ? () => {
                        this.props.onUpdateBtnClick(this.state.phone, this.state.roles);
                        this.setState(this.chosenUserInfo);
                    } : () => false, disabled: this.handleButtonState }))
        ];
    }
    renderForm() {
        return (React.createElement("div", null,
            React.createElement(TextField, { floatingLabelText: "User", fullWidth: true, value: this.state.phone, disabled: true }),
            " ",
            React.createElement("br", null),
            React.createElement("div", { onClick: () => document.body.classList.add("body") },
                React.createElement(SelectField, { id: "select-role-add", hintText: "Select a role", onChange: (e, i, v) => this.handleSelectFieldChange(v), value: this.state.roles, multiple: true, errorText: this.getSelectFieldErrorText }, this.renderMenuItems(this.state.roles)))));
    }
    handleSelectFieldChange(values) {
        this.setState({ roles: values });
    }
    renderMenuItems(values) {
        return this.roleTypes.map((role) => React.createElement(MenuItem, { key: role, insetChildren: true, checked: values && values.indexOf(role) > -1, value: role, primaryText: role }));
    }
    get handleButtonState() {
        return !(this.state.roles.length && this.getSelectFieldErrorText === "");
    }
    get getSelectFieldErrorText() {
        if (JSON.stringify(this.state.roles) === JSON.stringify(this.chosenUserInfo.roles)) {
            return "Nothing changed!";
        }
        else if (this.state.roles.length === 0) {
            return "Choose at least one role!";
        }
        else {
            return "";
        }
    }
}
export default EditUserDialog;
