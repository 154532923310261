import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { DataValidator } from "../../utils/DataValidator";
class AddNewSellerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            sellerPhone: "",
            sellerName: "",
            errorText: "",
        };
        this.state = this.initialState;
    }
    render() {
        return (React.createElement(Dialog, { title: "Add New Seller", actions: this.renderDialogActions, modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) }, this.renderAddForm()));
    }
    componentDidUpdate(prevProps) {
        if (!this.props.opened && prevProps.opened) {
            this.setState(this.initialState);
        }
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "seller-controls" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleDialog.bind(this) }),
                React.createElement(FlatButton, { label: "Accept", primary: true, onClick: () => {
                        this.props.onAddBtnClick(this.state.sellerPhone, this.state.sellerName);
                        this.setState(this.initialState);
                    }, disabled: this.getAddBtnState() }))
        ];
    }
    renderAddForm() {
        return [
            React.createElement(TextField, { autoFocus: true, key: "seller-name-input", floatingLabelText: "Enter seller name", hintText: "Boris Borisov", fullWidth: true, onChange: (e) => this.setState({ sellerName: e.target.value }) }),
            React.createElement(TextField, { key: "seller-phone-input", floatingLabelText: "Enter phone number", hintText: "373 XX XXX XXX", fullWidth: true, onChange: (e) => this.handleUpdateInput(e), errorText: this.state.errorText }),
        ];
    }
    handleUpdateInput(e) {
        DataValidator.phoneNumberValidator(e.target.value).then((result) => {
            this.setState({ sellerPhone: result, errorText: "" });
        }).catch((err) => {
            this.setState({ sellerPhone: "", errorText: err });
        });
    }
    getAddBtnState() {
        return !this.state.sellerPhone || !this.state.sellerName;
    }
}
export default AddNewSellerDialog;
