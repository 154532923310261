import * as moment from "moment";
import * as React from "react";
import * as lodash from "lodash";
import { defaultLogoUrl } from "../variables/Common";
import TableRow from "material-ui/Table/TableRow";
import TableRowColumn from "material-ui/Table/TableRowColumn";
import { Link } from "react-router-dom";
import { isNullOrUndefined } from "util";
import { UpgradeStatus } from "../interfaces/UpgradeRequest";
import { WithdrawalStatus } from "../interfaces/WithdrawalRequest";
const ReactToastify = require("react-toastify");
export function getTableColumnItem(key, label, render, sortable = true) {
    return { key, label, sortable, style: { overflow: "hidden" }, render };
}
export function getTableColumnAction(key, render) {
    return { key, style: { width: 90 }, render };
}
export function getTableCashInColumnAction(key, render) {
    return { key, style: { width: 190 }, render };
}
export function getTableColumnLogo(key) {
    return {
        key,
        style: {
            width: 60,
        },
        render: (url) => React.createElement("img", { src: url ? url : defaultLogoUrl, onError: (e) => e.target.src = defaultLogoUrl, className: "table-image" }),
    };
}
export function formatPhone(phone) {
    return phone;
}
export function formatTime(time) {
    if (!time) {
        return "";
    }
    if (typeof time === "string") {
        return moment(Number(time)).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(time).format("YYYY-MM-DD HH:mm:ss").trim();
}
export function formatDate(date) {
    if (!date) {
        return "";
    }
    if (typeof date === "string") {
        return moment(Number(date)).format("YYYY-MM-DD");
    }
    return moment(date).format("YYYY-MM-DD");
}
export function formatCurrency(value) {
    return Number(value).toFixed(2) + " MDL";
}
export function formatAmount(value) {
    return Number(value).toFixed(2);
}
export const mapStateToPropsBaseUrl = (state) => ({
    baseUrl: state.baseUrl,
});
export const mapStateToPropsFirebase = (state) => {
    return ({
        baseUrl: state.baseUrl,
        firebaseConfiguration: state.firebaseConfiguration
    });
};
export function setPageFromOffset(offset, size) {
    return offset ? ((offset / size) + 1) : 1;
}
export function getBreadcrumbObject(label, path) {
    return { label, path };
}
export function getCommissionPostData(commission) {
    const hiBefore = getCommissionDetailsFromForm(commission.hiRow);
    const hiAfter = getCommissionDetailsFromForm(commission.hiThresholdRow);
    const loBefore = getCommissionDetailsFromForm(commission.loRow);
    const loAfter = getCommissionDetailsFromForm(commission.loThresholdRow);
    return lodash.omitBy({
        hiAmountBefore: lodash.isEmpty(hiBefore) ? undefined : hiBefore,
        hiThreshold: commission.hiCheckboxRow.thresholdValue ? Number(commission.hiCheckboxRow.thresholdValue) : undefined,
        hiAmountAfter: lodash.isEmpty(hiAfter) ? undefined : hiAfter,
        loAmountBefore: lodash.isEmpty(loBefore) ? undefined : loBefore,
        loThreshold: commission.loCheckboxRow.thresholdValue ? Number(commission.loCheckboxRow.thresholdValue) : undefined,
        loAmountAfter: lodash.isEmpty(loAfter) ? undefined : loAfter,
        validFrom: commission.advancedOptions.validFrom ? moment(commission.advancedOptions.validFrom).format("x") : undefined,
        validUntil: commission.advancedOptions.validUntil ? moment(commission.advancedOptions.validUntil).format("x") : undefined,
    }, lodash.isNil);
}
export function transformCommissionResponse(commission) {
    return lodash.omitBy(Object.assign(Object.assign({}, commission), { hiAmountBefore: lodash.isEmpty(commission.hiAmountBefore) ? undefined : commission.hiAmountBefore, hiAmountAfter: lodash.isEmpty(commission.hiAmountAfter) ? undefined : commission.hiAmountAfter, loAmountBefore: lodash.isEmpty(commission.loAmountBefore) ? undefined : commission.loAmountBefore, loAmountAfter: lodash.isEmpty(commission.loAmountAfter) ? undefined : commission.loAmountAfter }), lodash.isNil);
}
function getCommissionDetailsFromForm(row) {
    return lodash.omitBy({
        fixed: row.fixedValue ? Number(row.fixedValue) : undefined,
        percent: row.percentValue ? Number(row.percentValue) : undefined,
        percentUp: row.percentUpValue ? Number(row.percentUpValue) : undefined,
        percentDown: row.percentDownValue ? Number(row.percentDownValue) : undefined,
    }, lodash.isNil);
}
export function getInfoTableRow(keyText, valueText, className) {
    return (React.createElement(TableRow, null,
        React.createElement(TableRowColumn, null,
            React.createElement("span", { className: "info-static-card-column-span" }, keyText)),
        React.createElement(TableRowColumn, { className: className }, valueText)));
}
export function getInfoTableRowWithLink(keyText, valueText, toPath) {
    return (React.createElement(TableRow, null,
        React.createElement(TableRowColumn, null,
            React.createElement("span", { className: "info-static-card-column-span" }, keyText)),
        React.createElement(TableRowColumn, null,
            React.createElement(Link, { to: toPath }, valueText))));
}
export function getName(name) {
    if (isNullOrUndefined(name) || name.trim() === "") {
        return "Is not specified";
    }
    else {
        return name;
    }
}
export function getDateFromTimestamp(timestamp) {
    if (timestamp) {
        return new Date(Number(timestamp));
    }
    else {
        return undefined;
    }
}
export function capitalizeFirstLetter(str) {
    if (str) {
        return str.replace(str[0], str[0].toUpperCase());
    }
    else {
        return str;
    }
}
export function getStatusCell(status) {
    switch (status) {
        case UpgradeStatus.ACCEPTED: {
            return React.createElement("p", { className: "status-accepted" }, capitalizeFirstLetter(status));
        }
        case UpgradeStatus.DENIED: {
            return React.createElement("p", { className: "status-denied" }, capitalizeFirstLetter(status));
        }
        case UpgradeStatus.PENDING:
        default: {
            return capitalizeFirstLetter(status);
        }
    }
}
export function getWithdrawStatusCell(status) {
    switch (status) {
        case WithdrawalStatus.ACCEPTED: {
            return React.createElement("p", { className: "status-accepted" }, status);
        }
        case WithdrawalStatus.REJECTED: {
            return React.createElement("p", { className: "status-denied" }, status);
        }
        case WithdrawalStatus.PENDING: {
            return status;
        }
    }
}
export function getNumberOrZero(value) {
    return value
        ? Number(value)
        : 0;
}
export function getStartOfDayMillis(date) {
    return moment(date).startOf("day").toDate().getTime();
}
export function getEndOfDayMillis(date) {
    return moment(date).endOf("day").toDate().getTime();
}
export function downloadReport(fileName, fileFormat, token, baseUrl) {
    const query = `?file=${fileName}&format=${fileFormat}`;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${baseUrl}/load${query}`, true);
    xhr.setRequestHeader("Authorization", token);
    xhr.responseType = "arraybuffer";
    let mimeType = "text";
    switch (fileFormat) {
        case 'pdf':
            xhr.responseType = "arraybuffer";
            mimeType = "application/pdf";
            break;
        case 'xlsx':
            xhr.responseType = "arraybuffer";
            mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            break;
        case 'csv':
        case 'txt':
            xhr.responseType = "text";
    }
    xhr.onload = function () {
        if (this.status === 200) {
            const newBlob = new Blob([this.response], { type: mimeType });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(newBlob);
            link.setAttribute('download', `${fileName}.${fileFormat}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        else {
            ReactToastify.toast.error("Could open report. Status: " + this.status);
        }
    };
    xhr.send();
}
