import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { HttpClient } from "../utils/HttpClient";
import { ServiceRiskGroup } from "../interfaces/Services";
import { setBreadcrumb } from "../actions/Actions";
import CircularProgress from "material-ui/CircularProgress";
import ServiceProfile from "../components/ServicesComponents/ServiceProfile";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            profileData: null,
            serviceNotFound: false,
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(ServiceProfile, { service: this.state.profileData, onUpdateClick: this.updateService.bind(this) }));
        }
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/services/${this.props.match.params.id}`)
            .then((result) => {
            if (result.service) {
                if (!result.service.riskGroup) {
                    result.service.riskGroup = ServiceRiskGroup.MINOR;
                }
            }
            this.setState({
                loading: false,
                profileData: result.service,
            });
            this.props.dispatch(setBreadcrumb([
                getBreadcrumbObject("Services", "/services"),
                getBreadcrumbObject(result.service.serviceName, `/services/${this.props.match.params.id}/info`),
            ]));
        })
            .catch((err) => {
            switch (err.status) {
                case 404:
                    this.setState({ loading: false, serviceNotFound: true });
                    ReactToastify.toast.error("Can't load QIWI Service! Service Not Found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't load QIWI Service! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
    updateService(updateService) {
        HttpClient.patchWithData(`${this.props.baseUrl}/services/${this.props.match.params.id}`, JSON.stringify(updateService))
            .then(() => {
            ReactToastify.toast.info("Service was successfully updated!");
            this.fetchData();
        })
            .catch((err) => {
            switch (err.status) {
                case 404:
                    this.setState({ loading: false, serviceNotFound: true });
                    ReactToastify.toast.error("Can't update QIWI Service. Service Not Found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't update QIWI Service! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(Service));
