import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
class DeleteCommissionDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.dialogActions = [
            React.createElement(FlatButton, { primary: true, label: "cancel", onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { primary: true, label: "delete", onClick: this.props.onDeleteBtnClick.bind(this) })
        ];
    }
    render() {
        return (React.createElement(Dialog, { open: this.props.opened, onRequestClose: this.props.toggleDialog, title: "Delete Commission", modal: false, actions: this.dialogActions }, "Are you sure you want to delete existing commission?"));
    }
}
export default DeleteCommissionDialog;
