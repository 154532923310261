import * as React from "react";
import { formatPhone, formatTime, getName, getStatusCell, getTableColumnItem } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import SmartDataTable from "../SmartDataTable";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
class RequestsTable extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("walletPhone", "Wallet", (phone, all) => React.createElement(Link, { to: `/upgrades/${all.id}/info` }, formatPhone(phone))),
            getTableColumnItem("whenUpdated", "Updated", (date) => formatTime(date)),
            getTableColumnItem("status", "Status", (status) => getStatusCell(status)),
            getTableColumnItem("userSurname", "Name", (surname, all) => this.formatNameAndSurname(all)),
            getTableColumnItem("whenSolved", "Solved", (date) => formatTime(date)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons() }));
    }
    formatNameAndSurname(all) {
        const { userName, userSurname } = all;
        const result = `${userName ? userName : ""} ${userSurname ? userSurname : ""}`;
        return getName(result);
    }
    getToolbarIcons() {
        return [
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default RequestsTable;
