import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import Tabs, { Tab } from "material-ui/Tabs";
import CircularProgress from "material-ui/CircularProgress";
import OwnerNotFound from "../components/OwnersComponents/OwnerNotFound";
import OwnerProfile from "../components/OwnersComponents/OwnerProfile";
import Businesses from "../components/OwnersComponents/Businesses";
import { HttpClient } from "../utils/HttpClient";
import { setBreadcrumb } from "../actions/Actions";
import { isNullOrUndefined } from "util";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Info"] = 0] = "Info";
    TabNames[TabNames["Businesses"] = 1] = "Businesses";
})(TabNames || (TabNames = {}));
class OwnerInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedTab: TabNames.Info,
            ownerNotFound: false,
            owner: null,
            businesses: [],
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            this.renderOwnerComponent()));
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/owner/${this.props.match.params.phone}`).then((result) => {
            this.props.dispatch(setBreadcrumb([
                getBreadcrumbObject("Business Owners", "/owners"),
                getBreadcrumbObject(this.getBreadcrumbLabel(result), `/owners/${this.props.match.params.phone}/info`),
            ]));
            this.setState({ loading: false, owner: result });
        }).catch((err) => {
            if (err.response.status === 404) {
                this.setState({ ownerNotFound: true, loading: false });
                ReactToastify.toast.error("Owner not found!");
            }
            else {
                ReactToastify.toast.error("Can't load owner info. Status: " +
                    JSON.stringify(err.response.status));
            }
        });
    }
    fetchOwnerBusinesses() {
        HttpClient.getJSON(`${this.props.baseUrl}/owner/list/${this.props.match.params.phone}`).then((result) => {
            this.setState({ businesses: result });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load owner's businesses. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    updateOwner(name, surname) {
        const data = JSON.stringify({ name, surname });
        HttpClient.putWithData(`${this.props.baseUrl}/owner/${this.props.match.params.phone}`, data).then((result) => {
            this.fetchData();
            ReactToastify.toast.info("Business owner successfully updated!");
        }).catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderTabs() {
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "250px" } },
                React.createElement(Tab, { label: "Info", value: TabNames.Info }),
                React.createElement(Tab, { label: "Businesses", value: TabNames.Businesses, disabled: this.state.ownerNotFound }))));
    }
    renderOwnerComponent() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else if (this.state.ownerNotFound) {
            return (React.createElement(OwnerNotFound, { phone: this.props.match.params.phone }));
        }
        else {
            return (React.createElement("div", null,
                this.state.selectedTab === TabNames.Info && React.createElement(OwnerProfile, { owner: this.state.owner, onUpdateBtnClick: this.updateOwner.bind(this) }),
                this.state.selectedTab === TabNames.Businesses && React.createElement(Businesses, { fetchData: this.fetchOwnerBusinesses.bind(this), businesses: this.state.businesses })));
        }
    }
    getBreadcrumbLabel(result) {
        if (isNullOrUndefined(result)) {
            return "";
        }
        else if (!isNullOrUndefined(result.name) || !isNullOrUndefined(result.surname)) {
            return result.name + " " + result.surname;
        }
        else {
            return result.phone;
        }
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(OwnerInfoPage));
