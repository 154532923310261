import * as React from "react";
import DataTables from 'material-ui-datatables';
import HeaderTable from './HedaerTable';
import { status } from '../../interfaces/CashByCode';
import * as moment from "moment";
import { formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import * as lodash from "lodash";
import { HttpClient } from "../../utils/HttpClient";
const ReactToastify = require("react-toastify");
import { Link } from "react-router-dom";
import CircularProgress from "material-ui/CircularProgress";
import { Typography } from "@material-ui/core";
class Archive extends React.Component {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("id", "ID", (id, all) => React.createElement(Link, { to: `/cbc/${all.id}` }, id)),
            getTableColumnItem("phone", "Telefon"),
            getTableColumnItem("amount", "Suma", (amount) => `${amount} MDL`),
            getTableColumnItem("status", "Status"),
            getTableColumnItem("createdAt", "Creat"),
            getTableColumnItem("finishedAt", "Utilizat"),
            getTableColumnItem("partner", "Partener", (partner) => {
                return (React.createElement("div", { className: "archive_table_element" },
                    React.createElement("img", { src: partner.logo, alt: partner.name }),
                    React.createElement("span", null, partner.name)));
            }),
        ];
        this.componentDidMount = () => {
            this.fetchData();
        };
        this.fetchData = (obj) => {
            const { filter: { startDate, endDate }, currentPage, orderKey, amount, phone, status, rowSize } = this.state;
            const { baseUrl } = this.props;
            const page = obj && obj.currentPage ? obj.currentPage : currentPage;
            const offset = page ? (page - 1) * rowSize : 0;
            const nowDate = new Date().getTime();
            let query = {
                order: "",
                orderKey,
                offset,
                limit: rowSize,
                amount,
                phone,
                startTime: startDate ? moment(startDate).format("x") : "",
                endTime: endDate ? moment(endDate).endOf("day").format("x") : "",
                status
            };
            if (obj && obj.date || obj && Object.keys(obj).length !== 0) {
                query = Object.assign(Object.assign({}, query), obj);
            }
            HttpClient.getJSON(`${baseUrl}/cbc/archive`, query).then((result) => {
                if (!lodash.isEmpty(result)) {
                    const changedDate = result.cbcs.map(item => {
                        return Object.assign(Object.assign({}, item), { createdAt: formatTime(new Date(item.createdAt).getTime()), endedAt: formatTime(new Date(item.finishedAt).getTime()) });
                    });
                    this.setState({
                        data: changedDate,
                        count: result.pagination.total
                    });
                }
                this.setState({ loading: false, contentLoading: false });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load system CBC Archive! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({ loading: false, contentLoading: false });
            });
        };
        this.changeDate = (date, key) => {
            const { filter } = this.state;
            const filteredData = Object.assign(Object.assign({}, filter), { [key]: date });
            const obj = {
                date: filteredData
            };
            this.setState({
                filter: filteredData
            });
            this.fetchDynamicData(obj);
        };
        this.changeInput = (value, key) => {
            const obj = {
                [key]: value
            };
            this.setState({
                [key]: value
            });
            this.fetchDynamicData(obj);
        };
        this.changePage = (num) => {
            const { currentPage } = this.state;
            const page = currentPage + (num);
            const obj = {
                currentPage: page
            };
            this.setState({
                currentPage: page
            });
            this.fetchDynamicData(obj);
        };
        this.changeSortOrder = (key) => {
            const obj = {
                orderKey: key
            };
            this.fetchDynamicData(obj);
        };
        this.refreshData = () => {
            this.fetchDynamicData();
        };
        this.fetchDynamicData = (obj) => {
            this.setState({
                contentLoading: true
            });
            this.fetchData(obj);
        };
        this.state = {
            count: 0,
            currentPage: 1,
            data: [],
            filter: {
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("day").toDate(),
            },
            loading: true,
            orderKey: "",
            amount: "",
            phone: "",
            status: "",
            rowSize: 20,
            contentLoading: false
        };
    }
    render() {
        const allStatus = ["any", ...Object.values(status)];
        const { currentPage, count, rowSize, data, filter, loading, contentLoading } = this.state;
        return (React.createElement("div", null, loading ? (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                top: "50%",
                left: "50%",
                position: "absolute",
                marginLeft: "-60px",
                marginTop: "-60px",
            } })) : (React.createElement(React.Fragment, null,
            React.createElement(HeaderTable, Object.assign({ filterData: filter, allStatus: allStatus, refresh: this.refreshData, changeDate: this.changeDate, changeInput: this.changeInput }, this.state)),
            contentLoading ? (React.createElement(CircularProgress, { size: 35, style: {
                    display: "flex",
                    margin: "auto",
                    padding: "50px 0"
                } })) : (React.createElement(React.Fragment, null, data && data.length === 0 ? (React.createElement(Typography, { style: { marginTop: 20, paddingBottom: 20, textAlign: "center" } }, " Ne pare r\u0103u, nu s-au g\u0103sit \u00EEnregistr\u0103ri potrivite ")) : (React.createElement(DataTables, { height: 'auto', selectable: false, showRowHover: true, columns: this.tableColumns, data: data, showCheckboxes: false, count: count, page: currentPage, rowSize: rowSize, rowSizeList: [20], onNextPageClick: () => this.changePage(+1), onPreviousPageClick: () => this.changePage(-1), onSortOrderChange: this.changeSortOrder }))))))));
    }
}
export default Archive;
