import * as React from "react";
import { Routes, Route, Navigate } from "react-router";
import Dashboard from "../containers/Dashboard";
import Users from "../containers/Users";
import Businesses from "../containers/Businesses";
import Transactions from "../containers/Transactions";
import BusinessInfoPage from "../containers/BusinessInfoPage";
import POSInfoPage from "../containers/POSInfoPage";
import Buyer from "../containers/Buyer";
import BusinessOwners from "../containers/BusinessOwners";
import POSReports from "../components/BusinessInfoComponents/POSReports";
import ShiftReports from "../components/BusinessInfoComponents/ShiftReports";
import Services from "../containers/Services";
import OwnerInfoPage from "../containers/OwnerInfoPage";
import TransactionInfoPage from "../containers/TransactionInfoPage";
import SellerInfoPage from "../containers/SellerInfoPage";
import Commissions from "../containers/Commissions";
import Service from "../containers/Service";
import SystemParams from "../containers/SystemParams";
import Commission from "../containers/Commission";
import WalletDailyActivity from "../components/ReportsComponents/WalletDailyActivity";
import WalletsStatus from "../components/ReportsComponents/WalletsStatus";
import WalletPeriodActivity from "../components/ReportsComponents/WalletPeriodActivity";
import SellerReports from "../components/BusinessInfoComponents/SellerReports";
import Buyers from "../containers/Buyers";
import Reports from "../containers/Reports";
import WalletsBalance from "../components/ReportsComponents/WalletsBalance";
import UpgradeRequests from "../containers/UpgradeRequests";
import WalletUpgradeRequestPage from "../containers/WalletUpgradeRequestPage";
import AccountsPeriodPayments from "../components/ReportsComponents/AccountsPeriodPayments";
import WalletsWithdrawals from "../components/ReportsComponents/WalletsWithdrawals";
import WithdrawalRequests from "../containers/WithdrawalRequests";
import WithdrawalRequestPage from "../containers/WithdrawalRequestPage";
import PendingPayments from "../containers/PendingPayments";
import PendingPaymentPage from "../containers/PendingPaymentPage";
import CnaSuspiciousTransactions from "../components/ReportsComponents/CnaSuspiciousTransactions";
import FiscOpenedAccounts from "../components/ReportsComponents/FiscOpenedAccounts";
import TurnoverAndProfit from "../components/ReportsComponents/TurnoverAndProfit";
import FiscAnnualTurnover from "../components/ReportsComponents/FiscAnnualTurnover";
import NotificationInfo from "../components/NotificationsComponents/NotificationInfo";
import Notifications from "../containers/Notifications";
import NotificationSummary from "../components/NotificationsComponents/NotificationSummary";
import Processing from "../containers/Processing";
import ProcessingPage from "../containers/ProcessingPage";
import Merchants from "../containers/Merchants";
import ArchiveUsers from "../containers/ArchiveUsers";
import ArchiveDetails from "../components/ArchiveUsersComponents/ArchiveDetails";
import SPCSB2Report from "../components/ReportsComponents/SPCSB2Report";
import SPCSB3Report from "../components/ReportsComponents/SPCSB3Report";
import CashoutBusiness from "../containers/CashoutBusiness";
import CashInRequests from "../containers/CashInRequests";
import CashInNewRequest from "../containers/CashInNewRequest";
import CashInRequestActions from "../containers/CashInRequestActions";
import BNMReport from "../components/ReportsComponents/BNMReport";
import MainBusiness from "../containers/MainBusiness";
import MainBusinessDetails from "../components/MainBusiness/MainBusinessDetails";
import Details from "../components/MainBusiness/Details";
import CashByCode from "../containers/CashByCode";
import CBCDetails from "../components/CashByCode/Details";
export const RoutesApp = ({ roles }) => {
    if (roles.indexOf("Admin") !== -1) {
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Dashboard, null) }),
            React.createElement(Route, { path: "/users", element: React.createElement(Users, null) }),
            React.createElement(Route, { path: "/owners", element: React.createElement(BusinessOwners, null) }),
            React.createElement(Route, { path: "/buyers", element: React.createElement(Buyers, null) }),
            React.createElement(Route, { path: "/buyer/:id/info", element: React.createElement(Buyer, { roles: roles }) }),
            React.createElement(Route, { path: "/buyer/:id/info/:tab", element: React.createElement(Buyer, { roles: roles }) }),
            React.createElement(Route, { path: "/business", element: React.createElement(Businesses, null) }),
            React.createElement(Route, { path: "/business/:id/info", element: React.createElement(BusinessInfoPage, null) }),
            React.createElement(Route, { path: "/business/:id/info/:tab", element: React.createElement(BusinessInfoPage, null) }),
            React.createElement(Route, { path: "/business/:id/pos_info/:externalId", element: React.createElement(POSInfoPage, null) }),
            React.createElement(Route, { path: "/business/:id/reports/pos", element: React.createElement(POSReports, null) }),
            React.createElement(Route, { path: "/business/:id/reports/shift", element: React.createElement(ShiftReports, null) }),
            React.createElement(Route, { path: "/business/:id/reports/seller", element: React.createElement(SellerReports, null) }),
            React.createElement(Route, { path: "/owners/:phone/info", element: React.createElement(OwnerInfoPage, null) }),
            React.createElement(Route, { path: "/transactions", element: React.createElement(Transactions, null) }),
            React.createElement(Route, { path: "/transactions/:type/:txId/info", element: React.createElement(TransactionInfoPage, { roles: roles }) }),
            React.createElement(Route, { path: "/seller/:id/info", element: React.createElement(SellerInfoPage, null) }),
            React.createElement(Route, { path: "/commissions", element: React.createElement(Commissions, null) }),
            React.createElement(Route, { path: "/services", element: React.createElement(Services, null) }),
            React.createElement(Route, { path: "/services/:id/info", element: React.createElement(Service, null) }),
            React.createElement(Route, { path: "/system", element: React.createElement(SystemParams, null) }),
            React.createElement(Route, { path: "/reports", element: React.createElement(Reports, null) }),
            React.createElement(Route, { path: "/reports/balance/info", element: React.createElement(WalletsBalance, null) }),
            React.createElement(Route, { path: "/reports/status/info", element: React.createElement(WalletsStatus, null) }),
            React.createElement(Route, { path: "/reports/period/info", element: React.createElement(WalletPeriodActivity, null) }),
            React.createElement(Route, { path: "/reports/daily/info", element: React.createElement(WalletDailyActivity, null) }),
            React.createElement(Route, { path: "/reports/payments/info", element: React.createElement(AccountsPeriodPayments, null) }),
            React.createElement(Route, { path: "/reports/withdrawals/info", element: React.createElement(WalletsWithdrawals, null) }),
            React.createElement(Route, { path: "/reports/cna/info", element: React.createElement(CnaSuspiciousTransactions, null) }),
            React.createElement(Route, { path: "/reports/fisc/info", element: React.createElement(FiscOpenedAccounts, null) }),
            React.createElement(Route, { path: "/reports/fisc/annual", element: React.createElement(FiscAnnualTurnover, null) }),
            React.createElement(Route, { path: "/reports/turnover/info", element: React.createElement(TurnoverAndProfit, null) }),
            React.createElement(Route, { path: "/reports/spcsb2/info", element: React.createElement(SPCSB2Report, null) }),
            React.createElement(Route, { path: "/reports/spcsb3/info", element: React.createElement(SPCSB3Report, null) }),
            React.createElement(Route, { path: "/reports/generate/bnm", element: React.createElement(BNMReport, null) }),
            React.createElement(Route, { path: "/commission/:bussId/:id/info", element: React.createElement(Commission, null) }),
            React.createElement(Route, { path: "/upgrades", element: React.createElement(UpgradeRequests, null) }),
            React.createElement(Route, { path: "/cash-in-requests", element: React.createElement(CashInRequests, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/new-request", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/details/:id", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/actions/:id/:isArchived", element: React.createElement(CashInRequestActions, { roles: roles }) }),
            React.createElement(Route, { path: "/upgrades/:id/info", element: React.createElement(WalletUpgradeRequestPage, null) }),
            React.createElement(Route, { path: "/withdrawals", element: React.createElement(WithdrawalRequests, null) }),
            React.createElement(Route, { path: "/withdrawals/:id/info", element: React.createElement(WithdrawalRequestPage, null) }),
            React.createElement(Route, { path: "/pendings", element: React.createElement(PendingPayments, null) }),
            React.createElement(Route, { path: "/pendings/:id/info", element: React.createElement(PendingPaymentPage, null) }),
            React.createElement(Route, { path: "/notifications", element: React.createElement(Notifications, null) }),
            React.createElement(Route, { path: "/notifications/:id", element: React.createElement(NotificationInfo, null) }),
            React.createElement(Route, { path: "/notifications/:id/summary", element: React.createElement(NotificationSummary, null) }),
            React.createElement(Route, { path: "/processing", element: React.createElement(Processing, null) }),
            React.createElement(Route, { path: "/processing/detail/:id", element: React.createElement(ProcessingPage, null) }),
            React.createElement(Route, { path: "/merchants", element: React.createElement(Merchants, null) }),
            React.createElement(Route, { path: "/company", element: React.createElement(MainBusiness, null) }),
            React.createElement(Route, { path: "/company/details/:name/:id", element: React.createElement(MainBusinessDetails, null) }),
            React.createElement(Route, { path: "/company/details/:name/:id/:type", element: React.createElement(MainBusinessDetails, null) }),
            React.createElement(Route, { path: "/company/detail/:type/:businessId/:companyName/:name/:id", element: React.createElement(Details, null) }),
            React.createElement(Route, { path: "/archive-users", element: React.createElement(ArchiveUsers, null) }),
            React.createElement(Route, { path: "/archive-users/:id/wallets", element: React.createElement(ArchiveDetails, null) }),
            React.createElement(Route, { path: "/cashout-company", element: React.createElement(CashoutBusiness, { roles: roles }) }),
            React.createElement(Route, { path: "/cbc", element: React.createElement(CashByCode, null) }),
            React.createElement(Route, { path: "/cbc/:id", element: React.createElement(CBCDetails, null) }),
            React.createElement(Route, { element: React.createElement(Dashboard, null) })));
    }
    else if (roles.indexOf("Support") !== -1 && roles.indexOf("Manager") !== -1) {
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Dashboard, null) }),
            React.createElement(Route, { path: "/reports", element: React.createElement(Reports, null) }),
            React.createElement(Route, { path: "/reports/balance/info", element: React.createElement(WalletsBalance, null) }),
            React.createElement(Route, { path: "/reports/status/info", element: React.createElement(WalletsStatus, null) }),
            React.createElement(Route, { path: "/reports/period/info", element: React.createElement(WalletPeriodActivity, null) }),
            React.createElement(Route, { path: "/reports/daily/info", element: React.createElement(WalletDailyActivity, null) }),
            React.createElement(Route, { path: "/reports/payments/info", element: React.createElement(AccountsPeriodPayments, null) }),
            React.createElement(Route, { path: "/reports/withdrawals/info", element: React.createElement(WalletsWithdrawals, null) }),
            React.createElement(Route, { path: "/reports/cna/info", element: React.createElement(CnaSuspiciousTransactions, null) }),
            React.createElement(Route, { path: "/reports/fisc/info", element: React.createElement(FiscOpenedAccounts, null) }),
            React.createElement(Route, { path: "/reports/turnover/info", element: React.createElement(TurnoverAndProfit, null) }),
            React.createElement(Route, { path: "/reports/fisc/annual", element: React.createElement(FiscAnnualTurnover, null) }),
            React.createElement(Route, { path: "/reports/spcsb2/info", element: React.createElement(SPCSB2Report, null) }),
            React.createElement(Route, { path: "/reports/spcsb3/info", element: React.createElement(SPCSB3Report, null) }),
            React.createElement(Route, { path: "/reports/generate/bnm", element: React.createElement(BNMReport, null) }),
            React.createElement(Route, { path: "/transactions", element: React.createElement(Transactions, null) }),
            React.createElement(Route, { path: "/buyers", element: React.createElement(Buyers, null) }),
            React.createElement(Route, { path: "/buyer/:id/info", element: React.createElement(Buyer, { roles: roles }) }),
            React.createElement(Route, { path: "/transactions/:type/:txId/info", element: React.createElement(TransactionInfoPage, { roles: roles }) }),
            React.createElement(Route, { path: "/upgrades", element: React.createElement(UpgradeRequests, null) }),
            React.createElement(Route, { path: "/upgrades/:id/info", element: React.createElement(WalletUpgradeRequestPage, null) }),
            React.createElement(Route, { path: "/withdrawals", element: React.createElement(WithdrawalRequests, null) }),
            React.createElement(Route, { path: "/withdrawals/:id/info", element: React.createElement(WithdrawalRequestPage, null) }),
            React.createElement(Route, { path: "/pendings", element: React.createElement(PendingPayments, null) }),
            React.createElement(Route, { path: "/pendings/:id/info", element: React.createElement(PendingPaymentPage, null) }),
            React.createElement(Route, { path: "/cashout-company", element: React.createElement(CashoutBusiness, { roles: roles }) }),
            React.createElement(Route, { path: "/cbc", element: React.createElement(CashByCode, null) }),
            React.createElement(Route, { path: "/cbc/:id", element: React.createElement(CBCDetails, null) }),
            React.createElement(Route, { element: React.createElement(Dashboard, null) })));
    }
    else if (roles.indexOf("Support") !== -1) {
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: "/transactions" }) }),
            React.createElement(Route, { path: "/transactions", element: React.createElement(Transactions, null) }),
            React.createElement(Route, { path: "/buyers", element: React.createElement(Buyers, null) }),
            React.createElement(Route, { path: "/buyer/:id/info", element: React.createElement(Buyer, { roles: roles }) }),
            React.createElement(Route, { path: "/transactions/:type/:txId/info", element: React.createElement(TransactionInfoPage, { roles: roles }) }),
            React.createElement(Route, { path: "/withdrawals", element: React.createElement(WithdrawalRequests, null) }),
            React.createElement(Route, { path: "/withdrawals/:id/info", element: React.createElement(WithdrawalRequestPage, null) }),
            React.createElement(Route, { path: "/upgrades", element: React.createElement(UpgradeRequests, null) }),
            React.createElement(Route, { path: "/upgrades/:id/info", element: React.createElement(WalletUpgradeRequestPage, null) }),
            React.createElement(Route, { path: "/pendings", element: React.createElement(PendingPayments, null) }),
            React.createElement(Route, { path: "/pendings/:id/info", element: React.createElement(PendingPaymentPage, null) }),
            React.createElement(Route, { path: "/cashout-company", element: React.createElement(CashoutBusiness, { roles: roles }) }),
            React.createElement(Route, { path: "/cbc", element: React.createElement(CashByCode, null) }),
            React.createElement(Route, { path: "/cbc/:id", element: React.createElement(CBCDetails, null) }),
            React.createElement(Route, { element: React.createElement(Transactions, null) })));
    }
    else if (roles.indexOf("Manager") !== -1) {
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Dashboard, null) }),
            React.createElement(Route, { path: "/cashout-business", element: React.createElement(CashoutBusiness, { roles: roles }) }),
            React.createElement(Route, { path: "/company", element: React.createElement(MainBusiness, null) }),
            React.createElement(Route, { path: "/company/details/:name/:id", element: React.createElement(MainBusinessDetails, null) }),
            React.createElement(Route, { path: "/company/detail/:type/:businessId/:companyName/:name/:id", element: React.createElement(Details, null) }),
            React.createElement(Route, { path: "/reports", element: React.createElement(Reports, null) }),
            React.createElement(Route, { path: "/reports/balance/info", element: React.createElement(WalletsBalance, null) }),
            React.createElement(Route, { path: "/reports/status/info", element: React.createElement(WalletsStatus, null) }),
            React.createElement(Route, { path: "/reports/period/info", element: React.createElement(WalletPeriodActivity, null) }),
            React.createElement(Route, { path: "/reports/daily/info", element: React.createElement(WalletDailyActivity, null) }),
            React.createElement(Route, { path: "/reports/payments/info", element: React.createElement(AccountsPeriodPayments, null) }),
            React.createElement(Route, { path: "/reports/withdrawals/info", element: React.createElement(WalletsWithdrawals, null) }),
            React.createElement(Route, { path: "/reports/cna/info", element: React.createElement(CnaSuspiciousTransactions, null) }),
            React.createElement(Route, { path: "/reports/fisc/info", element: React.createElement(FiscOpenedAccounts, null) }),
            React.createElement(Route, { path: "/reports/turnover/info", element: React.createElement(TurnoverAndProfit, null) }),
            React.createElement(Route, { path: "/reports/spcsb2/info", element: React.createElement(SPCSB2Report, null) }),
            React.createElement(Route, { path: "/reports/spcsb3/info", element: React.createElement(SPCSB3Report, null) }),
            React.createElement(Route, { path: "/reports/generate/bnm", element: React.createElement(BNMReport, null) }),
            React.createElement(Route, { path: "/reports/fisc/annual", element: React.createElement(FiscAnnualTurnover, null) }),
            React.createElement(Route, { path: "/cash-in-requests", element: React.createElement(CashInRequests, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/new-request", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/details/:id", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/actions/:id/:isArchived", element: React.createElement(CashInRequestActions, { roles: roles }) }),
            React.createElement(Route, { path: "/cbc", element: React.createElement(CashByCode, null) }),
            React.createElement(Route, { path: "/cbc/:id", element: React.createElement(CBCDetails, null) }),
            React.createElement(Route, { element: React.createElement(Dashboard, null) })));
    }
    else if (roles.indexOf("Accountant") !== -1) {
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/cash-in-requests", element: React.createElement(CashInRequests, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/new-request", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/details/:id", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/actions/:id/:isArchived", element: React.createElement(CashInRequestActions, { roles: roles }) }),
            React.createElement(Route, { path: "/cbc", element: React.createElement(CashByCode, null) }),
            React.createElement(Route, { path: "/cbc/:id", element: React.createElement(CBCDetails, null) }),
            React.createElement(Route, { element: React.createElement(CashInRequests, { roles: roles }) })));
    }
    else if (roles.indexOf("Cashier") !== -1) {
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/cash-in-requests", element: React.createElement(CashInRequests, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/new-request", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/details/:id", element: React.createElement(CashInNewRequest, { roles: roles }) }),
            React.createElement(Route, { path: "/cash-in-requests/actions/:id/:isArchived", element: React.createElement(CashInRequestActions, { roles: roles }) }),
            React.createElement(Route, { path: "/cbc", element: React.createElement(CashByCode, null) }),
            React.createElement(Route, { path: "/cbc/:id", element: React.createElement(CBCDetails, null) }),
            React.createElement(Route, { element: React.createElement(CashInRequests, { roles: roles }) })));
    }
};
