import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { HttpClient } from "../utils/HttpClient";
import Card from "material-ui/Card/Card";
import CircularProgress from "material-ui/CircularProgress";
import NotificationsTable from "../components/NotificationsComponents/NotificationsTable";
const ReactToastify = require("react-toastify");
class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            notifications: [],
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Notifications", "/notifications")]));
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        return (React.createElement(Card, { className: "card-full-size" }, this.renderDataTable()));
    }
    fetchData() {
        this.setState({
            loading: true,
        });
        const { baseUrl } = this.props;
        HttpClient.getJSON(`${baseUrl}/admin/notification/list`)
            .then((result) => {
            this.setState({
                loading: false,
                notifications: result || [],
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load notifications! Status: " +
                " Error:" + JSON.stringify(err.response && err.response.statusText));
            this.setState({ loading: false });
        });
    }
    renderDataTable() {
        return (React.createElement("div", null,
            this.state.loading && React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }),
            React.createElement(NotificationsTable, { data: this.state.notifications, reload: this.fetchData.bind(this) })));
    }
}
export default connect(mapStateToPropsBaseUrl)(Notifications);
