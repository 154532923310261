import * as React from "react";
import { formatCurrency, formatTime, getTableColumnItem, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { HttpClient } from "../../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import SmartDataTable from "../SmartDataTable";
import { connect } from "react-redux";
import Card from "material-ui/Card/Card";
import * as lodash from "lodash";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import IconButton from "material-ui/IconButton";
import { Link } from "react-router-dom";
import { RaisedButton } from "material-ui";
const ReactToastify = require("react-toastify");
class BusinessCommissions extends React.Component {
    constructor(props) {
        super(props);
        this.commissionsTableColumns = [
            getTableColumnItem("commId", "Id", (id) => React.createElement(Link, { to: `/commission/${this.props.id}/${id}/info` }, id)),
            getTableColumnItem("hiFixed", "High Commission", (p, all) => this.getHiCommissionRow(all), false),
            getTableColumnItem("loFixed", "Low Commission", (p, all) => this.getLowCommissionRow(all), false),
            getTableColumnItem("validFrom", "Valid From", (date) => this.renderValidDate(date)),
            getTableColumnItem("validUntil", "Valid Until", (date) => this.renderValidDate(date)),
        ];
        this.state = {
            loading: true,
            commissionsData: [],
        };
    }
    get renderIcons() {
        return [
            React.createElement("div", null,
                React.createElement(Link, { to: `/commission/${this.props.id}/new/info` },
                    React.createElement(RaisedButton, { label: "Add commission", primary: true }))),
            React.createElement(IconButton, { onClick: this.fetchCommissions.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
    componentDidMount() {
        this.fetchCommissions();
    }
    render() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(Card, { className: "card-full-size" },
                React.createElement(SmartDataTable, { columns: this.commissionsTableColumns, data: this.state.commissionsData, toolbarIconRight: this.renderIcons })));
        }
    }
    fetchCommissions() {
        HttpClient.getJSON(`${this.props.baseUrl}/commission/business/${this.props.id}`)
            .then((result) => {
            this.setState({
                commissionsData: lodash.isEmpty(result) ? [] : result.commissions,
                loading: false,
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load data,reload page! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    getHiCommissionRow(commission) {
        if (commission.hiThreshold) {
            return this.getRowWithThreshold(commission.hiAmountBefore, commission.hiAmountAfter, commission.hiThreshold);
        }
        else {
            return this.displayCommissionPart(commission.hiAmountBefore);
        }
    }
    getLowCommissionRow(commission) {
        if (commission.loThreshold) {
            return this.getRowWithThreshold(commission.loAmountBefore, commission.loAmountAfter, commission.loThreshold);
        }
        else {
            return this.displayCommissionPart(commission.loAmountBefore);
        }
    }
    getRowWithThreshold(before, after, threshold) {
        return `${this.displayCommissionPart(before)} < ${formatCurrency(threshold)} < ${this.displayCommissionPart(after)}`;
    }
    displayCommissionPart(part) {
        if (lodash.isEmpty(part) || !part) {
            return "Is not specified";
        }
        else if (part.fixed) {
            return String(part.fixed);
        }
        else {
            return this.displayPercent(part.percent, part.percentDown, part.percentUp);
        }
    }
    displayPercent(percent, down, up) {
        return `${percent}% (
            Min: ${down ? down : ""},
            Max: ${up ? up : ""} )`;
    }
    renderValidDate(date) {
        if (date) {
            return formatTime(date);
        }
        else {
            return "Unlimited";
        }
    }
}
export default connect(mapStateToPropsBaseUrl)(BusinessCommissions);
