import * as React from "react";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import { connect } from "react-redux";
import { setBreadcrumb } from "../actions/Actions";
import Tabs, { Tab } from "material-ui/Tabs";
import AmountLimits from "../components/SystemParamsComponents/AmountLimits";
import P2PParams from "../components/SystemParamsComponents/P2PParams";
import Maintenance from "../components/SystemParamsComponents/Maintenance";
import WithdrawalParams from "../components/SystemParamsComponents/WithdrawalParams";
import CBCParams from "../components/SystemParamsComponents/CBCParams";
import { withRouter } from '../utils/HOC/withRouter';
var TabNames;
(function (TabNames) {
    TabNames["Limits"] = "limits";
    TabNames["P2P"] = "p2p";
    TabNames["Maintenance"] = "maintenance";
    TabNames["Withdrawal"] = "withdrawal";
    TabNames["CBC"] = "CBC";
})(TabNames || (TabNames = {}));
class SystemParams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.props.match.params.tab || TabNames.Limits,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("System Parameters", "/system")]));
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            this.renderSystemParamsComponents()));
    }
    renderTabs() {
        const { selectedTab } = this.state;
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: selectedTab, onChange: (selectedTab) => this.setState({ selectedTab }), tabItemContainerStyle: { width: "600px", justifyContent: "space-around" } },
                React.createElement(Tab, { label: "Limits", value: TabNames.Limits, className: "tab-element" }),
                React.createElement(Tab, { label: "P2P", value: TabNames.P2P, className: "tab-element" }),
                React.createElement(Tab, { label: "Maintenance", value: TabNames.Maintenance, className: "tab-element" }),
                React.createElement(Tab, { label: "Withdrawal", value: TabNames.Withdrawal, className: "tab-element" }),
                React.createElement(Tab, { label: "CBC", value: TabNames.CBC, className: "tab-element" }))));
    }
    renderSystemParamsComponents() {
        const { selectedTab } = this.state;
        return (React.createElement("div", null,
            selectedTab === TabNames.Limits && React.createElement(AmountLimits, null),
            selectedTab === TabNames.P2P && React.createElement(P2PParams, null),
            selectedTab === TabNames.Maintenance && React.createElement(Maintenance, null),
            selectedTab === TabNames.Withdrawal && React.createElement(WithdrawalParams, null),
            selectedTab === TabNames.CBC && React.createElement(CBCParams, null)));
    }
}
export default withRouter(connect()(SystemParams));
