import * as React from "react";
import Card from "material-ui/Card/Card";
import TextField from "material-ui/TextField";
import CardText from "material-ui/Card/CardText";
import CardActions from "material-ui/Card/CardActions";
import FlatButton from "material-ui/FlatButton";
import EmailList from "./ProfileComponents/EmailList";
import AddEmailDialog from "./ProfileComponents/AddEmailDialog";
import { connect } from "react-redux";
import { addBusinessInfo, addEmail, deleteEmail, updateTitle } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import { blue500 } from "material-ui/styles/colors";
import { formatPhone } from "../../utils/DataHelpers";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import RaisedButton from "material-ui/RaisedButton";
const ReactToastify = require("react-toastify");
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddEmailDialogOpened: false,
        };
    }
    render() {
        return (React.createElement("div", null,
            this.renderCards(),
            React.createElement(AddEmailDialog, { dialogOpened: this.state.isAddEmailDialogOpened, closeModal: this.toggleAddEmailDialog(), addEmail: this.addNewEmail.bind(this) })));
    }
    renderCards() {
        return (React.createElement("div", null,
            this.renderStaticCard(),
            this.renderForm()));
    }
    renderForm() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardText, null,
                React.createElement(TextField, { id: "title", value: this.props.businessInfo.title, onChange: (e) => this.props.dispatch(updateTitle(e.target.value.toString())), errorText: !this.getUpdateBtnState ? "This field is required." : "", errorStyle: { color: blue500 }, floatingLabelText: "Title" }),
                React.createElement(EmailList, { deleteItem: this.deleteEmail.bind(this), emails: this.props.businessInfo.emails }),
                React.createElement(FlatButton, { label: "Add new email", primary: true, onClick: this.toggleAddEmailDialog() })),
            this.renderCardActions()));
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, label: "Reset", onClick: () => this.onCancelButtonTap(), disabled: this.getResetBtnState, className: "info-card-raisedbutton" }),
            React.createElement(RaisedButton, { primary: true, disabled: this.getUpdateBtnState, label: "Update", onClick: () => this.onUpdateButtonTap(), className: "info-card-raisedbutton" })));
    }
    renderStaticCard() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false, multiSelectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false },
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "ID: "),
                                React.createElement("span", { style: { textAlign: "right" } }, this.props.fetchedBusinessData.business.id || "")),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "IDNO: "),
                                React.createElement("span", { style: { textAlign: "right" } }, this.props.fetchedBusinessData.business.idno || ""))),
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Owner: "),
                                React.createElement("span", { style: { textAlign: "right" } }, formatPhone(this.props.fetchedBusinessData.business.ownerPhone) || ""))))))));
    }
    onUpdateButtonTap() {
        const data = JSON.stringify({
            title: this.props.businessInfo.title,
            emails: this.props.businessInfo.emails,
        });
        HttpClient.putWithData(`${this.props.baseUrl}/business/${this.props.businessInfo.id}`, data)
            .then(() => {
            ReactToastify.toast.success("Information successfully updated!");
            this.props.fetchBusinessInfoData();
        })
            .catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.props.dispatch(addBusinessInfo(this.props.fetchedBusinessData));
        });
    }
    addNewEmail(email) {
        this.setState({ isAddEmailDialogOpened: false });
        this.props.dispatch(addEmail(email));
    }
    deleteEmail(email) {
        this.props.dispatch(deleteEmail(email));
    }
    onCancelButtonTap() {
        this.props.dispatch(addBusinessInfo(this.props.fetchedBusinessData));
    }
    toggleAddEmailDialog() {
        return () => this.setState({ isAddEmailDialogOpened: !this.state.isAddEmailDialogOpened });
    }
    get getResetBtnState() {
        return !(this.props.businessInfo.title !== this.props.fetchedBusinessData.business.title ||
            this.props.businessInfo.emails.toString() !== this.props.fetchedBusinessData.business.emails.toString());
    }
    get getUpdateBtnState() {
        return this.props.businessInfo.title ? this.getResetBtnState : true;
    }
}
const mapStateToProps = (state) => ({
    businessInfo: state.businessInfo,
    baseUrl: state.baseUrl,
});
export default connect(mapStateToProps)(Profile);
