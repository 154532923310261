import * as React from "react";
import { connect } from "react-redux";
import { appendBreadcrumb } from "../../actions/Actions";
import { downloadReport, getBreadcrumbObject, getEndOfDayMillis, getStartOfDayMillis } from "../../utils/DataHelpers";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import { HttpClient } from "../../utils/HttpClient";
import Checkbox from "material-ui/Checkbox";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DateRangePicker from "../DateRangePicker";
const ReactToastify = require("react-toastify");
class FiscOpenedAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            startDate: null,
            endDate: null,
            onlyMini: false,
            reportFormat: "txt"
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: 'TXT', fileExt: 'txt' },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("FISC Opened Accounts", "/reports/fisc/info")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        const { startDate, endDate, onlyMini } = this.state;
        this.setState({ loading: true, generated: false });
        const query = {
            from: getStartOfDayMillis(startDate),
            to: getEndOfDayMillis(endDate),
            type: onlyMini ? "mini" : "all",
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/fisc`, query)
            .then((result) => {
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({ loading: false, generated: true });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create FISC report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    renderActionCard() {
        const { onlyMini } = this.state;
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "FISC Accounts Report" }),
            React.createElement(CardText, null,
                "Generate SIA CCDE PF report where will be displayed summary about new accounts.",
                React.createElement(DateRangePicker, { startDate: this.state.startDate, endDate: this.state.endDate, disabled: this.state.loading, onStartDateChange: this.handleStartDateChange.bind(this), onEndDateChange: this.handleEndDateChange.bind(this) }),
                React.createElement("br", null),
                React.createElement("div", { style: { maxWidth: 200 } },
                    React.createElement(Checkbox, { id: "mini-wallets", label: "Only MINI Wallets", labelPosition: "left", checked: onlyMini, disabled: this.state.loading, onCheck: () => this.setState({ onlyMini: !onlyMini }) })),
                React.createElement(SelectField, { id: 'report-format', hintText: "Select report format", style: { position: 'absolute' }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: 'Report format' }, this.renderMenuItems())),
            this.renderLoading(),
            this.renderCardActions()));
    }
    handleStartDateChange(e, date) {
        this.setState({ startDate: date });
    }
    handleEndDateChange(e, date) {
        this.setState({ endDate: date });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: this.isButtonDisabled() || this.state.loading })));
    }
    isButtonDisabled() {
        const { startDate, endDate } = this.state;
        return !(startDate && endDate);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(FiscOpenedAccounts);
