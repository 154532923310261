import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class UnlinkSellerDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.deleteDialogActions = [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { label: "Remove", primary: true, onClick: () => this.onRemoveTap() }),
        ];
    }
    render() {
        return (React.createElement(Dialog, { title: "Remove Seller", actions: this.deleteDialogActions, modal: false, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) }, "Are you sure you want to remove chosen Seller from POS?"));
    }
    onRemoveTap() {
        this.props.unlinkSeller();
        this.props.toggleDialog();
    }
}
export default UnlinkSellerDialog;
