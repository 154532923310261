export default function (data, page, perPage, sort, order, search, callback) {
    const start = (page - 1) * perPage;
    const end = page * perPage;
    if (search !== "") {
        const rule = new RegExp(sanitize(search));
        const result = {
            count: data.filter((obj) => {
                for (const key in obj) {
                    if (rule.test(sanitize(obj[key]))) {
                        return true;
                    }
                }
            }).length,
            data: data.filter((obj) => {
                for (const key in obj) {
                    if (rule.test(sanitize(obj[key]))) {
                        return true;
                    }
                }
            }).sort(sortFn).slice(start, end),
        };
        callback(result);
    }
    else {
        const result = {
            count: data.length,
            data: data.sort(sortFn).slice(start, end),
        };
        callback(result);
    }
    function sortFn(a, b) {
        if (order === "asc") {
            if (a[sort] < b[sort]) {
                return -1;
            }
            if (a[sort] > b[sort]) {
                return 1;
            }
            return 0;
        }
        else if (order === "desc") {
            if (a[sort] > b[sort]) {
                return -1;
            }
            if (a[sort] < b[sort]) {
                return 1;
            }
            return 0;
        }
    }
    function sanitize(text) {
        return String(text).toLowerCase().trim();
    }
}
