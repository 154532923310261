import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class RejectPendingPaymentDialog extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.dialogActions = [
            React.createElement(FlatButton, { primary: true, label: "cancel", onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { primary: true, label: "reject", onClick: () => {
                    this.props.toggleDialog();
                    this.props.onRejectBtnClick();
                } }),
        ];
    }
    render() {
        const { opened, toggleDialog } = this.props;
        return (React.createElement(Dialog, { open: opened, onRequestClose: toggleDialog, title: "Reject payment", modal: false, actions: this.dialogActions }, "Are you sure you want to reject payment?"));
    }
}
export default RejectPendingPaymentDialog;
