import * as React from "react";
import { downloadReport, getBreadcrumbObject, getEndOfDayMillis, getStartOfDayMillis } from "../../utils/DataHelpers";
import { appendBreadcrumb } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import * as moment from "moment";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import Card, { CardActions, CardHeader, CardText } from "material-ui/Card";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import { DataValidator } from "../../utils/DataValidator";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { DatePicker } from "material-ui";
const ReactToastify = require("react-toastify");
class WalletDailyActivity extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            selectedDate: null,
            wallet: "",
            errorText: "",
            reportFormat: "xlsx",
            fileName: ""
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: 'MS Excel', fileExt: 'xlsx' },
                { name: 'PDF', fileExt: 'pdf' },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("Wallet Daily Activity", "/reports/daily/info")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generateWalletDailyReport() {
        this.setState({ loading: true, generated: false });
        const query = {
            start: getStartOfDayMillis(this.state.selectedDate),
            end: getEndOfDayMillis(this.state.selectedDate),
            phone: this.state.wallet,
            reportFormat: this.state.reportFormat
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/dailyactivity`, query)
            .then((result) => {
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({ loading: false, generated: true });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("Wallet not found!");
                    this.setState({ errorText: "Wallet doesn't exist" });
                    break;
                default:
                    ReactToastify.toast.error("Could not create wallet daily activity report. Status: " +
                        JSON.stringify(err.response.status));
                    break;
            }
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generateWalletDailyReport(), disabled: this.isButtonDisabled() || this.state.loading })));
    }
    renderActionCard() {
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "Wallet Daily Activity Report" }),
            React.createElement(CardText, null,
                "Generate wallet daily activity report where will be displayed all daily transactions for specified wallet.",
                React.createElement(DatePicker, { id: "selected-date-picker", maxDate: moment().toDate(), formatDate: (date) => moment(date).format("DD.MM.YYYY"), autoOk: true, container: "inline", disabled: this.state.loading, floatingLabelText: "Selected date:", onChange: this.handleDateChange.bind(this), value: this.state.selectedDate }),
                React.createElement("br", null),
                "Wallet: ",
                this.renderWalletInput(),
                React.createElement("br", null),
                React.createElement(SelectField, { id: 'report-format', hintText: "Select report format", style: { position: 'absolute' }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: 'Report format' }, this.renderMenuItems())),
            this.renderLoading(),
            this.renderCardActions()));
    }
    handleDateChange(event, date) {
        this.setState({
            selectedDate: date
        });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value, fileName: null });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    renderWalletInput() {
        return (React.createElement(TextField, { id: "wallets-phone-number", hintText: "Wallet's phone number", onChange: (e) => this.handleInputChange(e.target.value), errorText: this.state.errorText, disabled: this.state.loading, errorStyle: { position: "absolute", bottom: "-0.9rem" } }));
    }
    handleInputChange(wallet) {
        DataValidator.phoneNumberValidator(wallet).then((result) => {
            this.setState({ wallet: result, errorText: "" });
        }).catch((err) => {
            this.setState({ wallet: "", errorText: err });
        });
    }
    isButtonDisabled() {
        const { selectedDate, wallet, errorText } = this.state;
        const isWalletPresent = errorText === "" && wallet !== "";
        return !(isWalletPresent && selectedDate);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(WalletDailyActivity);
