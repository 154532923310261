import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { FormLabel, FormControl, FormGroup } from "@material-ui/core";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { CircularProgress, Toggle } from "material-ui";
import { getInfoTableRow, } from "../../utils/DataHelpers";
import Table, { TableBody } from "material-ui/Table";
class MerchantDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { currentMerchant, dialogTitle, onCancelClick } = this.props;
        const readOnly = currentMerchant && currentMerchant.readOnly;
        const title = readOnly ?
            this.props.currentMerchant.title + " details" : dialogTitle;
        return (React.createElement(Dialog, { title: title, actions: this.renderDialogActions, modal: true, contentStyle: { width: 600 }, bodyStyle: { border: "none" }, autoScrollBodyContent: true, open: this.props.open, onRequestClose: onCancelClick.bind(this) },
            !readOnly && this.renderForm(),
            readOnly && this.renderMerchantInfo()));
    }
    get renderDialogActions() {
        const { onCancelClick, isSaving, onOkClick, currentMerchant } = this.props;
        const readOnly = currentMerchant && currentMerchant.readOnly;
        if (readOnly) {
            return [
                React.createElement("div", { id: "merchant-read-only" },
                    React.createElement(FlatButton, { label: "Close", primary: true, onClick: onCancelClick.bind(this), disabled: isSaving }))
            ];
        }
        return [
            React.createElement("div", { id: "merchant-actions" },
                React.createElement(FlatButton, { label: "Discard", primary: true, onClick: onCancelClick.bind(this), disabled: isSaving }),
                React.createElement(FlatButton, { primary: true, label: currentMerchant && currentMerchant.id ? "Update" : "Save", onClick: onOkClick, disabled: isSaving }, isSaving && React.createElement(CircularProgress, { size: 26 })))
        ];
    }
    renderForm() {
        const { currentMerchant, isSaving, handleInputChange } = this.props;
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
            React.createElement(TextField, { id: "title-input", autoFocus: true, required: true, style: { width: "50%" }, errorText: this.findErrorText("title"), floatingLabelText: "Title", onChange: (e) => handleInputChange(e.target.value, "title"), value: currentMerchant && currentMerchant.title, disabled: isSaving }),
            React.createElement(TextField, { id: "alias-input", floatingLabelText: "Alias", style: { width: "50%" }, onChange: (e) => handleInputChange(e.target.value, "alias"), value: currentMerchant && currentMerchant.alias, disabled: isSaving, required: true, errorText: this.findErrorText("alias") }),
            React.createElement(TextField, { id: "image-url-input", floatingLabelText: "Image URL", fullWidth: true, style: { width: "50%" }, onChange: (e) => handleInputChange(e.target.value, "logo"), value: currentMerchant && currentMerchant.logo, disabled: isSaving }),
            React.createElement(SelectField, { id: "select-service", hintText: "Select a service", value: currentMerchant && currentMerchant.serviceId, style: { width: "50%" }, floatingLabelText: "Service", onChange: (e, i, v) => handleInputChange(v, "serviceId"), disabled: isSaving }, this.renderMenuItems()),
            React.createElement(SelectField, { id: "select-time", hintText: "Select time", value: currentMerchant && currentMerchant.expare_min, style: { width: "50%" }, floatingLabelText: "Time", onChange: (e, i, v) => handleInputChange(v, "expare_min"), disabled: isSaving, errorText: this.findErrorText("expare_min") }, this.renderTimeItems()),
            React.createElement(TextField, { id: "publicKey-input", required: true, errorText: this.findErrorText("publicKey"), floatingLabelText: "Public Key", fullWidth: true, multiLine: true, rows: 3, rowsMax: 3, disabled: isSaving, onChange: (e) => handleInputChange(e.target.value, "publicKey"), value: currentMerchant && currentMerchant.publicKey }),
            React.createElement(FormControl, { component: "fieldset", style: { marginTop: 10 } },
                React.createElement(FormLabel, { style: { color: "gray", fontSize: 13, fontWeight: 300 } }, "Status"),
                React.createElement(FormGroup, { style: {
                        display: "flex", flexDirection: "row", justifyContent: "space-between", position: "absolute",
                        width: "120px", marginTop: 10,
                    } },
                    React.createElement(Toggle, { label: currentMerchant && currentMerchant.active ? "Active" : "Inactive", toggled: currentMerchant && currentMerchant.active, onToggle: (e) => handleInputChange(e.target.checked, "active") })))));
    }
    findErrorText(field) {
        const { errors } = this.props;
        if (errors) {
            const error = errors.find((e) => e.field === field);
            return error ? error.text : "";
        }
        return "";
    }
    renderMerchantInfo() {
        const { currentMerchant } = this.props;
        return (React.createElement(Table, { selectable: false },
            React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false, className: "border-none" },
                getInfoTableRow("Title", currentMerchant.title, "table-column-multiline"),
                getInfoTableRow("Alias", currentMerchant.alias, "table-column-multiline"),
                getInfoTableRow("Logo URL", currentMerchant.logo, "table-column-multiline"),
                getInfoTableRow("Service", currentMerchant.serviceName, "table-column-multiline"),
                getInfoTableRow("Time", currentMerchant.expare_min ? currentMerchant.expare_min + " minutes" : "", "table-column-multiline"),
                getInfoTableRow("Public key", currentMerchant.publicKey, "table-column-multiline"),
                getInfoTableRow("Status", currentMerchant.active ? "Active" : "Inactive", "table-column-multiline"))));
    }
    renderTimeItems() {
        const result = [];
        result.push(0.5);
        for (let item = 1; item <= 30; item++) {
            result.push(item);
        }
        const minutes = result.map((value, index) => {
            const label = value >= 1 ? `${value} minutes` : `${60 * value} seconds`;
            return (React.createElement(MenuItem, { key: index, insetChildren: true, value: value, primaryText: label }));
        });
        return minutes;
    }
    renderMenuItems() {
        const { services } = this.props;
        const result = [];
        if (!services) {
            return result;
        }
        services.forEach((item) => {
            result.push(React.createElement(MenuItem, { key: item.id, insetChildren: true, value: item.id, primaryText: item.name }));
        });
        return result;
    }
}
export default MerchantDialog;
