import * as React from "react";
import Card, { CardHeader, CardText } from "material-ui/Card";
import { Link } from "react-router-dom";
class OwnerNotFound extends React.Component {
    render() {
        return (React.createElement(Card, { className: "buyer-not-found-card" },
            React.createElement(CardHeader, { title: `Owner not found`, subtitle: `Error 404` }),
            React.createElement(CardText, null,
                "Owner with ",
                this.props.phone,
                " phone number doesn't exist. Click ",
                React.createElement(Link, { to: "/owners" }, "Here"),
                " to return, ore use \"Back Arrow\" button in your browser.")));
    }
}
export default OwnerNotFound;
