import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
class ChangeBuyerTypeDialog extends React.Component {
    constructor(props) {
        super(props);
        this.statusTypes = [
            { value: "anonymous", displayedName: "MINI" },
            { value: "digital", displayedName: "OPTIM" },
            { value: "normal", displayedName: "PREMIUM" },
        ];
        this.state = {
            status: this.props.currentStatus,
        };
    }
    render() {
        return (React.createElement(Dialog, { className: "wallet-actions", title: "Change Wallet status", actions: this.getDialogBtns(), modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) }, this.renderSelectField()));
    }
    getDialogBtns() {
        return [
            React.createElement("div", { id: "buyer-dialog-control" },
                React.createElement(FlatButton, { label: "CANCEL", primary: true, onClick: () => this.props.toggleDialog() }),
                React.createElement(FlatButton, { label: "CHANGE", primary: true, disabled: this.state.status.value === this.props.currentStatus.value, onClick: () => this.props.onChangeBtnClick(this.state.status.value) }))
        ];
    }
    renderSelectField() {
        return (React.createElement(SelectField, { id: "change-type-dropdown", hintText: "Select new status", onChange: (e, i, v) => this.handleSelectFieldChange(e, i, v), value: this.state.status.value }, this.renderMenuItems()));
    }
    renderMenuItems() {
        return this.statusTypes.map((status) => React.createElement(MenuItem, { key: status.value, insetChildren: true, value: status.value, checked: status.value === this.state.status.value, primaryText: status.displayedName }));
    }
    handleSelectFieldChange(event, index, values) {
        this.setState({ status: this.statusTypes[index] });
    }
}
export default ChangeBuyerTypeDialog;
