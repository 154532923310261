import * as React from "react";
import { appendBreadcrumb } from "../../actions/Actions";
import { getBreadcrumbObject } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import { HttpClient } from "../../utils/HttpClient";
const ReactToastify = require("react-toastify");
import * as lodash from "lodash";
import CBCTx from '../../components/TransactionsComponents/CBCTx';
import CircularProgress from "material-ui/CircularProgress";
import { withRouter } from '../../utils/HOC/withRouter';
class Details extends React.Component {
    constructor(props) {
        super(props);
        this.componentDidMount = () => {
            this.fetchData();
        };
        this.fetchData = () => {
            const { baseUrl, match: { params: { id } } } = this.props;
            HttpClient.getJSON(`${baseUrl}/cbc/details/${id}`).then((result) => {
                if (!lodash.isEmpty(result)) {
                    this.setState({
                        txInfoData: result
                    });
                }
                this.setState({ loading: false });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load system CBC Archive! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({ loading: false });
            });
        };
        this.state = {
            loading: true,
            txInfoData: null
        };
        this.props.dispatch(appendBreadcrumb([
            getBreadcrumbObject(`${this.props.match.params.id}`, `/cbc/${this.props.match.params.id}/`),
        ]));
    }
    render() {
        const { loading, txInfoData } = this.state;
        return (React.createElement("div", null, loading ? (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                top: "50%",
                left: "50%",
                position: "absolute",
                marginLeft: "-60px",
                marginTop: "-60px",
            } })) : (txInfoData ? (React.createElement(CBCTx, { txData: txInfoData, showAll: true })) : "")));
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
});
export default withRouter(connect(mapStateToProps)(Details));
