import * as React from "react";
import { Card, CardText } from "material-ui/Card";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
class Message extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { message } = this.props;
        return (React.createElement(Card, null,
            React.createElement(CardText, null,
                React.createElement(Typography, { variant: "h6", gutterBottom: true, className: "control-typography" },
                    this.getLanguage(),
                    message.lang === "ro" && React.createElement("span", { style: { color: "red", fontSize: "10pt", marginLeft: 10 } }, this.isValid() ? "" : "(*required)")),
                React.createElement(TextField, { id: "subject-input", label: "Subject", fullWidth: true, value: message.title, onChange: (e) => this.props.handleChange(message.lang, "title", e.target.value) }),
                React.createElement(ReactQuill, { value: message.body || "", onChange: (value) => this.props.handleChange(message.lang, "body", value), theme: "snow", style: { marginTop: 20 }, placeholder: "Message text..." }))));
    }
    isValid() {
        const { message } = this.props;
        return message.title && message.title !== "" &&
            message.body && message.body !== "" && message.body.replace(/<(.|\n)*?>/g, "").trim().length !== 0;
    }
    getLanguage() {
        const { message } = this.props;
        switch (message.lang) {
            case "en":
                return "English";
            case "ru":
                return "Russian";
            default:
                return "Romanian";
        }
    }
}
export default Message;
