import { DataValidator } from "../utils/DataValidator";
import { getDateFromTimestamp } from "../utils/DataHelpers";
import * as lodash from "lodash";
export const SET_FIX_COMM_AMOUNT = "SET_FIX_COMM_AMOUNT";
export const SET_PERC_COMM_AMOUNT = "SET_PERC_COMM_AMOUNT";
export const SET_PERC_UP_COMM_AMOUNT = "SET_PERC_UP_COMM_AMOUNT";
export const SET_PERC_DOWN_COMM_AMOUNT = "SET_PERC_DOWN_COMM_AMOUNT";
export const SET_HI_ROW_COMMISSION = "SET_HI_ROW_COMMISSION";
export const SET_HI_CHECKBOX_ROW = "SET_HI_CHECKBOX_ROW";
export const SET_LO_CHECKBOX_ROW = "SET_LO_CHECKBOX_ROW";
export const SET_HI_THRESHOLD_ROW_COMMISSION = "SET_HI_THRESHOLD_ROW_COMMISSION";
export const SET_LO_ROW_COMMISSION = "SET_LO_ROW_COMMISSION";
export const SET_LO_THRESHOLD_ROW_COMMISSION = "SET_LO_THRESHOLD_ROW_COMMISSION";
export const ENABLE_COMMISSION_WITH_THRESHOLD = "ENABLE_COMMISSION_WITH_THRESHOLD";
export const DISABLE_COMMISSION_WITH_THRESHOLD = "DISABLE_COMMISSION_WITH_THRESHOLD";
export const SET_THRESHOLD = "SET_THRESHOLD";
export const SET_COMMISSION = "SET_COMMISSION";
export const SET_VALID_UNTIL = "SET_VALID_UNTIL";
export const SET_VALID_FROM = "SET_VALID_FROM";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
const ENABLE_FIXED_AND_PERCENT_INPUTS = "ENABLE_FIXED_AND_PERCENT_INPUTS";
const DISABLE_AND_CLEAR_THRESHOLD_INPUTS = "DISABLE_AND_CLEAT_INPUTS";
const TOGGLE_LIMIT_INPUTS = "TOGGLE_LIMIT_INPUTS";
export function setCommFixAmount(amount) {
    return {
        type: SET_FIX_COMM_AMOUNT,
        amount,
    };
}
export function setCommPercAmount(amount) {
    return {
        type: SET_PERC_COMM_AMOUNT,
        amount,
    };
}
export function setCommPercUpAmount(amount) {
    return {
        type: SET_PERC_UP_COMM_AMOUNT,
        amount,
    };
}
export function setCommPercDownAmount(amount) {
    return {
        type: SET_PERC_DOWN_COMM_AMOUNT,
        amount,
    };
}
export function enableCommWithThreshold() {
    return {
        type: ENABLE_COMMISSION_WITH_THRESHOLD,
    };
}
export function disableCommWithThreshold() {
    return {
        type: DISABLE_COMMISSION_WITH_THRESHOLD,
    };
}
export function setThreshold(payload) {
    return {
        type: SET_THRESHOLD,
        payload,
    };
}
export function setValidUntil(date) {
    return {
        type: SET_VALID_UNTIL,
        date,
    };
}
export function setValidFrom(date) {
    return {
        type: SET_VALID_FROM,
        date,
    };
}
export function setHiRowCommission(rowAction) {
    return {
        type: SET_HI_ROW_COMMISSION,
        rowAction,
    };
}
export function setHiThresholdRowCommission(rowAction) {
    return {
        type: SET_HI_THRESHOLD_ROW_COMMISSION,
        rowAction,
    };
}
export function setLoRowCommission(rowAction) {
    return {
        type: SET_LO_ROW_COMMISSION,
        rowAction,
    };
}
export function setLoThresholdRowCommission(rowAction) {
    return {
        type: SET_LO_THRESHOLD_ROW_COMMISSION,
        rowAction,
    };
}
export function setHiCheckboxRow(rowAction) {
    return {
        type: SET_HI_CHECKBOX_ROW,
        rowAction,
    };
}
export function setLoCheckboxRow(rowAction) {
    return {
        type: SET_LO_CHECKBOX_ROW,
        rowAction,
    };
}
export function setCommission(commission) {
    return {
        type: SET_COMMISSION,
        commission,
    };
}
export function setInitialState() {
    return {
        type: SET_INITIAL_STATE,
    };
}
function handleThresholdCheckChange(action) {
    switch (action.type) {
        case SET_THRESHOLD:
            return action.payload ? { type: ENABLE_FIXED_AND_PERCENT_INPUTS } : { type: DISABLE_AND_CLEAR_THRESHOLD_INPUTS };
        case DISABLE_COMMISSION_WITH_THRESHOLD:
            return { type: DISABLE_AND_CLEAR_THRESHOLD_INPUTS };
        default:
            return {
                type: "TO_DEFAULT",
            };
    }
}
const initialRowState = {
    fixedValue: "",
    percentValue: "",
    percentUpValue: "",
    percentDownValue: "",
    fixedValueErr: "",
    percentValueErr: "",
    percentUpValueErr: "",
    percentDownValueErr: "",
    fixedDisabled: false,
    percentDisabled: false,
    percentDownDisabled: true,
    percentUpDisabled: true,
};
const initialThresholdRowState = Object.assign(Object.assign({}, initialRowState), { fixedDisabled: true, percentDisabled: true });
const initialCheckboxRow = {
    thresholdEnabled: false,
    thresholdErr: "",
    thresholdValue: "",
};
const initialAdvancedOptions = {
    validUntil: null,
    validFrom: null,
};
export function commissionRowReducer(state = initialRowState, action) {
    switch (action.type) {
        case SET_FIX_COMM_AMOUNT:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { fixedValue: action.amount, fixedValueErr: DataValidator.isFloat(action.amount), percentDisabled: !!action.amount }));
        case SET_PERC_COMM_AMOUNT:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { percentValue: action.amount, percentValueErr: DataValidator.isFloat(action.amount, "percents"), percentUpDisabled: !action.amount, percentDownDisabled: !action.amount, fixedDisabled: !!action.amount, percentUpValue: action.amount ? state.percentUpValue : "", percentDownValue: action.amount ? state.percentDownValue : "", percentUpValueErr: action.amount ? state.percentUpValueErr : "", percentDownValueErr: action.amount ? state.percentDownValueErr : "" }));
        case SET_PERC_UP_COMM_AMOUNT:
            const downLimitErrText = DataValidator.isFloat(action.amount, "downLimit", state.percentDownValue);
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { percentUpValue: action.amount, percentUpValueErr: downLimitErrText, percentDownValueErr: downLimitErrText ? state.percentDownValueErr : DataValidator.isFloat(state.percentDownValue) }));
        case SET_PERC_DOWN_COMM_AMOUNT:
            const highLimitErrText = DataValidator.isFloat(action.amount, "highLimit", state.percentUpValue);
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { percentDownValue: action.amount, percentDownValueErr: highLimitErrText, percentUpValueErr: highLimitErrText ? state.percentUpValueErr : DataValidator.isFloat(state.percentUpValue) }));
        case ENABLE_FIXED_AND_PERCENT_INPUTS:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { fixedDisabled: false, percentDisabled: false }));
        case DISABLE_AND_CLEAR_THRESHOLD_INPUTS:
            return Object.assign({}, state, Object.assign({}, initialThresholdRowState));
        default:
            return state;
    }
}
export function commissionCheckboxRowReducer(state = initialCheckboxRow, action) {
    switch (action.type) {
        case SET_THRESHOLD:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { thresholdValue: action.payload, thresholdErr: DataValidator.isFloat(action.payload) }));
        case ENABLE_COMMISSION_WITH_THRESHOLD:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { thresholdEnabled: true }));
        case DISABLE_COMMISSION_WITH_THRESHOLD:
            return Object.assign({}, state, Object.assign(Object.assign({}, state), { thresholdEnabled: false, thresholdErr: "", thresholdValue: "" }));
        default:
            return state;
    }
}
const initialFormState = {
    hiRow: initialRowState,
    hiCheckboxRow: initialCheckboxRow,
    hiThresholdRow: initialThresholdRowState,
    loRow: initialRowState,
    loCheckboxRow: initialCheckboxRow,
    loThresholdRow: initialThresholdRowState,
    advancedOptions: initialAdvancedOptions,
};
export function commissionFormReducer(state = initialFormState, action) {
    switch (action.type) {
        case SET_HI_ROW_COMMISSION:
            return Object.assign(Object.assign({}, state), { hiRow: commissionRowReducer(state.hiRow, action.rowAction) });
        case SET_HI_THRESHOLD_ROW_COMMISSION:
            return Object.assign(Object.assign({}, state), { hiThresholdRow: commissionRowReducer(state.hiThresholdRow, action.rowAction) });
        case SET_LO_ROW_COMMISSION:
            return Object.assign(Object.assign({}, state), { loRow: commissionRowReducer(state.loRow, action.rowAction) });
        case SET_LO_THRESHOLD_ROW_COMMISSION:
            return Object.assign(Object.assign({}, state), { loThresholdRow: commissionRowReducer(state.loThresholdRow, action.rowAction) });
        case SET_HI_CHECKBOX_ROW:
            return Object.assign(Object.assign({}, state), { hiCheckboxRow: commissionCheckboxRowReducer(state.hiCheckboxRow, action.rowAction), hiThresholdRow: commissionRowReducer(state.hiThresholdRow, handleThresholdCheckChange(action.rowAction)) });
        case SET_LO_CHECKBOX_ROW:
            return Object.assign(Object.assign({}, state), { loCheckboxRow: commissionCheckboxRowReducer(state.loCheckboxRow, action.rowAction), loThresholdRow: commissionRowReducer(state.loThresholdRow, handleThresholdCheckChange(action.rowAction)) });
        case SET_VALID_UNTIL:
            return Object.assign(Object.assign({}, state), { advancedOptions: Object.assign({}, {
                    validFrom: state.advancedOptions.validFrom,
                    validUntil: action.date,
                }) });
        case SET_VALID_FROM:
            return Object.assign(Object.assign({}, state), { advancedOptions: Object.assign({}, {
                    validFrom: action.date,
                    validUntil: state.advancedOptions.validUntil,
                }) });
        case SET_INITIAL_STATE:
            return Object.assign({}, initialFormState);
        case SET_COMMISSION:
            if (!action.commission || lodash.isEmpty(action.commission)) {
                return Object.assign({}, initialFormState);
            }
            const { hiThreshold, hiAmountBefore, hiAmountAfter, loThreshold, loAmountBefore, loAmountAfter, validUntil, validFrom, } = action.commission;
            return Object.assign({}, state, {
                hiRow: Object.assign({}, Object.assign(Object.assign({}, initialRowState), { fixedValue: hiAmountBefore ? hiAmountBefore.fixed || "" : "", percentValue: hiAmountBefore ? hiAmountBefore.percent || "" : "", percentUpValue: hiAmountBefore ? hiAmountBefore.percentUp || "" : "", percentDownValue: hiAmountBefore ? hiAmountBefore.percentDown || "" : "", percentDownDisabled: hiAmountBefore ? !hiAmountBefore.percent : true, percentUpDisabled: hiAmountBefore ? !hiAmountBefore.percent : true, fixedDisabled: hiAmountBefore ? !!hiAmountBefore.percent : false, percentDisabled: hiAmountBefore ? !!hiAmountBefore.fixed : false })),
                hiThresholdRow: Object.assign({}, Object.assign(Object.assign({}, initialRowState), { fixedValue: hiAmountAfter ? hiAmountAfter.fixed || "" : "", percentValue: hiAmountAfter ? hiAmountAfter.percent || "" : "", percentUpValue: hiAmountAfter ? hiAmountAfter.percentUp || "" : "", percentDownValue: hiAmountAfter ? hiAmountAfter.percentDown || "" : "", percentDownDisabled: hiAmountAfter ? !hiAmountAfter.percent : true, percentUpDisabled: hiAmountAfter ? !hiAmountAfter.percent : true, fixedDisabled: hiAmountAfter ? !hiThreshold || !!hiAmountAfter.percent : true, percentDisabled: hiAmountAfter ? !hiThreshold || !!hiAmountAfter.fixed : true })),
                hiCheckboxRow: Object.assign({}, Object.assign(Object.assign({}, initialCheckboxRow), { thresholdValue: hiThreshold ? hiThreshold : "", thresholdEnabled: !!hiThreshold })),
                loRow: Object.assign({}, Object.assign(Object.assign({}, initialRowState), { fixedValue: loAmountBefore ? loAmountBefore.fixed || "" : "", percentValue: loAmountBefore ? loAmountBefore.percent || "" : "", percentUpValue: loAmountBefore ? loAmountBefore.percentUp || "" : "", percentDownValue: loAmountBefore ? loAmountBefore.percentDown || "" : "", percentDownDisabled: loAmountBefore ? !loAmountBefore.percent : true, percentUpDisabled: loAmountBefore ? !loAmountBefore.percent : true, fixedDisabled: loAmountBefore ? !!loAmountBefore.percent : false, percentDisabled: loAmountBefore ? !!loAmountBefore.fixed : false })),
                loThresholdRow: Object.assign({}, Object.assign(Object.assign({}, initialRowState), { fixedValue: loAmountAfter ? loAmountAfter.fixed || "" : "", percentValue: loAmountAfter ? loAmountAfter.percent || "" : "", percentUpValue: loAmountAfter ? loAmountAfter.percentUp || "" : "", percentDownValue: loAmountAfter ? loAmountAfter.percentDown || "" : "", percentDownDisabled: loAmountAfter ? !loAmountAfter.percent : true, percentUpDisabled: loAmountAfter ? !loAmountAfter.percent : true, fixedDisabled: loAmountAfter ? !loThreshold || !!loAmountAfter.percent : true, percentDisabled: loAmountAfter ? !loThreshold || !!loAmountAfter.fixed : true })),
                loCheckboxRow: Object.assign({}, Object.assign(Object.assign({}, initialCheckboxRow), { thresholdValue: loThreshold ? loThreshold : "", thresholdEnabled: !!loThreshold })),
                advancedOptions: Object.assign({}, Object.assign(Object.assign({}, initialAdvancedOptions), { validFrom: getDateFromTimestamp(validFrom), validUntil: getDateFromTimestamp(validUntil) })),
            });
        default:
            return state;
    }
}
