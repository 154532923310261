import * as React from "react";
import SmartDataTable from "../SmartDataTable";
import { TimeInterval } from "../../interfaces/BusinessReports";
import { formatCurrency, formatPhone, getBreadcrumbObject, getName, getTableColumnItem, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { Card, CardText, CardTitle } from "material-ui/Card";
import Table, { TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Divider from "material-ui/Divider";
import * as moment from "moment";
import DatePickerDialog from "material-ui/DatePicker/DatePickerDialog";
import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { months } from "../../variables/Common";
import { connect } from "react-redux";
import { HttpClient } from "../../utils/HttpClient";
import { Link } from "react-router-dom";
import CircularProgress from "material-ui/CircularProgress";
import { appendBreadcrumb } from "../../actions/Actions";
import { withRouter } from '../../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class SellerReports extends React.Component {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("sellerPhone", "Seller Phone", (phone) => React.createElement(Link, { to: `/seller/${phone}/info` }, formatPhone(phone))),
            getTableColumnItem("sellerName", "Seller Name", (name) => getName(name)),
            getTableColumnItem("txCount", "Transactions"),
            getTableColumnItem("sum", "Sum(mdl)"),
        ];
        this.state = {
            data: [],
            selectedInterval: TimeInterval.TODAY,
            from: moment().startOf("day").unix(),
            to: moment().endOf("day").unix(),
            loading: true,
            selectedMonth: { year: moment().year(), month: moment().month() + 1 },
            showMonthPicker: false,
        };
        this.props.dispatch(appendBreadcrumb([
            getBreadcrumbObject("Reports", `/business/${this.props.match.params.id}/info/reports`),
        ]));
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Card, { className: "card-998" },
                React.createElement(CardTitle, { title: "Seller report" }),
                this.renderPage()),
            React.createElement(DatePickerDialog, { ref: r => this.setDataPickerDialogReference(r), firstDayOfWeek: 0, autoOk: false, onAccept: (date) => this.setSelectedDate(date) })));
    }
    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.from !== this.state.from && prevState.to !== this.state.to) {
            this.fetchData();
        }
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/reports/business/${this.props.match.params.id}/seller`, {
            from: this.state.from,
            to: this.state.to,
        })
            .then((result) => {
            this.setState({ data: result.report ? result.report : [], loading: false });
        })
            .catch((error) => {
            ReactToastify.toast.error("Can't load reports! Status: " +
                JSON.stringify(error.response.status) +
                " Error:" + JSON.stringify(error.response.statusText));
            this.setState({ loading: false });
        });
    }
    renderPage() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 60, thickness: 8, style: {
                    width: "60px",
                    margin: "auto",
                    display: "block",
                } }));
        }
        else {
            return [
                React.createElement(CardText, { key: "business-reports-table" }, this.renderReportsTable()),
                React.createElement(CardText, { key: "business-totals" }, this.renderTotals()),
            ];
        }
    }
    renderReportsTable() {
        return (React.createElement(SmartDataTable, { data: this.state.data, columns: this.tableColumns, toolbarIconRight: this.toolbarIcons() }));
    }
    toolbarIcons() {
        return [
            this.renderDataPicker(),
        ];
    }
    renderTotals() {
        return (React.createElement(Table, { selectable: false, multiSelectable: false },
            React.createElement(TableBody, { displayRowCheckbox: false },
                React.createElement(TableRow, null,
                    React.createElement(TableRowColumn, { className: "info-static-card-column" },
                        React.createElement("span", { className: "info-static-card-column-span" }, "Transactions count")),
                    React.createElement(TableRowColumn, { className: "info-static-card-column" }, this.getTransactionsCount())),
                React.createElement(TableRow, null,
                    React.createElement(TableRowColumn, { className: "info-static-card-column" },
                        React.createElement("span", { className: "info-static-card-column-span" }, "Transactions sum amount")),
                    React.createElement(TableRowColumn, { className: "info-static-card-column" }, formatCurrency(this.getTotalAmount().toFixed(2)))))));
    }
    renderDataPicker() {
        return (React.createElement(Picker, { years: { min: 2010, max: 2100 }, value: this.state.selectedMonth, lang: months, onChange: (y, m) => this.setSelectedMonth(y, m), show: this.state.showMonthPicker },
            React.createElement(SelectField, { id: "time-interval", floatingLabelText: "Interval", value: this.state.selectedInterval, onChange: (e, i, value) => this.setState({ selectedInterval: value }) },
                React.createElement(MenuItem, { value: TimeInterval.TODAY, label: this.getMenuItemLabel(TimeInterval.TODAY), primaryText: "Today", onClick: () => this.setState({
                        from: moment().startOf("day").unix(),
                        to: moment().endOf("day").unix(),
                    }) }),
                React.createElement(MenuItem, { value: TimeInterval.YESTERDAY, label: this.getMenuItemLabel(TimeInterval.YESTERDAY), primaryText: "Yesterday", onClick: () => this.setState({
                        from: moment().subtract(1, "day").startOf("day").unix(),
                        to: moment().subtract(1, "day").endOf("day").unix(),
                    }) }),
                React.createElement(MenuItem, { value: TimeInterval.THIS_MONTH, label: this.getMenuItemLabel(TimeInterval.THIS_MONTH), primaryText: "This Month", onClick: () => this.setState({
                        from: moment().startOf("month").unix(),
                        to: moment().endOf("month").unix(),
                    }) }),
                React.createElement(MenuItem, { value: TimeInterval.LAST_MONTH, label: this.getMenuItemLabel(TimeInterval.LAST_MONTH), primaryText: "Last Month", onClick: () => this.setState({
                        from: moment().subtract(1, "month").startOf("month").unix(),
                        to: moment().subtract(1, "month").endOf("month").unix(),
                    }) }),
                React.createElement(Divider, null),
                React.createElement(MenuItem, { value: TimeInterval.CUSTOM_DAY, label: this.getMenuItemLabel(TimeInterval.CUSTOM_DAY), primaryText: "Custom Day...", onClick: () => this.datePickerDialog.show() }),
                React.createElement(Divider, null),
                React.createElement(MenuItem, { value: TimeInterval.CUSTOM_MONTH, label: this.getMenuItemLabel(TimeInterval.CUSTOM_MONTH), primaryText: "Custom Month...", onClick: () => this.setState({ showMonthPicker: true }) }))));
    }
    getMenuItemLabel(interval) {
        switch (interval) {
            case TimeInterval.TODAY:
                return moment().format("L");
            case TimeInterval.YESTERDAY:
                return moment().subtract(1, "days").format("L");
            case TimeInterval.THIS_MONTH:
                return moment().startOf("month").format("L") + " - " + moment().format("L");
            case TimeInterval.LAST_MONTH:
                return moment().subtract(1, "month").startOf("month").format("L")
                    + " - " + moment().subtract(1, "month").endOf("month").format("L");
            case TimeInterval.CUSTOM_DAY:
                return moment.unix(this.state.from).format("L");
            case TimeInterval.CUSTOM_MONTH:
                return moment.unix(this.state.from).format("L") + " - " + moment.unix(this.state.to).format("L");
        }
    }
    getTransactionsCount() {
        let total = 0;
        this.state.data.map((summary) => total += summary.txCount);
        return total;
    }
    getTotalAmount() {
        let total = 0;
        this.state.data.map((summary) => total += summary.sum);
        return total;
    }
    setDataPickerDialogReference(r) {
        this.datePickerDialog = r;
    }
    setSelectedDate(date) {
        this.setState({
            from: moment(date).startOf("day").unix(),
            to: moment(date).endOf("day").unix(),
        });
    }
    setSelectedMonth(year, month) {
        this.setState({
            from: moment().year(year).month(month - 1).startOf("month").unix(),
            to: moment().year(year).month(month - 1).endOf("month").unix(),
            selectedMonth: { year, month },
            showMonthPicker: false,
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(SellerReports));
