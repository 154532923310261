import * as React from "react";
import { Route } from "react-router";
import POSProfile from "../components/POSInfoComponents/POSProfile";
import POSTransactions from "../components/POSInfoComponents/POSTransactions";
import POSShifts from "../components/POSInfoComponents/POSShifts";
import { HttpClient } from "../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import POSSellers from "../components/POSInfoComponents/POSSellers";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { Tab, Tabs } from "material-ui/Tabs";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../actions/Actions";
import { POSType } from "../variables/Common";
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Profile"] = 0] = "Profile";
    TabNames[TabNames["Transactions"] = 1] = "Transactions";
    TabNames[TabNames["Shifts"] = 2] = "Shifts";
    TabNames[TabNames["Sellers"] = 3] = "Sellers";
    TabNames[TabNames["Commission"] = 4] = "Commission";
})(TabNames || (TabNames = {}));
class POSInfoPage extends React.Component {
    constructor(props) {
        super(props);
        switch (location.pathname.split("/").pop()) {
            case "sellers":
                this.state = { selectedTab: TabNames.Sellers, posData: null, loading: true };
                break;
            case "transactions":
                this.state = { selectedTab: TabNames.Transactions, posData: null, loading: true };
                break;
            case "shifts":
                this.state = { selectedTab: TabNames.Shifts, posData: null, loading: true };
                break;
            case "commission":
                this.state = { selectedTab: TabNames.Commission, posData: null, loading: true };
                break;
            default:
                this.state = { selectedTab: TabNames.Profile, posData: null, loading: true };
                break;
        }
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            this.renderChildComponents()));
    }
    componentDidMount() {
        this.fetchPOSData();
    }
    fetchPOSData() {
        HttpClient
            .getJSON(`${this.props.baseUrl}/business/${this.props.match.params.id}/pos/${this.props.match.params.externalId}`)
            .then((result) => {
            this.props.dispatch(setBreadcrumb([
                getBreadcrumbObject("Businesses", "/business"),
                getBreadcrumbObject(result.pos.businessTitle, `/business/${this.props.match.params.id}/info`),
                getBreadcrumbObject(result.pos.title, `/business/${this.props.match.params.id}/pos_info/${this.props.match.params.externalId}`),
            ]));
            this.setState({ posData: result, loading: false });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load data,reload page! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    updatePOSType(type = POSType.DEFAULT, validTime) {
        let data = {};
        if (type === POSType.TRANSPORT) {
            data = { transport: { validTime: Number(validTime) * 60 } };
        }
        HttpClient
            .patchWithData(`${this.props.baseUrl}/business/${this.props.match.params.id}/pos/type/${this.props.match.params.externalId}`, JSON.stringify(data))
            .then((result) => {
            ReactToastify.toast.info("POS type was successfully updated!");
            this.fetchPOSData();
        })
            .catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    updatePOSIcon(iconUrl) {
        const patchData = { iconUrl };
        HttpClient
            .patchWithData(`${this.props.baseUrl}/business/${this.props.match.params.id}/pos/icon/${this.props.match.params.externalId}`, JSON.stringify(patchData))
            .then((result) => {
            ReactToastify.toast.info("POS was successfully updated!");
            this.fetchPOSData();
        })
            .catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderChildComponents() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(Route, { path: `${this.props.match.url}`, element: () => React.createElement(POSProfile, { profileData: this.state.posData.pos, updatePosType: this.updatePOSType.bind(this), updatePos: this.updatePOSIcon.bind(this) }) }),
                React.createElement(Route, { path: `${this.props.match.url}/transactions`, element: () => React.createElement(POSTransactions, { transactions: this.state.posData.txs, fetchData: () => this.fetchPOSData() }) }),
                React.createElement(Route, { path: `${this.props.match.url}/shifts`, element: () => React.createElement(POSShifts, { shiftsData: this.state.posData.shifts, fetchData: () => this.fetchPOSData() }) }),
                React.createElement(Route, { path: `${this.props.match.url}/sellers`, element: () => React.createElement(POSSellers, Object.assign({ sellersData: this.state.posData.sellers, fetchData: this.fetchPOSData.bind(this) }, this.props)) })));
        }
    }
    renderTabs() {
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "business-info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "625px" } },
                React.createElement(Tab, { label: "Profile", value: TabNames.Profile, containerElement: React.createElement(Link, { to: `${this.props.match.url}` }) }),
                React.createElement(Tab, { label: "Transactions", value: TabNames.Transactions, containerElement: React.createElement(Link, { to: `${this.props.match.url}/transactions` }) }),
                React.createElement(Tab, { label: "Shifts", value: TabNames.Shifts, containerElement: React.createElement(Link, { to: `${this.props.match.url}/shifts` }) }),
                React.createElement(Tab, { label: "Sellers", value: TabNames.Sellers, containerElement: React.createElement(Link, { to: `${this.props.match.url}/sellers` }) }))));
    }
}
export default connect(mapStateToPropsBaseUrl)(POSInfoPage);
