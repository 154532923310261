import { PhoneNumberType, PhoneNumberUtil, PhoneNumberUtil as util } from "google-libphonenumber";
import * as validator from "validator";
export class DataValidator {
    static phoneNumberValidator(number) {
        return new Promise((resolve, reject) => {
            if (!/^\d+$/.test(number)) {
                reject("Number should contain digits only!");
            }
            if (number.length < 2) {
                reject("Number is too short!");
            }
            if (number.length > 17) {
                reject("Number is too long!");
            }
            const numberUtil = PhoneNumberUtil.getInstance();
            const phone = numberUtil.parseAndKeepRawInput(number, "MD");
            const countryCode = phone.getCountryCode();
            const nationalNumber = phone.getNationalNumber();
            const numberType = numberUtil.getNumberType(phone);
            const validationResult = numberUtil.isPossibleNumberForTypeWithReason(phone, PhoneNumberType.MOBILE);
            if (validationResult === util.ValidationResult.IS_POSSIBLE && numberType === PhoneNumberType.MOBILE) {
                resolve(countryCode.toString() + nationalNumber);
            }
            else if (validationResult !== util.ValidationResult.IS_POSSIBLE) {
                switch (validationResult) {
                    case util.ValidationResult.INVALID_COUNTRY_CODE:
                        reject("Invalid country code!");
                        break;
                    case util.ValidationResult.TOO_LONG:
                        reject("Number is too long!");
                        break;
                    case util.ValidationResult.TOO_SHORT:
                        reject("Number is too short!");
                        break;
                    default:
                        reject("Invalid phone number!");
                        break;
                }
            }
            else if (numberType !== PhoneNumberType.MOBILE) {
                reject("Input MOBILE phone number!");
            }
            else {
                reject("Error");
            }
        });
    }
    static fractionalNumberValidator(val, option) {
        return new Promise((resolve, reject) => {
            const validationWithoutOption = () => {
                if (validator.isFloat(val)) {
                    resolve("");
                }
                else {
                    reject("Input numeric value");
                }
            };
            if (val === "") {
                reject("");
            }
            if (option === "percents") {
                if (validator.isNumeric(val) && Number(val) <= 100) {
                    resolve("");
                }
                else {
                    reject("Percentage can not be higher than 100");
                }
            }
            else {
                validationWithoutOption();
            }
        });
    }
    static isFloat(val, option, limit) {
        const validationWithoutOption = () => {
            if (validator.isFloat(String(val))) {
                return "";
            }
            else {
                return "Input numeric value";
            }
        };
        if (val === "") {
            return "";
        }
        switch (option) {
            case "percents":
                if (Number(val) > 100) {
                    return "Percentage can not be higher than 100";
                }
                else {
                    return validationWithoutOption();
                }
            case "downLimit":
                if (Number(val) <= Number(limit) && limit !== "") {
                    return "Should be higher than down limit";
                }
                else {
                    return validationWithoutOption();
                }
            case "highLimit":
                if (Number(val) >= Number(limit) && limit !== "") {
                    return "Should be lower than high limit";
                }
                else {
                    return validationWithoutOption();
                }
            default:
                return validationWithoutOption();
        }
    }
    static numberValidator(val) {
        return new Promise((resolve, reject) => {
            if (val === "") {
                reject("");
            }
            if (validator.isNumeric(val)) {
                resolve("");
            }
            else {
                resolve("Input numeric value");
            }
        });
    }
    static testImage(url, timeoutT) {
        return new Promise((resolve, reject) => {
            const timeout = timeoutT || 5000;
            let timer;
            const img = new Image();
            img.onerror = img.onabort = () => {
                clearTimeout(timer);
                reject("Not a valid image URL");
            };
            img.onload = () => {
                clearTimeout(timer);
                resolve(url);
            };
            timer = setTimeout(() => {
                img.src = "//!!!!/noexist.jpg";
                reject("Can not verify URL");
            }, timeout);
            img.src = url;
        });
    }
}
