import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { setBreadcrumb } from "../actions/Actions";
import { connect } from "react-redux";
import { HttpClient } from "../utils/HttpClient";
import Card from "material-ui/Card/Card";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import MerchantsTable from "../components/MerchantsComponents/MerchantsTable";
import MerchantDialog from "../components/MerchantsComponents/MerchantDialog";
import { CircularProgress } from "material-ui";
const ReactToastify = require("react-toastify");
class Merchants extends React.Component {
    constructor(props) {
        super(props);
        this.mapObjectToServiceNames = (obj) => {
            const result = [];
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    result.push({
                        id: key,
                        name: obj[key],
                    });
                }
            }
            return result.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
        };
        this.state = {
            merchantDialogOpened: false,
            deleteMerchantDialogOpened: false,
            loading: false,
            services: [],
            merchants: [],
            currentMerchant: null,
            isSaving: false,
            errors: null,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Merchants", "/merchants")]));
    }
    render() {
        return (React.createElement("div", { className: "users-content" },
            React.createElement(Card, { className: "card-full-size" }, this.renderTable()),
            this.renderMerchantDialog(),
            this.renderDeleteDialog()));
    }
    componentDidMount() {
        this.fetchMerchants();
    }
    fetchMerchants() {
        this.setState({
            loading: true,
        });
        HttpClient.getJSON(`${this.props.baseUrl}/merchant/list`)
            .then((response) => {
            if (response.body) {
                const serviceNames = this.mapObjectToServiceNames(response.body.services);
                this.setState({
                    loading: false,
                    merchants: response.body.merchants || [],
                    services: serviceNames,
                });
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading data! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    validateMerchant() {
        const result = [];
        const { currentMerchant } = this.state;
        if (!currentMerchant || !currentMerchant.alias || currentMerchant.alias.trim() === "") {
            result.push({
                field: "alias",
                text: "Alias must not be empty!",
            });
        }
        if (!currentMerchant || !currentMerchant.publicKey || currentMerchant.publicKey.trim() === "") {
            result.push({
                field: "publicKey",
                text: "Public Key must not be empty!",
            });
        }
        if (!currentMerchant || !currentMerchant.title || currentMerchant.title.trim() === "") {
            result.push({
                field: "title",
                text: "Title must not be empty!",
            });
        }
        if (!currentMerchant || !currentMerchant.expare_min) {
            result.push({
                field: "expare_min",
                text: "Expire minutes must not be empty!",
            });
        }
        return result;
    }
    updateMerchant() {
        const errs = this.validateMerchant();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        const { currentMerchant } = this.state;
        if (currentMerchant) {
            this.setState({
                isSaving: true,
            });
            HttpClient.post(`${this.props.baseUrl}/merchant`, JSON.stringify(Object.assign({}, currentMerchant)))
                .then(() => {
                this.closeMerchantDialog();
                this.fetchMerchants();
                currentMerchant.id
                    ? ReactToastify.toast.info("Merchant successfully updated!")
                    : ReactToastify.toast.info("Merchant was added successfully!");
            })
                .catch((err) => {
                this.setState({
                    isSaving: false,
                });
                ReactToastify.toast.error("Could not add merchant! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    deleteSelectedMerchant() {
        const { currentMerchant } = this.state;
        if (currentMerchant) {
            HttpClient.delete(`${this.props.baseUrl}/merchant/${this.state.currentMerchant.id}`)
                .then(() => {
                this.closeDeleteDialog();
                ReactToastify.toast.info("Merchant was deleted successfully!");
                this.fetchMerchants();
            })
                .catch((err) => {
                ReactToastify.toast.error("Could not delete merchant! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    renderDeleteDialog() {
        const title = this.state.currentMerchant && this.state.currentMerchant.title;
        return (React.createElement(Dialog, { title: "Delete merchant with title " + title + "?", actions: [
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.closeDeleteDialog.bind(this), disabled: this.state.isSaving }),
                React.createElement(FlatButton, { label: "OK", onClick: this.deleteSelectedMerchant.bind(this), disabled: this.state.isSaving }),
            ], modal: true, open: this.state.deleteMerchantDialogOpened, onRequestClose: this.closeDeleteDialog.bind(this) }));
    }
    renderTable() {
        return (React.createElement("div", null,
            this.state.loading && React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }),
            React.createElement(MerchantsTable, { merchants: this.state.merchants, reloadData: () => this.fetchMerchants(), updateMerchant: this.openMerchantDialog.bind(this), deleteMerchant: this.openDeleteDialog.bind(this) })));
    }
    handleInputChange(value, name) {
        this.setState((state) => ({
            currentMerchant: Object.assign(Object.assign({}, state.currentMerchant), { [name]: value }),
            errors: state.errors && state.errors.filter((e) => e.field !== name),
        }));
    }
    renderMerchantDialog() {
        const { currentMerchant } = this.state;
        return (React.createElement(MerchantDialog, { open: this.state.merchantDialogOpened, currentMerchant: currentMerchant, services: this.state.services, dialogTitle: currentMerchant && currentMerchant.id ? "Update merchant" : "Create a new merchant", handleInputChange: this.handleInputChange.bind(this), isSaving: this.state.isSaving, onCancelClick: this.closeMerchantDialog.bind(this), onOkClick: this.updateMerchant.bind(this), errors: this.state.errors }));
    }
    closeMerchantDialog() {
        this.setState({
            merchantDialogOpened: false,
            currentMerchant: null,
            isSaving: false,
            errors: null,
        });
    }
    openMerchantDialog(merchant) {
        this.setState({
            currentMerchant: merchant,
            merchantDialogOpened: true,
        });
    }
    closeDeleteDialog() {
        this.setState({
            deleteMerchantDialogOpened: false,
            currentMerchant: null,
            isSaving: false,
        });
    }
    openDeleteDialog(merchant) {
        this.setState({
            deleteMerchantDialogOpened: true,
            currentMerchant: merchant,
        });
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
});
export default connect(mapStateToProps)(Merchants);
