import * as React from "react";
import Card from "material-ui/Card/Card";
import CardText from "material-ui/Card/CardText";
import CardActions from "material-ui/Card/CardActions";
import { connect } from "react-redux";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { CardTitle } from "material-ui";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Reports", "/reports")]));
    }
    render() {
        return (React.createElement("div", { className: "reports-grid" },
            this.renderReportCard("Wallets Balance Report", "Generate Wallets Balance report.", `/reports/balance/info`),
            this.renderReportCard("Wallets Status Report", "Generate Wallets Status report.", `/reports/status/info`),
            this.renderReportCard("Wallet Period Activity Report", "Generate Wallet activity for a time span report.", `/reports/period/info`),
            this.renderReportCard("Wallet Daily Activity Report", "Generate Wallet daily activity report.", `/reports/daily/info`),
            this.renderReportCard("Accounts Period Payments Report", "Generate Accounts period payments report.", `/reports/payments/info`),
            this.renderReportCard("Wallets Withdrawals Report", "Generate Wallets withdrawals report.", `/reports/withdrawals/info`),
            this.renderReportCard("CNA SPCSB report", "Generate report about daily suspicious transactions.", "/reports/cna/info"),
            this.renderReportCard("Fisc Opened Accounts Repors", "Generate report about new opened accounts.", "/reports/fisc/info"),
            this.renderReportCard("Turnover and Profit Report", "Generate Turnover and Profit report.", "/reports/turnover/info"),
            this.renderReportCard("Fisc: Moneda Electronica", "Generate Fisc annual report.", "/reports/fisc/annual"),
            "  ",
            this.renderReportCard("SPCSB 2 report", "", "/reports/spcsb2/info"),
            this.renderReportCard("SPCSB 3 report", "", "/reports/spcsb3/info"),
            this.renderReportCard("BNM report", "Generate BNM Report", "/reports/generate/bnm")));
    }
    renderReportCard(title, cardText, path) {
        return (React.createElement(Card, { className: "reports-view-card" },
            React.createElement(CardTitle, { title: title }),
            React.createElement(CardText, null, cardText),
            React.createElement(CardActions, { className: "info-page-cardactions" }, this.renderCardBtn(path))));
    }
    renderCardBtn(path) {
        return (React.createElement(RaisedButton, { label: "View", containerElement: React.createElement(Link, { to: path }), primary: true }));
    }
}
export default connect()(Reports);
