import * as React from "react";
import { connect } from "react-redux";
import { HttpClient } from "../utils/HttpClient";
import { appendBreadcrumb } from "../actions/Actions";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import CircularProgress from "material-ui/CircularProgress";
import WithdrawalRequestProfile from "../components/WithdrawalRequestsComponents/WithdrawalRequestProfile";
import download from "downloadjs";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class WithdrawalRequestPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            requestData: null,
            reportLoading: false,
        };
    }
    componentDidMount() {
        this.fetchData(true);
    }
    render() {
        const { loading, requestData, reportLoading } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(WithdrawalRequestProfile, { data: requestData, loadingRequest: reportLoading, onDownloadRequestClick: this.generateRequest.bind(this), onDownloadWarrantClick: this.generateCashWarrant.bind(this), onAcceptButtonClick: this.acceptWithdrawal.bind(this), onRejectButtonClick: this.rejectWithdrawal.bind(this), onUpdateButtonClick: this.updateRequestNumber.bind(this) }));
        }
    }
    fetchData(isFirst) {
        const { baseUrl, match } = this.props;
        HttpClient.getJSON(`${baseUrl}/withdrawal/${match.params.id}`)
            .then((result) => {
            this.setState({
                loading: false,
                requestData: result,
            });
            if (isFirst) {
                this.props.dispatch(appendBreadcrumb([
                    getBreadcrumbObject(`${result.walletPhone}`, `/upgrade/${this.props.match.params.id}/info`),
                ]));
            }
        })
            .catch((err) => {
            switch (err.status) {
                case 404:
                    ReactToastify.toast.error("Can't load Withdrawal request! Request Not Found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't load Withdrawal request! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
    acceptWithdrawal() {
        const { baseUrl, match } = this.props;
        HttpClient.put(`${baseUrl}/withdrawal/${match.params.id}/accept`)
            .then(() => {
            ReactToastify.toast.success("Withdrawal was successfully accepted!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't accept request. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    rejectWithdrawal() {
        const { baseUrl, match } = this.props;
        HttpClient.put(`${baseUrl}/withdrawal/${match.params.id}/reject`)
            .then(() => {
            ReactToastify.toast.info("Withdrawal was rejected!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't reject request. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    updateRequestNumber(requestNumber) {
        const { baseUrl } = this.props;
        const { id } = this.props.match.params;
        const query = `?number=${requestNumber}`;
        HttpClient.put(`${baseUrl}/withdrawal/${id}/update${query}`)
            .then(() => {
            ReactToastify.toast.info("Withdrawal request number was successfully changed!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't update request info. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    generateRequest() {
        const { id } = this.props.match.params;
        const query = { id };
        this.setState({ reportLoading: true });
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/withdrawrequest`, query)
            .then((result) => {
            ReactToastify.toast.info("Request was successfully created.");
            this.setState({ reportLoading: false });
            this.downloadReport(result.fileName, "cerere");
        })
            .catch((err) => {
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("Request not found!");
                    break;
                default:
                    ReactToastify.toast.error("Could not create withdraw request. Status: " +
                        JSON.stringify(err.response.status));
                    break;
            }
            this.setState({ reportLoading: false });
        });
    }
    generateCashWarrant() {
        const { id } = this.props.match.params;
        const query = { id };
        this.setState({ reportLoading: true });
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/cashwarrant`, query)
            .then((result) => {
            ReactToastify.toast.info("Cash warrant was successfully created.");
            this.downloadReport(result.fileName, "dispozitie-de-plata");
            this.setState({ reportLoading: false });
        })
            .catch((err) => {
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("Request not found!");
                    break;
                default:
                    ReactToastify.toast.error("Could not create cash warrant. Status: " +
                        JSON.stringify(err.response.status));
                    break;
            }
            this.setState({ reportLoading: false });
        });
    }
    downloadReport(fileName, downloadPrefix) {
        const { walletPhone } = this.state.requestData;
        const query = `?file=${fileName}&format=pdf`;
        const downloadName = `${downloadPrefix}-${walletPhone}.pdf`;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", `${this.props.baseUrl}/load${query}`, true);
        xhr.setRequestHeader("Authorization", this.props.token);
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
            if (this.status === 200) {
                const newBlob = new Blob([this.response], { type: "application/pdf" });
                download(newBlob, downloadName, "application/pdf");
            }
            else {
                ReactToastify.toast.error("Could open report. Status: " +
                    this.status);
            }
        };
        xhr.send();
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default withRouter(connect(mapStateToProps)(WithdrawalRequestPage));
