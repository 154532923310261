import * as React from "react";
import { formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import IconButton from "material-ui/IconButton";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
class NotificationsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("name", "Subject", (title, info) => React.createElement(Link, { to: `/notifications/${info.id}` }, title)),
            getTableColumnItem("userCount", "Audience Size"),
            getTableColumnItem("whenSent", "When sent", (date) => formatTime(date)),
            getTableColumnItem("createdBy", "Author"),
            getTableColumnItem("sentBy", "Sender"),
            getTableColumnItem("status", "Status"),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons() }));
    }
    getToolbarIcons() {
        return [
            React.createElement(Link, { to: `/notifications/new` },
                React.createElement(RaisedButton, { label: "New message", primary: true, buttonStyle: { marginRight: 10 } })),
            React.createElement(IconButton, { onClick: this.props.reload.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default NotificationsTable;
