import * as $ from "jquery";
export class HttpClient {
    static getJSON(url, data) {
        return ajax({
            url,
            method: "GET",
            contentType: "application/json; charset=UTF-8",
            dataType: "json",
            data,
            processData: true,
        });
    }
    static getText(url, data) {
        return ajax({
            url,
            method: "GET",
            data,
            processData: true,
        });
    }
    static getByType(url, data) {
        return ajax({
            url,
            method: "GET",
            data,
            processData: false,
        });
    }
    static post(url, data) {
        return ajax({
            url,
            method: "POST",
            contentType: "application/json; charset=UTF-8",
            data,
            processData: true,
        });
    }
    static postUrl(url, data) {
        return ajax({
            url,
            method: "POST",
            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
            data,
            processData: true,
        });
    }
    static postMultiPart(url, data) {
        return ajax({
            url,
            method: "POST",
            contentType: false,
            processData: false,
            data,
        });
    }
    static delete(url) {
        return ajax({
            url,
            method: "DELETE",
            processData: true,
        });
    }
    static deleteWithData(url, data) {
        return ajax({
            url,
            method: "DELETE",
            data,
            processData: true,
        });
    }
    static putWithData(url, data) {
        return ajax({
            url,
            method: "PUT",
            contentType: "application/json; charset=UTF-8",
            data,
            processData: true,
        });
    }
    static put(url) {
        return ajax({
            url,
            method: "PUT",
            contentType: "application/json; charset=UTF-8",
            processData: true,
        });
    }
    static patchWithData(url, data) {
        return ajax({
            url,
            method: "PATCH",
            contentType: "application/json; charset=UTF-8",
            data,
            processData: true,
        });
    }
    static patch(url) {
        return ajax({
            url,
            method: "PATCH",
            contentType: "application/json; charset=UTF-8",
            processData: true,
        });
    }
    static getHeader(url, xhr) {
        return getAjax({
            url,
            method: "GET",
            contentType: "application/json; charset=UTF-8",
            processData: true,
            xhr,
        });
    }
}
function getAjax(settings) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "GET",
            data: settings.data,
            url: settings.url,
            success(output, status, xhr) {
                resolve(xhr.getResponseHeader("Version"));
            },
            error(response) {
                const errorMessage = `${response.status} ${response.statusText}: ${response.responseText}`;
                const error = new Error(errorMessage);
                error.response = response;
                reject(error);
            },
        });
    });
}
function ajax(settings) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: settings.url,
            dataType: settings.dataType,
            method: settings.method,
            data: settings.data,
            headers: settings.headers,
            contentType: settings.contentType,
            processData: settings.processData,
        }).then(successHandler, failureHandler);
        function successHandler(data) {
            resolve(data);
        }
        function failureHandler(response) {
            const errorMessage = `${response.status} ${response.statusText}: ${response.responseText}`;
            const error = new Error(errorMessage);
            error.response = response;
            reject(error);
        }
    });
}
