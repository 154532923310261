import { setPageFromOffset } from "./DataHelpers";
export function getQuery(offset, limit, search, orderKey, order) {
    return {
        offset,
        limit,
        search,
        orderKey,
        order,
    };
}
export function getOffset(currentPage, rowSize) {
    return (currentPage - 1) * rowSize;
}
export function getTableState(result, rowSize, search, orderKey, order, key) {
    return {
        count: result.pagination.total,
        currentPage: setPageFromOffset(result.pagination.offset, result.pagination.length),
        rowSize,
        search,
        orderKey,
        order,
        data: result[key],
    };
}
