import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { CircularProgress } from "material-ui";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { mapStateToPropsBaseUrl, getBreadcrumbObject } from "../utils/DataHelpers";
import { HttpClient } from "../utils/HttpClient";
import RaisedButton from "material-ui/RaisedButton";
import { Navigate } from "react-router-dom";
import { setBreadcrumb } from "../actions/Actions";
import "./styles.css";
import CashinFileLoader from "../components/CashInRequests/CashinFileLoader";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class CashInRequestActions extends React.Component {
    constructor(props) {
        super(props);
        this.fetchRequestDetails = (requestId) => {
            const { baseUrl } = this.props;
            const defaultErrorMessage = "Error while getting the request details";
            this.setState({
                isLoading: true,
            });
            HttpClient.getJSON(`${baseUrl}/cashin/company/requests/${requestId}/detail`).then((response) => {
                if (response && response.status && response.status.code === "ok") {
                    this.mapToInsertedData(response.body);
                    this.setState({
                        isLoading: false,
                    });
                }
                else {
                    ReactToastify.toast.info(defaultErrorMessage);
                    this.setState({
                        isLoading: false,
                    });
                }
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isLoading: false,
                    redirect: true,
                });
            });
        };
        this.mapToInsertedData = (cashinDetail) => {
            if (!cashinDetail) {
                ReactToastify.toast.info("Error while getting the request details. Details is null");
            }
            const company = cashinDetail.company;
            this.setState({
                insertedData: {
                    id: cashinDetail.id,
                    companyName: company && company.companyName,
                    companyIDNO: company && company.companyIDNO,
                    companyId: company && company.id,
                    companyCount: cashinDetail.accountNo,
                    amount: cashinDetail.amount,
                    operatorName: cashinDetail.operatorName,
                    operationNotes: cashinDetail.operationNotes,
                    created: cashinDetail.created,
                    lastUpdate: cashinDetail.lastUpdate,
                    attachments: cashinDetail.attachments || [],
                    status: cashinDetail.status,
                    accountantNote: cashinDetail.accountantNote,
                    payerName: cashinDetail.payerName,
                    payerCode: cashinDetail.payerCode,
                    empoweredPersonName: cashinDetail.trustedPerson.trustedName,
                    empoweredPersonIdnp: cashinDetail.trustedPerson.trustedIDNP,
                    empoweredPersonGetNumber: cashinDetail.lastUpdate,
                    empoweredPersonGetData: cashinDetail.loaDate,
                    empoweredPersonGetPassport: cashinDetail.loaNumber,
                    ccy: cashinDetail.ccy,
                    summInWords: cashinDetail.amountText,
                    operationPurpose: cashinDetail.operationPurpose,
                },
            });
        };
        this.sendToArchive = (requestId) => {
            const { baseUrl } = this.props;
            const { operatorNote } = this.state.insertedData;
            this.setState({
                isLoading: true,
            });
            const query = {
                note: operatorNote,
            };
            const defaultErrorMessage = "Error while trying to send request cashin to archive";
            HttpClient.postUrl(`${baseUrl}/cashin/company/requests/${requestId}/archive`, query)
                .then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    ReactToastify.toast.success("CashIn request was added to Archive");
                    this.setState({
                        isLoading: false,
                        redirect: true,
                    });
                }
                else {
                    this.setState({
                        isLoading: false,
                    });
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Error loading data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isLoading: false,
                });
            });
        };
        this.renderDialogActions = () => {
            const { match: { params: { isArchived } } } = this.props;
            const { requestId, isLoading } = this.state;
            return [
                React.createElement("div", { id: "cashin-decision", style: { display: !requestId && "block" } },
                    React.createElement("div", { className: "button-parent-container" }, Number(isArchived) === 0 && React.createElement(FlatButton, { label: "To archive", primary: true, onClick: () => this.sendToArchive(requestId), disabled: isLoading })),
                    React.createElement("div", { className: "button-parent-container" },
                        React.createElement(FlatButton, { label: "Close", primary: true, onClick: () => this.setState({ redirect: true }) }))),
            ];
        };
        this.renderForClosedStatus = () => {
            const { requestId, insertedData: { companyIDNO, amount, companyName, operatorNote, attachments, status, accountantNote, amountErrorText, payerName, payerCode, companyCount, empoweredPersonName, empoweredPersonIdnp, empoweredPersonGetNumber, empoweredPersonGetData, empoweredPersonGetPassport, summInWords, ccy, operationPurpose, controllerNote }, insertedData } = this.state;
            return (React.createElement("div", { className: "parent-container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Beneficiar"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "select-orgranization", required: true, disabled: true, style: { width: "100%" }, label: "Companie*", value: companyName, InputLabelProps: {
                                    shrink: true,
                                } }),
                            React.createElement(TextField, { id: "Idno", required: true, style: { width: "86%", marginLeft: 15 }, inputProps: { maxLength: 12 }, label: "IDNO", value: companyIDNO, disabled: true, InputLabelProps: {
                                    shrink: true,
                                } })),
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "Idno", required: true, style: { width: "86%", marginLeft: 15 }, inputProps: { maxLength: 12 }, label: "Contul beneficiarului/IBAN", value: companyCount || "MD24 AG23 0225 1000 1310 4168 | 20.000 MDL", disabled: true, InputLabelProps: {
                                    shrink: true,
                                } })))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Platitor"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "select-company", required: true, disabled: true, style: { width: "100%" }, label: "Nume/Companie**", value: payerName, InputLabelProps: {
                                    shrink: true,
                                } }),
                            React.createElement(TextField, { id: "idnpIdno", required: true, style: { width: "86%", marginLeft: 15 }, inputProps: { maxLength: 12 }, label: "IDNP/IDNO*", value: payerCode, disabled: true, InputLabelProps: {
                                    shrink: true,
                                } })))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Persoana imputernicita"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "select-company", required: true, disabled: true, label: "Numele*", value: empoweredPersonName, InputLabelProps: {
                                        shrink: true,
                                    } })),
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "idnpIdno", required: true, inputProps: { maxLength: 12 }, label: "IDNP*", value: empoweredPersonIdnp, disabled: true, InputLabelProps: {
                                        shrink: true,
                                    } }))),
                        React.createElement("div", { className: "form-line" },
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "select-company", required: true, disabled: true, label: "Nr. procura*", value: empoweredPersonGetNumber, InputLabelProps: {
                                        shrink: true,
                                    } })),
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "idnpIdno", required: true, inputProps: { maxLength: 12 }, label: "Data procura*", value: empoweredPersonGetData, disabled: true, InputLabelProps: {
                                        shrink: true,
                                    } })),
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "idnpIdno", required: true, inputProps: { maxLength: 12 }, label: "Buletin(seria si numar)*", value: empoweredPersonGetPassport, disabled: true, InputLabelProps: {
                                        shrink: true,
                                    } }))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Detalii operatie"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "summInWords", required: true, inputProps: { maxLength: 12 }, label: "Suma in cifre*", value: amount, disabled: true, InputLabelProps: {
                                        shrink: true,
                                    } })),
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "select-company", required: true, disabled: true, value: ccy || "MDL", InputLabelProps: {
                                        shrink: true,
                                    } }))),
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "select-company", required: true, disabled: true, value: summInWords, InputLabelProps: {
                                    shrink: true,
                                } })),
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "select-company", required: true, disabled: true, label: "Scopul operatiei*", value: operationPurpose, InputLabelProps: {
                                    shrink: true,
                                } })))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Note"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "controller-note", style: { width: "100%" }, label: "Controller", placeholder: "Type here", InputLabelProps: {
                                    shrink: true,
                                }, disabled: true, value: accountantNote })))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement(CashinFileLoader, { isEditable: false, fileIdList: attachments || [], addFileId: null, deleteFile: null }))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2" }),
                    React.createElement("div", { className: "col-md-10 generate-buttons-container" },
                        React.createElement(TextField, { id: "controller-note", style: { width: "100%" }, placeholder: "Dispozitie de incasare", disabled: true }),
                        React.createElement(RaisedButton, { label: "GENERATE", onClick: () => this.setState({ showGeneratePopup: true }), primary: true, style: { minWidth: 100, marginTop: -4 } }))),
                this.renderDialogActions()));
        };
        this.generateFile = () => {
            console.log("Генерируем файлt");
        };
        this.showGenerateFilePopup = () => {
            const { showGeneratePopup } = this.state;
            return (React.createElement(Dialog, { title: "Generate File", actions: this.isSelectedCompanyActions, contentStyle: { maxWidth: "700px" }, bodyStyle: { border: "none" }, modal: true, open: showGeneratePopup, autoScrollBodyContent: true },
                React.createElement("div", null,
                    React.createElement(TextField, { id: "controller-note", style: { width: "100%" }, label: "Simbol operatiune*" })),
                React.createElement("div", null,
                    React.createElement(TextField, { id: "controller-note", style: { width: "100%" }, label: "ID System QIWI*" })),
                React.createElement("div", null,
                    React.createElement(TextField, { id: "controller-note", style: { width: "100%" }, label: "Dispozitie nr.*" }))));
        };
        this.state = {
            isSelected: false,
            isLoading: false,
            insertInSecondRow: false,
            files: [],
            errors: [],
            isSaving: false,
            operatorImagePreview: "",
            operatorImageFile: null,
            requestId: "",
            redirect: false,
            companyAccounts: [],
            trustedPersonList: [],
            insertedData: {
                id: "",
                companyName: "",
                companyIDNO: "",
                companyId: "",
                accountNo: "",
                amount: "",
                operatorName: "",
                operatorNote: "",
                operationNotes: "",
                created: "",
                lastUpdate: "",
                attachments: [],
                status: "New",
                amountErrorText: "",
                ccy: "",
                payerName: "",
                payerCode: "",
                companyCount: "",
                empoweredPersonName: "",
                empoweredPersonIdnp: "",
                empoweredPersonGetNumber: "",
                empoweredPersonGetData: "",
                empoweredPersonGetPassport: "",
                summInNumbers: "",
                summInWords: "",
                operationPurpose: "",
            },
            companyList: [],
            companyCount: 0,
            sameCompany: false,
            isSelectedTrustedPersons: false,
            showGeneratePopup: false
        };
    }
    componentDidMount() {
        const { match: { params: { id } }, baseUrl } = this.props;
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("CashIn requests > Details", "/cash-in-requests")]));
        this.setState({ requestId: id }, () => this.fetchRequestDetails(id));
    }
    renderForm() {
        const { isLoading, redirect, } = this.state;
        if (isLoading) {
            return (React.createElement(CircularProgress, { size: 50, style: { left: "50%" } }));
        }
        return (React.createElement("div", { className: "new-request-page" },
            redirect && React.createElement(Navigate, { to: `/cash-in-requests` }),
            this.renderForClosedStatus()));
    }
    get isSelectedCompanyActions() {
        return [
            React.createElement("div", null,
                React.createElement(FlatButton, { label: "Save", primary: true, onClick: () => this.generateFile() }),
                React.createElement(FlatButton, { label: "Discard", primary: true, onClick: () => this.setState({ showGeneratePopup: false }) }))
        ];
    }
    render() {
        const { showGeneratePopup } = this.state;
        return (React.createElement(React.Fragment, null,
            this.renderForm(),
            showGeneratePopup && this.showGenerateFilePopup()));
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(CashInRequestActions));
