import * as React from "react";
import { formatCurrency, formatTime, getTableColumnAction, getTableColumnItem, } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import More from "material-ui/svg-icons/navigation/more-vert";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import DetailsIcon from "material-ui/svg-icons/action/zoom-in";
import { FormControl, TextField } from "@material-ui/core";
import * as moment from "moment";
import { DatePicker } from "material-ui";
import Clear from "material-ui/svg-icons/content/clear";
const DataTable = require("material-ui-datatables");
class CashoutBusinessArchiveTable extends React.Component {
    constructor() {
        super(...arguments);
        this.withdrawalBussinesTableColumns = [
            getTableColumnItem("companyName", "Organization", (businessName) => (businessName)),
            getTableColumnItem("amount", "Amount", (amount) => formatCurrency(amount)),
            getTableColumnItem("percent", "Commission", percent => (percent ? percent + " %" : "")),
            getTableColumnItem("whenFinished", "Closed", (whenFinished) => formatTime(whenFinished)),
            getTableColumnItem("status", "Status", (status) => (status === "accepted"
                ? React.createElement("div", { style: { color: "#07BC0C" } }, "Approved")
                : React.createElement("div", { style: { color: "#FF0000" } }, "Rejected"))),
            getTableColumnAction("id", (name, archiveInfo) => this.renderIconMenu(archiveInfo)),
        ];
        this.sanitize = (text) => {
            return text.toLowerCase().trim();
        };
        this.handleKeyPress = (event) => {
            if (event.key === "Enter") {
                this.props.reloadData(this.props.currentPage, this.props.rowSize, this.props.orderKey, this.props.order, this.props.filter);
            }
        };
    }
    render() {
        return (React.createElement("div", { className: "full-size-card-wrapper" }, this.renderTable()));
    }
    componentDidMount() {
        this.props.reloadData();
    }
    renderTable() {
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.withdrawalBussinesTableColumns, selectable: false, data: this.props.data, showCheckboxes: false, showHeaderToolbarFilterIcon: false, showHeaderToolbar: true, rowSizeList: [5, 10, 20, 40], rowSize: this.props.rowSize, count: this.props.count, page: this.props.currentPage, tableRowStyle: { cursor: "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleSearchValueChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), toolbarIconRight: this.renderIcons }));
    }
    handleNextPageClick() {
        const page = this.props.currentPage + 1;
        this.props.reloadData(page, this.props.rowSize, this.props.filter);
    }
    handlePreviousPageClick() {
        const page = this.props.currentPage - 1;
        this.props.reloadData(page, this.props.rowSize, this.props.filter);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.props.currentPage;
        if ((page - 1) * rowSize > this.props.count) {
            page = 1;
        }
        this.props.reloadData(page, rowSize, this.props.filter);
    }
    handleSearchValueChange(search) {
        this.props.reloadData(this.props.currentPage, this.props.rowSize, this.sanitize(search), this.props.filter);
    }
    handleSortOrderChange(key, order) {
        this.props.reloadData(this.props.currentPage, this.props.rowSize, key, order, this.props.filter);
    }
    get renderIcons() {
        return [
            React.createElement(React.Fragment, null,
                React.createElement("div", { style: {
                        display: "flex",
                        width: 850,
                        flexDirection: "row",
                        alignItems: "center",
                    } },
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "idno-filter", label: "Filter by IDNO", style: { marginRight: "20px", marginTop: 8 }, value: this.props.filter.idno, onChange: (e) => this.props.handleChangeFilter(e.target.value, "idno"), onKeyPress: this.handleKeyPress, disabled: this.props.filter.searchName })),
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "name-filter", label: "Filter by name", style: { marginRight: "20px", marginTop: 8 }, value: this.props.filter.searchName, onChange: (e) => this.props.handleChangeFilter(e.target.value, "searchName"), onKeyPress: this.handleKeyPress, disabled: this.props.filter.idno })),
                    React.createElement("div", { className: "date-picker-wrapper" },
                        React.createElement(DatePicker, { id: "filter-date-from", autoOk: true, formatDate: (date) => moment(date).format("DD.MM.YYYY"), maxDate: this.props.filter.end, value: this.props.filter.start, floatingLabelText: "Filter date from:", textFieldStyle: { width: "150px", marginLeft: "40px" }, onChange: (e, date) => this.props.handleStartDateChange(date) }),
                        React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => this.props.handleStartDateChange(null), disabled: !this.props.filter.start },
                            React.createElement(Clear, null))),
                    React.createElement("div", { className: "date-picker-wrapper" },
                        React.createElement(DatePicker, { id: "filter-date-to", autoOk: true, formatDate: (date) => moment(date).format("DD.MM.YYYY"), minDate: this.props.filter.start, floatingLabelText: "Filter date to:", textFieldStyle: { width: "150px" }, value: this.props.filter.end, onChange: (e, date) => this.props.handleEndDateChange(date) }),
                        React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => this.props.handleEndDateChange(null), disabled: !this.props.filter.end },
                            React.createElement(Clear, null))))),
            React.createElement(IconButton, { onClick: () => this.props.reloadData(this.props.currentPage, this.props.rowSize) },
                React.createElement(Refresh, null)),
        ];
    }
    renderIconMenu(items) {
        return (React.createElement(IconMenu, { iconButtonElement: React.createElement(IconButton, { id: "more-button" },
                React.createElement(More, null)) },
            React.createElement(MenuItem, { leftIcon: React.createElement(DetailsIcon, null), primaryText: "Details", onClick: () => this.props.toggleDetailsDialog(items) })));
    }
}
export default CashoutBusinessArchiveTable;
