import * as React from "react";
import { Table, TableBody } from "material-ui/Table";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, } from "../../utils/DataHelpers";
import { Card, CardText } from "material-ui/Card";
class P2PTx extends React.PureComponent {
    render() {
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("ID", this.props.txData.id),
                        this.getParticipant("Sender", this.props.txData.sender),
                        this.getParticipant("Recipient", this.props.txData.recipient),
                        getInfoTableRow("Amount", formatCurrency(this.props.txData.amount)),
                        getInfoTableRow("Created", formatTime(this.props.txData.created)),
                        this.props.txData.notes
                            ? getInfoTableRow("Notes", this.props.txData.notes, "multiline-value-row")
                            : null,
                        this.props.txData.employeeContract
                            ? getInfoTableRow("Employee Contract", this.props.txData.employeeContract)
                            : null)))));
    }
    getParticipant(keyText, keyValue) {
        return getInfoTableRowWithLink(keyText, formatPhone(keyValue), `/buyer/${keyValue}/info`);
    }
}
export default P2PTx;
