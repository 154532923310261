import * as React from "react";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { DataValidator } from "../../utils/DataValidator";
class AddWithdrawRequestDialog extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            walletPhone: "",
            requestNumber: "",
            amount: "",
            phoneErrorText: "",
            amountErrorText: "",
        };
        this.state = this.initialState;
    }
    get renderDialogActions() {
        const { showDisabledButtons } = this.props;
        return [
            React.createElement("div", { id: "add-withdrawal-request" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleDialog.bind(this) }),
                React.createElement(FlatButton, { label: "Accept", primary: true, onClick: () => this.props.onAddClick(this.state.walletPhone, this.state.amount, this.state.requestNumber), disabled: this.handleButtonState || showDisabledButtons }))
        ];
    }
    get handleButtonState() {
        const { amount, walletPhone, amountErrorText, phoneErrorText } = this.state;
        const isNotEmpty = amount !== "" && walletPhone !== "";
        const noError = amountErrorText === "" && phoneErrorText === "";
        return !(isNotEmpty && noError);
    }
    componentDidUpdate(prevProps) {
        if (!this.props.opened && prevProps.opened) {
            this.setState(this.initialState);
        }
    }
    render() {
        return (React.createElement(Dialog, { title: "Add withdraw request", actions: this.renderDialogActions, modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) }, this.renderForm()));
    }
    renderForm() {
        const { requestNumber, walletPhone, amount, phoneErrorText, amountErrorText } = this.state;
        return (React.createElement("div", null,
            React.createElement(TextField, { id: "wallet-phone-number", autoFocus: true, hintText: "373 XX XXX XXX", floatingLabelText: "Wallet phone number", fullWidth: true, onChange: (e) => this.handlePhoneChange(e.target.value), value: walletPhone, errorText: phoneErrorText }),
            React.createElement(TextField, { id: "amount-input", hintText: "00.00", floatingLabelText: "Amount", fullWidth: true, onChange: (e) => this.handleAmountChange(e.target.value), value: amount, errorText: amountErrorText }),
            React.createElement(TextField, { id: "request-number-input", floatingLabelText: "Request number", fullWidth: true, onChange: (e) => this.handleRequestNumberChange(e.target.value), value: requestNumber })));
    }
    handlePhoneChange(value) {
        DataValidator.phoneNumberValidator(value).then((result) => {
            this.setState({ walletPhone: result, phoneErrorText: "" });
        }).catch((err) => {
            this.setState({ walletPhone: value, phoneErrorText: err });
        });
    }
    handleAmountChange(value) {
        DataValidator.fractionalNumberValidator(value).then(() => {
            this.setState({ amount: value, amountErrorText: "" });
        }).catch((errText) => {
            this.setState({ amount: value, amountErrorText: errText });
        });
    }
    handleRequestNumberChange(requestNumber) {
        this.setState({ requestNumber });
    }
}
export default connect(mapStateToPropsBaseUrl)(AddWithdrawRequestDialog);
