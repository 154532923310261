import * as React from "react";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, } from "../../utils/DataHelpers";
import { Card, CardText } from "material-ui/Card";
class SpendTx extends React.Component {
    render() {
        const { txData: { id, finished, amount, commHi, commLo, state, payOption, invoiceId, revertId } } = this.props;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("ID", id),
                        this.getBuyerRow(),
                        getInfoTableRow("Finished", formatTime(finished)),
                        getInfoTableRow("Amount", formatCurrency(this.getTotalAmount(amount, commHi))),
                        getInfoTableRow("High commission", formatCurrency(commHi)),
                        getInfoTableRow("Low commission", formatCurrency(commLo)),
                        this.getBusinessRow(),
                        this.getServiceRow(),
                        this.getClientIdRow(),
                        this.getTxnIdRow(),
                        getInfoTableRow("State", state),
                        getInfoTableRow("Pay Option", payOption),
                        getInfoTableRow("Invoice ID", invoiceId),
                        revertId ?
                            React.createElement(TableRow, null,
                                React.createElement(TableRowColumn, null,
                                    React.createElement("span", { className: "info-static-card-column-span" }, "Revert ID")),
                                React.createElement(TableRowColumn, null,
                                    React.createElement("span", { className: "tx-dialog-warn" }, revertId)))
                            : null)))));
    }
    getBuyerRow() {
        return getInfoTableRowWithLink("Buyer", formatPhone(this.props.txData.buyer), `/buyer/${this.props.txData.buyer}/info`);
    }
    getBusinessRow() {
        return getInfoTableRowWithLink("Business", this.props.txData.business, `/business/${this.props.txData.businessId}/info`);
    }
    getServiceRow() {
        const { serviceName, serviceUuid } = this.props.txData;
        return serviceUuid && serviceName
            ? getInfoTableRowWithLink("Service", serviceName, `/services/${serviceUuid}/info`)
            : null;
    }
    getClientIdRow() {
        const { clientId } = this.props.txData;
        return clientId
            ? getInfoTableRow("Client ID", clientId)
            : null;
    }
    getTxnIdRow() {
        const { txnId } = this.props.txData;
        return txnId
            ? getInfoTableRow("Txn ID", txnId)
            : null;
    }
    getTotalAmount(amount, commHi) {
        return Number(amount) + Number(commHi);
    }
}
export default SpendTx;
