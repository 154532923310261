import * as React from "react";
import { isNull } from "util";
import LinearProgress from "material-ui/LinearProgress";
import SmartDataTable from "../SmartDataTable";
import Card from "material-ui/Card/Card";
import { formatCurrency, formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
export default class POSTransactions extends React.Component {
    constructor() {
        super(...arguments);
        this.transactionTableColumns = [
            getTableColumnItem("id", "ID"),
            getTableColumnItem("from", "From"),
            getTableColumnItem("time", "Time", (time) => formatTime(time)),
            getTableColumnItem("amount", "Amount", (amount) => formatCurrency(amount)),
            getTableColumnItem("state", "State"),
        ];
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Card, { className: "card-full-size" }, this.renderTransactionDataTable())));
    }
    renderTransactionDataTable() {
        return isNull(this.props.transactions)
            ? React.createElement(LinearProgress, { mode: "indeterminate" })
            : React.createElement(SmartDataTable, { data: this.props.transactions, columns: this.transactionTableColumns, toolbarIconRight: this.renderUpdateIcon });
    }
    get renderUpdateIcon() {
        return (React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
            React.createElement(Refresh, null)));
    }
}
