import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import { CircularProgress } from "material-ui";
class ConfirmDialog extends React.PureComponent {
    render() {
        const { opened } = this.props;
        return (React.createElement(Dialog, { open: opened, onRequestClose: this.props.onCancelClick.bind(this), title: this.props.title, modal: true, actions: this.renderDialogButtons() }, this.props.content));
    }
    renderDialogButtons() {
        return ([
            React.createElement(FlatButton, { primary: true, label: "Cancel", onClick: this.props.onCancelClick.bind(this), disabled: this.props.isActioning }),
            React.createElement(FlatButton, { primary: true, label: "OK", onClick: this.props.onOkClick.bind(this), disabled: this.props.isActioning }, this.props.isActioning && React.createElement(CircularProgress, { size: 26 })),
        ]);
    }
}
export default ConfirmDialog;
