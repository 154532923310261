import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class AcceptUpgradeRequestDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.dialogActions = [
            React.createElement(FlatButton, { primary: true, label: "cancel", onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { primary: true, label: "accept", onClick: () => {
                    this.props.toggleDialog();
                    this.props.onAcceptBtnClick();
                } }),
        ];
    }
    render() {
        return (React.createElement(Dialog, { open: this.props.opened, onRequestClose: this.props.toggleDialog, title: "Accept upgrade request", modal: false, actions: this.dialogActions }, "Are you sure you want to accept Wallet upgrade request?"));
    }
}
export default AcceptUpgradeRequestDialog;
