import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export function withRouter(Child) {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const match = { params: useParams() };
        return React.createElement(Child, Object.assign({}, props, { history: navigate, location: location, match: match }));
    };
}
