import * as React from "react";
import { connect } from "react-redux";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { HttpClient } from "../../utils/HttpClient";
import { Card, CardText, CardActions } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import { Typography, CircularProgress, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import ConfirmDialog from "./ConfirmDialog";
import { withRouter } from '../../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class NotificationSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notificationData: null,
            confirmDeleteOpen: false,
            confirmSendOpen: false,
            isAction: false,
        };
    }
    componentDidMount() {
        this.fetchNotificationData();
    }
    render() {
        const { loading, notificationData, isAction } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(Card, { className: "card-998" },
                React.createElement(CardText, null, notificationData && React.createElement("div", null,
                    React.createElement(Typography, { variant: "subtitle1" }, `Audience: ${notificationData.userCount} users`),
                    React.createElement(Typography, { variant: "subtitle1" }, `Status: ${notificationData.status}`),
                    this.renderMessageContent("RO", notificationData.title_ro, notificationData.body_ro),
                    notificationData.title_ru && this.renderMessageContent("RU", notificationData.title_ru, notificationData.body_ru),
                    notificationData.title_en && this.renderMessageContent("EN", notificationData.title_en, notificationData.body_en))),
                React.createElement(CardActions, { className: "info-page-cardactions" },
                    !this.isReadOnly() && React.createElement(RaisedButton, { label: "Delete", className: "info-card-raisedbutton", onClick: this.toggleDeleteDialog.bind(this) }),
                    React.createElement(Link, { to: `/notifications` },
                        React.createElement(RaisedButton, { label: "Close", primary: true, className: "info-card-raisedbutton" })),
                    !this.isReadOnly() && React.createElement(Tooltip, { title: this.getTooltipTitle() },
                        React.createElement(RaisedButton, { label: "Send", secondary: true, className: "info-card-raisedbutton", onClick: this.toggleSendDialog.bind(this), disabled: notificationData && notificationData.userCount === 0 }))),
                React.createElement(ConfirmDialog, { opened: this.state.confirmDeleteOpen, onCancelClick: this.toggleDeleteDialog.bind(this), onOkClick: this.deleteNotification.bind(this), title: "Delete current notification data?", isActioning: isAction }),
                React.createElement(ConfirmDialog, { opened: this.state.confirmSendOpen, onCancelClick: this.toggleSendDialog.bind(this), onOkClick: this.sendNotification.bind(this), title: "Send current notifications?", isActioning: isAction, content: `Messages will be sent to ${notificationData && notificationData.userCount} users` })));
        }
    }
    isReadOnly() {
        return this.state.notificationData && this.state.notificationData.status !== "CREATED";
    }
    toggleDeleteDialog() {
        this.setState((state) => ({
            confirmDeleteOpen: !state.confirmDeleteOpen,
        }));
    }
    toggleSendDialog() {
        this.setState((state) => ({
            confirmSendOpen: !state.confirmSendOpen,
        }));
    }
    getTooltipTitle() {
        const { notificationData } = this.state;
        if (notificationData) {
            return notificationData.userCount > 0 ? `Send to ${notificationData.userCount} users` : "No users added!";
        }
        return "";
    }
    renderMessageContent(lang, title, body) {
        return (React.createElement(Card, { className: "card-full-size" },
            React.createElement(CardText, null,
                React.createElement(Typography, { variant: "h6", style: { color: "#FF8C00" } }, lang),
                React.createElement(Typography, { variant: "subtitle2" }, title),
                React.createElement(Typography, { variant: "subtitle1", dangerouslySetInnerHTML: { __html: body } }))));
    }
    sendNotification() {
        this.setState({
            isAction: true,
        });
        HttpClient.post(`admin/notification/send/${this.props.match.params.id}`)
            .then(() => {
            this.goToNotificationsList();
            ReactToastify.toast.success("Messages were sent successfully!");
        })
            .catch((err) => {
            this.setState({
                isAction: false,
            });
            ReactToastify.toast.error("Can't send messages. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    fetchNotificationData() {
        this.setState({
            loading: true,
        });
        const { match: { params: { id } }, baseUrl } = this.props;
        HttpClient.getJSON(`${baseUrl}/admin/notification/load/${id}`)
            .then((result) => {
            this.setState({
                loading: false,
                notificationData: result,
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't get notification data. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    goToNotificationsList() {
        this.props.history.push("/notifications");
    }
    deleteNotification() {
        this.setState({
            isAction: true,
        });
        HttpClient.post(`admin/notification/delete/${this.props.match.params.id}`)
            .then(() => {
            this.setState({
                isAction: false,
            });
            this.goToNotificationsList();
            ReactToastify.toast.info("Notification data was deleted successfully!");
        })
            .catch((err) => {
            this.setState({
                isAction: false,
            });
            ReactToastify.toast.error("Can't delete notification data. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.statusText));
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(NotificationSummary));
