import * as React from "react";
import TextField from "material-ui/TextField";
import { setCommFixAmount, setCommPercAmount, setCommPercDownAmount, setCommPercUpAmount, } from "../../reducers/CommissionReducer";
class CommissionFormRow extends React.PureComponent {
    render() {
        const { className, action } = this.props;
        const { fixedValue, percentValue, percentDownValue, percentUpValue, fixedValueErr, percentValueErr, percentDownValueErr, percentUpValueErr, percentDisabled, fixedDisabled, percentUpDisabled, percentDownDisabled, } = this.props.state;
        return (React.createElement("div", { className: className ? className : undefined },
            React.createElement(TextField, { id: "fixed-amount", floatingLabelText: "Fixed", hintText: "Enter fixed amount", value: fixedValue, className: "commission-input-field", onChange: (e) => action(setCommFixAmount(e.target.value)), errorText: fixedValueErr, disabled: fixedDisabled }),
            React.createElement(TextField, { id: "percent-input", floatingLabelText: "Percent", hintText: "Enter percent", value: percentValue, className: "commission-input-field", onChange: (e) => action(setCommPercAmount(e.target.value)), errorText: percentValueErr, disabled: percentDisabled }),
            React.createElement(TextField, { id: "percent-lower-limit", floatingLabelText: "Percent lower limit", hintText: "Enter lower percent limit", value: percentDownValue, className: "commission-input-field", onChange: (e) => action(setCommPercDownAmount(e.target.value)), errorText: percentDownValueErr, disabled: percentDownDisabled }),
            React.createElement(TextField, { id: "percent-upper-limit", floatingLabelText: "Percent upper limit", hintText: "Enter upper percent limit", value: percentUpValue, onChange: (e) => action(setCommPercUpAmount(e.target.value)), errorText: percentUpValueErr, disabled: percentUpDisabled })));
    }
}
export default CommissionFormRow;
