import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { CircularProgress, IconButton } from "material-ui";
import { InputAdornment, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { mapStateToPropsBaseUrl, formatAmount, getBreadcrumbObject } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { HttpClient } from "../utils/HttpClient";
import { getTableColumnItem } from "../utils/DataHelpers";
import SmartDataTable from "../components/SmartDataTable";
import SearchIcon from "material-ui/svg-icons/action/search";
import DateRabge from "material-ui/svg-icons/action/date-range";
import { Navigate } from "react-router-dom";
import { alphaNumericRegex } from "./utils";
import "./styles.css";
import moment from "moment";
import CashinFileLoader from "../components/CashInRequests/CashinFileLoader";
import { SelectField } from "material-ui";
import MenuItem from "material-ui/MenuItem";
import Switch from '@material-ui/core/Switch';
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import classNames from 'classnames';
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
const emptyErrorObj = {
    companyName: "",
    accountNo: "",
    payerName: "",
    payerCode: "",
    empoweredPersonName: "",
    empoweredPersonIdnp: "",
    empoweredPersonGetNumber: "",
    empoweredPersonGetData: "",
    empoweredPersonGetPassport: "",
    amount: "",
    summInWords: "",
    operationPurpose: "",
};
class CashInNewRequest extends React.Component {
    constructor(props) {
        super(props);
        this.fetchTrustedPersonsList = (companyId) => {
            const { baseUrl } = this.props;
            const defaultErrorMessage = "Error while getting the list of trusted persons";
            HttpClient.getJSON(`${baseUrl}/cashin/company/${companyId}/trustedPerson/list`).then((response) => {
                if (response && response.status && response.status.code === "ok") {
                    this.setState({ trustedPersonList: response.body || [] });
                }
                else {
                    ReactToastify.toast.info(defaultErrorMessage);
                }
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        };
        this.fetchCompanyList = () => {
            const { baseUrl } = this.props;
            const defaultErrorMessage = "Error while getting the list of companies for cashin";
            HttpClient.getJSON(`${baseUrl}/cashin/company/clients/list`).then((response) => {
                if (response && response.status && response.status.code === "ok") {
                    this.setState({ companyList: response.body || [] });
                }
                else {
                    ReactToastify.toast.info(defaultErrorMessage);
                }
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        };
        this.selectOrganizationTable = [
            getTableColumnItem("companyName", "Organization", (companyName) => (companyName), false),
            getTableColumnItem("companyIDNO", "IDNO", (companyIDNO) => (companyIDNO), false),
            getTableColumnItem("id", "Organization ID", (companyId) => (companyId), false),
        ];
        this.selectOrganizationTableTrustedPersons = [
            getTableColumnItem("trustedName", "Name", (trustedName) => (trustedName), false),
            getTableColumnItem("trustedIDNP", "IDNO", (trustedIDNP) => (trustedIDNP), false),
            getTableColumnItem("biNumber", "Bi Number", (biNumber) => (biNumber), false),
        ];
        this.fetchRequestDetails = (requestId) => {
            const { baseUrl, roles } = this.props;
            const { insertedData: { status } } = this.state;
            const defaultErrorMessage = "Error while getting the request details";
            this.setState({
                isSaving: true,
            });
            const isCashier = roles.indexOf("Cashier") !== -1;
            HttpClient.getJSON(`${baseUrl}/cashin/company/requests/${requestId}/detail`).then((response) => {
                if (response && response.status && response.status.code === "ok") {
                    this.mapToInsertedData(response.body);
                    if (isCashier) {
                        this.fetchTrustedPersonsList(response.body.company.id);
                        this.getCompanyAccounts(response.body.company.id);
                    }
                    if (response.body.status.toLowerCase() !== "ready") {
                        this.fetchCompanyList();
                    }
                }
                else {
                    ReactToastify.toast.info(defaultErrorMessage);
                }
                this.setState({
                    isSaving: false,
                });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load upgrade requests! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isSaving: false,
                    redirect: true,
                });
            });
        };
        this.mapToInsertedData = (cashinDetail) => {
            if (!cashinDetail) {
                ReactToastify.toast.info("Error while getting the request details. Details is null");
            }
            const company = cashinDetail.company;
            this.setState({
                insertedData: {
                    id: cashinDetail.id,
                    companyName: company && company.companyName,
                    companyIDNO: company && company.companyIDNO,
                    companyId: company && company.id,
                    companyCount: cashinDetail.accountNo,
                    amount: cashinDetail.amount,
                    operatorName: cashinDetail.operatorName,
                    operationNotes: cashinDetail.operationNotes || " ",
                    created: cashinDetail.created,
                    lastUpdate: cashinDetail.lastUpdate,
                    attachments: cashinDetail.attachments || [],
                    status: cashinDetail.status,
                    accountantNote: cashinDetail.accountantNote,
                    payerName: cashinDetail.payerName,
                    payerCode: cashinDetail.payerCode,
                    empoweredPersonName: cashinDetail.trustedPerson.trustedName,
                    empoweredPersonIdnp: cashinDetail.trustedPerson.trustedIDNP,
                    empoweredPersonGetNumber: cashinDetail.loaNumber,
                    empoweredPersonGetData: cashinDetail.loaDate,
                    empoweredPersonGetPassport: cashinDetail.trustedPerson.biNumber,
                    ccy: cashinDetail.ccy,
                    summInWords: cashinDetail.amountText,
                    operationPurpose: cashinDetail.operationPurpose,
                },
                validateFields: emptyErrorObj,
            });
        };
        this.fetchEditRequest = (requestId) => {
            const { baseUrl } = this.props;
            const { insertedData: { companyId, companyName, companyIDNO, amount, created, operatorNote, attachments, status, operationNotes, amountErrorText, payerName, payerCode, companyCount, empoweredPersonName, empoweredPersonIdnp, empoweredPersonGetNumber, empoweredPersonGetData, empoweredPersonGetPassport, summInWords, ccy, operationPurpose, accountantNote }, } = this.state;
            const query = {
                companyId,
                accountNo: companyCount,
                payerName: payerName,
                payerCode: payerCode,
                tpName: empoweredPersonName,
                tpIDNP: empoweredPersonIdnp,
                tpIDnumber: empoweredPersonGetPassport,
                loaDate: empoweredPersonGetData,
                loaNumber: empoweredPersonGetNumber,
                ccy,
                amount,
                operationNotes: operationNotes || " ",
                attaches: attachments,
                operationPurpose: operationPurpose,
                amountText: summInWords,
                accountantNote: accountantNote,
            };
            this.setState({
                isSaving: true,
            });
            const formData = JSON.stringify(query);
            const defaultErrorMessage = "Error while trying to update request for cashin";
            HttpClient.postUrl(`${baseUrl}/cashin/company/requests/${requestId}/update`, formData)
                .then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    ReactToastify.toast.success(`CashIn request was updated`);
                    this.setState({
                        isSaving: false,
                        redirect: true,
                    });
                }
                else {
                    this.setState({
                        isSaving: false,
                    });
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Error loading data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isSaving: false,
                });
            });
        };
        this.checkFieldsAndSubmitForm = (action, requestId = "") => {
            const { insertedData: { companyName, companyCount, payerName, payerCode, empoweredPersonName, empoweredPersonIdnp, empoweredPersonGetNumber, empoweredPersonGetData, empoweredPersonGetPassport, amount, summInWords, operationPurpose } } = this.state;
            let err = 0;
            if (companyName === "" ||
                companyCount === "" ||
                payerName === "" ||
                payerCode === "" ||
                empoweredPersonName === "" ||
                empoweredPersonIdnp === "" ||
                empoweredPersonGetNumber === "" ||
                empoweredPersonGetData === "" ||
                empoweredPersonGetPassport === "" ||
                amount === "" ||
                summInWords === "" ||
                operationPurpose === "") {
                this.setState({
                    validateFields: {
                        companyName: !companyName ? "Is required." : "",
                        companyCount: !companyCount ? "Is required." : "",
                        payerName: !payerName ? "Is required." : "",
                        payerCode: !payerCode ? "Is required." : "",
                        empoweredPersonName: !empoweredPersonName ? "Is required." : "",
                        empoweredPersonIdnp: !empoweredPersonIdnp ? "Is required." : "",
                        empoweredPersonGetNumber: !empoweredPersonGetNumber ? "Is required." : "",
                        empoweredPersonGetData: !empoweredPersonGetData ? "Is required." : "",
                        empoweredPersonGetPassport: !empoweredPersonGetPassport ? "Is required." : "",
                        amount: !amount ? "Is required." : "",
                        summInWords: !summInWords ? "Is required." : "",
                        operationPurpose: !operationPurpose ? "Is required." : "",
                    },
                });
            }
            else {
                if (action === "add") {
                    this.fetchAddNewRequest();
                }
                else {
                    this.fetchEditRequest(requestId);
                }
            }
        };
        this.setErrorValue = (state, value) => {
            this.setState({
                validateFields: Object.assign(Object.assign({}, this.state.validateFields), { [state]: value }),
            });
        };
        this.fetchAddNewRequest = () => {
            const { insertedData: { companyId, companyName, companyIDNO, amount, created, operatorNote, attachments, status, operationNotes, amountErrorText, payerName, payerCode, companyCount, empoweredPersonName, empoweredPersonIdnp, empoweredPersonGetNumber, empoweredPersonGetData, empoweredPersonGetPassport, summInWords, ccy, operationPurpose, accountantNote }, } = this.state;
            const query = {
                companyId,
                accountNo: companyCount,
                payerName: payerName,
                payerCode: payerCode,
                tpName: empoweredPersonName,
                tpIDNP: empoweredPersonIdnp,
                tpIDnumber: empoweredPersonGetPassport,
                loaDate: empoweredPersonGetData,
                loaNumber: empoweredPersonGetNumber,
                ccy,
                amount,
                operationNotes: operationNotes || " ",
                attaches: attachments,
                operationPurpose: operationPurpose,
                amountText: summInWords,
            };
            this.setState({
                isSaving: true,
            });
            const formData = JSON.stringify(query);
            const defaultErrorMessage = "Error while trying to create a new request for cashin";
            HttpClient.postUrl(`${this.props.baseUrl}/cashin/company/requests/create`, formData)
                .then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    ReactToastify.toast.success(`CashIn  request was created`);
                    this.setState({
                        isSaving: false,
                        redirect: true,
                    });
                }
                else {
                    this.setState({
                        isSaving: false,
                    });
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Error loading data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isSaving: false,
                });
            });
        };
        this.toApproveRequestRoleController = (requestId) => {
            const { baseUrl } = this.props;
            const { operationNotes } = this.state.insertedData;
            this.fetchEditRequest(requestId);
            this.setState({
                isSaving: true,
            });
            const query = {
                note: operationNotes,
            };
            const defaultErrorMessage = "Error while trying to send request cashin for controller";
            HttpClient.postUrl(`${baseUrl}/cashin/company/requests/${requestId}/send`, query)
                .then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    ReactToastify.toast.success("CashIn request has been sent to controller");
                    this.setState({
                        isSaving: false,
                        redirect: true,
                    });
                }
                else {
                    this.setState({
                        isSaving: false,
                    });
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Error loading data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isSaving: false,
                });
            });
        };
        this.sendToArchive = (requestId) => {
            const { baseUrl } = this.props;
            const { operatorNote } = this.state.insertedData;
            this.setState({
                isSaving: true,
            });
            const query = {
                note: operatorNote,
            };
            const defaultErrorMessage = "Error while trying to send request cashin to archive";
            HttpClient.postUrl(`${baseUrl}/cashin/company/requests/${requestId}/archive`, query)
                .then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                if (status && status.code === "ok") {
                    ReactToastify.toast.success("CashIn request was added to Archive");
                    this.setState({
                        isSaving: false,
                        redirect: true,
                    });
                }
                else {
                    this.setState({
                        isSaving: false,
                    });
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Error loading data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isSaving: false,
                });
            });
        };
        this.setRequestDecision = (decision, requestId) => {
            const { baseUrl } = this.props;
            const { insertedData: { accountantNote } } = this.state;
            this.setState({
                isSaving: true,
            });
            const query = {
                action: decision,
                note: accountantNote,
            };
            const defaultErrorMessage = "Error while trying to confirm request cashin";
            HttpClient.postUrl(`${baseUrl}/cashin/company/requests/${requestId}/confirm`, query)
                .then((response) => {
                const resp = JSON.parse(response);
                const status = resp && resp.status;
                const actionStatus = decision === "reject" ? " CashIn request was rejected" : "CashIn request was accepted";
                if (status && status.code === "ok") {
                    ReactToastify.toast.success(actionStatus);
                    this.setState({
                        isSaving: false,
                        redirect: true,
                    });
                }
                else {
                    this.setState({
                        isSaving: false,
                    });
                    ReactToastify.toast.info((status && status.message) || defaultErrorMessage);
                }
            })
                .catch((err) => {
                ReactToastify.toast.error("Error loading data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({
                    isSaving: false,
                });
            });
        };
        this.addFileIdForRequest = (fileId) => {
            const { insertedData: { attachments } } = this.state;
            this.setState({
                insertedData: Object.assign(Object.assign({}, this.state.insertedData), { attachments: [
                        ...attachments,
                        fileId,
                    ] }),
            });
        };
        this.deleteFileIdForRequest = (fileId) => {
            const { insertedData: { attachments } } = this.state;
            const ignoreFilesId = (attachments || []).filter((value) => {
                return value !== fileId;
            });
            this.setState({
                insertedData: Object.assign(Object.assign({}, this.state.insertedData), { attachments: [
                        ...ignoreFilesId,
                    ] }),
            });
        };
        this.setValue = (state, value) => {
            this.setErrorValue(state, "");
            this.setState({
                insertedData: Object.assign(Object.assign({}, this.state.insertedData), { [state]: value }),
            });
        };
        this.getCompanyAccounts = (companyId) => {
            const { baseUrl } = this.props;
            const defaultErrorMessage = "Error loading company accounts ";
            HttpClient.getJSON(`${baseUrl}/cashin/company/clients/${companyId}/accounts`)
                .then((response) => {
                if (response && response.status && response.status.code === "ok") {
                    this.setState({ companyAccounts: response.body || [] });
                }
                else {
                    ReactToastify.toast.info(defaultErrorMessage);
                }
            }).catch((err) => {
                ReactToastify.toast.error(defaultErrorMessage + " Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
            this.fetchTrustedPersonsList(companyId);
        };
        this.renderStyledMenuItems = (number, amount, ccy) => {
            return (React.createElement("div", { className: "accounts-menu-tems" },
                React.createElement("span", null, number),
                " ",
                React.createElement("span", null,
                    formatAmount(amount),
                    " ",
                    ccy)));
        };
        this.renderMenuItems = () => {
            const { companyAccounts } = this.state;
            return companyAccounts.map((txType, index) => React.createElement(MenuItem, { key: index, insetChildren: true, value: txType.accNumber, primaryText: this.renderStyledMenuItems(txType.accNumber, txType.amount || 0, txType.ccy || "MDL"), className: "custom-menu-item" }));
        };
        this.renderMenuItemsTrustedPersons = () => {
            const { trustedPersonList } = this.state;
            return trustedPersonList.map((personItem, index) => {
                return (React.createElement(MenuItem, { key: index, insetChildren: true, value: personItem.trustedName, primaryText: personItem.trustedName, onClick: () => this.changeCompanyTrustedPersonsSelected(personItem), className: "custom-menu-item" }));
            });
        };
        this.renderCcyMenuItems = () => {
            const txTypes = [
                "MDL",
                "EURO",
                "USD",
            ];
            return txTypes.map((txType, index) => React.createElement(MenuItem, { key: index, insetChildren: true, value: txType, primaryText: txType, className: "custom-menu-item" }));
        };
        this.sameCompanyPlatitor = (value) => {
            const { insertedData: { companyName, companyId, companyIDNO } } = this.state;
            if (value) {
                this.setState({
                    insertedData: Object.assign(Object.assign({}, this.state.insertedData), { payerName: companyName, payerCode: companyIDNO, platitorId: companyId }),
                    sameCompany: value
                });
            }
            else {
                this.setState({
                    insertedData: Object.assign(Object.assign({}, this.state.insertedData), { payerName: "", payerCode: "", platitorId: "" }),
                    sameCompany: value
                });
            }
        };
        this.orderCreateDate = (date) => {
            this.setErrorValue("empoweredPersonGetData", "");
            this.setState((prevState) => ({
                insertedData: Object.assign(Object.assign({}, prevState.insertedData), { empoweredPersonGetData: date }),
            }));
        };
        this.renderCommonForm = () => {
            const { roles } = this.props;
            const { isSaving, insertedData: { companyName, companyIDNO, amount, attachments, status, operationNotes, payerName, payerCode, companyCount, empoweredPersonName, empoweredPersonIdnp, empoweredPersonGetNumber, empoweredPersonGetData, empoweredPersonGetPassport, summInWords, ccy, operationPurpose, accountantNote }, validateFields, sameCompany } = this.state;
            const isAccountant = roles.indexOf("Accountant") !== -1;
            const isCashier = roles.indexOf("Cashier") !== -1;
            const disabled = status && status.toLowerCase() === "ready" || status && status.toLowerCase() === "accepted";
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Beneficiar"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "companyName", required: true, disabled: isSaving || disabled, style: { width: "100%" }, label: "Companie", onFocus: this.toggleSelectedCompany(), value: companyName, error: validateFields.companyName, helperText: validateFields.companyName, InputLabelProps: {
                                    shrink: true,
                                }, InputProps: {
                                    endAdornment: React.createElement(InputAdornment, { position: "end", disablePointerEvents: isSaving || disabled, disableTypography: isSaving || disabled },
                                        React.createElement(IconButton, { disabled: isSaving || disabled, onFocus: this.toggleSelectedCompany() },
                                            React.createElement(SearchIcon, null))),
                                } }),
                            React.createElement(TextField, { id: "companyIDNO", style: { width: "86%", marginLeft: 15 }, inputProps: { maxLength: 12 }, label: "IDNO", value: companyIDNO, disabled: true, InputLabelProps: {
                                    shrink: true,
                                } })),
                        React.createElement("div", { className: "form-line" },
                            React.createElement(SelectField, { id: "tx-type-select", onChange: (e, i, value) => this.setValue("companyCount", value), value: companyCount, className: classNames({
                                    'select-count': true,
                                    'error-select': validateFields.companyCount,
                                }), disabled: !companyName || disabled, floatingLabelText: "Contul beneficiarului/IBAN:" }, this.renderMenuItems())))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Platitor"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "payerName", required: true, disabled: isSaving || !companyName || sameCompany || disabled, style: { width: "100%" }, label: "Nume/Companie", onFocus: this.toggleSelectedCompany(true), onChange: (e) => this.setValue("payerName", e.target.value), value: payerName, InputLabelProps: {
                                    shrink: true,
                                }, error: validateFields.payerName, helperText: validateFields.payerName, InputProps: {
                                    endAdornment: React.createElement(InputAdornment, { position: "end", disablePointerEvents: isSaving || disabled, disableTypography: isSaving || disabled }, companyName && !sameCompany && React.createElement(IconButton, { disabled: isSaving || disabled, onFocus: this.toggleSelectedCompany(true) },
                                        React.createElement(SearchIcon, null))),
                                } }),
                            React.createElement(TextField, { id: "payerCode", required: true, error: validateFields.payerCode, helperText: validateFields.payerCode, style: { width: "86%", marginLeft: 15 }, inputProps: { maxLength: 12 }, label: "IDNP/IDNO", value: payerCode, onChange: (e) => this.setValue("payerCode", e.target.value), disabled: true, InputLabelProps: {
                                    shrink: true,
                                } })),
                        React.createElement("div", { className: "form-line one-item-row" },
                            React.createElement("label", null, "Coincide cu beneficiarul"),
                            React.createElement(Switch, { checked: sameCompany || (companyIDNO === payerCode), onChange: e => this.sameCompanyPlatitor(e.target.checked), color: "primary", name: "checkedB", disabled: !companyName || disabled, inputProps: { 'aria-label': 'primary checkbox' } })))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Persoana imputernicita"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement("div", { className: "width-30 select-persons" },
                                React.createElement("div", null,
                                    React.createElement(TextField, { id: "empoweredPersonName", required: true, error: validateFields.empoweredPersonName, helperText: validateFields.empoweredPersonName, disabled: isSaving || disabled || !companyName, style: { width: "100%" }, label: "Numele", onChange: (e) => this.setValue("empoweredPersonName", e.target.value), value: empoweredPersonName, InputLabelProps: {
                                            shrink: true,
                                        } }),
                                    React.createElement(InputAdornment, { position: "end", disablePointerEvents: isSaving || disabled, disableTypography: isSaving || disabled },
                                        React.createElement(IconButton, { disabled: isSaving || disabled, onFocus: this.toggleSelectedTrustedPersons() },
                                            React.createElement(SearchIcon, null))))),
                            React.createElement("div", { className: "width-30 m-t-15" },
                                React.createElement(TextField, { id: "empoweredPersonIdnp", required: true, inputProps: { maxLength: 12 }, label: "IDNP", onChange: (e) => this.handleChangeNumericInputs("empoweredPersonIdnp", e.target.value), value: empoweredPersonIdnp, disabled: isSaving || disabled || !companyName, InputLabelProps: {
                                        shrink: true,
                                    }, error: validateFields.empoweredPersonIdnp, helperText: validateFields.empoweredPersonIdnp })),
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "empoweredPersonGetPassport", required: true, label: "Buletin(seria si numar)", onChange: (e) => this.setValue("empoweredPersonGetPassport", e.target.value), value: empoweredPersonGetPassport, disabled: isSaving || disabled || !companyName, InputLabelProps: {
                                        shrink: true,
                                    }, inputProps: { maxLength: 12 }, style: { marginTop: "15px" }, error: validateFields.empoweredPersonGetPassport, helperText: validateFields.empoweredPersonGetPassport }))),
                        React.createElement("div", { className: "form-line" },
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "empoweredPersonGetNumber", required: true, disabled: isSaving || disabled || !companyName, label: "Nr. procura", onChange: (e) => this.setValue("empoweredPersonGetNumber", e.target.value), value: empoweredPersonGetNumber, InputLabelProps: {
                                        shrink: true,
                                    }, error: validateFields.empoweredPersonGetNumber, helperText: validateFields.empoweredPersonGetNumber })),
                            React.createElement("div", { className: "width-30", style: { display: "flex", alignItems: "baseline" } },
                                React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils, locale: moment.locale(), moment: moment },
                                    React.createElement(InlineDatePicker, { format: "DD.MM.YYYY", id: "empoweredPersonGetData", autoOk: true, disableFuture: true, disabled: isSaving || disabled || !companyName, clearable: true, style: { marginTop: 0 }, label: "Data emiterii*", onChange: this.orderCreateDate, value: empoweredPersonGetData, error: validateFields.empoweredPersonGetData, helperText: validateFields.empoweredPersonGetData }),
                                    React.createElement(DateRabge, { className: "date-range-ico" })))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Detalii operatie"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement("div", { className: "width-30" },
                                React.createElement(TextField, { id: "amount", required: true, inputProps: { maxLength: 12 }, label: "Suma in cifre", onChange: (e) => this.setValue("amount", e.target.value), value: amount, disabled: isSaving || disabled || !companyName, InputLabelProps: {
                                        shrink: true,
                                    }, error: validateFields.amount, helperText: validateFields.amount })),
                            React.createElement("div", { className: "width-30" },
                                React.createElement(SelectField, { id: "tx-type-select", onChange: (e, i, value) => this.setValue("ccy", value), value: ccy, disabled: true, className: "select-count ccy", floatingLabelText: "Valuta*" }, this.renderCcyMenuItems()))),
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "summInWords", required: true, disabled: isSaving || disabled || !companyName, label: "Suma in litere", onChange: (e) => this.setValue("summInWords", e.target.value), value: summInWords, InputLabelProps: {
                                    shrink: true,
                                }, error: validateFields.summInWords, helperText: validateFields.summInWords })),
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "operationPurpose", required: true, disabled: isSaving || disabled || !companyName, onChange: (e) => this.setValue("operationPurpose", e.target.value), label: "Scopul operatiei", value: operationPurpose, InputLabelProps: {
                                    shrink: true,
                                }, error: validateFields.operationPurpose, helperText: validateFields.operationPurpose })))),
                (isAccountant || accountantNote) && React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-2 line-title" }, "Note"),
                    React.createElement("div", { className: "col-md-10" },
                        React.createElement("div", { className: "form-line" },
                            React.createElement(TextField, { id: "accountantNote", style: { width: "100%" }, label: "Controller", disabled: isSaving || !companyName || !isAccountant, placeholder: "Type here", onChange: (e) => this.setValue("accountantNote", e.target.value), InputLabelProps: {
                                    shrink: true,
                                }, value: accountantNote })))),
                !(isCashier && (status && status.toLowerCase() === "ready")) &&
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-2 line-title" }),
                        React.createElement("div", { className: "col-md-10" },
                            React.createElement(CashinFileLoader, { isEditable: true, fileIdList: attachments || [], addFileId: this.addFileIdForRequest.bind(this), deleteFile: this.deleteFileIdForRequest.bind(this) }))),
                React.createElement("div", { style: { display: "flex", flexDirection: "row", marginTop: 15, minHeight: 100 } },
                    React.createElement(TextField, { id: "operationNotes", label: "Operation notes", style: { width: "100%" }, value: operationNotes, onChange: (e) => this.setValue("operationNotes", e.target.value), multiline: true, disabled: isSaving || disabled || !companyName, rows: 2, rowsMax: 6 }))));
        };
        this.renderCommonContent = () => {
            const { insertedData: { status } } = this.state;
            const buttons = status && status.toLowerCase() === "ready" ? this.renderDialogActionsForController : this.renderDialogActions;
            return (React.createElement("div", { className: "parent-container" },
                this.renderCommonForm(),
                buttons));
        };
        this.state = {
            isSelected: false,
            insertInSecondRow: false,
            files: [],
            errors: [],
            isSaving: false,
            operatorImagePreview: "",
            operatorImageFile: null,
            requestId: "",
            redirect: false,
            companyAccounts: [],
            trustedPersonList: [],
            insertedData: {
                id: "",
                companyName: "",
                companyIDNO: "",
                companyId: "",
                accountNo: "",
                amount: "",
                operatorName: "",
                operatorNote: "",
                operationNotes: "",
                created: "",
                lastUpdate: "",
                attachments: [],
                status: "New",
                amountErrorText: "",
                ccy: "MDL",
                payerName: "",
                payerCode: "",
                companyCount: "",
                empoweredPersonName: "",
                empoweredPersonIdnp: "",
                empoweredPersonGetNumber: "",
                empoweredPersonGetData: new Date(),
                empoweredPersonGetPassport: "",
                summInNumbers: "",
                summInWords: "",
                operationPurpose: "",
            },
            validateFields: emptyErrorObj,
            companyList: [],
            companyCount: 0,
            sameCompany: false,
            isSelectedTrustedPersons: false
        };
    }
    componentDidMount() {
        const { match: { params: { id } }, dispatch } = this.props;
        if (id) {
            this.props.dispatch(setBreadcrumb([getBreadcrumbObject("CashIn requests > Details", "/cash-in-requests")]));
            this.setState({ requestId: id }, () => this.fetchRequestDetails(id));
        }
        else {
            this.props.dispatch(setBreadcrumb([getBreadcrumbObject("CashIn requests > Add new request", "/cash-in-requests")]));
            this.fetchCompanyList();
        }
    }
    get renderDialogActions() {
        const { requestId, insertedData: { companyName, companyIDNO, amount, companyId, status }, isSaving, } = this.state;
        return [
            React.createElement("div", { id: "cashin-decision", style: { display: !requestId && "block" } },
                requestId && React.createElement("div", { className: "button-parent-container" },
                    React.createElement(FlatButton, { label: "To archive", primary: true, onClick: () => this.sendToArchive(requestId), disabled: isSaving }),
                    React.createElement(FlatButton, { label: "To approve", primary: true, onClick: () => this.toApproveRequestRoleController(requestId), disabled: !companyName || !companyIDNO || !amount || !companyId || isSaving })),
                React.createElement("div", { className: "button-parent-container" },
                    React.createElement(FlatButton, { label: "Discard", primary: true, onClick: () => this.setState({ redirect: true }) }),
                    React.createElement(FlatButton, { label: "Save", primary: true, disabled: !companyName || !companyIDNO || !amount || !companyId || isSaving, onClick: () => requestId ? this.checkFieldsAndSubmitForm("edit", requestId) : this.checkFieldsAndSubmitForm("add") }))),
        ];
    }
    get renderDialogActionsForController() {
        const { roles } = this.props;
        const { requestId, insertedData: { accountantNote } } = this.state;
        return [
            React.createElement("div", { id: "cashin-decision", style: { display: !requestId && "block", marginTop: 30 } },
                requestId && React.createElement("div", { className: "button-parent-container" },
                    React.createElement(FlatButton, { label: "Approve", primary: true, disabled: !accountantNote, onClick: () => this.setRequestDecision("accept", requestId) }),
                    React.createElement(FlatButton, { label: "Reject", primary: true, disabled: !accountantNote, onClick: () => this.setRequestDecision("reject", requestId) })),
                React.createElement("div", { className: "button-parent-container" },
                    React.createElement(FlatButton, { label: "Discard", primary: true, onClick: () => this.setState({ redirect: true }) }))),
        ];
    }
    handleChangeNumericInputs(state, value) {
        const isValid = (alphaNumericRegex.test(value) && value.length <= 13) || value === "";
        if (isValid) {
            this.setState({
                insertedData: Object.assign(Object.assign({}, this.state.insertedData), { [state]: value }),
            });
        }
    }
    ;
    changeCompanySelected(row) {
        const company = this.state.companyList[row];
        this.setState({
            insertedData: Object.assign(Object.assign({}, this.state.insertedData), { companyName: company ? company.companyName : "", companyIDNO: company ? company.companyIDNO : "", companyId: company ? company.id : "" }),
            isSelected: false,
        }, () => {
            this.getCompanyAccounts(company.id);
        });
    }
    changeCompanyTrustedPersonsSelected(row) {
        const trustedPersonList = this.state.trustedPersonList[row];
        this.setState({
            insertedData: Object.assign(Object.assign({}, this.state.insertedData), { empoweredPersonName: trustedPersonList ? trustedPersonList.trustedName : "", empoweredPersonIdnp: trustedPersonList ? trustedPersonList.trustedIDNP : "", empoweredPersonGetPassport: trustedPersonList ? trustedPersonList.biNumber : "", empoweredPersonGetData: new Date() }),
            isSelectedTrustedPersons: false,
        });
    }
    changeCompanySelectedSecondRow(row) {
        const company = this.state.companyList[row];
        this.setState({
            insertedData: Object.assign(Object.assign({}, this.state.insertedData), { payerName: company ? company.companyName : "", payerCode: company ? company.companyIDNO : "", platitorId: company ? company.id : "" }),
            isSelected: false,
        });
    }
    toggleSelectedCompany(insertInSecondRow = false) {
        return () => this.setState({ isSelected: !this.state.isSelected, insertInSecondRow });
    }
    toggleSelectedTrustedPersons() {
        return () => this.setState({ isSelectedTrustedPersons: !this.state.isSelectedTrustedPersons });
    }
    renderCompanyList() {
        const { insertInSecondRow } = this.state;
        return (React.createElement(SmartDataTable, { data: this.state.companyList, columns: this.selectOrganizationTable, showFooterToolbar: false, isCursorPointer: true, showHeaderToolbarFilterIcon: false, onRowSelection: (row) => !insertInSecondRow ? this.changeCompanySelected(row) : this.changeCompanySelectedSecondRow(row) }));
    }
    renderTrustedPersonsList() {
        const { trustedPersonList } = this.state;
        return (React.createElement(SmartDataTable, { data: trustedPersonList, columns: this.selectOrganizationTableTrustedPersons, showFooterToolbar: false, isCursorPointer: true, showHeaderToolbarFilterIcon: false, onRowSelection: (row) => this.changeCompanyTrustedPersonsSelected(row) }));
    }
    renderDialogSelectTrustedPersonInfo() {
        const { isSelectedTrustedPersons } = this.state;
        return (React.createElement(Dialog, { title: "Select company", actions: this.isSelectedCompanyTrustedPersons, contentStyle: { maxWidth: "700px" }, bodyStyle: { border: "none" }, modal: true, open: isSelectedTrustedPersons, autoScrollBodyContent: true }, this.renderTrustedPersonsList()));
    }
    renderDialogSelectCompany() {
        const { isSelected } = this.state;
        return (React.createElement(Dialog, { title: "Select company", actions: this.isSelectedCompanyActions, contentStyle: { maxWidth: "700px" }, bodyStyle: { border: "none" }, modal: true, open: isSelected, autoScrollBodyContent: true, onRequestClose: this.toggleSelectedCompany.bind(this) }, this.renderCompanyList()));
    }
    get isSelectedCompanyTrustedPersons() {
        return [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.toggleSelectedTrustedPersons() }),
        ];
    }
    get isSelectedCompanyActions() {
        return [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.toggleSelectedCompany() }),
        ];
    }
    renderForm() {
        const { isSaving, redirect, insertedData: { status } } = this.state;
        if (isSaving) {
            return (React.createElement(CircularProgress, { size: 50, style: { left: "50%" } }));
        }
        return (React.createElement("div", { className: "new-request-page" },
            redirect && React.createElement(Navigate, { to: `/cash-in-requests` }),
            this.renderCommonContent()));
    }
    render() {
        return (React.createElement("div", null,
            this.renderForm(),
            this.renderDialogSelectCompany(),
            this.renderDialogSelectTrustedPersonInfo()));
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(CashInNewRequest));
