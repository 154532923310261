import * as React from "react";
import { connect } from "react-redux";
import { formatPhone, formatTime, getBreadcrumbObject, getTableColumnAction, getTableColumnItem, mapStateToPropsBaseUrl, } from "../../utils/DataHelpers";
import { setBreadcrumb } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import { IconButton, Card, Checkbox, Dialog, FlatButton, TextField, CircularProgress } from "material-ui";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import { withRouter } from '../../utils/HOC/withRouter';
const DataTable = require("material-ui-datatables");
const ReactToastify = require("react-toastify");
class ArchiveDetails extends React.Component {
    constructor(props) {
        super(props);
        this.archivesTableColumns = [
            getTableColumnItem("phone", "Phone", (phone) => formatPhone(phone)),
            getTableColumnItem("walletType", "Type"),
            getTableColumnItem("created", "Date", (created) => formatTime(created)),
            getTableColumnItem("lastActive", "Last activity date", (lastActive) => formatTime(lastActive)),
            getTableColumnItem("name", "Name"),
            getTableColumnItem("surname", "Surname"),
            getTableColumnItem("idnp", "IDNP"),
            getTableColumnAction("id", (name, archiveInfo) => this.renderIconMenu(archiveInfo)),
        ];
        this.state = {
            archivesDetails: [],
            loading: false,
            rowSize: 20,
            count: 0,
            currentPage: 1,
            search: "",
            orderKey: "",
            order: "",
            open: false,
            errors: [],
            excludeText: "",
            isSaving: false,
            currentDetails: null,
            openExcludeDialog: false,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Wallet archive", `/archive-users/`),
            getBreadcrumbObject("Wallet list", `/archive-users/${this.props.match.params.id}/wallets`)]));
    }
    render() {
        const { currentDetails } = this.state;
        return (React.createElement("div", { className: "full-size-card-wrapper" },
            React.createElement(Card, { className: "card-full-size" },
                this.renderTable(),
                ";"),
            currentDetails && !currentDetails.excluded && this.openExcludeDialog(),
            currentDetails && currentDetails.excluded && this.openIncludeDialog()));
    }
    componentDidMount() {
        this.fetchArchiveDetails(this.state.currentPage, this.state.rowSize, this.state.orderKey, this.state.order);
    }
    renderTable() {
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.archivesTableColumns, selectable: false, data: this.state.archivesDetails, showCheckboxes: false, showHeaderToolbar: true, rowSizeList: [5, 10, 20, 40], rowSize: this.state.rowSize, count: this.state.count, page: this.state.currentPage, tableRowStyle: { cursor: "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.renderUpdateIcon }));
    }
    handleNextPageClick() {
        const page = this.state.currentPage + 1;
        this.fetchArchiveDetails(page, this.state.rowSize, this.state.orderKey, this.state.order);
    }
    handlePreviousPageClick() {
        const page = this.state.currentPage - 1;
        this.fetchArchiveDetails(page, this.state.rowSize, this.state.orderKey, this.state.order);
    }
    handleSortOrderChange(key, order) {
        this.fetchArchiveDetails(this.state.currentPage, this.state.rowSize, key, order);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.state.currentPage;
        if ((page - 1) * rowSize > this.state.count) {
            page = 1;
        }
        this.fetchArchiveDetails(page, rowSize, this.state.orderKey, this.state.order);
    }
    get renderUpdateIcon() {
        return [
            React.createElement(IconButton, { onClick: () => this.fetchArchiveDetails(this.state.currentPage, this.state.rowSize, this.state.orderKey, this.state.order) },
                React.createElement(Refresh, null)),
        ];
    }
    handleClose() {
        this.setState(Object.assign(Object.assign({}, this.state), { open: false, currentDetails: null }));
    }
    handleOpen(archive) {
        this.setState(Object.assign(Object.assign({}, this.state), { open: true, currentDetails: archive }));
    }
    openExcludeDialog() {
        return (React.createElement(Dialog, { title: "Exclude user", actions: this.renderDialogActions, modal: true, open: this.state.open, onRequestClose: this.handleClose.bind(this) }, this.renderForm()));
    }
    openIncludeDialog() {
        return (React.createElement(Dialog, { title: "Include user", actions: this.renderDialogActions, modal: true, open: this.state.open, onRequestClose: this.handleClose.bind(this) }, this.renderIncludeForm()));
    }
    renderForm() {
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
            React.createElement(TextField, { id: "exclude-text-input", autoFocus: true, required: true, style: { width: "50%" }, errorText: this.findErrorText("excludeText"), floatingLabelText: "Exclude text", onChange: (e) => this.setState({ excludeText: e.target.value }) })));
    }
    renderIncludeForm() {
        const { currentDetails } = this.state;
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
            React.createElement("p", null,
                "You include back user with phone ",
                currentDetails.phone)));
    }
    findErrorText(field) {
        const { errors } = this.state;
        if (errors) {
            const error = errors.find((e) => e.field === field);
            return error ? error.text : "";
        }
        return "";
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "archive-details-dialog-actions" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.handleClose.bind(this) }, this.state.isSaving && React.createElement(CircularProgress, { size: 26 })),
                React.createElement(FlatButton, { label: "Ok", primary: true, onClick: this.excludeUser.bind(this) }, this.state.isSaving && React.createElement(CircularProgress, { size: 26 })))
        ];
    }
    renderIconMenu(archive) {
        return (React.createElement("div", { id: "user-checkbox", className: "commission-form-checkbox-row-wrapper" },
            React.createElement("div", { className: "commission-form-checkbox-row" },
                React.createElement("div", { className: "commission-form-checkbox" },
                    React.createElement(Checkbox, { onCheck: () => this.handleOpen(archive), checked: archive.excluded === 1 })))));
    }
    excludeUser() {
        const { currentDetails, excludeText } = this.state;
        const data = JSON.stringify([{
                walletPhone: currentDetails.phone,
                excludeMessage: excludeText,
            }]);
        HttpClient.putWithData(`${this.props.baseUrl}/archive/${this.props.match.params.id}/exclude?include=${!!currentDetails.excluded}`, data)
            .then(() => {
            this.setState({
                loading: false,
                open: false,
            });
            ReactToastify.toast.success("Information successfully updated!");
        })
            .catch((err) => {
            this.setState({
                loading: false,
                open: false,
            });
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.fetchArchiveDetails();
        });
    }
    fetchArchiveDetails(currentPage = this.state.currentPage, rowSize = this.state.rowSize, sortCol = this.state.orderKey, sortDir = this.state.order) {
        const query = {
            offset: (currentPage - 1) * rowSize,
            limit: rowSize,
            sortCol,
            sortDir,
        };
        this.setState({
            loading: true,
        });
        HttpClient.getJSON(`${this.props.baseUrl}/archive/${this.props.match.params.id}/wallets`, query)
            .then((response) => {
            this.setState({
                loading: false,
                archivesDetails: response && response.archiveUsers || [],
                count: response && response.count || 0,
                currentPage: currentPage || 1,
                orderKey: sortCol,
                order: sortDir,
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading data! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(ArchiveDetails));
