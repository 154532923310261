export var TabNames;
(function (TabNames) {
    TabNames[TabNames["Active"] = 0] = "Active";
    TabNames[TabNames["Archive"] = 1] = "Archive";
})(TabNames || (TabNames = {}));
export var status;
(function (status) {
    status["NEW"] = "NEW";
    status["ACTIVE"] = "ACTIVE";
    status["WITHDRAWN"] = "WITHDRAWN";
    status["CANCELLED"] = "CANCELLED";
    status["FAILED"] = "FAILED";
    status["EXPIRED"] = "EXPIRED";
})(status || (status = {}));
