import * as React from "react";
import { BuyerDocTypes, BuyerType } from "../../interfaces/Buyer";
import { Card, CardActions, CardText } from "material-ui/Card";
import TextField from "material-ui/TextField";
import { formatCurrency, formatPhone, formatTime } from "../../utils/DataHelpers";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import { setBuyerInfo, } from "../../actions/Actions";
import * as moment from "moment";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import { isNullOrUndefined } from "util";
import BuyerPassportDialog from "./BuyerPassportDialog";
import Lock from "material-ui/svg-icons/action/lock";
import LockOpen from "material-ui/svg-icons/action/lock-open";
import Star from "material-ui/svg-icons/toggle/star";
import { maxInputLength } from "../../variables/Common";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import BuyerNotes from "./BuyerNotes";
class BuyerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.noteDialogActions = [
            React.createElement("div", { id: "note-dialog-actions" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.toggleNoteDialog() }),
                React.createElement(FlatButton, { label: "Save", primary: true, onClick: () => this.saveNote() }))
        ];
        this.changeNote = (e) => {
            const newValue = e.target.value;
            this.setState({
                note: newValue,
            });
        };
        this.handleBirthdayChange = (date) => {
            this.setState((prevState) => ({
                fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { birthday: date }),
                birthdayError: !date ? "Birthday must not be empty!" : "",
            }));
        };
        this.state = {
            showActions: false,
            dialogOpen: false,
            fetchedData: this.props.data,
            addressError: "",
            formNumberError: "",
            idnpError: "",
            nameError: "",
            surnameError: "",
            noteDialogOpen: false,
            note: "",
            birthdayError: "",
            documentError: "",
        };
    }
    get getButtonState() {
        return (JSON.stringify(this.state.fetchedData) === JSON.stringify(this.props.data));
    }
    get getUpdateButtonState() {
        return this.getButtonState;
    }
    get getDocumentValue() {
        let result = "";
        const { passportSeries, issueDate, expDate, issuingAuthority, docType } = this.state.fetchedData;
        if (docType || docType === 0) {
            result += BuyerDocTypes().get(docType) + ", ";
        }
        if (passportSeries) {
            result += passportSeries + ", ";
        }
        if (issueDate) {
            result += "Date of issue: " + moment(issueDate).format("DD.MM.YYYY") + ", ";
        }
        if (expDate) {
            result += "Date of expiry: " + moment(expDate).format("DD.MM.YYYY") + ", ";
        }
        if (issuingAuthority) {
            result += "Issuing Authority: " + issuingAuthority;
        }
        return result;
    }
    render() {
        return (React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils, locale: moment.locale(), moment: moment },
            this.renderInfoCard(),
            React.createElement(Card, { className: "info-page-card" },
                React.createElement(CardText, null, this.renderTextFields()),
                this.renderCardActions()),
            this.renderNotes(),
            this.renderPassportDialog(),
            this.renderNoteDialog()));
    }
    renderTextFields() {
        const { name, surname, birthday, idnp, address, formNumber } = this.state.fetchedData;
        const { addressError, idnpError, nameError, formNumberError, surnameError, birthdayError, documentError } = this.state;
        return [
            React.createElement("div", { className: "commission-form-row", key: "first-row" },
                React.createElement(TextField, { key: "name", className: "commission-input-field", id: "firstname", value: name, floatingLabelText: "Name", onChange: (e) => this.handleNameChange(e.target.value), errorText: nameError }),
                React.createElement(TextField, { key: "surname", id: "surname", className: "commission-input-field", value: surname, floatingLabelText: "Surname", onChange: (e) => this.handleSurnameChange(e.target.value), errorText: surnameError }),
                React.createElement(InlineDatePicker, { format: "DD.MM.YYYY", id: "birthdayDate", autoOk: true, disableFuture: true, keyboard: true, clearable: true, style: { marginTop: 16, marginRight: 16 }, label: "Birthday", mask: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/], onChange: this.handleBirthdayChange, value: birthday || null, error: !birthdayError, helperText: birthdayError })),
            React.createElement("div", { className: "commission-form-row", key: "second-row" },
                React.createElement(TextField, { key: "idnp", id: "idnp", className: "commission-input-field", value: idnp, floatingLabelText: "IDNP", onChange: (e) => this.handleIdnpChange(e.target.value), errorText: idnpError }),
                React.createElement(TextField, { key: "address", id: "address", className: "commission-input-field", value: address, floatingLabelText: "Address", onChange: (e) => this.handleAddressChange(e.target.value), errorText: addressError }),
                React.createElement(TextField, { key: "formNumber", id: "formNumber", className: "commission-input-field", value: formNumber, floatingLabelText: "Form Number", onChange: (e) => this.handleFormNumberChange(e.target.value), errorText: formNumberError })),
            React.createElement("div", { className: "commission-form-row", key: "third-row" },
                React.createElement(TextField, { key: "passportInfo", id: "passportInfo", className: "commission-input-field", fullWidth: true, value: this.getDocumentValue, floatingLabelText: "Document", onFocus: this.togglePassportDialog(), errorText: documentError })),
        ];
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, key: "buyer-info-cancel-btn", label: "RESET", className: "info-card-raisedbutton", onClick: () => {
                    this.props.dispatch(setBuyerInfo(this.props.data));
                }, disabled: this.getButtonState }),
            React.createElement(RaisedButton, { primary: true, key: "buyer-info-update-btn", label: "UPDATE", className: "info-card-raisedbutton", onClick: () => this.callUpdate(), disabled: this.getUpdateButtonState })));
    }
    renderPassportDialog() {
        const { expDate, issueDate, passportSeries, issuingAuthority, docType } = this.state.fetchedData;
        return (React.createElement(BuyerPassportDialog, { opened: this.state.dialogOpen, toggleDialog: this.togglePassportDialog(), expDate: expDate, issueDate: issueDate, docType: docType, passportSeries: passportSeries, issuingAuthority: issuingAuthority, onUpdateClick: this.updatePassportInfo.bind(this) }));
    }
    saveNote() {
        this.props.addNote(this.state.note);
        this.setState({
            noteDialogOpen: false,
        });
    }
    renderNoteDialog() {
        return (React.createElement(Dialog, { title: "New wallet note", modal: false, open: this.state.noteDialogOpen, actions: this.noteDialogActions, onRequestClose: this.toggleNoteDialog() },
            React.createElement(TextField, { multiLine: true, id: "note", floatingLabelText: "Note", required: true, fullWidth: true, value: this.state.note, onChange: this.changeNote.bind(this) })));
    }
    renderNotes() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardActions, { className: "info-page-cardactions" },
                React.createElement(RaisedButton, { primary: true, label: "ADD NOTE", className: "info-card-raisedbutton", onClick: this.toggleNoteDialog() })),
            React.createElement(CardText, null,
                React.createElement(BuyerNotes, { notes: this.props.notes, deleteNote: this.handleDeleteNote.bind(this) }))));
    }
    renderInfoCard() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false, multiSelectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false },
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Phone: "),
                                formatPhone(this.props.data.phone)),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Created: "),
                                formatTime(Number(this.props.data.created)))),
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Balance: "),
                                isNullOrUndefined(this.props.data.balance) ? formatCurrency(0)
                                    : formatCurrency(this.props.data.balance)),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Accessed: "),
                                formatTime(Number(this.props.data.accessed)))),
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Type: "),
                                this.props.data.type === BuyerType.NORMAL
                                    ? React.createElement("span", { className: "buyer-normal-status" }, "PREMIUM")
                                    : this.props.data.type === BuyerType.DIGITAL
                                        ? React.createElement("span", { className: "buyer-digital-status " }, "OPTIM")
                                        : React.createElement("span", { className: "buyer-anonymous-status" }, "MINI")),
                            this.getBlockedBuyerInfoColumn())))),
            this.renderInfoCardActions()));
    }
    handleNameChange(val) {
        this.setState((prevState) => ({
            fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { name: val }),
            nameError: val.length > maxInputLength ? "Message too long" : "",
        }));
    }
    handleSurnameChange(val) {
        this.setState((prevState) => ({
            fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { surname: val }),
            surnameError: val.length > maxInputLength ? "Message too long" : "",
        }));
    }
    handleIdnpChange(val) {
        this.setState((prevState) => ({
            fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { idnp: val }),
            idnpError: val.length > maxInputLength ? "Message too long" : "",
        }));
    }
    callUpdate() {
        const { idnp, birthday, docType } = this.state.fetchedData;
        if (!idnp || idnp.trim() === "" || !birthday || (!docType && docType !== 0)) {
            this.setState({
                idnpError: (!idnp || idnp.trim() === "") ? "IDNP must not be empty!" : "",
                birthdayError: (!birthday) ? "Birthday must not be empty!" : "",
                documentError: (!docType && docType !== 0) ? "Document Type must not be empty!" : "",
            });
            return;
        }
        this.props.update(this.state.fetchedData);
        this.setState({ fetchedData: this.props.data });
    }
    handleAddressChange(val) {
        this.setState((prevState) => ({
            fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { address: val }),
            addressError: val.length > maxInputLength ? "Message too long" : "",
        }));
    }
    handleFormNumberChange(val) {
        this.setState((prevState) => ({
            fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { formNumber: val }),
            formNumberError: val.length > maxInputLength ? "Message too long" : "",
        }));
    }
    updatePassportInfo(series, issueDate, expDate, issuingAuthority, docType) {
        this.setState((prevState) => ({
            fetchedData: Object.assign(Object.assign({}, prevState.fetchedData), { passportSeries: series, issueDate,
                expDate,
                issuingAuthority,
                docType }),
            dialogOpen: false,
            documentError: (!docType && docType !== 0) ? "Document Type must not be empty!" : "",
        }));
    }
    toggleNoteDialog() {
        return () => this.setState((state) => ({
            noteDialogOpen: !state.noteDialogOpen,
        }));
    }
    handleDeleteNote(noteId) {
        this.props.deleteNote(noteId);
    }
    togglePassportDialog() {
        return () => this.setState({ dialogOpen: !this.state.dialogOpen });
    }
    getBlockedBuyerInfoColumn() {
        if (this.props.data.blocked) {
            return (React.createElement(TableRowColumn, { className: "info-static-card-column" },
                React.createElement("span", { className: "info-static-card-column-span" }, "Status: "),
                React.createElement("span", { className: "tx-dialog-warn" }, "Blocked")));
        }
    }
    renderInfoCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, label: "EXPORT TRANSACTIONS", className: "info-card-raisedbutton", onClick: this.props.exportTransactions.bind(this) }),
            React.createElement(RaisedButton, { primary: true, label: "CHANGE TYPE", className: "info-card-raisedbutton", onClick: this.props.openChangeTypeDialog.bind(this), icon: React.createElement(Star, null), disabled: this.getChangeTypeBtnState() }),
            this.getBlockBtn()));
    }
    getBlockBtn() {
        if (this.props.data.blocked) {
            return (React.createElement(RaisedButton, { primary: true, label: "UNBLOCK", className: "info-card-raisedbutton", onClick: this.props.openUnblockDialog.bind(this), icon: React.createElement(LockOpen, null) }));
        }
        else {
            return (React.createElement(RaisedButton, { secondary: true, label: "BLOCK", className: "info-card-raisedbutton", onClick: this.props.openBlockDialog.bind(this), icon: React.createElement(Lock, null) }));
        }
    }
    getChangeTypeBtnState() {
        return !(this.state.fetchedData.name && this.state.fetchedData.surname);
    }
}
export default connect()(BuyerInfo);
