import * as React from "react";
import { HttpClient } from "../../utils/HttpClient";
import { getNumberOrZero, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import { Card, CardActions, CardText, CardTitle } from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import TextField from "material-ui/TextField";
import { DataValidator } from "../../utils/DataValidator";
import RaisedButton from "material-ui/RaisedButton";
import * as lodash from "lodash";
import { canBeEmpty } from "../../variables/Common";
const ReactToastify = require("react-toastify");
class AmountLimits extends React.Component {
    constructor(props) {
        super(props);
        this.fetchedData = {
            anonymous: {},
            normal: {},
            digital: {},
        };
        this.initialState = Object.assign({ loading: true, anonDayError: "", anonMonthError: "", anonTxError: "", anonBalanceError: "", normalDayError: "", normalMonthError: "", normalBalanceError: "", normalTxError: "", digitalDayError: "", digitalMonthError: "", digitalTxError: "", digitalBalanceError: "" }, this.fetchedData);
        this.state = this.initialState;
    }
    render() {
        return (this.renderParamsCard());
    }
    componentDidMount() {
        this.fetchSystemLimitsParams();
    }
    fetchSystemLimitsParams() {
        HttpClient.getJSON(`${this.props.baseUrl}/params/limits`).then((result) => {
            if (!lodash.isEmpty(result)) {
                this.fetchedData = result;
            }
            this.setState(Object.assign({ loading: false }, result));
        }).catch((err) => {
            ReactToastify.toast.error("Can't load system limits params! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    changeSystemLimitsParams() {
        const { anonymous, normal, digital } = this.state;
        const putData = {
            anonymous: {
                dayLimit: getNumberOrZero(anonymous.dayLimit),
                monthLimit: getNumberOrZero(anonymous.monthLimit),
                txLimit: getNumberOrZero(anonymous.txLimit),
                balanceLimit: getNumberOrZero(anonymous.balanceLimit),
            },
            normal: {
                dayLimit: getNumberOrZero(normal.dayLimit),
                monthLimit: getNumberOrZero(normal.monthLimit),
                txLimit: getNumberOrZero(normal.txLimit),
                balanceLimit: getNumberOrZero(normal.balanceLimit),
            },
            digital: {
                dayLimit: getNumberOrZero(digital.dayLimit),
                monthLimit: getNumberOrZero(digital.monthLimit),
                txLimit: getNumberOrZero(digital.txLimit),
                balanceLimit: getNumberOrZero(digital.balanceLimit),
            },
        };
        HttpClient.putWithData(`${this.props.baseUrl}/params/limits`, JSON.stringify(putData)).then(() => {
            this.fetchSystemLimitsParams();
            ReactToastify.toast.info("System limits parameters were successfully changed!");
        }).catch((err) => {
            ReactToastify.toast.error("Can't change system limits params! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderParamsCard() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(Card, { className: "card-998" },
                React.createElement(CardTitle, { title: "MINI Wallet", subtitle: "Wallet without set name and surname" }),
                React.createElement(CardText, null, this.renderAnonymousForm()),
                React.createElement(CardTitle, { title: "OPTIM Wallet", subtitle: "Upgraded MINI Wallet" }),
                React.createElement(CardText, null, this.renderDigitalForm()),
                React.createElement(CardTitle, { title: "PREMIUM Wallet", subtitle: "Manually upgraded Wallet" }),
                React.createElement(CardText, null, this.renderNormalForm()),
                this.renderCardActions()));
        }
    }
    renderAnonymousForm() {
        return (React.createElement("div", { className: "commission-form-row" },
            React.createElement(TextField, { id: "day-limit-input", floatingLabelText: "Day Limit", className: "commission-input-field", value: this.state.anonymous.dayLimit || "", errorText: this.state.anonDayError, onChange: (e) => this.handleInputChange(e.target.value, "dayLimit", "anonDayError", "anonymous"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "month-limit-input", floatingLabelText: "Month Limit", className: "commission-input-field", value: this.state.anonymous.monthLimit || "", errorText: this.state.anonMonthError, onChange: (e) => this.handleInputChange(e.target.value, "monthLimit", "anonMonthError", "anonymous"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "transaction-limit-input", floatingLabelText: "Transaction Limit", className: "commission-input-field", value: this.state.anonymous.txLimit || "", errorText: this.state.anonTxError, onChange: (e) => this.handleInputChange(e.target.value, "txLimit", "anonTxError", "anonymous"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "balance-input-limit", floatingLabelText: "Balance Limit", value: this.state.anonymous.balanceLimit || "", errorText: this.state.anonBalanceError, onChange: (e) => this.handleInputChange(e.target.value, "balanceLimit", "anonBalanceError", "anonymous"), hintText: canBeEmpty })));
    }
    renderNormalForm() {
        return (React.createElement("div", { className: "commission-form-row" },
            React.createElement(TextField, { id: "day-limit-premium", floatingLabelText: "Day Limit", className: "commission-input-field", value: this.state.normal.dayLimit || "", errorText: this.state.normalDayError, onChange: (e) => this.handleInputChange(e.target.value, "dayLimit", "normalDayError", "normal"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "month-limit-premium", floatingLabelText: "Month Limit", className: "commission-input-field", value: this.state.normal.monthLimit || "", errorText: this.state.normalMonthError, onChange: (e) => this.handleInputChange(e.target.value, "monthLimit", "normalMonthError", "normal"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "transaction-limit-premium", floatingLabelText: "Transaction Limit", className: "commission-input-field", value: this.state.normal.txLimit || "", errorText: this.state.normalTxError, onChange: (e) => this.handleInputChange(e.target.value, "txLimit", "normalTxError", "normal"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "balance-limit-premium", floatingLabelText: "Balance Limit", value: this.state.normal.balanceLimit || "", errorText: this.state.normalBalanceError, onChange: (e) => this.handleInputChange(e.target.value, "balanceLimit", "normalBalanceError", "normal"), hintText: canBeEmpty })));
    }
    renderDigitalForm() {
        return (React.createElement("div", { className: "commission-form-row" },
            React.createElement(TextField, { id: "day-limit-digital", floatingLabelText: "Day Limit", className: "commission-input-field", value: this.state.digital.dayLimit || "", errorText: this.state.digitalDayError, onChange: (e) => this.handleInputChange(e.target.value, "dayLimit", "digitalDayError", "digital"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "month-limit-digital", floatingLabelText: "Month Limit", className: "commission-input-field", value: this.state.digital.monthLimit || "", errorText: this.state.digitalMonthError, onChange: (e) => this.handleInputChange(e.target.value, "monthLimit", "digitalMonthError", "digital"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "transaction-limit-digital", floatingLabelText: "Transaction Limit", className: "commission-input-field", value: this.state.digital.txLimit || "", errorText: this.state.digitalTxError, onChange: (e) => this.handleInputChange(e.target.value, "txLimit", "digitalTxError", "digital"), hintText: canBeEmpty }),
            React.createElement(TextField, { id: "balance-limit-digital", floatingLabelText: "Balance Limit", value: this.state.digital.balanceLimit || "", errorText: this.state.digitalBalanceError, onChange: (e) => this.handleInputChange(e.target.value, "balanceLimit", "digitalBalanceError", "digital"), hintText: canBeEmpty })));
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { className: "info-card-raisedbutton", label: "reset", primary: true, disabled: this.getResetBtnState(), onClick: () => this.onResetBtnClick() }),
            React.createElement(RaisedButton, { className: "info-card-raisedbutton", label: "change", primary: true, disabled: this.getChangeBtnState(), onClick: () => this.changeSystemLimitsParams() })));
    }
    onResetBtnClick() {
        this.setState(Object.assign(Object.assign(Object.assign({}, this.initialState), this.fetchedData), { loading: false }));
    }
    getResetBtnState() {
        return lodash.isEqual(this.state.anonymous, this.fetchedData.anonymous)
            && lodash.isEqual(this.state.digital, this.fetchedData.digital)
            && lodash.isEqual(this.state.normal, this.fetchedData.normal);
    }
    getChangeBtnState() {
        if (this.state.anonTxError !== "" || this.state.anonDayError !== "" || this.state.anonMonthError !== ""
            || this.state.normalTxError !== "" || this.state.normalDayError !== "" || this.state.normalMonthError !== ""
            || this.state.anonBalanceError !== "" || this.state.normalBalanceError !== ""
            || this.state.digitalTxError !== "" || this.state.digitalBalanceError !== "" || this.state.digitalDayError !== ""
            || this.state.digitalMonthError !== "") {
            return true;
        }
        else {
            return this.getResetBtnState();
        }
    }
    handleInputChange(value, state, errState, param) {
        const newState = Object.assign({}, this.state);
        const paramState = Object.assign({}, this.state[param]);
        paramState[state] = value;
        newState[param] = paramState;
        DataValidator.numberValidator(value).then((result) => {
            newState[errState] = result;
            this.setState(newState);
        }).catch((errText) => {
            newState[errState] = errText;
            this.setState(newState);
        });
    }
}
export default connect(mapStateToPropsBaseUrl)(AmountLimits);
