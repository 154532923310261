import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import Table, { TableBody } from "material-ui/Table";
import { capitalizeFirstLetter, formatAmount, formatCurrency, formatPhone, formatTime, getInfoTableRow, } from "../../utils/DataHelpers";
import { CircularProgress } from "material-ui";
class CashoutBusinessDetails extends React.Component {
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "cashout-dialog-actions" },
                React.createElement(FlatButton, { label: "Close", primary: true, onClick: this.props.onCancelClick.bind(this), disabled: this.props.isSaving }))
        ];
    }
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }
    componentDidMount() {
        this.props.getBusinessDetails();
    }
    render() {
        const dialogTitle = "Details";
        return (React.createElement(Dialog, { title: dialogTitle, actions: this.renderDialogActions, modal: true, contentStyle: { maxWidth: 600, border: "none" }, autoScrollBodyContent: true, bodyStyle: { border: "none" }, open: this.props.open, onRequestClose: this.props.onCancelClick.bind(this) }, this.renderBusinessInfo()));
    }
    renderBusinessInfo() {
        const { details } = this.props;
        if (!details) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false, className: "border-none" },
                        getInfoTableRow("Organization", details.companyName, "table-column-multiline"),
                        getInfoTableRow("Amount", formatCurrency(details.amount), "table-column-multiline"),
                        getInfoTableRow("Commission", `${formatAmount(details.percent)} % / ${formatCurrency(details.chargedAmount - details.amount)}`, "table-column-multiline"),
                        getInfoTableRow("Status", capitalizeFirstLetter(details.status), "table-column-multiline"),
                        getInfoTableRow("Closed", formatTime(details.whenCreated), "table-column-multiline"),
                        getInfoTableRow("Operator", formatPhone(details.operator), "table-column-multiline"),
                        getInfoTableRow("Comment", details.notes, "table-column-multiline-nowrap"))),
                (details && details.images || []).map((el, index) => {
                    const lastIndexOf = el.lastIndexOf("/");
                    const imgName = lastIndexOf === -1 ? "Untitled" : el.slice(lastIndexOf + 1);
                    return (React.createElement("div", null, imgName &&
                        React.createElement("div", { key: index, style: { width: "100%", backgroundColor: "#f5f5f5", marginTop: 10 } },
                            React.createElement("ul", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    margin: 0,
                                    padding: 10,
                                } },
                                React.createElement("div", null,
                                    React.createElement("a", { style: { color: "#0921CB", fontWeight: 500, cursor: "pointer" }, href: details.images[index], target: "_blank" },
                                        imgName,
                                        " "))))));
                })));
        }
    }
}
export default CashoutBusinessDetails;
