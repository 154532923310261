import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import AppBar from "material-ui/AppBar";
import Sidebar from "./Sidebar";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.logoutDialogActions = [
            React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.toggleModal() }),
            React.createElement(FlatButton, { label: "Logout", primary: true, onClick: this.props.onLogoutClick }),
        ];
        this.state = { logoutModalOpened: false };
    }
    render() {
        return (React.createElement("div", null,
            this.renderDialogLogout(),
            this.renderAppBar(),
            this.renderAdminDrawer()));
    }
    renderDialogLogout() {
        if (this.state.logoutModalOpened) {
            return (React.createElement(Dialog, { title: "Logout", actions: this.logoutDialogActions, modal: false, open: this.state.logoutModalOpened, onRequestClose: this.toggleModal() }, "Are you sure you want to log out?"));
        }
        else {
            return null;
        }
    }
    renderAppBar() {
        return (React.createElement(AppBar, { className: "main-header", title: `QIWIMD ${this.getTitleRole()}`, iconElementRight: React.createElement(FlatButton, { label: "Logout", primary: true, onClick: this.toggleModal() }), showMenuIconButton: false }));
    }
    renderAdminDrawer() {
        return (React.createElement(Sidebar, Object.assign({}, this.props)));
    }
    toggleModal() {
        return () => this.setState({ logoutModalOpened: !this.state.logoutModalOpened });
    }
    getTitleRole() {
        const { roles } = this.props;
        if (roles.indexOf("Admin") !== -1) {
            return "Admin";
        }
        else if (roles.indexOf("Support") !== -1) {
            return "Support";
        }
        else if (roles.indexOf("Manager") !== -1) {
            return "Manager";
        }
        else if (roles.indexOf("Cashier") !== -1) {
            return "Cashier";
        }
        else if (roles.indexOf("Accountant") !== -1) {
            return "Accountant";
        }
    }
}
export default Header;
