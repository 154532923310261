import * as React from "react";
import { formatTime, getTableColumnItem, } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import MenuItem from "material-ui/MenuItem";
import { FormControl, TextField } from "@material-ui/core";
import { formatAmount, } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import { SelectField } from "material-ui";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import SmartDataTable from "../SmartDataTable";
const DataTable = require("material-ui-datatables");
class CashInBusinessArchiveTable extends React.Component {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("id", "ID", (id, row) => this.renderRoleLink(id), false),
            getTableColumnItem("companyName", "Company", (companyName) => companyName, false),
            getTableColumnItem("companyIDNO", "IDNO", (companyIDNO) => companyIDNO, false),
            getTableColumnItem("amount", "Amount", (amount) => this.setAmountCell(amount), false),
            getTableColumnItem("lastUpdate", "Date", (date) => formatTime(date), false),
            getTableColumnItem("operatorName", "Operator", (operatorName) => operatorName, false),
            getTableColumnItem("status", "Status", (status) => this.setStatusText(status.toLowerCase()), false),
        ];
        this.setAmountCell = (amount) => {
            return (React.createElement("div", { style: { textAlign: "right" } }, formatAmount(amount)));
        };
        this.renderRoleLink = (lineId) => {
            const { roles } = this.props;
            const roleLink = roles.indexOf("Admin") !== -1;
            const requestId = lineId.substr(0, 5);
            return React.createElement(Link, { to: `/cash-in-requests/actions/${lineId}/1` }, requestId);
        };
        this.setStatusText = (status) => {
            let statusText;
            switch (status) {
                case "new":
                    statusText = "Created";
                    break;
                case "ready":
                    statusText = "Sent";
                    break;
                case "accepted":
                    statusText = "Accepted";
                    break;
                default:
                    statusText = "Rejected";
                    break;
            }
            return statusText;
        };
        this.renderMenuItems = () => {
            const txTypes = [
                "any",
                "rejected",
                "accepted",
                "created"
            ];
            return txTypes.map((txType, index) => React.createElement(MenuItem, { key: index, insetChildren: true, value: txType, primaryText: txType }));
        };
    }
    componentDidMount() {
        this.props.fetchData();
    }
    renderTable() {
        const { roles, data, isLoading, rowSize, count, currentPage } = this.props;
        if (isLoading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        return (React.createElement(SmartDataTable, { data: data, columns: this.tableColumns, toolbarIconRight: this.renderIcons(), showHeaderToolbarFilterIcon: false }));
    }
    renderIcons() {
        const { filter: { company, amount, status }, fetchData, handleChangeFilter } = this.props;
        return [
            React.createElement(React.Fragment, null,
                React.createElement("div", { style: {
                        display: "flex",
                        width: 850,
                        flexDirection: "row",
                        alignItems: "center",
                    } },
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "idno-filter", label: "Filter by company", style: { marginRight: "20px", marginTop: 8 }, value: company, onChange: (e) => handleChangeFilter("company", e.target.value) })),
                    React.createElement(FormControl, null,
                        React.createElement(TextField, { id: "name-filter", label: "Filter by amount", style: { marginRight: "20px", marginTop: 8 }, value: amount, onChange: (e) => handleChangeFilter("amount", e.target.value) })),
                    React.createElement(SelectField, { id: "tx-type-select", onChange: (e, i, value) => handleChangeFilter("status", value), value: status, floatingLabelText: "Filter type:" }, this.renderMenuItems()),
                    React.createElement(RaisedButton, { label: "Search", onClick: () => fetchData(), primary: true }))),
            React.createElement(IconButton, { onClick: () => fetchData() },
                React.createElement(Refresh, null)),
        ];
    }
    render() {
        return (React.createElement("div", { className: "full-size-card-wrapper" }, this.renderTable()));
    }
}
export default CashInBusinessArchiveTable;
