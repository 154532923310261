import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class FinishPendingPaymentDialog extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.dialogActions = [
            React.createElement(FlatButton, { primary: true, label: "cancel", onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { primary: true, label: "accept", onClick: () => {
                    this.props.toggleDialog();
                    this.props.onFinishBtnClick();
                } }),
        ];
    }
    render() {
        const { opened, toggleDialog } = this.props;
        return (React.createElement(Dialog, { open: opened, onRequestClose: toggleDialog, title: "Finish payment", modal: false, actions: this.dialogActions }, "Are you sure you want to finish payment?"));
    }
}
export default FinishPendingPaymentDialog;
