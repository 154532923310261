import * as React from "react";
import DataTableAPI from "../utils/DataTableAPI";
import { isUndefined } from "util";
const DataTable = require("material-ui-datatables");
class SmartDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data.slice(0, 20),
            currentPage: 1,
            rowSize: 20,
            total: this.props.data.length,
            sort: "",
            order: "",
            filter: "",
        };
    }
    render() {
        return (this.renderDataTable());
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            DataTableAPI(nextProps.data, this.state.currentPage, this.state.rowSize, this.state.sort, this.state.order, this.state.filter, ((result) => {
                this.setState({
                    total: result.count,
                    data: result.data,
                });
            }));
        }
    }
    get isSelectable() {
        return !isUndefined(this.props.onRowSelection);
    }
    get showFooterToolbar() {
        if (isUndefined(this.props.showFooterToolbar)) {
            return true;
        }
        else {
            return this.props.showFooterToolbar;
        }
    }
    get showHeaderToolbarFilterIcon() {
        const checkShow = this.props.showHeaderToolbarFilterIcon;
        return isUndefined(checkShow) || checkShow;
    }
    handleNextPageClick() {
        const page = this.state.currentPage + 1;
        DataTableAPI(this.props.data, page, this.state.rowSize, this.state.sort, this.state.order, this.state.filter, (result) => {
            this.setState({
                total: result.count,
                data: result.data,
                currentPage: page,
            });
        });
    }
    handlePreviousPageClick() {
        const page = this.state.currentPage - 1;
        DataTableAPI(this.props.data, page, this.state.rowSize, this.state.sort, this.state.order, this.state.filter, (result) => {
            this.setState({
                total: result.count,
                data: result.data,
                currentPage: page,
            });
        });
    }
    handleFilterValueChange(filter) {
        const page = 1;
        DataTableAPI(this.props.data, page, this.state.rowSize, this.state.sort, this.state.order, filter, (result) => {
            this.setState({
                total: result.count,
                data: result.data,
                filter,
                currentPage: page,
            });
        });
    }
    handleSortOrderChange(key, order) {
        DataTableAPI(this.props.data, this.state.currentPage, this.state.rowSize, key, order, this.state.filter, (result) => {
            this.setState({
                total: result.count,
                data: result.data,
                sort: key,
                order,
            });
        });
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.state.currentPage;
        if ((page - 1) * rowSize > this.state.total) {
            page = 1;
        }
        DataTableAPI(this.props.data, page, rowSize, this.state.sort, this.state.order, this.state.filter, (result) => {
            this.setState({
                total: result.count,
                data: result.data,
                currentPage: page,
                rowSize,
            });
        });
    }
    renderDataTable() {
        const { isCursorPointer } = this.props;
        return (React.createElement(DataTable.DataTables, { height: "auto", showRowHover: true, columns: this.props.columns, data: this.state.data, showCheckboxes: false, selectable: this.isSelectable, showHeaderToolbar: true, rowSizeList: [5, 10, 20], rowSize: this.state.rowSize, count: this.state.total, page: this.state.currentPage, tableRowStyle: { cursor: isCursorPointer && "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleFilterValueChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.props.toolbarIconRight, showFooterToolbar: this.showFooterToolbar, showHeaderToolbarFilterIcon: this.showHeaderToolbarFilterIcon, onRowSelection: isUndefined(this.props.onRowSelection)
                ? undefined : (row) => this.props.onRowSelection(row) }));
    }
}
export default SmartDataTable;
