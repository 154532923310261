import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { HttpClient } from "../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import NewBuyersChart from "../components/DashboardComponents/NewBuyersChart";
import * as moment from "moment";
import Card, { CardHeader, CardText } from "material-ui/Card";
import { setBreadcrumb } from "../actions/Actions";
import * as lodash from "lodash";
import TxsChart from "../components/DashboardComponents/TxsChart";
const ReactToastify = require("react-toastify");
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingNewBuyersStats: true,
            loadingTxsStats: true,
            newBuyersStatsData: [],
            spendTxsStatsData: [],
            supplyTxsStatsData: [],
            p2pTxsStatsData: [],
            totalCount: 0,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Dashboard", "/")]));
    }
    componentDidMount() {
        this.fetchNewBuyersStats();
        this.fetchSpendTxsStats();
        this.fetchSupplyTxsStats();
        this.fetchP2pTxsStats();
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Card, { className: "card-998" },
                React.createElement(CardHeader, { title: "New Wallets joined QIWIMD", subtitle: "Last 30 Days Statistics" }),
                React.createElement(CardText, null, this.renderNewBuyersChart())),
            React.createElement(Card, { className: "card-998" },
                React.createElement(CardHeader, { title: "Spend Transactions", subtitle: "Last 30 Days Statistics" }),
                React.createElement(CardText, null, this.renderSpendTxsChart())),
            React.createElement(Card, { className: "card-998" },
                React.createElement(CardHeader, { title: "Supply Transactions", subtitle: "Last 30 Days Statistics" }),
                React.createElement(CardText, null, this.renderSupplyTxsChart())),
            React.createElement(Card, { className: "card-998" },
                React.createElement(CardHeader, { title: "P2P Transactions", subtitle: "Last 30 Days Statistics" }),
                React.createElement(CardText, null, this.renderP2pTxsChart()))));
    }
    fetchNewBuyersStats() {
        HttpClient.getJSON(`${this.props.baseUrl}/statistics/buyers`).then((result) => {
            this.setState({
                loadingNewBuyersStats: false,
                newBuyersStatsData: lodash.has(result, "newBuyers") ? result.newBuyers : [],
                totalCount: lodash.has(result, "totalCount") ? Number(result.totalCount) : 0,
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load new buyers statistics. Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    fetchSpendTxsStats() {
        HttpClient.getJSON(`${this.props.baseUrl}/statistics/spendTxs`).then((result) => {
            this.setState({
                loadingTxsStats: false,
                spendTxsStatsData: lodash.has(result, "txsStats") ? result.txsStats : [],
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load transactions statistics. Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    fetchSupplyTxsStats() {
        HttpClient.getJSON(`${this.props.baseUrl}/statistics/supplyTxs`).then((result) => {
            this.setState({
                loadingTxsStats: false,
                supplyTxsStatsData: lodash.has(result, "txsStats") ? result.txsStats : [],
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load transactions statistics. Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    fetchP2pTxsStats() {
        HttpClient.getJSON(`${this.props.baseUrl}/statistics/p2pTxs`).then((result) => {
            this.setState({
                loadingTxsStats: false,
                p2pTxsStatsData: lodash.has(result, "txsStats") ? result.txsStats : [],
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load transactions statistics. Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    getNewBuyersChartData(fetchedNewBuyersData, total) {
        const chartData = [];
        for (let i = 29; i >= 0; i--) {
            const day = moment().subtract(i + 1, "days").format("DD MMM") + "";
            const index = fetchedNewBuyersData.findIndex((val) => moment(Number(val.date)).format("DD MMM") === day);
            let newCount = 0;
            if (index !== -1) {
                newCount = Number(fetchedNewBuyersData[index].count);
            }
            chartData.push({
                name: day,
                new: newCount,
                total,
            });
        }
        const dayWithNewBuyersIndex = lodash.findLastIndex(chartData, (val) => val.new !== 0);
        let totalForDay = total;
        for (let i = dayWithNewBuyersIndex; i >= 0 && dayWithNewBuyersIndex !== -1; i--) {
            chartData[i].total = totalForDay;
            if (chartData[i].new !== 0) {
                totalForDay -= chartData[i].new;
            }
        }
        return chartData;
    }
    getTxsChartData(fetchedTxsAmountData) {
        const chartData = [];
        for (let i = 29; i >= 0; i--) {
            const day = moment().subtract(i + 1, "days").format("DD MMM") + "";
            const index = fetchedTxsAmountData.findIndex((val) => moment(Number(val.date)).format("DD MMM") === day);
            let amount = 0;
            let totalCount = 0;
            let executedCount = 0;
            if (index !== -1) {
                amount = fetchedTxsAmountData[index].amount;
                totalCount = Number(fetchedTxsAmountData[index].totalCount);
                executedCount = Number(fetchedTxsAmountData[index].executedCount);
            }
            chartData.push({
                name: day,
                amount: amount,
                totalCount: totalCount,
                executedCount: executedCount,
            });
        }
        return chartData;
    }
    renderNewBuyersChart() {
        if (this.state.loadingNewBuyersStats) {
            return (React.createElement(CircularProgress, { size: 60, thickness: 8, style: {
                    width: "60px",
                    margin: "auto",
                    display: "block",
                } }));
        }
        else {
            return (React.createElement(NewBuyersChart, { data: this.getNewBuyersChartData(this.state.newBuyersStatsData, this.state.totalCount) }));
        }
    }
    renderSpendTxsChart() {
        if (this.state.loadingTxsStats) {
            return (React.createElement(CircularProgress, { size: 60, thickness: 8, style: {
                    width: "60px",
                    margin: "auto",
                    display: "block",
                } }));
        }
        else {
            return (React.createElement(TxsChart, { data: this.getTxsChartData(this.state.spendTxsStatsData) }));
        }
    }
    renderSupplyTxsChart() {
        if (this.state.loadingTxsStats) {
            return (React.createElement(CircularProgress, { size: 60, thickness: 8, style: {
                    width: "60px",
                    margin: "auto",
                    display: "block",
                } }));
        }
        else {
            return (React.createElement(TxsChart, { data: this.getTxsChartData(this.state.supplyTxsStatsData) }));
        }
    }
    renderP2pTxsChart() {
        if (this.state.loadingTxsStats) {
            return (React.createElement(CircularProgress, { size: 60, thickness: 8, style: {
                    width: "60px",
                    margin: "auto",
                    display: "block",
                } }));
        }
        else {
            return (React.createElement(TxsChart, { data: this.getTxsChartData(this.state.p2pTxsStatsData) }));
        }
    }
}
export default connect(mapStateToPropsBaseUrl)(Dashboard);
