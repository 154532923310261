import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { HttpClient } from "../utils/HttpClient";
import { appendBreadcrumb } from "../actions/Actions";
import CircularProgress from "material-ui/CircularProgress";
import ProcessingDetails from "../components/ProcessingComponents/ProcessingDetails";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class ProcessingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            processing: null,
        };
    }
    componentDidMount() {
        this.fetchData(true);
    }
    render() {
        const { loading, processing } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(ProcessingDetails, { data: processing }));
        }
    }
    fetchData(isFirst) {
        const { baseUrl, match } = this.props;
        HttpClient.getJSON(`${baseUrl}/processing/detail/${match.params.id}`)
            .then((result) => {
            this.setState({
                loading: false,
                processing: result,
            });
            if (isFirst) {
                this.props.dispatch(appendBreadcrumb([
                    getBreadcrumbObject(`${result.wallet}`, `/processing/detail/${match.params.id}`),
                ]));
            }
        })
            .catch((err) => {
            this.props.history.push("/processing");
            switch (err.status) {
                case 404:
                    ReactToastify.toast.error("Can't load Processing Items! Request Not Found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't load Processing Items! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(ProcessingPage));
