import * as React from "react";
import { capitalizeFirstLetter, getTableColumnAction, getTableColumnItem } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import RaisedButton from "material-ui/RaisedButton";
import { HttpClient } from "../../utils/HttpClient";
import { Link } from "react-router-dom";
import { Checkbox } from "material-ui";
const ReactToastify = require("react-toastify");
class MainBusinessDetailsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("title", "Title", (title, items) => React.createElement(Link, { to: `/company/detail/${this.props.type}/${items.id}/${this.props.match.params.name}/${items.name}/${this.props.match.params.id}` }, items.name), false),
            getTableColumnAction("id", (name, archiveInfo) => this.renderIconMenu(archiveInfo)),
        ];
        this.state = {
            open: false,
            currentDetails: {},
            selectedRowIds: [],
            loading: false,
            companyData: []
        };
    }
    componentDidMount() {
        this.fetchDetails();
    }
    fetchDetails() {
        const { baseUrl, type } = this.props;
        this.setState({
            loading: true,
        });
        HttpClient.getJSON(`${baseUrl}/company/${type}`)
            .then((response) => {
            this.setState({
                loading: false,
                companyData: response.body || [],
            });
        })
            .catch((err) => {
            ReactToastify.toast.error(`Error loading ${type} list! Status: ` +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    render() {
        const { companyData } = this.state;
        return (React.createElement("div", { className: "card-700" },
            React.createElement(SmartDataTable, { data: companyData, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons() })));
    }
    renderIconMenu(business) {
        const { selectedRowIds } = this.state;
        return (React.createElement("div", { className: "commission-form-checkbox-row-wrapper" },
            React.createElement("div", { className: "commission-form-checkbox-row" },
                React.createElement("div", { className: "commission-form-checkbox" },
                    React.createElement(Checkbox, { onCheck: () => this.checkOptions(business), checked: selectedRowIds.indexOf(business.id) !== -1 })))));
    }
    selectedBusiness(typeSelection) {
        const { selectedRowIds } = this.state;
        const { type, baseUrl } = this.props;
        const providers = selectedRowIds.map(value => ({ id: value }));
        const merchants = selectedRowIds.map(value => ({ id: value }));
        const id = selectedRowIds.toString();
        const sliceType = type.slice(0, -1);
        HttpClient.post(`${baseUrl}/company/${this.props.match.params.id}/${sliceType}/${typeSelection}`, type === 'providers' ? JSON.stringify({ providers })
            : type === "merchants" ? JSON.stringify({ merchants })
                : JSON.stringify({ id }))
            .then(() => (typeSelection === "add"
            ? ReactToastify.toast.info(`${capitalizeFirstLetter(type)} was added successfully!`)
            : ReactToastify.toast.info(`${capitalizeFirstLetter(type)} was removed successfully!`)))
            .catch((err) => {
            const errMessageRemove = `Could not remove ${type}! Status: ${JSON.stringify(err.response.status)} Error: ${JSON.stringify(err.response.statusText)}`;
            const errMessageAdd = `Could not add company! Status: ${JSON.stringify(err.response.status)} Error: ${JSON.stringify(err.response.statusText)}`;
            typeSelection === "add"
                ? ReactToastify.toast.error(errMessageAdd)
                : ReactToastify.toast.error(errMessageRemove);
        });
    }
    checkOptions(business) {
        const result = [...this.state.selectedRowIds];
        const businessId = business ? business.id : null;
        if (!businessId) {
            return;
        }
        const indexOfResult = result.indexOf(businessId);
        if (indexOfResult !== -1) {
            result.splice(indexOfResult, 1);
        }
        else {
            result.push(businessId);
        }
        this.setState({
            selectedRowIds: result
        });
        return result;
    }
    getToolbarIcons() {
        const { selectedRowIds } = this.state;
        const checkIfSelected = selectedRowIds.length > 0;
        return [
            React.createElement(React.Fragment, null, checkIfSelected && React.createElement("div", { className: "flex" },
                React.createElement("div", { className: "add-btn-wrapper" },
                    React.createElement(RaisedButton, { primary: true, label: "Add", style: { marginRight: 10 }, onClick: () => this.selectedBusiness("add") })),
                React.createElement("div", { className: "add-btn-wrapper" },
                    React.createElement(RaisedButton, { primary: true, label: "Remove", onClick: () => this.selectedBusiness("remove") })))),
            React.createElement(IconButton, { onClick: this.fetchDetails.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default MainBusinessDetailsTable;
