export var BuyerType;
(function (BuyerType) {
    BuyerType["ANONYMOUS"] = "ANONYMOUS";
    BuyerType["NORMAL"] = "NORMAL";
    BuyerType["DIGITAL"] = "DIGITAL";
})(BuyerType || (BuyerType = {}));
export const BuyerDocTypes = () => {
    const result = new Map();
    result.set(-1, "Nu este setat");
    result.set(0, "Document necunoscut");
    result.set(1, "Buletin de identitate al RM");
    result.set(2, "Pașaport");
    result.set(6, "Permis de ședere permanentă");
    result.set(7, "Permis de ședere provizorie pentru cetățeni străini");
    result.set(8, "Permis de ședere provizorie pentru apatrizi");
    return result;
};
