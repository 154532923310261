import * as React from "react";
import { DataValidator } from "../../utils/DataValidator";
import TextField from "material-ui/TextField";
class ImageInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: "",
            testingImage: false,
        };
    }
    render() {
        return (React.createElement(TextField, { id: "logo-image-url", floatingLabelText: "Logo Image URL", hintText: "Paste logo image URL", errorText: this.state.errorText, fullWidth: true, onChange: (e) => this.onInputChange(e), disabled: this.state.testingImage, autoFocus: true }));
    }
    onInputChange(e) {
        this.setState({ testingImage: true });
        if (e.target.value) {
            DataValidator.testImage(e.target.value, 5000).then((url) => {
                this.setState({ errorText: "", testingImage: false });
                this.props.onUrlChange(url, "");
            }).catch((err) => {
                this.setState({ errorText: err, testingImage: false });
                this.props.onUrlChange("", err);
            });
        }
        else {
            this.setState({ errorText: "", testingImage: false });
            this.props.onUrlChange("", "");
        }
    }
}
export default ImageInput;
