import * as React from "react";
import TextField from "material-ui/TextField";
import { isNullOrUndefined } from "util";
import { numberRegExp, POSType } from "../../variables/Common";
class POSValidTimeTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validTime: this.getValidTimeValue,
            errorText: this.getErrorText(this.props.validTime),
        };
        if (this.props.posType === POSType.TRANSPORT) {
            this.props.setError(!!this.getErrorText(this.props.validTime));
        }
    }
    get getValidTimeValue() {
        if (isNullOrUndefined(this.props.validTime)) {
            return "";
        }
        else {
            return this.props.validTime;
        }
    }
    render() {
        if (this.props.posType === POSType.TRANSPORT) {
            return (React.createElement(TextField, { floatingLabelText: "Valid Time", hintText: "Value in minutes", className: this.props.className, value: this.state.validTime, errorText: this.state.errorText, onChange: (e) => this.handleTimeChange(e) }));
        }
        else {
            return null;
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.validTime !== nextProps.validTime) {
            this.setState({ validTime: nextProps.validTime, errorText: this.getErrorText(nextProps.validTime) });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.posType !== this.props.posType && this.props.posType !== POSType.DEFAULT) {
            this.props.setError(!!this.getErrorText(this.state.validTime));
        }
        if (prevProps.posType !== this.props.posType && this.props.posType === POSType.DEFAULT) {
            this.props.setError(false);
        }
    }
    handleTimeChange(e) {
        this.setState({ validTime: e.target.value, errorText: this.getErrorText(e.target.value) });
        this.props.setValidTime(e.target.value);
        this.props.setError(!!this.getErrorText(e.target.value));
    }
    getErrorText(value) {
        if (numberRegExp.test(value)) {
            return "";
        }
        else if (value === "") {
            return "Value can not be empty!";
        }
        else {
            return "Input only digits!";
        }
    }
}
export default POSValidTimeTextField;
