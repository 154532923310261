import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { HttpClient } from "../utils/HttpClient";
import { ServiceRiskGroup } from "../interfaces/Services";
import * as lodash from "lodash";
import ServicesTable from "../components/ServicesComponents/ServicesTable";
import CircularProgress from "material-ui/CircularProgress";
import Card from "material-ui/Card/Card";
const ReactToastify = require("react-toastify");
class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            services: null,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Services", "/services")]));
    }
    render() {
        return (React.createElement(Card, { className: "card-998" }, this.renderTable()));
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/services/list`).then((result) => {
            if (result.services) {
                result.services.map((value) => (value.riskGroup = value.riskGroup ? value.riskGroup : ServiceRiskGroup.MINOR));
            }
            this.setState({
                loading: false,
                services: lodash.isEmpty(result.services) ? [] : result.services,
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load QIWI services! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderTable() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(ServicesTable, { data: this.state.services, fetchData: this.fetchData.bind(this) }));
        }
    }
}
export default connect(mapStateToPropsBaseUrl)(Services);
