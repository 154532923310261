import * as React from "react";
import { formatTime, getBreadcrumbObject, mapStateToPropsBaseUrl, } from "../../utils/DataHelpers";
import { Card, CardText } from "material-ui/Card";
import { HttpClient } from "../../utils/HttpClient";
import { setBreadcrumb } from "../../actions/Actions";
import { connect } from "react-redux";
import { CircularProgress } from "material-ui";
import { Tab, Tabs } from "material-ui/Tabs";
import MainBusinessDetailsTable from "./MainBusinessDetailsTable";
import TextField from "material-ui/TextField";
import CardActions from "material-ui/Card/CardActions";
import RaisedButton from "material-ui/RaisedButton";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withRouter } from '../../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Profile"] = 0] = "Profile";
    TabNames[TabNames["Providers"] = 1] = "Providers";
    TabNames[TabNames["Merchants"] = 2] = "Merchants";
    TabNames[TabNames["WithdrawBusiness"] = 3] = "WithdrawBusiness";
})(TabNames || (TabNames = {}));
class MainBusinessDetails extends React.PureComponent {
    constructor(props) {
        super(props);
        this.getCurrentComponent = () => {
            const { selectedTab, loading, companyData } = this.state;
            const { baseUrl, match, match: { params: { name } } } = this.props;
            const optionalProps = {
                TabNames,
                selectedTab,
                match,
                baseUrl,
                data: companyData,
            };
            switch (selectedTab) {
                case 1:
                    return React.createElement(MainBusinessDetailsTable, Object.assign({}, optionalProps, { type: "providers", loading: loading, key: 1 }));
                case 2:
                    return React.createElement(MainBusinessDetailsTable, Object.assign({}, optionalProps, { type: "merchants", loading: loading, key: 2 }));
                case 3:
                    return React.createElement(MainBusinessDetailsTable, Object.assign({}, optionalProps, { type: "withdrawalAccounts", loading: loading, key: 3 }));
                default:
                    return null;
            }
        };
        let selectedTab = TabNames.Profile;
        switch (location.pathname.split("/").pop()) {
            case "providers":
                selectedTab = TabNames.Providers;
                break;
            case "merchants":
                selectedTab = TabNames.Merchants;
                break;
            case "withdrawalAccounts":
                selectedTab = TabNames.WithdrawBusiness;
                break;
            default:
                selectedTab = TabNames.Profile;
        }
        this.state = {
            selectedTab,
            loading: true,
            fetchedBusinessData: null,
            businessNotFound: false,
            data: {},
            companyData: [],
            errors: null,
            isSaving: false
        };
        const { dispatch, match: { params: { id, name } } } = this.props;
        dispatch(setBreadcrumb([getBreadcrumbObject("Company", `/company/`),
            getBreadcrumbObject(name, `/company/${id}`)]));
    }
    componentDidMount() {
        this.fetchProfile();
    }
    circularProgress() {
        return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                top: "50%",
                left: "50%",
                position: "absolute",
                marginLeft: "-60px",
                marginTop: "-60px",
            } }));
    }
    componentDidUpdate(prevProps, prevState) {
        const { selectedTab } = this.state;
        if (prevState.selectedTab !== selectedTab) {
            if (selectedTab === TabNames.Profile) {
                this.fetchProfile();
            }
        }
    }
    fetchProfile() {
        const { baseUrl, match: { params: { id } } } = this.props;
        this.setState({
            loading: true,
        });
        HttpClient.getJSON(`${baseUrl}/company/${id}/detail`)
            .then((response) => {
            if (response.body && response.status.code === "ok") {
                this.setState({
                    loading: false,
                    data: response.body,
                    fetchedBusinessData: response.body
                });
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading company details! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    validateBusiness() {
        const result = [];
        const { data } = this.state;
        if (!data || !data.name || data.name.trim() === "") {
            result.push({
                field: "name",
                text: "Name must not be empty!",
            });
        }
        if (!data || !data.idno || data.idno.trim() === "") {
            result.push({
                field: "idno",
                text: "Name must not be empty!",
            });
        }
        return result;
    }
    findErrorText(field) {
        const { errors } = this.state;
        if (errors) {
            const error = errors.find((e) => e.field === field);
            return error ? error.text : "";
        }
        return "";
    }
    updateBusiness() {
        const { data } = this.state;
        const { baseUrl } = this.props;
        const errs = this.validateBusiness();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        if (data) {
            this.setState({
                isSaving: true,
            });
            HttpClient.putWithData(`${baseUrl}/company`, JSON.stringify(Object.assign({}, data)))
                .then(() => {
                ReactToastify.toast.info("Company successfully updated!");
            })
                .catch((err) => {
                this.setState({
                    isSaving: false,
                });
                ReactToastify.toast.error("Could not update company! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    renderTables() {
        return (React.createElement(Card, { className: "card-700" }, this.renderDataTable()));
    }
    render() {
        const { selectedTab } = this.state;
        const content = selectedTab === TabNames.Profile ? this.renderForm() : this.renderTables();
        return (React.createElement("div", null,
            this.renderTabs(),
            content));
    }
    renderTabs() {
        const { selectedTab } = this.state;
        const { match: { params: { id, name } } } = this.props;
        const pathname = `/company/details/${name}/${id}`;
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "750px" } },
                React.createElement(Tab, { label: "Profile", value: TabNames.Profile, containerElement: React.createElement(Link, { to: `${pathname}` }) }),
                React.createElement(Tab, { label: "Providers", value: TabNames.Providers, containerElement: React.createElement(Link, { to: `${pathname}/providers` }) }),
                React.createElement(Tab, { label: "Merchants", value: TabNames.Merchants, containerElement: React.createElement(Link, { to: `${pathname}/merchants` }) }),
                React.createElement(Tab, { label: "Withdraw Business", value: TabNames.WithdrawBusiness, containerElement: React.createElement(Link, { to: `${pathname}/withdrawalAccounts` }) }))));
    }
    renderDataTable() {
        const { selectedTab, loading, companyData } = this.state;
        const { baseUrl, match, match: { url } } = this.props;
        if (loading) {
            return this.circularProgress();
        }
        else {
            return (React.createElement("div", { className: selectedTab !== TabNames.Profile && "full-size-card-wrapper" }, this.getCurrentComponent()));
        }
    }
    handleInputChange(value, name) {
        this.setState((state) => ({
            data: Object.assign(Object.assign({}, state.data), { [name]: value }),
            errors: state.errors && state.errors.filter((e) => e.field !== name),
        }));
    }
    get getButtonState() {
        const { fetchedBusinessData, data } = this.state;
        return (JSON.stringify(fetchedBusinessData) === JSON.stringify(data));
    }
    renderForm() {
        const { loading, data: { name, idno, whenCreated, providers, merchants, withdrawBusiness } } = this.state;
        const extractedProvider = (providers || []).map(value => (value.title || value.name)).join(", ");
        const extractedMerchant = (merchants || []).map(value => (value.title || value.name)).join(", ");
        const extractedWithdrawal = (withdrawBusiness || []).map(value => (value.title || value.name)).join(", ");
        if (loading) {
            return this.circularProgress();
        }
        else {
            return (React.createElement(Card, { className: "card-700" },
                React.createElement(CardText, null,
                    React.createElement("div", { className: "flex-col" },
                        React.createElement(TextField, { id: "name", value: name, maxlength: "50", onChange: (e) => this.handleInputChange(e.target.value, "name"), className: "w-full", floatingLabelText: "Title", errorText: this.findErrorText("name") }),
                        React.createElement(TextField, { id: "idno", value: idno, maxlength: "13", onChange: (e) => this.handleInputChange(e.target.value, "idno"), className: "w-full", floatingLabelText: "IDNO", errorText: this.findErrorText("idno") })),
                    React.createElement("div", { className: "mt10" },
                        React.createElement(Typography, { gutterBottom: true, variant: "body2" },
                            React.createElement("span", { className: "main-color" }, "Created: "),
                            " ",
                            formatTime(whenCreated)),
                        React.createElement(Typography, { gutterBottom: true, variant: "body2" },
                            React.createElement("span", { className: "main-color" }, "Merchants:"),
                            " ",
                            extractedMerchant),
                        React.createElement(Typography, { gutterBottom: true, variant: "body2" },
                            React.createElement("span", { className: "main-color" }, "Providers:"),
                            " ",
                            extractedProvider),
                        React.createElement(Typography, { gutterBottom: true, variant: "body2" },
                            React.createElement("span", { className: "main-color" }, "Withdrawal Accounts:"),
                            " ",
                            extractedWithdrawal))),
                this.renderCardActions()));
        }
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, label: "Update", onClick: this.updateBusiness.bind(this), disabled: this.getButtonState, className: "info-card-raisedbutton" })));
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(MainBusinessDetails));
