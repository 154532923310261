import * as React from "react";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, } from "../../utils/DataHelpers";
import RaisedButton from "material-ui/RaisedButton";
import { Card, CardActions, CardText } from "material-ui/Card";
import Table, { TableBody } from "material-ui/Table";
import FinishPendingPaymentDialog from "./FinishPendingPaymentDialog";
import RejectPendingPaymentDialog from "./RejectPendingPaymentDialog";
class PendingPaymentProfile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            finishPaymentDialogOpened: false,
            rejectPaymentDialogOpened: false,
        };
    }
    render() {
        const { finishPaymentDialogOpened, rejectPaymentDialogOpened } = this.state;
        const { finishPendingPayment, rejectPendingPayment } = this.props;
        return (React.createElement("div", null,
            React.createElement(FinishPendingPaymentDialog, { opened: finishPaymentDialogOpened, toggleDialog: this.toggleFinishPendingPaymentDialog(), onFinishBtnClick: () => finishPendingPayment() }),
            React.createElement(RejectPendingPaymentDialog, { opened: rejectPaymentDialogOpened, toggleDialog: this.toggleRejectPendingPaymentDialog(), onRejectBtnClick: () => rejectPendingPayment() }),
            this.renderProfileCard()));
    }
    renderProfileCard() {
        const { walletPhone, amount, clientId, commHi, commLo, id, operatorPhone, providerId, providerName, prvTxnId, serviceName, txnId, whenResolved, whenStarted, serviceUuid, } = this.props.data;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRowWithLink("Wallet", formatPhone(walletPhone), `/buyer/${walletPhone}/info`),
                        getInfoTableRow("When started", formatTime(whenStarted)),
                        getInfoTableRow("Amount", formatCurrency(amount)),
                        commHi &&
                            getInfoTableRow("High commission", formatCurrency(commHi)),
                        commLo &&
                            getInfoTableRow("Low commission", formatCurrency(commLo)),
                        getInfoTableRow("Txn ID", prvTxnId),
                        getInfoTableRow("Provider Txn ID", txnId),
                        getInfoTableRow("QWallet payment ID", id),
                        getInfoTableRow("Client ID", clientId),
                        getInfoTableRowWithLink("Service", serviceName, `/services/${serviceUuid}/info`),
                        getInfoTableRowWithLink("Provider", providerName, `/business/${providerId}/info`),
                        whenResolved &&
                            getInfoTableRow("When resolved", formatTime(whenResolved)),
                        operatorPhone &&
                            getInfoTableRow("Operator phone", formatPhone(operatorPhone))))),
            this.renderCardActions()));
    }
    renderCardActions() {
        const { whenResolved } = this.props.data;
        if (!whenResolved) {
            return (React.createElement(CardActions, { className: "info-page-cardactions" },
                this.renderRejectButton(),
                this.renderFinishButton()));
        }
        else {
            return null;
        }
    }
    renderFinishButton() {
        const { whenResolved } = this.props.data;
        if (!whenResolved) {
            return (React.createElement(RaisedButton, { secondary: true, label: "Finish", className: "info-card-raisedbutton", onClick: this.toggleFinishPendingPaymentDialog() }));
        }
        else {
            return null;
        }
    }
    renderRejectButton() {
        const { whenResolved } = this.props.data;
        if (!whenResolved) {
            return (React.createElement(RaisedButton, { primary: true, label: "Reject", className: "info-card-raisedbutton", onClick: this.toggleRejectPendingPaymentDialog() }));
        }
        else {
            return null;
        }
    }
    toggleRejectPendingPaymentDialog() {
        return () => this.setState({ rejectPaymentDialogOpened: !this.state.rejectPaymentDialogOpened });
    }
    toggleFinishPendingPaymentDialog() {
        return () => this.setState({ finishPaymentDialogOpened: !this.state.finishPaymentDialogOpened });
    }
}
export default PendingPaymentProfile;
