import * as React from "react";
import BusinessesTable from "../components/BusinessesComponents/BusinessesTable";
import AddBusinessDialog from "../components/BusinessesComponents/AddBusinessDialog";
import { HttpClient } from "../utils/HttpClient";
import Card from "material-ui/Card/Card";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { CircularProgress } from "material-ui";
const ReactToastify = require("react-toastify");
class Businesses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            addBusinessDialogOpened: false,
            businessData: [],
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Businesses", "/business")]));
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(AddBusinessDialog, { opened: this.state.addBusinessDialogOpened, toggleDialog: this.toggleAddBusinessDialog(), onAddBtnClick: this.addNewBusiness.bind(this) }),
            React.createElement(Card, { className: "card-998" }, this.renderDataTable())));
    }
    componentDidMount() {
        this.fetchBusinessData();
    }
    fetchBusinessData() {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        HttpClient.getJSON(`${this.props.baseUrl}/business/list`)
            .then((result) => {
            const data = [];
            result.data.map((item) => data.push({
                title: item.business.title,
                emails: item.business.emails,
                id: item.business.id,
                ownerPhone: item.business.ownerPhone,
            }));
            this.setState({ businessData: data, loading: false });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load data,reload page! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    toggleAddBusinessDialog() {
        return () => this.setState({ addBusinessDialogOpened: !this.state.addBusinessDialogOpened });
    }
    addNewBusiness(title, emails, id, ownerPhone) {
        this.setState({ addBusinessDialogOpened: false });
        const postData = JSON.stringify({ title, emails, id, ownerPhone });
        HttpClient.post(`${this.props.baseUrl}/business`, postData)
            .then(() => {
            this.fetchBusinessData();
            ReactToastify.toast.success("Business successfully added!");
        })
            .catch((err) => {
            ReactToastify.toast.error("Smth went wrong! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderDataTable() {
        return (React.createElement("div", null,
            this.state.loading && React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }),
            React.createElement(BusinessesTable, { businessesData: this.state.businessData, addNewBusiness: this.toggleAddBusinessDialog(), fetchData: () => this.fetchBusinessData() })));
    }
}
export default connect(mapStateToPropsBaseUrl)(Businesses);
