import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { DataValidator } from "../../utils/DataValidator";
import "./styles.css";
class AddUserDialog extends React.Component {
    constructor(props) {
        super(props);
        this.roleTypes = [
            "Admin",
            "Support",
            "Manager",
            "Cashier",
            "Accountant"
        ];
        this.initialState = {
            phone: "",
            errorText: "",
            roles: [],
        };
        this.state = this.initialState;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.opened && prevProps.opened) {
            this.setState(this.initialState);
        }
    }
    render() {
        return (React.createElement(Dialog, { title: "Add New User", actions: this.renderDialogActions, modal: true, open: this.props.opened, onRequestClose: this.props.toggleModal.bind(this) }, this.renderForm()));
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "add-user-dialog-actions" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleModal.bind(this) }),
                React.createElement(FlatButton, { label: "Accept", primary: true, onClick: !this.handleButtonState
                        ? () => this.props.onAddBtnClick(this.state.phone, this.state.roles)
                        : () => false, disabled: this.handleButtonState }))
        ];
    }
    renderForm() {
        return (React.createElement("div", null,
            React.createElement(TextField, { id: "phone-number-input", autoFocus: true, hintText: "373 XX XXX XXX", floatingLabelText: "Enter number", fullWidth: true, onChange: (e) => this.handleUpdateInput(e), errorText: this.state.errorText }),
            " ",
            React.createElement("br", null),
            React.createElement("div", { onClick: () => document.body.classList.add("body") },
                React.createElement(SelectField, { id: "select-role", hintText: "Select a role", onChange: (e, i, v) => this.handleSelectFieldChange(e, i, v), value: this.state.roles, multiple: true }, this.renderMenuItems(this.state.roles)))));
    }
    handleSelectFieldChange(event, index, values) {
        this.setState({ roles: values });
    }
    renderMenuItems(values) {
        return this.roleTypes.map((role) => React.createElement(MenuItem, { id: role, key: role, insetChildren: true, checked: values && values.indexOf(role) > -1, value: role, primaryText: role }));
    }
    handleUpdateInput(e) {
        DataValidator.phoneNumberValidator(e.target.value).then((result) => {
            this.setState({ phone: result, errorText: "" });
        }).catch((err) => {
            this.setState({ phone: "", errorText: err });
        });
    }
    get handleButtonState() {
        return !(this.state.roles.length !== 0 && this.state.errorText === "");
    }
}
export default AddUserDialog;
