import * as React from "react";
import { formatTime, getTableColumnAction, getTableColumnItem } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import Delete from "material-ui/svg-icons/action/delete";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
const DataTable = require("material-ui-datatables");
class MainBusinessTable extends React.Component {
    constructor() {
        super(...arguments);
        this.businessTableColumns = [
            getTableColumnItem("name", "Name", (name, items) => React.createElement(Link, { to: `/company/details/${items.name}/${items.id}` }, name), false),
            getTableColumnItem("whenCreated", "When Created", (whenCreated) => formatTime(whenCreated), true),
            getTableColumnItem("idno", "IDNO", idno => idno, false),
            getTableColumnAction("id", (name, businessInfo) => this.renderIconMenu(businessInfo)),
        ];
        this.sanitize = (text) => {
            return text.toLowerCase().trim();
        };
    }
    render() {
        return this.renderTable();
    }
    renderTable() {
        const { items, rowSize, currentPage, count } = this.props;
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.businessTableColumns, selectable: false, data: items, showCheckboxes: false, showHeaderToolbar: true, rowSizeList: [5, 10, 20, 40], rowSize: rowSize, count: count, page: currentPage, tableRowStyle: { cursor: "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleSearchValueChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), toolbarIconRight: this.renderIcons }));
    }
    get renderIcons() {
        const { updateBusiness, reloadData, currentPage, rowSize, search, order } = this.props;
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { className: "button-control", primary: true, label: "New Company", onClick: () => updateBusiness(null) })),
            React.createElement(IconButton, { onClick: () => reloadData(currentPage, rowSize, search, order) },
                React.createElement(Refresh, null)),
        ];
    }
    handleNextPageClick() {
        const { reloadData, rowSize, search, currentPage, order } = this.props;
        const page = currentPage + 1;
        reloadData(page, rowSize, search, order);
    }
    handlePreviousPageClick() {
        const { reloadData, rowSize, search, currentPage, order } = this.props;
        const page = currentPage - 1;
        reloadData(page, rowSize, search, order);
    }
    handleSearchValueChange(search) {
        const { reloadData, rowSize, order } = this.props;
        reloadData(1, rowSize, this.sanitize(search), order);
    }
    handleRowSizeChange(index, rowSize) {
        const { reloadData, search, currentPage, count, order } = this.props;
        let page = currentPage - 1;
        if (page * rowSize > count) {
            page = 1;
        }
        reloadData(page, rowSize, search, order);
    }
    handleSortOrderChange(key, order) {
        const { reloadData, search, currentPage, count } = this.props;
        reloadData(currentPage, count, search, order);
    }
    renderIconMenu(business) {
        const { deleteBusiness } = this.props;
        return (React.createElement(IconButton, { onClick: () => deleteBusiness(business) },
            React.createElement(Delete, null)));
    }
}
export default MainBusinessTable;
