import * as React from "react";
import { Tab, Tabs } from "material-ui/Tabs";
import { HttpClient } from "../utils/HttpClient";
import { BuyerType, } from "../interfaces/Buyer";
import { isNullOrUndefined } from "util";
import CircularProgress from "material-ui/CircularProgress";
import BuyerInfo from "../components/BuyerComponents/BuyerInfo";
import { connect } from "react-redux";
import { setBreadcrumb, setBuyerInfo, } from "../actions/Actions";
import { downloadReport, getBreadcrumbObject } from "../utils/DataHelpers";
import BuyerNotFound from "../components/BuyerComponents/BuyerNotFound";
import { Link } from "react-router-dom";
import BlockBuyerDialog from "../components/BuyerComponents/BlockBuyerDialog";
import UnblockBuyerDialog from "../components/BuyerComponents/UnblockBuyerDialog";
import BuyerBlockHistory from "../components/BuyerComponents/BuyerBlockHistory";
import ChangeBuyerTypeDialog from "../components/BuyerComponents/ChangeBuyerTypeDialog";
import * as moment from "moment";
import BuyerSpendTransactions from "../components/BuyerComponents/BuyerSpendTransactions";
import BuyerSupplyTransactions from "../components/BuyerComponents/BuyerSupplyTransactions";
import BuyerP2PTransactions from "../components/BuyerComponents/BuyerP2PTransactions";
import BuyerWithdrawalTransactions from "../components/BuyerComponents/BuyerWithdrawalTransactions";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Info"] = 0] = "Info";
    TabNames[TabNames["Payments"] = 1] = "Payments";
    TabNames[TabNames["TopUps"] = 2] = "TopUps";
    TabNames[TabNames["P2P"] = 3] = "P2P";
    TabNames[TabNames["Withdrawals"] = 4] = "Withdrawals";
    TabNames[TabNames["Block"] = 5] = "Block";
})(TabNames || (TabNames = {}));
class Buyer extends React.Component {
    constructor(props) {
        super(props);
        this.getCurrentComponent = () => {
            const { match: { params: { tab, id } }, buyerData, roles } = this.props;
            const { notes } = this.state;
            switch (tab) {
                case "payments":
                    return React.createElement(BuyerSpendTransactions, { id: id });
                case "ups":
                    return React.createElement(BuyerSupplyTransactions, { id: id });
                case "p2p":
                    return React.createElement(BuyerP2PTransactions, { id: id });
                case "withdrawals":
                    return React.createElement(BuyerWithdrawalTransactions, { id: id });
                case "blocks":
                    return React.createElement(BuyerBlockHistory, { id: id });
                default:
                    return React.createElement(BuyerInfo, { data: buyerData, adminPerspective: roles.indexOf("Admin") !== -1, update: this.updateBuyerInfo.bind(this), openUnblockDialog: this.toggleUnblockBuyerDialog(), openBlockDialog: this.toggleBlockBuyerDialog(), openChangeTypeDialog: this.toggleChangeBuyerTypeDialog(), addNote: this.addBuyerNote.bind(this), notes: notes, deleteNote: this.deleteBuyerNote.bind(this), exportTransactions: this.exportTransactions.bind(this) });
            }
        };
        let selectedTab = TabNames.Info;
        switch (location.pathname.split("/").pop()) {
            case "payments":
                selectedTab = TabNames.Payments;
                break;
            case "ups":
                selectedTab = TabNames.TopUps;
                break;
            case "p2p":
                selectedTab = TabNames.P2P;
                break;
            case "withdrawals":
                selectedTab = TabNames.Withdrawals;
                break;
            case "blocks":
                selectedTab = TabNames.Block;
                break;
            default:
                selectedTab = TabNames.Info;
        }
        this.state = {
            selectedTab,
            loading: true,
            buyerNotFound: false,
            blockDialogOpened: false,
            unblockDialogOpened: false,
            changeTypeDialogOpened: false,
            notes: [],
        };
    }
    get getAppbarTitle() {
        if (isNullOrUndefined(this.props.buyerData)) {
            return "";
        }
        if (!isNullOrUndefined(this.props.buyerData.name) && !isNullOrUndefined(this.props.buyerData.surname)) {
            return this.props.buyerData.name + " " + this.props.buyerData.surname;
        }
        else {
            return this.props.buyerData.phone;
        }
    }
    render() {
        return (React.createElement("div", { className: "buyer-info-main-div" },
            this.renderTabs(),
            this.renderBuyerComponent(),
            React.createElement(BlockBuyerDialog, { opened: this.state.blockDialogOpened, toggleDialog: this.toggleBlockBuyerDialog(), onBlockBtnClick: this.blockBuyer.bind(this) }),
            React.createElement(UnblockBuyerDialog, { opened: this.state.unblockDialogOpened, toggleDialog: this.toggleUnblockBuyerDialog(), onUnblockBtnClick: this.unblockBuyer.bind(this) }),
            this.renderChangeBuyerTypeDialog()));
    }
    componentDidMount() {
        this.fetchBuyerProfileInfo();
        this.fetchBuyerNotes();
    }
    renderChangeBuyerTypeDialog() {
        if (this.state.loading) {
            return null;
        }
        else {
            return (React.createElement(ChangeBuyerTypeDialog, { opened: this.state.changeTypeDialogOpened, toggleDialog: this.toggleChangeBuyerTypeDialog(), currentStatus: this.prepareBuyerStatus(), onChangeBtnClick: this.changeBuyerType.bind(this) }));
        }
    }
    prepareBuyerStatus() {
        switch (this.props.buyerData.type) {
            case BuyerType.DIGITAL:
                return { value: "digital", displayedName: "OPTIM" };
            case BuyerType.NORMAL:
                return { value: "normal", displayedName: "PREMIUM" };
            default:
            case BuyerType.ANONYMOUS:
                return { value: "anonymous", displayedName: "MINI" };
        }
    }
    fetchBuyerProfileInfo() {
        HttpClient.getJSON(`${this.props.baseUrl}/buyer/${this.props.match.params.id}`)
            .then((result) => {
            const fetchedProfile = Object.assign(Object.assign({}, result), { birthday: result.birthday ? moment(Number(result.birthday)) : undefined, issueDate: result.issueDate ? moment(Number(result.issueDate)).toDate() : undefined, expDate: result.expDate ? moment(Number(result.expDate)).toDate() : undefined });
            this.props.dispatch(setBuyerInfo(fetchedProfile));
            this.props.dispatch(setBreadcrumb([
                getBreadcrumbObject("Wallets", "/buyers"),
                getBreadcrumbObject(this.getAppbarTitle, `/buyer/${this.props.match.params.id}/info`),
            ]));
            this.setState({ loading: false });
        })
            .catch((err) => {
            if (err.response.status === 404) {
                this.setState({ buyerNotFound: true, loading: false });
                ReactToastify.toast.error("Buyer not found!");
            }
            else {
                ReactToastify.toast.error("Can't load buyer info. Status: " +
                    JSON.stringify(err.response.status));
            }
        });
    }
    fetchBuyerNotes() {
        HttpClient.getJSON(`${this.props.baseUrl}/buyer/${this.props.match.params.id}/notes`)
            .then((result) => {
            this.setState({
                notes: result.notes || [],
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load buyer notes! Status: " +
                JSON.stringify(err.response.status));
        });
    }
    blockBuyer(reasonMsg) {
        this.setState({ blockDialogOpened: false });
        const patchData = JSON.stringify({ reasonMsg });
        HttpClient.patchWithData(`${this.props.baseUrl}/block/buyer/${this.props.match.params.id}`, patchData)
            .then(() => {
            ReactToastify.toast.info("Buyer was successfully blocked!");
            this.fetchBuyerProfileInfo();
        })
            .catch((err) => {
            switch (err.response.status) {
                case 409:
                    ReactToastify.toast.error("Can't block buyer. Buyer is already blocked!");
                    break;
                case 404:
                    ReactToastify.toast.error("Can't block buyer. Buyer not found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't block buyer. Status: " +
                        JSON.stringify(err.response.status));
                    break;
            }
        });
    }
    unblockBuyer() {
        this.setState({ unblockDialogOpened: false });
        HttpClient.patchWithData(`${this.props.baseUrl}/unblock/buyer/${this.props.match.params.id}`)
            .then(() => {
            ReactToastify.toast.info("Buyer was successfully unblocked!");
            this.fetchBuyerProfileInfo();
        })
            .catch((err) => {
            switch (err.response.status) {
                case 409:
                    ReactToastify.toast.error("Can't unblock buyer. Buyer is not blocked!");
                    break;
                case 404:
                    ReactToastify.toast.error("Can't unblock buyer. Buyer not found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't unblock buyer. Status: " +
                        JSON.stringify(err.response.status));
                    break;
            }
        });
    }
    updateBuyerInfo(buyerData) {
        const { name, surname, birthday, idnp, address, passportSeries, expDate, issueDate, issuingAuthority, formNumber, docType } = buyerData;
        const putData = JSON.stringify({
            name,
            surname,
            birthday: birthday ? birthday.valueOf() : undefined,
            idnp,
            address,
            passportSeries,
            formNumber,
            expDate: expDate ? expDate.valueOf() : undefined,
            issueDate: issueDate ? issueDate.valueOf() : undefined,
            issuingAuthority,
            docType,
        });
        HttpClient.putWithData(`${this.props.baseUrl}/buyer/${this.props.buyerData.phone}`, putData)
            .then(() => {
            this.fetchBuyerProfileInfo();
            ReactToastify.toast.info("Buyer information was successfully updated!");
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't update buyer info. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    addBuyerNote(note) {
        const { id } = this.props.match.params;
        const { baseUrl } = this.props;
        const postData = JSON.stringify({
            buyerPhone: id,
            note,
        });
        HttpClient.post(`${baseUrl}/buyer/note/add`, postData)
            .then(() => {
            this.fetchBuyerNotes();
            ReactToastify.toast.info("Buyer note was successfully added!");
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't update buyer note! Status: " + JSON.stringify(err.response.status));
        });
    }
    deleteBuyerNote(noteId) {
        const { baseUrl } = this.props;
        const postData = JSON.stringify({
            noteId,
        });
        HttpClient.post(`${baseUrl}/buyer/note/delete`, postData)
            .then(() => {
            this.fetchBuyerNotes();
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't delete buyer note! Status: " + JSON.stringify(err.response.status));
        });
    }
    exportTransactions() {
        const { id } = this.props.match.params;
        const query = {
            search: id.toString(),
            startTime: 0,
            orderKey: "time",
            order: "asc",
            endTime: moment().valueOf(),
            type: null,
            exact: "true",
        };
        HttpClient.getJSON(`${this.props.baseUrl}/tx/export`, query)
            .then((result) => {
            ReactToastify.toast.info("Transactions file was successfully created");
            this.setState({ loading: false });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false });
            ReactToastify.toast.error("Could not export transactions. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, "xlsx", this.props.token, this.props.baseUrl);
    }
    changeBuyerType(type) {
        this.setState({ changeTypeDialogOpened: false });
        const { baseUrl } = this.props;
        const { id } = this.props.match.params;
        const query = `?phone=${id}&type=${type}`;
        HttpClient.patch(`${baseUrl}/buyer/type${query}`)
            .then(() => {
            ReactToastify.toast.info("Buyer type was successfully changed!");
            this.fetchBuyerProfileInfo();
        })
            .catch((err) => {
            if (err.response.status === 404) {
                ReactToastify.toast.error("Can't change buyer type. Buyer not found!");
            }
            else {
                ReactToastify.toast.error("Can't change buyer type. Status: " +
                    JSON.stringify(err.response.status));
            }
        });
    }
    renderTabs() {
        const { id } = this.props.match.params;
        const pathname = `/buyer/${id}/info`;
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "750px" } },
                React.createElement(Tab, { label: "Info", value: TabNames.Info, containerElement: React.createElement(Link, { to: `${pathname}` }) }),
                React.createElement(Tab, { label: "Payments", value: TabNames.Payments, containerElement: React.createElement(Link, { to: `${pathname}/payments` }), disabled: this.state.buyerNotFound }),
                React.createElement(Tab, { label: "Top Ups", value: TabNames.TopUps, containerElement: React.createElement(Link, { to: `${pathname}/ups` }), disabled: this.state.buyerNotFound }),
                React.createElement(Tab, { label: "P2P", value: TabNames.P2P, containerElement: React.createElement(Link, { to: `${pathname}/p2p` }), disabled: this.state.buyerNotFound }),
                React.createElement(Tab, { label: "Withdrawals", value: TabNames.Withdrawals, containerElement: React.createElement(Link, { to: `${pathname}/withdrawals` }), disabled: this.state.buyerNotFound }),
                React.createElement(Tab, { label: "Block History", value: TabNames.Block, containerElement: React.createElement(Link, { to: `${pathname}/blocks` }), disabled: this.state.buyerNotFound }))));
    }
    renderBuyerComponent() {
        const { loading, buyerNotFound, selectedTab } = this.state;
        const { match: { params: { id } } } = this.props;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 4, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else if (buyerNotFound) {
            return (React.createElement(BuyerNotFound, { phone: id }));
        }
        else {
            return (React.createElement("div", { className: selectedTab !== TabNames.Info && "full-size-card-wrapper" }, this.getCurrentComponent()));
        }
    }
    toggleBlockBuyerDialog() {
        return () => this.setState({ blockDialogOpened: !this.state.blockDialogOpened });
    }
    toggleUnblockBuyerDialog() {
        return () => this.setState({ unblockDialogOpened: !this.state.unblockDialogOpened });
    }
    toggleChangeBuyerTypeDialog() {
        return () => this.setState({ changeTypeDialogOpened: !this.state.changeTypeDialogOpened });
    }
}
const mapStateToProps = (state) => ({
    buyerData: state.buyerProfileInfo,
    baseUrl: state.baseUrl,
    token: state.token,
});
export default withRouter(connect(mapStateToProps)(Buyer));
