import * as React from "react";
import { ServiceRiskGroup } from "../../interfaces/Services";
import { getTableColumnItem, getTableColumnLogo } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import IconButton from "material-ui/IconButton";
import SmartDataTable from "../SmartDataTable";
class ServicesTable extends React.Component {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnLogo("imageUrl"),
            getTableColumnItem("serviceId", "ID", (name, all) => React.createElement(Link, { to: `/services/${all.serviceUuid}/info` }, name)),
            getTableColumnItem("serviceName", "Name"),
            getTableColumnItem("riskGroup", "Risk group", (riskGroup) => React.createElement("span", null,
                " ",
                ServiceRiskGroup[riskGroup],
                " ")),
            getTableColumnItem("providerName", "Provider", (name, all) => React.createElement(Link, { to: `/business/${all.providerId}/info` }, name)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.renderIcons() }));
    }
    renderIcons() {
        return [
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default ServicesTable;
