import * as React from "react";
import Card from "material-ui/Card/Card";
import CircularProgress from "material-ui/CircularProgress";
import { HttpClient } from "../utils/HttpClient";
import TransactionsTable from "../components/TransactionsComponents/TransactionsTable";
import { connect } from "react-redux";
import { setBreadcrumb, setTxsTableState } from "../actions/Actions";
import { downloadReport, getBreadcrumbObject, setPageFromOffset } from "../utils/DataHelpers";
import * as moment from "moment";
const ReactToastify = require("react-toastify");
class Transactions extends React.Component {
    constructor(props) {
        super(props);
        const initialTableState = {
            rowSize: 20,
            count: 0,
            currentPage: 1,
            search: "",
            orderKey: "",
            order: "",
            filter: {
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("day").toDate(),
            },
            data: []
        };
        this.state = {
            loading: true,
            transactions: null,
            tableState: this.props.tableState || initialTableState,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Transactions", "/transactions")]));
    }
    render() {
        return (React.createElement("div", { className: "full-size-card-wrapper" },
            React.createElement(Card, { className: "card-full-size" }, this.renderTransactionTable())));
    }
    componentDidMount() {
        this.fetchTransactionData();
    }
    fetchTransactionData(currentPage = this.state.tableState.currentPage, rowSize = this.state.tableState.rowSize, search = this.state.tableState.search, orderKey = this.state.tableState.orderKey, order = this.state.tableState.order, filter = this.state.tableState.filter) {
        const offset = currentPage ? (currentPage - 1) * rowSize : 0;
        const query = {
            offset,
            limit: rowSize,
            search,
            orderKey,
            order,
            startTime: filter.startDate ? filter.startDate.getTime() : 0,
            endTime: filter.endDate ? filter.endDate.getTime() : 0,
            type: filter.txType,
            state: filter.txState
        };
        HttpClient.getJSON(`${this.props.baseUrl}/tx/list`, query)
            .then((result) => {
            const tableState = {
                count: result.pagination.total,
                currentPage: setPageFromOffset(result.pagination.offset, result.pagination.length),
                rowSize,
                search,
                orderKey,
                order,
                filter,
                data: result.txs,
            };
            this.props.dispatch(setTxsTableState(tableState));
            this.setState({ loading: false, tableState: tableState });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load data,reload page! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    exportTransactions(search = this.state.tableState.search, orderKey = this.state.tableState.orderKey, order = this.state.tableState.order, filter = this.state.tableState.filter) {
        this.setState({ loading: true });
        const query = {
            search,
            orderKey,
            order,
            startTime: filter.startDate ? filter.startDate.getTime() : 0,
            endTime: filter.endDate ? filter.endDate.getTime() : 0,
            type: filter.txType,
            state: filter.txState
        };
        HttpClient.getJSON(`${this.props.baseUrl}/tx/export`, query)
            .then((result) => {
            ReactToastify.toast.info("Transactions file was successfully created");
            this.setState({ loading: false });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false });
            ReactToastify.toast.error("Could not export transactions. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, 'xlsx', this.props.token, this.props.baseUrl);
    }
    renderTransactionTable() {
        const { loading } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(TransactionsTable, { fetchData: this.fetchTransactionData.bind(this), tableState: this.props.tableState, export: this.exportTransactions.bind(this) }));
        }
    }
}
const mapStateToProp = (state) => ({
    tableState: state.txsTableState,
    baseUrl: state.baseUrl,
    token: state.token
});
export default connect(mapStateToProp)(Transactions);
