import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { setBreadcrumb } from "../actions/Actions";
import { connect } from "react-redux";
import { HttpClient } from "../utils/HttpClient";
import Card from "material-ui/Card/Card";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import { CircularProgress } from "material-ui";
import ArchiveUsersDialog from "../components/ArchiveUsersComponents/ArchiveUsersDialog";
import ArchiveUsersTable from "../components/ArchiveUsersComponents/ArchiveUsersTable";
const ReactToastify = require("react-toastify");
class ArchiveUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            archiveDialogOpened: false,
            deleteArchiveDialogOpened: false,
            loading: false,
            archives: [],
            currentArchive: null,
            isSaving: false,
            errors: null,
            hover: false,
            rowSize: 20,
            count: 0,
            currentPage: 1,
            sortCol: "created",
            sortDir: "desc",
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Wallet archive", "/archive-users")]));
    }
    render() {
        return (React.createElement("div", { className: "users-content" },
            React.createElement(Card, { className: "card-full-size" }, this.renderTable()),
            this.renderArchiveDialog(),
            this.renderFinishDialog()));
    }
    componentDidMount() {
        this.fetchArchives();
    }
    fetchArchives(currentPage = this.state.currentPage, rowSize = this.state.rowSize, sortCol = this.state.sortCol, sortDir = this.state.sortDir) {
        const query = {
            offset: (currentPage - 1) * rowSize,
            limit: rowSize,
            sortCol,
            sortDir,
        };
        this.setState({
            loading: true,
        });
        HttpClient.getJSON(`${this.props.baseUrl}/archive/list`, query)
            .then((response) => {
            this.setState({
                loading: false,
                archives: response && response.archives || [],
                count: response && response.count || 0,
                currentPage: currentPage || 1,
                sortCol,
                sortDir,
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading data! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    validateArchive() {
        const result = [];
        const { currentArchive } = this.state;
        if (!currentArchive || !currentArchive.inactivityDays || currentArchive.inactivityDays === 0) {
            result.push({
                field: "inactivityDays",
                text: "Inactivity days must not be empty!",
            });
        }
        return result;
    }
    finishArchive(approve) {
        const errs = this.validateArchive();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        const { currentArchive } = this.state;
        if (!currentArchive) {
            return;
        }
        this.setState({
            isSaving: true,
        });
        HttpClient.post(`${this.props.baseUrl}/archive/${currentArchive.id}/finish?approve=${approve}`)
            .then(() => {
            this.fetchArchives();
            ReactToastify.toast.info("Archive was finished successfully!");
            this.setState({
                isSaving: false,
                deleteArchiveDialogOpened: false,
            });
        })
            .catch((err) => {
            this.setState({
                isSaving: false,
                deleteArchiveDialogOpened: false,
            });
            ReactToastify.toast.error("Could not finish archive! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    addArchive() {
        const errs = this.validateArchive();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        const { currentArchive } = this.state;
        if (!currentArchive) {
            return;
        }
        this.setState({
            isSaving: true,
        });
        const inactivityDays = currentArchive.inactivityDays;
        HttpClient.post(`${this.props.baseUrl}/archive/create?inactivityDays=${inactivityDays}`)
            .then(() => {
            this.fetchArchives();
            ReactToastify.toast.info("Archive was created successfully!");
            this.setState({
                isSaving: false,
                archiveDialogOpened: false,
            });
        })
            .catch((err) => {
            this.setState({
                isSaving: false,
            });
            ReactToastify.toast.error("Could not add archive! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderFinishDialog() {
        return (React.createElement(Dialog, { title: "Finish archive ?", actions: [
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: () => this.finishArchive(false), disabled: this.state.isSaving }),
                React.createElement(FlatButton, { label: "Finish", onClick: () => this.finishArchive(true), disabled: this.state.isSaving }),
            ], modal: true, open: this.state.deleteArchiveDialogOpened, onRequestClose: this.closeDeleteDialog.bind(this) }));
    }
    renderTable() {
        return (React.createElement("div", null,
            this.state.loading && React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }),
            React.createElement(ArchiveUsersTable, { archives: this.state.archives, reloadData: this.fetchArchives.bind(this), addArchive: this.openArchiveDialog.bind(this), finishArchive: this.openFinishDialog.bind(this), currentArchive: this.state.currentArchive, rowSize: this.state.rowSize, count: this.state.count, currentPage: this.state.currentPage, sortCol: this.state.sortCol, sortDir: this.state.sortDir })));
    }
    handleInputChange(value, name) {
        this.setState((state) => (Object.assign(Object.assign({}, state), { currentArchive: Object.assign(Object.assign({}, state.currentArchive), { [name]: value }), errors: state.errors && state.errors.filter((e) => e.field !== name) })));
    }
    renderArchiveDialog() {
        const { currentArchive } = this.state;
        return (React.createElement(ArchiveUsersDialog, { open: this.state.archiveDialogOpened, currentArchive: currentArchive, dialogTitle: "Create archive", handleInputChange: this.handleInputChange.bind(this), isSaving: this.state.isSaving, onCancelClick: this.closeArchiveDialog.bind(this), onOkClick: this.addArchive.bind(this), errors: this.state.errors, hover: this.state.hover }));
    }
    closeArchiveDialog() {
        this.setState({
            archiveDialogOpened: false,
            currentArchive: null,
            isSaving: false,
            errors: null,
        });
    }
    openArchiveDialog(archive) {
        this.setState({
            currentArchive: archive,
            archiveDialogOpened: true,
        });
    }
    closeDeleteDialog() {
        this.setState({
            deleteArchiveDialogOpened: false,
            currentArchive: null,
            isSaving: false,
        });
    }
    openFinishDialog(archive) {
        this.setState({
            deleteArchiveDialogOpened: true,
            currentArchive: archive,
        });
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
});
export default connect(mapStateToProps)(ArchiveUsers);
