import * as React from "react";
import { formatPhone, getTableColumnItem } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import IconButton from "material-ui/IconButton";
import { Link } from "react-router-dom";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import RaisedButton from "material-ui/RaisedButton";
class BusinessesTable extends React.Component {
    constructor() {
        super(...arguments);
        this.businessesTableColumns = [
            getTableColumnItem("title", "Title", (title, all) => React.createElement(Link, { to: `/business/${all.id}/info` }, title ? title : "Is not specified")),
            getTableColumnItem("emails", "Emails", (emails) => emails.join(", ")),
            getTableColumnItem("ownerPhone", "Owner", (phone) => formatPhone(phone)),
        ];
    }
    get renderAddNewBusinessIcon() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { label: "ADD BUSINESS", onClick: this.props.addNewBusiness.bind(this), primary: true })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.businessesData, columns: this.businessesTableColumns, toolbarIconRight: this.renderAddNewBusinessIcon }));
    }
}
export default BusinessesTable;
