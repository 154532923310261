import * as React from "react";
import Card from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import SmartDataTable from "../SmartDataTable";
import { formatTime, getTableColumnItem, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { HttpClient } from "../../utils/HttpClient";
import { connect } from "react-redux";
import * as lodash from "lodash";
const ReactToastify = require("react-toastify");
class BuyerBlockHistory extends React.PureComponent {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("admin", "Admin"),
            getTableColumnItem("whenBlocked", "When Blocked", (time) => formatTime(time)),
            getTableColumnItem("whenUnblocked", "When Unblocked", (time) => time ? formatTime(time) : ""),
            getTableColumnItem("reason", "Reason"),
        ];
        this.state = {
            loading: true,
            blockHistory: null
        };
    }
    render() {
        return (React.createElement(Card, { className: "card-998" }, this.renderTable()));
    }
    fetchBuyerBlockHistory() {
        HttpClient.getJSON(`${this.props.baseUrl}/block/history/${this.props.id}`)
            .then((result) => {
            this.setState({
                loading: false,
                blockHistory: lodash.isEmpty(result) ? [] : result.history,
            });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't load buyer block history. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    componentDidMount() {
        this.fetchBuyerBlockHistory();
    }
    renderTable() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(SmartDataTable, { data: this.state.blockHistory, columns: this.tableColumns, toolbarIconRight: this.getIcons(), showFooterToolbar: false }));
        }
    }
    getIcons() {
        return (React.createElement(IconButton, { onClick: () => this.fetchBuyerBlockHistory() },
            React.createElement(Refresh, null)));
    }
}
export default connect(mapStateToPropsBaseUrl)(BuyerBlockHistory);
