import * as React from "react";
import { HttpClient } from "../../utils/HttpClient";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import { Card, CardActions, CardText, CardTitle } from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as lodash from "lodash";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
const ReactToastify = require("react-toastify");
const Input = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'rgb(255, 140, 0)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(255, 140, 0)',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgb(255, 140, 0)',
            },
            '&:hover fieldset': {
                borderColor: 'rgb(255, 140, 0)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(255, 140, 0)',
            },
        },
    },
})(TextField);
class CBCParams extends React.Component {
    constructor(props) {
        super(props);
        this.initialStateData = {
            loading: false,
            availableTime: "",
            changedWallet: false
        };
        this.componentDidMount = () => {
            this.getCBCData();
        };
        this.getCBCData = () => {
            this.setState({
                loading: true
            });
            HttpClient.getJSON(`${this.props.baseUrl}/cbc/settings`).then((result) => {
                if (!lodash.isEmpty(result)) {
                    const keys = Object.keys(result).filter(key => key !== "hours");
                    const fetchData = (keys || []).map(key => {
                        if (key === "optim") {
                            return {
                                id: "optim",
                                title: "OPTIM Wallet",
                                description: "Upgraded MINI Wallet",
                                data: Object.assign({}, this.formatWalletData(result[key]))
                            };
                        }
                        if (key === "premium") {
                            return {
                                id: "premium",
                                title: "PREMIUM Wallet",
                                description: "Cont verificat manual",
                                data: Object.assign({}, this.formatWalletData(result[key]))
                            };
                        }
                    });
                    this.setState({
                        wallets: fetchData,
                        availableTime: result.hours,
                        initialTime: result.hours,
                        intialWallet: JSON.parse(JSON.stringify(fetchData))
                    });
                }
                this.setState({ loading: false });
            }).catch((err) => {
                ReactToastify.toast.error("Can't load system CBC params! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
                this.setState({ loading: false });
            });
        };
        this.insertCBCSettings = () => {
            const { wallets, availableTime } = this.state;
            const optimWallet = wallets.find(item => item.id === "optim");
            const premiumWallet = wallets.find(item => item.id === "premium");
            const hasError = this.checkOnErrors();
            if (!hasError) {
                const insertObj = {
                    hours: availableTime,
                    optim: {
                        minSum: Number(optimWallet.data.minSum.value),
                        maxSum: Number(optimWallet.data.maxSum.value),
                        commission: Number(optimWallet.data.commission.value),
                        limit: Number(optimWallet.data.limit.value)
                    },
                    premium: {
                        minSum: Number(premiumWallet.data.minSum.value),
                        maxSum: Number(premiumWallet.data.maxSum.value),
                        commission: Number(premiumWallet.data.commission.value),
                        limit: Number(premiumWallet.data.limit.value)
                    }
                };
                const { baseUrl } = this.props;
                HttpClient.putWithData(`${baseUrl}/cbc/settings`, JSON.stringify(insertObj)).then(() => {
                    this.getCBCData();
                    ReactToastify.toast.info("System CBC parameters were successfully changed!");
                }).catch((err) => {
                    ReactToastify.toast.error("Can't change system CBC params! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                });
            }
        };
        this.checkOnErrors = () => {
            const { wallets, timeError } = this.state;
            const hasError = wallets.map(item => {
                const keys = Object.keys(item.data);
                return keys.map(key => {
                    return item.data[key].error === "";
                });
            }).reduce((acc, val) => acc.concat(val), []);
            if (hasError.includes(false)) {
                return true;
            }
            return false || timeError.length !== 0;
        };
        this.formatWalletData = (obj) => {
            const returnData = {};
            obj = this.fillAllObjects(obj);
            const keys = Object.keys(obj);
            keys.map(item => {
                returnData[item] = {
                    value: obj[item].toString().replace(/,/g, '.'),
                    error: ""
                };
                return returnData;
            });
            return returnData;
        };
        this.fillAllObjects = (obj) => {
            if (obj.minSum === undefined) {
                obj.minSum = "";
            }
            if (obj.maxSum === undefined) {
                obj.maxSum = "";
            }
            if (obj.commission === undefined) {
                obj.commission = "";
            }
            if (obj.limit === undefined) {
                obj.limit = "";
            }
            return obj;
        };
        this.changeWalletData = (e, index, key) => {
            const { wallets } = this.state;
            const wallet = wallets.map((item, i) => {
                if (i === index) {
                    const re = /^[0-9\b]+$/;
                    const isValid = re.test(e.target.value);
                    const element = item.data[key];
                    if (Number(e.target.value) >= 0) {
                        if ((key !== "commission" && isValid) || key === "commission") {
                            element.value = e.target.value;
                        }
                    }
                    if (key === "minSum") {
                        if (Number(element.value) < 50) {
                            element.error = "Suma minimă pentru tranzacțiile CBC trebuie să fie de minimum 50";
                        }
                        else if (Number(element.value) >= Number(item.data["maxSum"].value)) {
                            element.error = "Suma nu trebuie să depășească maximul";
                        }
                        else {
                            element.error = "";
                        }
                    }
                    if (key === "maxSum") {
                        if (Number(element.value) <= Number(item.data["minSum"].value)) {
                            element.error = "Suma nu trebuie să fie mai mică decât minimul";
                        }
                        else {
                            element.error = "";
                        }
                    }
                }
                return item;
            });
            this.setState({
                wallets: wallet,
                changedWallet: true
            });
        };
        this.setDefaultData = () => {
            const { intialWallet, initialTime } = this.state;
            this.setState(Object.assign(Object.assign({}, this.initialStateData), { availableTime: initialTime, wallets: JSON.parse(JSON.stringify(intialWallet)) }));
        };
        this.checkIfDataChanged = (status) => {
            const { intialWallet, wallets } = this.state;
            let changedData = [];
            intialWallet.forEach((item, i) => {
                const currentWalletData = wallets.find((el, index) => index === i);
                const keys = Object.keys(item.data);
                keys.forEach(key => {
                    if (item.data[key].value !== currentWalletData.data[key].value) {
                        changedData.push(key);
                    }
                });
            });
            const hasError = this.checkOnErrors();
            const statement = changedData.length === 0 && this.state.availableTime === this.state.initialTime;
            if (status) {
                return hasError ? true : statement;
            }
            return statement;
        };
        this.changeTime = (e) => {
            const value = e.target.value;
            const numberValue = Number(value);
            let error = "";
            if (numberValue >= 0) {
                if (numberValue < 4 || numberValue > 24) {
                    error = "Selectați valori de la 4 la 24";
                }
                else {
                    error = "";
                }
                this.setState({
                    availableTime: value,
                    timeError: error
                });
            }
        };
        this.state = Object.assign({ loading: true, wallets: [], initialTime: "", intialWallet: [], timeError: "" }, this.initialStateData);
    }
    render() {
        const { loading } = this.state;
        const { availableTime, wallets, timeError } = this.state;
        return (React.createElement(Card, { className: "card-998 cbc_card" }, loading ? (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                top: "50%",
                left: "50%",
                position: "absolute",
                marginLeft: "-60px",
                marginTop: "-60px",
            } })) : (React.createElement(React.Fragment, null,
            React.createElement(CardText, null,
                React.createElement(FormControl, null,
                    React.createElement(InputLabel, { shrink: true, htmlFor: "bootstrap-input" }, "Termen de valabilitate"),
                    React.createElement(Input, { id: "terms-validity", label: "Termen de valabilitate", value: availableTime, type: "number", error: timeError !== "", helperText: timeError, onChange: this.changeTime, InputProps: {
                            endAdornment: React.createElement(InputAdornment, { position: "start" }, "ore"),
                        } }))),
            wallets.length !== 0 && wallets.map((item, index) => {
                const { minSum, maxSum, commission, limit } = item.data;
                return (React.createElement("div", { key: index },
                    React.createElement(CardTitle, { title: item.title, subtitle: item.description }),
                    React.createElement(CardText, null,
                        React.createElement("div", { className: "commission-form-row" },
                            React.createElement(FormControl, null,
                                React.createElement(InputLabel, { shrink: true, htmlFor: "bootstrap-input" }, "Suma minimala"),
                                React.createElement(Input, { id: "min-sum-cbc", label: "Suma minimala", className: "commission-input-field", value: minSum.value || "", error: minSum.error !== "", helperText: minSum.error, type: "number", onChange: (e) => this.changeWalletData(e, index, "minSum"), InputProps: {
                                        endAdornment: React.createElement(InputAdornment, { position: "start" }, "MDL"),
                                    } })),
                            React.createElement(FormControl, null,
                                React.createElement(InputLabel, { shrink: true, htmlFor: "bootstrap-input" }, "Suma maximala"),
                                React.createElement(Input, { id: "max-sum-cbc", label: "Suma maximala", className: "commission-input-field", value: maxSum.value || "", error: maxSum.error !== "", helperText: maxSum.error, type: "number", onChange: (e) => this.changeWalletData(e, index, "maxSum"), InputProps: {
                                        endAdornment: React.createElement(InputAdornment, { position: "start" }, "MDL"),
                                    } })),
                            React.createElement(FormControl, null,
                                React.createElement(InputLabel, { shrink: true, htmlFor: "bootstrap-input" }, "Comission la retragere"),
                                React.createElement(Input, { id: "commission-cbc", label: "Comission la retragere", className: "commission-input-field", value: commission.value || "", error: commission.error !== "", helperText: commission.error, onChange: (e) => this.changeWalletData(e, index, "commission"), InputProps: {
                                        endAdornment: React.createElement(InputAdornment, { position: "start" }, "%"),
                                    } })),
                            React.createElement(FormControl, null,
                                React.createElement(InputLabel, { shrink: true, htmlFor: "bootstrap-input" }, "Limita per zi"),
                                React.createElement(Input, { id: "limits-cbc", label: "Limita per zi", value: limit.value || "", error: limit.error !== "", helperText: limit.error, type: "number", onChange: (e) => this.changeWalletData(e, index, "limit"), InputProps: {
                                        endAdornment: React.createElement(InputAdornment, { position: "start" }, "MDL"),
                                    } }))))));
            }),
            React.createElement(CardActions, { className: "info-page-cardactions" },
                React.createElement(Button, { variant: "outlined", className: "info_card_outlinedbutton", disabled: this.checkIfDataChanged(), onClick: this.setDefaultData }, "RESET"),
                React.createElement(Button, { variant: "contained", className: "info-card-raisedbutton", disabled: this.checkIfDataChanged(true), onClick: this.insertCBCSettings }, "CHANGE"))))));
    }
}
export default connect(mapStateToPropsBaseUrl)(CBCParams);
