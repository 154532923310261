import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { setBreadcrumb } from "../actions/Actions";
import { connect } from "react-redux";
import { HttpClient } from "../utils/HttpClient";
import Card from "material-ui/Card/Card";
import { getBreadcrumbObject, setPageFromOffset } from "../utils/DataHelpers";
import MainBusinessTable from "../components/MainBusiness/MainBusinessTable";
import MainBusinessDialog from "../components/MainBusiness/MainBusinessDialog";
const ReactToastify = require("react-toastify");
class MainBusiness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainBusinessDialogOpened: false,
            removeMainBusiness: false,
            loading: false,
            business: [],
            providers: [],
            merchants: [],
            withdrawBusiness: [],
            currentBusiness: null,
            isSaving: false,
            errors: null,
            rowSize: 20,
            count: 0,
            currentPage: 1,
            search: "",
            order: "",
        };
        const { dispatch } = this.props;
        dispatch(setBreadcrumb([getBreadcrumbObject("Company", "/company")]));
    }
    render() {
        return (React.createElement("div", { className: "users-content" },
            React.createElement(Card, { className: "card-full-size" }, this.renderTable()),
            this.renderBusinessDialog(),
            this.renderDeleteDialog()));
    }
    componentDidMount() {
        this.fetchBusiness();
    }
    fetchBusiness(currentPage = this.state.currentPage, rowSize = this.state.rowSize, search = this.state.search, order = this.state.order) {
        const { baseUrl } = this.props;
        const offset = currentPage ? (currentPage - 1) * rowSize : 0;
        const params = {
            offset,
            limit: rowSize,
            search,
            order
        };
        this.setState({
            loading: true,
        });
        HttpClient.getJSON(`${baseUrl}/company/list`, params)
            .then((response) => {
            if (response) {
                this.setState({
                    loading: false,
                    business: response && response.company || [],
                    count: response && response.pagination.total || 0,
                    currentPage: setPageFromOffset(response && response.pagination.offset, response && response.pagination.length),
                    search,
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
        })
            .catch((err) => {
            ReactToastify.toast.error("Error loading data! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    validateBusiness() {
        const result = [];
        const { currentBusiness } = this.state;
        if (!currentBusiness || !currentBusiness.name || currentBusiness.name.trim() === "") {
            result.push({
                field: "name",
                text: "Name must not be empty!",
            });
        }
        if (!currentBusiness || !currentBusiness.idno || currentBusiness.idno.trim() === "") {
            result.push({
                field: "idno",
                text: "Name must not be empty!",
            });
        }
        return result;
    }
    updateBusiness() {
        const { currentBusiness } = this.state;
        const { baseUrl } = this.props;
        const errs = this.validateBusiness();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        if (currentBusiness) {
            this.setState({
                isSaving: true,
            });
            currentBusiness.id
                ? HttpClient.putWithData(`${baseUrl}/company`, JSON.stringify(Object.assign({}, currentBusiness)))
                : HttpClient.post(`${baseUrl}/company/create`, JSON.stringify(Object.assign({}, currentBusiness)))
                    .then(() => {
                    this.closeBusinessDialog();
                    this.fetchBusiness();
                    currentBusiness.id
                        ? ReactToastify.toast.info("Company successfully updated!")
                        : ReactToastify.toast.info("Company was added successfully!");
                })
                    .catch((err) => {
                    this.setState({
                        isSaving: false,
                    });
                    ReactToastify.toast.error("Could not add company! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                });
        }
    }
    deleteSelectedBusiness() {
        const { currentBusiness, currentBusiness: { id } } = this.state;
        const { baseUrl } = this.props;
        if (currentBusiness) {
            HttpClient.delete(`${baseUrl}/company/${id}`)
                .then(() => {
                this.closeDeleteDialog();
                ReactToastify.toast.info("Company was deleted successfully!");
                this.fetchBusiness();
            })
                .catch((err) => {
                ReactToastify.toast.error("Could not delete company! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    renderDeleteDialog() {
        const { currentBusiness, isSaving, removeMainBusiness } = this.state;
        const title = currentBusiness && currentBusiness.name;
        return (React.createElement(Dialog, { title: "Delete company with title " + title + "?", actions: [
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.closeDeleteDialog.bind(this), disabled: isSaving }),
                React.createElement(FlatButton, { label: "OK", onClick: this.deleteSelectedBusiness.bind(this), disabled: isSaving }),
            ], modal: true, open: removeMainBusiness, onRequestClose: this.closeDeleteDialog.bind(this) }));
    }
    renderTable() {
        const { business, currentPage, search, count, rowSize, order } = this.state;
        return (React.createElement(MainBusinessTable, Object.assign({}, { count }, { search }, { rowSize }, { currentPage }, { order }, { items: business, reloadData: this.fetchBusiness.bind(this), updateBusiness: this.openMerchantDialog.bind(this), deleteBusiness: this.openDeleteDialog.bind(this) })));
    }
    handleInputChange(value, name) {
        this.setState((state) => ({
            currentBusiness: Object.assign(Object.assign({}, state.currentBusiness), { [name]: value }),
            errors: state.errors && state.errors.filter((e) => e.field !== name),
        }));
    }
    renderBusinessDialog() {
        const { currentBusiness, providers, mainBusinessDialogOpened, isSaving, errors, merchants, withdrawBusiness } = this.state;
        return (React.createElement(MainBusinessDialog, { open: mainBusinessDialogOpened, currentBusiness: currentBusiness, providers: providers, merchants: merchants, withdrawBusiness: withdrawBusiness, dialogTitle: currentBusiness && currentBusiness.id ? "Update company" : "Create new company", handleInputChange: this.handleInputChange.bind(this), isSaving: isSaving, onCancelClick: this.closeBusinessDialog.bind(this), onOkClick: this.updateBusiness.bind(this), errors: errors }));
    }
    closeBusinessDialog() {
        this.setState({
            mainBusinessDialogOpened: false,
            currentBusiness: null,
            isSaving: false,
            errors: null,
        });
    }
    openMerchantDialog(business) {
        this.setState({
            currentBusiness: business,
            mainBusinessDialogOpened: true,
        });
    }
    closeDeleteDialog() {
        this.setState({
            removeMainBusiness: false,
            currentBusiness: null,
            isSaving: false,
        });
    }
    openDeleteDialog(business) {
        this.setState({
            removeMainBusiness: true,
            currentBusiness: business,
        });
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
});
export default connect(mapStateToProps)(MainBusiness);
