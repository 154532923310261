export function getStatusText(status) {
    let statusText;
    switch (status) {
        case "new":
            statusText = "Created";
            break;
        case "ready":
            statusText = "Sent";
            break;
        case "accepted":
            statusText = "Accepted";
            break;
        default:
            statusText = "Rejected";
            break;
    }
    return statusText;
}
export function base64ToArrayBuffer(base64) {
    return base64;
}
export function createAndDownloadBlobFile(body, filename, type) {
    const blob = new Blob([body], { type });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    }
    else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
export function fromParamsUrlList(name, params) {
    if (!params || !params.length) {
        return "";
    }
    let urlParams = "";
    params.forEach((param, index) => {
        urlParams += `${index === 0 ? "" : "&"}${name}=${param}`;
    });
    return urlParams;
}
