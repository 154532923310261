import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
class ErrorListDialog extends React.PureComponent {
    render() {
        const { opened } = this.props;
        return (React.createElement(Dialog, { open: opened, onRequestClose: this.props.onOkClick.bind(this), title: this.props.title, modal: true, actions: this.renderDialogButtons() },
            React.createElement("div", { style: { overflow: "auto", maxHeight: 400 } }, this.getContent())));
    }
    renderDialogButtons() {
        return ([
            React.createElement(FlatButton, { primary: true, label: "OK", onClick: this.props.onOkClick.bind(this) }),
        ]);
    }
    getContent() {
        return this.props.content.map((str) => (React.createElement("div", null, str)));
    }
}
export default ErrorListDialog;
