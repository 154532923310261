import * as React from "react";
import * as moment from "moment";
import { DatePicker } from "material-ui";
class DateRangePicker extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    ;
    render() {
        return (React.createElement("div", { className: "date-range" },
            React.createElement(DatePicker, { id: "start-date-picker", maxDate: this.props.endDate, formatDate: (date) => moment(date).format("DD.MM.YYYY"), autoOk: true, container: "inline", disabled: this.props.disabled, floatingLabelText: "Selected start date:", onChange: this.props.onStartDateChange, textFieldStyle: { marginRight: "20px" }, value: this.props.startDate }),
            React.createElement(DatePicker, { id: "end-date-picker", minDate: this.props.startDate, maxDate: moment().toDate(), formatDate: (date) => moment(date).format("DD.MM.YYYY"), autoOk: true, container: "inline", disabled: this.props.disabled, floatingLabelText: "Selected end date:", onChange: this.props.onEndDateChange, value: this.props.endDate })));
    }
}
export default DateRangePicker;
