import * as React from "react";
import { formatTime, getInfoTableRow, getBreadcrumbObject, mapStateToPropsBaseUrl, capitalizeFirstLetter, } from "../../utils/DataHelpers";
import { Card, CardHeader, CardText } from "material-ui/Card";
import Table, { TableBody } from "material-ui/Table";
import { HttpClient } from "../../utils/HttpClient";
import { setBreadcrumb } from "../../actions/Actions";
import { connect } from "react-redux";
import { CircularProgress } from "material-ui";
import { defaultLogoUrl } from "../../variables/Common";
import { withRouter } from '../../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class Details extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {}
        };
        const { match: { params: { id, type, name, companyName } }, dispatch } = this.props;
        dispatch(setBreadcrumb([getBreadcrumbObject("Company", `/company`),
            getBreadcrumbObject(companyName, `/company/details/${companyName}/${id}`),
            getBreadcrumbObject(capitalizeFirstLetter(type), `/company/details/${companyName}/${id}/${type}`),
            getBreadcrumbObject(name, `/company/detail/${type}/${companyName}`),
        ]));
    }
    componentDidMount() {
        this.fetchDetails();
    }
    fetchDetails() {
        const { match: { params: { businessId, type } }, baseUrl } = this.props;
        this.setState({
            loading: true,
        });
        const selectedType = type.slice(0, -1);
        HttpClient.getJSON(`${baseUrl}/company/${selectedType}/${businessId}`)
            .then((response) => {
            if (response.body && response.status.code === "ok") {
                this.setState({
                    loading: false,
                    data: response.body,
                });
            }
            else {
                this.setState({
                    loading: false
                });
            }
        })
            .catch((err) => {
            ReactToastify.toast.error(`Error loading ${type} details! Status: ` +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({
                loading: false,
            });
        });
    }
    render() {
        const { loading } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            const { match: { params: { type } } } = this.props;
            return (React.createElement("div", null,
                type === "providers" && this.renderProviders(),
                type === "merchants" && this.renderMerchants(),
                type === "withdrawalAccounts" && this.renderWithdrawAccounts()));
        }
    }
    renderProviders() {
        const { data: { prv_idno, prv_name, name } } = this.state;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Name", name),
                        getInfoTableRow("Provider Name", prv_name),
                        getInfoTableRow("Provider IDNO", prv_idno))))));
    }
    renderMerchants() {
        const { data: { title, alias, registered, logo, serviceId, name } } = this.state;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardHeader, { title: title, avatar: React.createElement("img", { alt: "", className: "table-image", src: logo ? logo : defaultLogoUrl, onError: (e) => e.target.src = defaultLogoUrl }) }),
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Name", name),
                        getInfoTableRow("Alias", alias),
                        getInfoTableRow("Registered", formatTime(registered)),
                        getInfoTableRow("serviceId", serviceId))))));
    }
    renderWithdrawAccounts() {
        const { data: { account, name, idno } } = this.state;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Name", account),
                        getInfoTableRow("Provider Name", name),
                        getInfoTableRow("Provider IDNO", idno))))));
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(Details));
