import * as React from "react";
import { HttpClient } from "../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import BusinessOwnersTable from "../components/OwnersComponents/BusinessOwnersTable";
import Card from "material-ui/Card/Card";
import AddOwnerDialog from "../components/OwnersComponents/AddOwnerDialog";
import { connect } from "react-redux";
import { setBreadcrumb } from "../actions/Actions";
import { getBreadcrumbObject } from "../utils/DataHelpers";
const ReactToastify = require("react-toastify");
class BusinessOwners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            ownersData: null,
            addDialogOpened: false,
            ownerExists: false,
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Business Owners", "/owners")]));
    }
    render() {
        return (React.createElement("div", null,
            this.renderDialogs(),
            React.createElement(Card, { className: "card-700" }, this.renderOwnersTable())));
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        HttpClient.getJSON(`${this.props.baseUrl}/owner/list`).then((result) => {
            this.setState({ ownersData: result, loading: false });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load owner list. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    addNewOwner(name, surname, phone) {
        const data = JSON.stringify({ name, surname, phone });
        HttpClient.post(`${this.props.baseUrl}/owner`, data).then(() => {
            this.fetchData();
            this.setState({ addDialogOpened: false, ownerExists: false });
            ReactToastify.toast.success("Business owner successfully added!");
        }).catch((err) => {
            if (err.response.status === 400) {
                this.setState({ ownerExists: true });
            }
            else {
                ReactToastify.toast.error("Smth went wrong! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            }
        });
    }
    renderOwnersTable() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(BusinessOwnersTable, { data: this.state.ownersData.owners, fetchData: () => this.fetchData(), openAddDialog: this.toggleAddDialog() }));
        }
    }
    renderDialogs() {
        if (this.state.loading) {
            return null;
        }
        else {
            return (React.createElement("div", null,
                React.createElement(AddOwnerDialog, { opened: this.state.addDialogOpened, toggleDialog: this.toggleAddDialog(), onAddBtnClick: this.addNewOwner.bind(this), phoneAlreadyExists: this.state.ownerExists })));
        }
    }
    toggleAddDialog() {
        return () => this.setState({ addDialogOpened: !this.state.addDialogOpened });
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    chosenOwner: state.chosenOwner,
});
export default connect(mapStateToProps)(BusinessOwners);
