import * as React from "react";
import * as moment from "moment";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Divider from "material-ui/Divider";
import DatePickerDialog from "material-ui/DatePicker/DatePickerDialog";
import { formatCurrency, formatPhone, formatTime, getBreadcrumbObject, getTableColumnItem, mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import { HttpClient } from "../../utils/HttpClient";
import { connect } from "react-redux";
import Table, { TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import { Card, CardText } from "material-ui/Card";
import CircularProgress from "material-ui/CircularProgress";
import CardTitle from "material-ui/Card/CardTitle";
import { appendBreadcrumb } from "../../actions/Actions";
import { isNullOrUndefined } from "util";
import { withRouter } from '../../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
var Day;
(function (Day) {
    Day[Day["TODAY"] = 0] = "TODAY";
    Day[Day["YESTERDAY"] = 1] = "YESTERDAY";
    Day[Day["CUSTOM"] = 2] = "CUSTOM";
})(Day || (Day = {}));
class ShiftReports extends React.Component {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("sellerName", "Seller Name", (name) => this.getSellerName(name)),
            getTableColumnItem("sellerPhone", "Seller Phone", (phone) => formatPhone(phone)),
            getTableColumnItem("posTitle", "POS"),
            getTableColumnItem("startedAt", "Started", (time) => this.getShiftTime(time)),
            getTableColumnItem("stoppedAt", "Stopped", (time) => this.getShiftTime(time)),
            getTableColumnItem("txCount", "Txs Count"),
            getTableColumnItem("sum", "Sum(mdl)"),
        ];
        this.state = {
            selectedDay: Day.TODAY,
            from: moment().startOf("day").unix(),
            to: moment().endOf("day").unix(),
            data: [],
            loading: true,
            date: new Date(),
        };
        this.props.dispatch(appendBreadcrumb([
            getBreadcrumbObject("Reports", `/business/${this.props.match.params.id}/info/reports`),
        ]));
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(DatePickerDialog, { ref: r => this.setDataPickerDialogReference(r), firstDayOfWeek: 0, autoOk: false, onAccept: (date) => this.setSelectedDate(date), initialDate: this.state.date }),
            React.createElement(Card, { className: "card-1200" },
                React.createElement(CardTitle, { title: "Shift report" }),
                this.renderPage())));
    }
    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.from !== this.state.from && prevState.to !== this.state.to) {
            this.fetchData();
        }
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/reports/business/${this.props.match.params.id}/shifts`, {
            from: this.state.from,
            to: this.state.to,
        })
            .then((result) => {
            this.setState({ data: result.report ? result.report : [], loading: false });
        })
            .catch((error) => {
            ReactToastify.toast.error("Can't load reports! Status: " +
                JSON.stringify(error.response.status) +
                " Error:" + JSON.stringify(error.response.statusText));
            this.setState({ loading: false });
        });
    }
    toolbarIcons() {
        return [
            this.renderDataPicker(),
        ];
    }
    renderPage() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 60, thickness: 8, style: {
                    width: "60px",
                    margin: "auto",
                    display: "block",
                } }));
        }
        else {
            return [
                React.createElement(CardText, { key: "shift-report-table" }, this.renderShiftReportTable()),
                React.createElement(CardText, { key: "shift-totals" }, this.renderTotals()),
            ];
        }
    }
    renderShiftReportTable() {
        return (React.createElement(SmartDataTable, { data: this.state.data, columns: this.tableColumns, toolbarIconRight: this.toolbarIcons() }));
    }
    renderTotals() {
        return (React.createElement(Table, { selectable: false, multiSelectable: false },
            React.createElement(TableBody, { displayRowCheckbox: false },
                React.createElement(TableRow, null,
                    React.createElement(TableRowColumn, { className: "info-static-card-column" },
                        React.createElement("span", { className: "info-static-card-column-span" }, "Transactions count")),
                    React.createElement(TableRowColumn, { className: "info-static-card-column" }, this.getTransactionsCount())),
                React.createElement(TableRow, null,
                    React.createElement(TableRowColumn, { className: "info-static-card-column" },
                        React.createElement("span", { className: "info-static-card-column-span" }, "Transactions sum amount")),
                    React.createElement(TableRowColumn, { className: "info-static-card-column" }, formatCurrency(this.getTotalAmount().toFixed(2)))))));
    }
    renderDataPicker() {
        return (React.createElement(SelectField, { id: "select-day", floatingLabelText: "Day", value: this.state.selectedDay, onChange: (e, i, value) => this.setState({ selectedDay: value }) },
            React.createElement(MenuItem, { value: Day.TODAY, label: this.getMenuItemLabel(Day.TODAY), primaryText: "Today", onClick: () => this.setState({
                    from: moment().startOf("day").unix(),
                    to: moment().endOf("day").unix(),
                }) }),
            React.createElement(MenuItem, { value: Day.YESTERDAY, label: this.getMenuItemLabel(Day.YESTERDAY), primaryText: "Yesterday", onClick: () => this.setState({
                    from: moment().subtract(1, "day").startOf("day").unix(),
                    to: moment().subtract(1, "day").endOf("day").unix(),
                }) }),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { value: Day.CUSTOM, label: this.getMenuItemLabel(Day.CUSTOM), primaryText: "Custom Day...", onClick: () => this.datePickerDialog.show() })));
    }
    getMenuItemLabel(day) {
        switch (day) {
            case Day.TODAY:
                return moment().format("L");
            case Day.YESTERDAY:
                return moment().subtract(1, "days").format("L");
            case Day.CUSTOM:
                return moment.unix(this.state.from).format("L");
        }
    }
    getTransactionsCount() {
        let total = 0;
        this.state.data.map((pos) => total += pos.txCount);
        return total;
    }
    getTotalAmount() {
        let total = 0;
        this.state.data.map((pos) => total += pos.sum);
        return total;
    }
    setDataPickerDialogReference(r) {
        this.datePickerDialog = r;
    }
    setSelectedDate(date) {
        this.setState({
            from: moment(date).startOf("day").unix(),
            to: moment(date).endOf("day").unix(),
            date,
        });
    }
    getShiftTime(time) {
        if (moment(Number(time)).get("date") === moment.unix(this.state.to).get("date")) {
            return moment(Number(time)).format("HH:mm:ss");
        }
        else {
            return formatTime(Number(time));
        }
    }
    getSellerName(name) {
        if (isNullOrUndefined(name) || name === "") {
            return "not defined";
        }
        else {
            return name;
        }
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(ShiftReports));
