import * as React from "react";
import { formatCurrency, formatPhone, formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import SmartDataTable from "../SmartDataTable";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
class PendingPaymentsTable extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("walletPhone", "Wallet", (phone, all) => React.createElement(Link, { to: `/pendings/${all.id}/info` }, formatPhone(phone))),
            getTableColumnItem("whenStarted", "Started", (date) => formatTime(date)),
            getTableColumnItem("amount", "Amount", (amount) => formatCurrency(amount)),
            getTableColumnItem("serviceName", "Service"),
            getTableColumnItem("clientId", "Client ID"),
            getTableColumnItem("prvTxnId", "Txn ID"),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons() }));
    }
    getToolbarIcons() {
        return [
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default PendingPaymentsTable;
