import * as React from "react";
import { connect } from "react-redux";
import { downloadReport, getBreadcrumbObject, getEndOfDayMillis, getStartOfDayMillis } from "../../utils/DataHelpers";
import { appendBreadcrumb } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import CardActions from "material-ui/Card/CardActions";
import { Card, CardHeader, CardText } from "material-ui/Card";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DateRangePicker from "../DateRangePicker";
import { TextField } from "material-ui";
const ReactToastify = require("react-toastify");
class SPCSB2Report extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            startDate: null,
            endDate: null,
            fileName: "",
            reportFormat: "xlsx",
            amount: 4900,
            operationsCount: 12,
            riskLevels: "3",
            errors: [],
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: "MS Excel", fileExt: "xlsx" },
                { name: "CSV", fileExt: "csv" },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("SPCSB 2 Report", "/reports/spcsb2/info")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        const errs = this.validateReport();
        if (errs && errs.length > 0) {
            this.setState({
                errors: errs,
            });
            return;
        }
        this.setState({ loading: true, generated: false });
        const query = {
            dateFrom: getStartOfDayMillis(this.state.startDate),
            dateTo: getEndOfDayMillis(this.state.endDate),
            amount: this.state.amount,
            operationsCount: this.state.operationsCount.toString(),
            riskLevels: this.state.riskLevels,
            reportType: this.state.reportFormat,
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/spcsb2`, query)
            .then((result) => {
            if (!result.fileName) {
                this.setState({ loading: false, generated: false }),
                    ReactToastify.toast.info("No data for this period.");
                return;
            }
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({
                loading: false,
                generated: true,
                fileName: `Raport tranzactii suspecte SPCSB2 ${result.fileName} Moneda electronica`,
            });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create SPCSB 2 report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: this.isButtonDisabled() || this.state.loading })));
    }
    renderActionCard() {
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "SPCSB 2 Report" }),
            React.createElement(CardText, null,
                React.createElement(DateRangePicker, { startDate: this.state.startDate, endDate: this.state.endDate, disabled: this.state.loading, onStartDateChange: this.handleStartDateChange.bind(this), onEndDateChange: this.handleEndDateChange.bind(this) }),
                React.createElement("div", null,
                    React.createElement("div", { style: { marginTop: 20 } },
                        React.createElement(TextField, { id: "amount-input", onChange: (e) => this.setState({ amount: e.target.value, errors: [] }), errorText: this.findErrorText("amount"), value: this.state.amount, type: "number", disabled: this.state.loading, errorStyle: { position: "absolute", bottom: "-0.9rem" }, style: { marginRight: 20 }, floatingLabelText: "Amount" }),
                        React.createElement(TextField, { id: "operations-count", onChange: (e) => this.setState({ operationsCount: e.target.value, errors: [] }), errorText: this.findErrorText("operationsCount"), value: this.state.operationsCount, type: "number", disabled: this.state.loading, errorStyle: { position: "absolute", bottom: "-0.9rem" }, floatingLabelText: "Operations count" })),
                    React.createElement("div", { style: { marginTop: 20 } },
                        React.createElement(TextField, { id: "risk-levels", onChange: (e) => this.setState({ riskLevels: e.target.value, errors: [] }), errorText: this.findErrorText("riskLevels"), disabled: this.state.loading, value: this.state.riskLevels, errorStyle: { position: "absolute", bottom: "-0.9rem" }, style: { marginRight: 20 }, floatingLabelText: "Risk levels" }),
                        React.createElement(SelectField, { id: "report-format", hintText: "Select report format", style: { position: "absolute" }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: "Report format" }, this.renderMenuItems())))),
            this.renderLoading(),
            this.renderCardActions()));
    }
    validateReport() {
        const result = [];
        const { riskLevels, operationsCount, amount } = this.state;
        if (!riskLevels) {
            result.push({
                field: "riskLevels",
                text: "Risk levels must not be empty!",
            });
        }
        else if (riskLevels.match(/^[a-zA-Z]+$/)) {
            result.push({
                field: "riskLevels",
                text: "Try comma separated numbers!",
            });
        }
        if (!operationsCount) {
            result.push({
                field: "operationsCount",
                text: "Operations count must not be empty!",
            });
        }
        else if (operationsCount < 0) {
            result.push({
                field: "operationsCount",
                text: "Operations count can't contain negative values!",
            });
        }
        if (!amount) {
            result.push({
                field: "amount",
                text: "Amount must not be empty!",
            });
        }
        else if (amount < 0) {
            result.push({
                field: "amount",
                text: "Amount can't contain negative values!",
            });
        }
        return result;
    }
    findErrorText(field) {
        const { errors } = this.state;
        if (errors) {
            const error = errors.find((e) => e.field === field);
            return error ? error.text : "";
        }
        return "";
    }
    handleStartDateChange(e, date) {
        this.setState({ startDate: date });
    }
    handleEndDateChange(e, date) {
        this.setState({ endDate: date });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value, fileName: null });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    isButtonDisabled() {
        const { startDate, endDate } = this.state;
        return !(startDate && endDate);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(SPCSB2Report);
