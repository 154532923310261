import * as React from "react";
import { capitalizeFirstLetter, formatTime, getTableColumnAction, getTableColumnItem } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import More from "material-ui/svg-icons/navigation/more-vert";
import Finish from "material-ui/svg-icons/action/check-circle";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
import DetailsIcon from "material-ui/svg-icons/action/zoom-in";
import WalletIcon from "material-ui/svg-icons/action/account-balance-wallet";
const DataTable = require("material-ui-datatables");
class ArchiveUsersTable extends React.Component {
    constructor() {
        super(...arguments);
        this.archivesTableColumns = [
            getTableColumnItem("created", "Created", (created) => formatTime(created)),
            getTableColumnItem("createdBy", "User"),
            getTableColumnItem("inactivityDays", "Inactivity"),
            getTableColumnItem("count", "Wallets"),
            getTableColumnItem("finished", "Finished", (finished) => formatTime(finished)),
            getTableColumnItem("result", "Result", (result) => capitalizeFirstLetter(result)),
            getTableColumnAction("id", (name, archiveInfo) => this.renderIconMenu(archiveInfo)),
        ];
    }
    render() {
        return (React.createElement("div", { className: "full-size-card-wrapper" }, this.renderTable()));
    }
    renderTable() {
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.archivesTableColumns, selectable: false, data: this.props.archives, showCheckboxes: false, showHeaderToolbar: true, rowSizeList: [5, 10, 20, 40], rowSize: this.props.rowSize, count: this.props.count, page: this.props.currentPage, tableRowStyle: { cursor: "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.renderIcons }));
    }
    handleNextPageClick() {
        const page = this.props.currentPage + 1;
        this.props.reloadData(page, this.props.rowSize, this.props.sortCol, this.props.sortDir);
    }
    handlePreviousPageClick() {
        const page = this.props.currentPage - 1;
        this.props.reloadData(page, this.props.rowSize, this.props.sortCol, this.props.sortDir);
    }
    handleSortOrderChange(key, sortDir) {
        this.props.reloadData(this.props.currentPage, this.props.rowSize, key, sortDir);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.props.currentPage;
        if ((page - 1) * rowSize > this.props.count) {
            page = 1;
        }
        this.props.reloadData(page, rowSize, this.props.sortCol, this.props.sortDir);
    }
    get renderIcons() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { primary: true, label: "Create Archive", onClick: () => this.props.addArchive(null) })),
            React.createElement(IconButton, { onClick: () => this.props.reloadData(this.props.currentPage, this.props.rowSize, this.props.sortCol, this.props.sortDir) },
                React.createElement(Refresh, null)),
        ];
    }
    renderIconMenu(archive) {
        return (React.createElement(IconMenu, { iconButtonElement: React.createElement(IconButton, { id: "more-button" },
                React.createElement(More, null)) },
            React.createElement(MenuItem, { id: "details-button", leftIcon: React.createElement(DetailsIcon, null), primaryText: "Details", onClick: (e) => this.handleArchiveClick(e, archive) }),
            !archive.result && React.createElement(Link, { to: `/archive-users/${archive.id}/wallets` },
                React.createElement(MenuItem, { id: "wallet-button", leftIcon: React.createElement(WalletIcon, null), primaryText: "Wallet list" })),
            !archive.result && React.createElement(MenuItem, { id: "finish-button", leftIcon: React.createElement(Finish, null), primaryText: "Finish", onClick: () => this.props.finishArchive(archive) })));
    }
    handleArchiveClick(e, archive) {
        e.preventDefault();
        const readOnlyArchive = Object.assign(Object.assign({}, archive), { readOnly: true });
        this.props.addArchive(readOnlyArchive);
    }
}
export default ArchiveUsersTable;
