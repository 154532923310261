import * as React from "react";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import { TxType } from "../../interfaces/Transactions";
import { formatCurrency, formatPhone, formatTime, getTableColumnItem } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import { SelectField, MenuItem, DatePicker } from "material-ui";
import * as moment from "moment";
import Clear from "material-ui/svg-icons/content/clear";
import Export from "material-ui/svg-icons/content/unarchive";
const DataTable = require("material-ui-datatables");
class TransactionsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.tableColumns = [
            getTableColumnItem("time", "Time", (time, all) => React.createElement(Link, { to: `/transactions/${all.type.toLowerCase()}/${all.id}/info` }, formatTime(time))),
            getTableColumnItem("sender", "Sender", (sender) => formatPhone(sender)),
            getTableColumnItem("service", "Service"),
            getTableColumnItem("prv_txn_id", "prvTxnId"),
            getTableColumnItem("receiver", "Receiver"),
            getTableColumnItem("amount", "Amount", (amount) => formatCurrency(amount)),
            getTableColumnItem("type", "Type"),
            getTableColumnItem("state", "State"),
        ];
        this.renderMenuItems = () => {
            const txTypes = [
                "any",
                TxType.SPEND,
                TxType.SUPPLY,
                TxType.P2P,
                TxType.WITHDRAW,
                TxType.CBC
            ];
            return txTypes.map((txType) => React.createElement(MenuItem, { key: txType, insetChildren: true, value: txType, primaryText: txType }));
        };
        this.sanitize = (text) => {
            return text.toLowerCase().trim();
        };
    }
    get renderUpdateIcon() {
        return [
            React.createElement("div", { className: "date-picker-wrapper" },
                React.createElement(DatePicker, { autoOk: true, id: "filterDateFrom", formatDate: (date) => moment(date).format("DD.MM.YYYY"), maxDate: this.props.tableState.filter.endDate, value: this.props.tableState.filter.startDate, container: "inline", floatingLabelText: "Filter date from:", textFieldStyle: { width: "150px", marginLeft: '40px' }, onChange: this.handleStartDateChange.bind(this) }),
                React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => this.handleStartDateChange(null, null), disabled: !this.props.tableState.filter.startDate },
                    React.createElement(Clear, null))),
            React.createElement("div", { className: "date-picker-wrapper" },
                React.createElement(DatePicker, { autoOk: true, id: "filterDateTo", container: "inline", formatDate: (date) => moment(date).format("DD.MM.YYYY"), maxDate: moment().toDate(), minDate: this.props.tableState.filter.startDate, floatingLabelText: "Filter date to:", textFieldStyle: { width: "150px" }, value: this.props.tableState.filter.endDate, onChange: this.handleEndDateChange.bind(this) }),
                React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => this.handleEndDateChange(null, null), disabled: !this.props.tableState.filter.endDate },
                    React.createElement(Clear, null))),
            this.renderTxTypeSelect(),
            React.createElement(IconButton, { onClick: () => this.props.fetchData() },
                React.createElement(Refresh, null)),
            React.createElement(IconButton, { onClick: () => this.props.export(), title: "Export in MS Excel" },
                React.createElement(Export, null))
        ];
    }
    render() {
        return (this.renderTable());
    }
    renderTable() {
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.tableColumns, selectable: false, data: this.props.tableState.data, showCheckboxes: false, showHeaderToolbar: true, rowSizeList: [5, 10, 20, 40], rowSize: this.props.tableState.rowSize, count: this.props.tableState.count, page: this.props.tableState.currentPage, tableRowStyle: { cursor: "pointer" }, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleSearchValueChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.renderUpdateIcon }));
    }
    renderTxTypeSelect() {
        return (React.createElement(SelectField, { id: 'tx-type-select', onChange: (e, i, v) => this.handleTxTypeChange(e, i, v), value: this.props.tableState.filter.txType || 'any', autoWidth: true, style: { position: "absolute" }, floatingLabelText: "Filter type:" }, this.renderMenuItems()));
    }
    handleTxTypeChange(event, index, value) {
        this.applyFilter(Object.assign(Object.assign({}, this.props.tableState.filter), { txType: value }));
    }
    applyFilter(newFilter) {
        this.props.fetchData(0, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, newFilter);
    }
    handleStartDateChange(e, date) {
        this.applyFilter(Object.assign(Object.assign({}, this.props.tableState.filter), { startDate: date ? moment(date).startOf('day').toDate() : null }));
    }
    handleEndDateChange(e, date) {
        this.applyFilter(Object.assign(Object.assign({}, this.props.tableState.filter), { endDate: date ? moment(date).endOf('day').toDate() : null }));
    }
    handleNextPageClick() {
        const page = this.props.tableState.currentPage + 1;
        this.props.fetchData(page, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.props.tableState.filter);
    }
    handlePreviousPageClick() {
        const page = this.props.tableState.currentPage - 1;
        this.props.fetchData(page, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.props.tableState.filter);
    }
    handleSearchValueChange(search) {
        this.props.fetchData(1, this.props.tableState.rowSize, this.sanitize(search), this.props.tableState.orderKey, this.props.tableState.order, this.props.tableState.filter);
    }
    handleSortOrderChange(key, order) {
        this.props.fetchData(this.props.tableState.currentPage, this.props.tableState.rowSize, this.props.tableState.search, key, order, this.props.tableState.filter);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.props.tableState.currentPage;
        if ((page - 1) * rowSize > this.props.tableState.count) {
            page = 1;
        }
        this.props.fetchData(page, rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order, this.props.tableState.filter);
    }
}
export default TransactionsTable;
