import * as React from "react";
import { UpgradeStatus } from "../../interfaces/UpgradeRequest";
import { formatDate, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, getName, getStatusCell, } from "../../utils/DataHelpers";
import Card, { CardActions, CardText } from "material-ui/Card";
import Table, { TableBody } from "material-ui/Table";
import GridList, { GridTile } from "material-ui/GridList";
import AcceptUpgradeRequestDialog from "./AcceptUpgradeRequestDialog";
import RejectUpgradeRequestDialog from "./RejectUpgradeRequestDialog";
import RaisedButton from "material-ui/RaisedButton";
import Lightbox from "react-image-lightbox-rotate";
import "react-image-lightbox/style.css";
import Dropzone from "react-dropzone";
import Remove from "material-ui/svg-icons/content/remove";
import IconButton from "material-ui/IconButton";
import { white } from "material-ui/styles/colors";
import { isNullOrUndefined } from "util";
import DeleteIcon from "material-ui/svg-icons/navigation/close";
class UpgradeRequestProfile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            acceptDialogOpened: false,
            rejectDialogOpened: false,
            imagesLightboxOpened: false,
            imageIndex: 0,
            operatorImagePreview: "",
            operatorImageFile: null,
        };
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(AcceptUpgradeRequestDialog, { opened: this.state.acceptDialogOpened, toggleDialog: this.toggleAcceptDialog(), onAcceptBtnClick: this.props.onAcceptButtonClick.bind(this) }),
            React.createElement(RejectUpgradeRequestDialog, { opened: this.state.rejectDialogOpened, toggleDialog: this.toggleRejectDialog(), onRejectBtnClick: this.props.onRejectButtonClick.bind(this) }),
            this.renderLightBox(),
            this.renderProfileCard(),
            this.renderAdditionalDataCard()));
    }
    renderProfileCard() {
        const { userName, status, userAddress, userBirthday, userExpirationDate, userIdnp, userPassport, userSurname, walletPhone, userIssueDate, userIssuingAuthority, } = this.props.request;
        const { firstImageUrl, secondImageUrl, thirdImageUrl } = this.props;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement("div", { className: "images-list-root" },
                    this.renderDropZone(),
                    React.createElement(GridList, { style: { display: "flex", flexWrap: "nowrap", overflowX: "auto" }, cols: 2.2 },
                        this.renderOperatorGridTile(),
                        this.renderGridTile(firstImageUrl, 0),
                        this.renderGridTile(secondImageUrl, 1),
                        this.renderGridTile(thirdImageUrl, 2)))),
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRowWithLink("Wallet", formatPhone(walletPhone), `/buyer/${walletPhone}/info`),
                        getInfoTableRow("First name", getName(userName)),
                        getInfoTableRow("Last name", getName(userSurname)),
                        getInfoTableRow("Birthday", formatDate(userBirthday)),
                        getInfoTableRow("Address", getName(userAddress)),
                        getInfoTableRow("IDNP", getName(userIdnp)),
                        getInfoTableRow("Passport series and number", getName(userPassport)),
                        getInfoTableRow("Passport date of issue", formatDate(userIssueDate)),
                        getInfoTableRow("Passport expiration date", formatDate(userExpirationDate)),
                        getInfoTableRow("Passport issuing authority", getName(userIssuingAuthority)),
                        getInfoTableRow("Status", getStatusCell(status))))),
            this.renderCardActions()));
    }
    renderLightBox() {
        const { firstImageUrl, secondImageUrl, thirdImageUrl, operatorImageUrl } = this.props;
        const { imagesLightboxOpened, imageIndex, operatorImagePreview } = this.state;
        const images = [firstImageUrl, secondImageUrl, thirdImageUrl, operatorImageUrl || operatorImagePreview];
        return imagesLightboxOpened && (React.createElement(Lightbox, { mainSrc: images[imageIndex], nextSrc: images[(imageIndex + 1) % images.length], prevSrc: images[(imageIndex + images.length - 1) % images.length], onCloseRequest: this.toggleImagesLightbox(), onPreMovePrevRequest: () => this.setState({
                imageIndex: (imageIndex + images.length - 1) % images.length,
            }), onPreMoveNextRequest: () => this.setState({
                imageIndex: (imageIndex + 1) % images.length,
            }) }));
    }
    renderDropZone() {
        if (this.imageIsNotPresent()) {
            return (React.createElement("div", { onPaste: (e) => this.handlePaste(e), style: { display: "flex", flexWrap: "nowrap" } },
                React.createElement(Dropzone, { onDrop: (accepted) => this.setState({
                        operatorImagePreview: accepted[0].preview,
                        operatorImageFile: accepted[0],
                    }), disableClick: true }, ({ open }) => (React.createElement("div", { style: { textAlign: "center" } },
                    React.createElement("p", null,
                        "Drop image here ",
                        React.createElement("br", null),
                        " Or"),
                    React.createElement("p", null, "Focus and paste from clipboard using Ctrl + V"),
                    React.createElement("p", null, "Or"),
                    React.createElement("button", { type: "button", onClick: () => open() }, "Open File Dialog"))))));
        }
        else {
            return null;
        }
    }
    imageIsNotPresent() {
        const { operatorImageFile } = this.state;
        const { operatorImageUrl } = this.props;
        return isNullOrUndefined(operatorImageFile) && !operatorImageUrl;
    }
    handlePaste(e) {
        const data = e.clipboardData.items[0].getAsFile();
        if (data && data.type.split("/")[0] === "image") {
            this.setState({
                operatorImageFile: data,
                operatorImagePreview: window.URL.createObjectURL(data),
            });
        }
    }
    renderOperatorGridTile() {
        const { operatorImageUrl } = this.props;
        const { operatorImagePreview } = this.state;
        if (operatorImageUrl || operatorImagePreview) {
            return (React.createElement(GridTile, { actionPosition: "left", title: !operatorImageUrl && "Remove", style: { minWidth: 240 }, actionIcon: this.getOperatorTileIcon() },
                React.createElement("img", { src: operatorImageUrl || operatorImagePreview, onClick: () => this.setState({ imagesLightboxOpened: true, imageIndex: 3 }), className: "grid-tile-operator-img" }),
                operatorImageUrl &&
                    React.createElement(DeleteIcon, { onClick: this.props.deleteOperatorImage, style: {
                            position: "absolute",
                            top: 2,
                            left: 2,
                            zIndex: 1,
                            cursor: "pointer",
                            borderRadius: "50%",
                            background: "rgba(0, 0, 0, 0.4)",
                        }, color: "#fff" })));
        }
        else {
            return (React.createElement(GridTile, null));
        }
    }
    getOperatorTileIcon() {
        const { operatorImageUrl } = this.props;
        if (!operatorImageUrl) {
            return (React.createElement(IconButton, { onClick: () => {
                    this.setState({
                        operatorImagePreview: "",
                        operatorImageFile: null,
                    });
                    window.URL.revokeObjectURL(operatorImageUrl);
                } },
                React.createElement(Remove, { color: white })));
        }
        else {
            return undefined;
        }
    }
    renderGridTile(imageSrc, imageIndex) {
        return (React.createElement(GridTile, { style: { minWidth: 200 } },
            React.createElement("img", { src: imageSrc, className: "grid-tile-img", onClick: () => this.setState({ imagesLightboxOpened: true, imageIndex }) })));
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            this.renderUpdateButton(),
            this.renderRejectButton(),
            this.renderAcceptButton()));
    }
    renderRejectButton() {
        const { status } = this.props.request;
        if (status === UpgradeStatus.PENDING) {
            return (React.createElement(RaisedButton, { primary: true, label: "Reject", className: "info-card-raisedbutton", onClick: this.toggleRejectDialog() }));
        }
        else {
            return null;
        }
    }
    renderUpdateButton() {
        return (React.createElement(RaisedButton, { primary: true, label: "Update", className: "info-card-raisedbutton", onClick: () => this.props.onUpdateButtonClick(this.state.operatorImageFile, this.state.operatorImagePreview), disabled: this.isUpdateButtonDisabled() }));
    }
    isUpdateButtonDisabled() {
        const { operatorImageFile, operatorImagePreview } = this.state;
        const hasOperatorImageChanged = !isNullOrUndefined(operatorImageFile) && operatorImagePreview !== this.props.operatorImageUrl;
        return !(hasOperatorImageChanged);
    }
    renderAcceptButton() {
        const { status } = this.props.request;
        if (status !== UpgradeStatus.ACCEPTED) {
            return (React.createElement(RaisedButton, { secondary: true, label: "Accept", className: "info-card-raisedbutton", onClick: this.toggleAcceptDialog() }));
        }
        else {
            return null;
        }
    }
    renderAdditionalDataCard() {
        const { notes, status, whenSent, whenSolved, whenUpdated, adminPhoneNumber, reasonMessage, } = this.props.request;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("Sent", formatTime(whenSent)),
                        getInfoTableRow("Updated", formatTime(whenUpdated)),
                        status !== UpgradeStatus.PENDING
                            ? getInfoTableRow("Solved", formatTime(whenSolved))
                            : null,
                        status !== UpgradeStatus.PENDING
                            ? getInfoTableRow("Solved by", formatPhone(adminPhoneNumber))
                            : null,
                        status !== UpgradeStatus.PENDING && reasonMessage
                            ? getInfoTableRow("Reason", reasonMessage, "multiline-value-row")
                            : getName(reasonMessage),
                        status !== UpgradeStatus.PENDING && notes
                            ? getInfoTableRow("Notes", notes, "multiline-value-row")
                            : getName(notes))))));
    }
    toggleAcceptDialog() {
        return () => this.setState({ acceptDialogOpened: !this.state.acceptDialogOpened });
    }
    toggleRejectDialog() {
        return () => this.setState({ rejectDialogOpened: !this.state.rejectDialogOpened });
    }
    toggleImagesLightbox() {
        return () => this.setState({ imagesLightboxOpened: !this.state.imagesLightboxOpened });
    }
}
export default UpgradeRequestProfile;
