import * as React from "react";
import { formatPhone, getTableColumnItem } from "../../utils/DataHelpers";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import IconButton from "material-ui/IconButton";
import SmartDataTable from "../SmartDataTable";
import { connect } from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
class BusinessOwnersTable extends React.Component {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("name", "Name"),
            getTableColumnItem("surname", "Surname"),
            getTableColumnItem("phone", "Phone", (phone) => React.createElement(Link, { to: `/owners/${phone}/info` }, formatPhone(phone))),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.renderIcons }));
    }
    get renderIcons() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { primary: true, onClick: this.props.openAddDialog.bind(this), label: "ADD OWNER" })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default connect()(BusinessOwnersTable);
