import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { CircularProgress } from "material-ui";
class MainBusinessDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { dialogTitle, onCancelClick, open } = this.props;
        return (React.createElement(Dialog, { title: dialogTitle, actions: this.renderDialogActions, modal: true, contentStyle: { width: 500 }, bodyStyle: { border: "none" }, autoScrollBodyContent: true, open: open, onRequestClose: onCancelClick.bind(this) }, this.renderForm()));
    }
    get renderDialogActions() {
        const { onCancelClick, isSaving, onOkClick } = this.props;
        return [
            React.createElement(FlatButton, { label: "Discard", primary: true, onClick: onCancelClick.bind(this), disabled: isSaving }),
            React.createElement(FlatButton, { primary: true, label: "Save", onClick: onOkClick, disabled: isSaving, labelPosition: "before", icon: isSaving && React.createElement(CircularProgress, { size: 20 }) })
        ];
    }
    renderForm() {
        const { currentBusiness, isSaving, handleInputChange } = this.props;
        return (React.createElement("div", { className: "flex-col" },
            React.createElement(TextField, { id: "name-input", autoFocus: true, required: true, maxlength: "50", className: "w-full", errorText: this.findErrorText("name"), floatingLabelText: "Name", onChange: (e) => handleInputChange(e.target.value, "name"), value: currentBusiness && currentBusiness.name, disabled: isSaving }),
            React.createElement(TextField, { id: "idno-input", required: true, errorText: this.findErrorText("idno"), maxlength: "13", className: "w-full", floatingLabelText: "IDNO", onChange: (e) => handleInputChange(e.target.value, "idno"), value: currentBusiness && currentBusiness.idno, disabled: isSaving })));
    }
    findErrorText(field) {
        const { errors } = this.props;
        if (errors) {
            const error = errors.find((e) => e.field === field);
            return error ? error.text : "";
        }
        return "";
    }
}
export default MainBusinessDialog;
