import * as React from "react";
import TextField from "material-ui/TextField";
import { DataValidator } from "../../utils/DataValidator";
class POSLogoTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: "",
        };
    }
    render() {
        return (React.createElement(TextField, { floatingLabelText: "Logo Image URL", hintText: "Enter logo image URL", className: this.props.className ? this.props.className : undefined, onChange: (e) => this.onInputChange(e), errorText: this.state.errorText, fullWidth: true }));
    }
    onInputChange(e) {
        if (e.target.value) {
            DataValidator.testImage(e.target.value, 5000).then((url) => {
                this.setState({ errorText: "" });
                this.props.onUrlChange(url, "");
            }).catch((err) => {
                this.setState({ errorText: err });
                this.props.onUrlChange("", err);
            });
        }
        else {
            this.setState({ errorText: "" });
            this.props.onUrlChange("", "");
        }
    }
}
export default POSLogoTextField;
