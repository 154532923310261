import * as React from "react";
import { formatAmount, formatTime, getTableColumnItem, } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import SmartDataTable from "../SmartDataTable";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
import CircularProgress from "material-ui/CircularProgress";
import { getStatusText } from "./CashinUtils";
const ReactToastify = require("react-toastify");
class CashInBusinessOpenList extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("id", "ID", (id, row) => this.renderRoleLink(id, row.status.toLowerCase()), false),
            getTableColumnItem("companyName", "Company", (companyName) => companyName, false),
            getTableColumnItem("companyIDNO", "IDNO", (companyIDNO) => companyIDNO, false),
            getTableColumnItem("amount", "Amount", (amount) => this.setAmountCell(amount), false),
            getTableColumnItem("lastUpdate", "Date", (date) => formatTime(date), false),
            getTableColumnItem("status", "Status", (status) => getStatusText(status.toLowerCase()), false),
        ];
        this.setAmountCell = (amount) => {
            return (React.createElement("div", { style: { textAlign: "right" } }, formatAmount(amount)));
        };
        this.renderRoleLink = (lineId, status) => {
            const { roles } = this.props;
            const roleLink = roles.indexOf("Admin") !== -1;
            const requestId = lineId.substr(0, 5);
            if (status === "accepted") {
                return React.createElement(Link, { to: `/cash-in-requests/actions/${lineId}/0` }, requestId);
            }
            else {
                return React.createElement(Link, { to: `/cash-in-requests/details/${lineId}` }, requestId);
            }
        };
        this.filterRequestDataOnRole = (requestItems, roles) => {
            const isOperator = !(roles.indexOf("Admin") !== -1);
            const statusForControler = "new";
            let arr = [];
            if (isOperator) {
                arr = requestItems.filter((request) => request.status.toLowerCase() !== statusForControler);
            }
            else {
                arr = requestItems.filter((request) => request.status.toLowerCase() === statusForControler);
            }
            return requestItems;
        };
    }
    render() {
        const { roles, data, isLoading } = this.props;
        if (isLoading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        return (React.createElement(SmartDataTable, { data: this.filterRequestDataOnRole(data, roles), columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons(), showHeaderToolbarFilterIcon: false }));
    }
    componentDidMount() {
        this.props.fetchData();
    }
    checkRoles() {
        const { roles } = this.props;
        return !(roles.indexOf("Admin") !== -1);
    }
    getToolbarIcons() {
        const { roles } = this.props;
        const isCashier = roles.indexOf("Cashier") !== -1;
        return [
            React.createElement("div", { className: "add-btn-wrapper" }, isCashier && React.createElement(RaisedButton, { label: "New request", containerElement: React.createElement(Link, { to: "/cash-in-requests/new-request" }), primary: true })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default CashInBusinessOpenList;
