import * as React from "react";
import { connect } from "react-redux";
import { downloadReport, getBreadcrumbObject, getEndOfDayMillis, getStartOfDayMillis } from "../../utils/DataHelpers";
import { appendBreadcrumb } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import CardActions from "material-ui/Card/CardActions";
import { Card, CardHeader, CardText } from "material-ui/Card";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DateRangePicker from "../DateRangePicker";
import { TextField } from "material-ui";
import { DataValidator } from "../../utils/DataValidator";
const ReactToastify = require("react-toastify");
class SPCSB3Report extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            startDate: null,
            endDate: null,
            fileName: "",
            reportFormat: "xlsx",
            phone: "",
            errors: [],
            errorText: "",
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: "MS Excel", fileExt: "xlsx" },
                { name: "CSV", fileExt: "csv" },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("SPCSB 3 Report", "/reports/spcsb3/info")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        this.setState({ loading: true, generated: false });
        const query = {
            dateFrom: getStartOfDayMillis(this.state.startDate),
            dateTo: getEndOfDayMillis(this.state.endDate),
            reportType: this.state.reportFormat,
            phone: this.state.phone,
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/spcsb3`, query)
            .then((result) => {
            if (!result.fileName) {
                this.setState({ loading: false, generated: false }),
                    ReactToastify.toast.info("No data for this period.");
                return;
            }
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({
                loading: false,
                generated: true,
                fileName: `Raport tranzactii suspecte SPCSB 3 ${result.fileName} Moneda electronica`,
            });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create SPCSB 3 report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    renderCardActions(loading) {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: this.isButtonDisabled() || loading })));
    }
    renderActionCard() {
        const { startDate, endDate, loading, errorText, reportFormat } = this.state;
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "SPCSB 3 Report" }),
            React.createElement(CardText, null,
                React.createElement(DateRangePicker, { startDate: startDate, endDate: endDate, disabled: loading, onStartDateChange: this.handleStartDateChange.bind(this), onEndDateChange: this.handleEndDateChange.bind(this) }),
                React.createElement("div", null,
                    React.createElement("div", { style: { marginTop: 20 } },
                        React.createElement(TextField, { id: "wallets-phone-number", onChange: (e) => this.handleInputChange(e.target.value), errorText: errorText, disabled: loading, errorStyle: { position: "absolute", bottom: "-0.9rem" }, style: { marginRight: 20 }, floatingLabelText: "Wallet's phone number" }),
                        React.createElement(SelectField, { id: "report-format", hintText: "Select report format", style: { position: "absolute" }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: reportFormat, disabled: loading, floatingLabelText: "Report format" }, this.renderMenuItems())))),
            this.renderLoading(loading),
            this.renderCardActions(loading)));
    }
    handleInputChange(wallet) {
        DataValidator.phoneNumberValidator(wallet).then((result) => {
            this.setState({ phone: result, errorText: "" });
        }).catch((err) => {
            this.setState({ phone: "", errorText: err });
        });
    }
    handleStartDateChange(e, date) {
        this.setState({ startDate: date });
    }
    handleEndDateChange(e, date) {
        this.setState({ endDate: date });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value, fileName: null });
    }
    renderLoading(loading) {
        if (loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    isButtonDisabled() {
        const { startDate, endDate, phone } = this.state;
        return !(startDate && endDate && phone);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(SPCSB3Report);
