import * as React from "react";
import { formatAmount, formatPhone, formatTime, getName, getTableColumnItem } from "../../utils/DataHelpers";
import { Link } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import SmartDataTable from "../SmartDataTable";
import RaisedButton from "material-ui/RaisedButton";
class WithdrawalRequestsTable extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnItem("walletPhone", "Wallet", (phone, all) => React.createElement(Link, { to: `/withdrawals/${all.id}/info` }, formatPhone(phone))),
            getTableColumnItem("whenCreated", "Created", (date) => formatTime(date)),
            getTableColumnItem("amount", "Amount", (amount) => formatAmount(amount)),
            getTableColumnItem("userSurname", "Name", (surname, all) => this.formatNameAndSurname(all)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.data, columns: this.tableColumns, toolbarIconRight: this.getToolbarIcons() }));
    }
    componentDidMount() {
        this.props.fetchData();
    }
    formatNameAndSurname(all) {
        const { userName, userSurname } = all;
        const result = `${userName ? userName : ""} ${userSurname ? userSurname : ""}`;
        return getName(result);
    }
    getToolbarIcons() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { label: "Add request", onClick: this.props.openNewRequestDialog.bind(this), primary: true })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
}
export default WithdrawalRequestsTable;
