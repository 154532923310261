import * as React from "react";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import { disableCommWithThreshold, enableCommWithThreshold, setThreshold, } from "../../reducers/CommissionReducer";
class CommissionFormThresholdOption extends React.PureComponent {
    render() {
        const { thresholdEnabled, thresholdValue, thresholdErr } = this.props.state;
        const { action } = this.props;
        return (React.createElement("div", { className: "commission-form-checkbox-row-wrapper" },
            React.createElement("div", { className: "commission-form-checkbox-row" },
                React.createElement("div", { className: "commission-form-checkbox " },
                    React.createElement(Checkbox, { checked: thresholdEnabled, label: "Threshold", onCheck: (event, isChecked) => action(isChecked ? enableCommWithThreshold() : disableCommWithThreshold()) })),
                React.createElement(TextField, { id: "enter-threshold-input", hintText: "Enter threshold", value: thresholdValue, onChange: (e) => action(setThreshold(e.target.value)), errorText: thresholdErr, className: "commission-form-textfield", disabled: !thresholdEnabled }))));
    }
}
export default CommissionFormThresholdOption;
