import * as React from "react";
import { HttpClient } from "../../utils/HttpClient";
import { connect } from "react-redux";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import CircularProgress from "material-ui/CircularProgress";
import { Card, CardActions, CardText, CardTitle } from "material-ui/Card";
import * as lodash from "lodash";
import RaisedButton from "material-ui/RaisedButton";
const ReactToastify = require("react-toastify");
class P2PParams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allow: null,
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(Card, { className: "card-700" },
                React.createElement(CardTitle, { title: "Standart P2P transactions" }),
                React.createElement(CardText, null,
                    "Deny or allow P2P transactions for Wallets with MINI type.",
                    React.createElement("br", null),
                    "Currently P2P transactions for MINI Wallets are ",
                    this.renderAllowStatus()),
                React.createElement(CardActions, { className: "info-page-cardactions" }, this.renderActionBtn())));
        }
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/params/anon`).then((result) => {
            this.setState({
                loading: false,
                allow: !lodash.isEmpty(result),
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load system anonymous P2P params! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    changeAnonP2PParams(allow) {
        HttpClient.putWithData(`${this.props.baseUrl}/params/anon`, JSON.stringify({ allow })).then(() => {
            this.fetchData();
            ReactToastify.toast.info(`P2P txs for anonymous wallets were successfully ${allow ? "allowed" : "denied"}`);
        }).catch((err) => {
            ReactToastify.toast.error(`Can't ${allow ? "allow" : "deny"} P2P txs for Anonymous Wallets` +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderAllowStatus() {
        return this.state.allow ? React.createElement("span", { className: "qiwi-primary-color" }, " allowed.") :
            React.createElement("span", { className: "qiwi-secondary-color" }, " denied.");
    }
    renderActionBtn() {
        if (this.state.allow) {
            return (React.createElement(RaisedButton, { secondary: true, label: "DENY P2P", className: "info-card-raisedbutton", onClick: () => this.changeAnonP2PParams(false) }));
        }
        else {
            return (React.createElement(RaisedButton, { secondary: true, label: "ALLOW P2P", className: "info-card-raisedbutton", onClick: () => this.changeAnonP2PParams(true) }));
        }
    }
}
export default connect(mapStateToPropsBaseUrl)(P2PParams);
