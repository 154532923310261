import * as React from "react";
import DatePicker from "material-ui/DatePicker";
class CommissionFormAdvancedOptions extends React.PureComponent {
    render() {
        const { validUntil, validFrom } = this.props.state;
        const { validFromAction, validUntilAction } = this.props;
        return (React.createElement("div", { className: "commission-form-checkbox-row-wrapper" },
            React.createElement(DatePicker, { id: "valid-from-picker", value: validFrom, className: "commission-form-textfield", textFieldStyle: { width: 232.5 }, floatingLabelText: "Valid from", onChange: (e, date) => validFromAction(date) }),
            React.createElement(DatePicker, { id: "valid-until-picker", value: validUntil, style: { marginLeft: 16 }, className: "commission-form-textfield", textFieldStyle: { width: 232.5 }, floatingLabelText: "Expiration date", onChange: (e, date) => validUntilAction(date) })));
    }
}
export default CommissionFormAdvancedOptions;
