import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import { Dialog } from "material-ui";
import TextField from "material-ui/TextField";
class RejectUpgradeReqeustDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            reason: "",
        };
    }
    render() {
        return (React.createElement(Dialog, { open: this.props.opened, onRequestClose: this.props.toggleDialog, title: "Accept upgrade request", modal: false, actions: this.getDialogButtons() },
            "Are you sure you want to reject Wallet upgrade request?",
            this.renderReasonMessageEntry(),
            this.renderNotesEntry(),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("i", null,
                "Inputs marked with ",
                React.createElement("b", null, "*"),
                " are optional")));
    }
    renderNotesEntry() {
        return (React.createElement(TextField, { floatingLabelText: "Notes*", fullWidth: true, value: this.state.notes, hintText: "Displayed only for QIWI staff", onChange: (e) => this.setState({ notes: e.target.value }) }));
    }
    renderReasonMessageEntry() {
        return (React.createElement(TextField, { floatingLabelText: "Reason", fullWidth: true, value: this.state.reason, hintText: "Displayed for a user", onChange: (e) => this.setState({ reason: e.target.value }) }));
    }
    getDialogButtons() {
        const { notes, reason } = this.state;
        return [
            React.createElement(FlatButton, { primary: true, label: "cancel", onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { primary: true, label: "reject", onClick: () => {
                    this.props.toggleDialog();
                    this.props.onRejectBtnClick(notes, reason);
                }, disabled: reason === "" }),
        ];
    }
}
export default RejectUpgradeReqeustDialog;
