import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import ImageInput from "./ImageInput";
class ChangeImageDialog extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            iconUrl: "",
            iconUrlError: "",
        };
        this.state = this.initialState;
    }
    render() {
        return (React.createElement(Dialog, { title: "Add Image", actions: this.renderDialogActions(), modal: true, open: this.props.opened, onRequestClose: this.props.toggleDialog.bind(this) },
            React.createElement(ImageInput, { onUrlChange: (iconUrl, iconUrlError) => this.setState({ iconUrl, iconUrlError }) })));
    }
    componentDidUpdate(prevProps) {
        if (!this.props.opened && prevProps.opened) {
            this.setState(this.initialState);
        }
    }
    renderDialogActions() {
        return [
            React.createElement("div", { id: "image-dialog" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.toggleDialog.bind(this) }),
                React.createElement(FlatButton, { label: "Open", primary: true, onClick: () => this.props.onOpenBtnClick(this.state.iconUrl) }))
        ];
    }
}
export default ChangeImageDialog;
