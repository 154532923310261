import * as React from "react";
import BusinessesGrid from "../OwnersLandingComponents/BusinessesGrid";
class Businesses extends React.Component {
    componentDidMount() {
        this.props.fetchData();
    }
    render() {
        return (React.createElement("div", { className: "owners-businesses-admin" },
            React.createElement(BusinessesGrid, { businesses: this.props.businesses })));
    }
}
export default Businesses;
