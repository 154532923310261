import * as React from "react";
import { SelectField, MenuItem, DatePicker } from "material-ui";
import IconButton from "material-ui/IconButton";
import Clear from "material-ui/svg-icons/content/clear";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import * as moment from "moment";
import { FormControl, TextField } from "@material-ui/core";
class HeaderTable extends React.Component {
    render() {
        const { status, allStatus, filterData, refresh, amount, phone, changeInput, changeDate } = this.props;
        return (React.createElement("div", { className: "archive_header_table" },
            React.createElement("div", { className: "date-picker-wrapper" },
                React.createElement(DatePicker, { autoOk: true, id: "filterDateFrom", formatDate: (date) => moment(date).format("DD.MM.YYYY"), maxDate: filterData.endDate, value: filterData.startDate, container: "inline", floatingLabelText: "Filter date from:", textFieldStyle: { width: "150px", marginLeft: '40px' }, onChange: (e, date) => changeDate(date, "startDate") }),
                React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => changeDate(null, "startDate"), disabled: !filterData.startDate },
                    React.createElement(Clear, null))),
            React.createElement("div", { className: "date-picker-wrapper" },
                React.createElement(DatePicker, { autoOk: true, id: "filterDateTo", container: "inline", formatDate: (date) => moment(date).format("DD.MM.YYYY"), maxDate: moment().toDate(), minDate: filterData.startDate, floatingLabelText: "Filter date to:", textFieldStyle: { width: "150px" }, value: filterData.endDate, onChange: (e, date) => changeDate(date, "endDate") }),
                React.createElement(IconButton, { className: "timepicker-clear-button", onClick: () => changeDate(null, "endDate"), disabled: !filterData.endDate },
                    React.createElement(Clear, null))),
            React.createElement(FormControl, null,
                React.createElement(TextField, { id: "phone", label: "Dupa telefon", style: { marginRight: "20px", marginTop: 8 }, value: phone, onChange: (e) => changeInput(e.target.value, "phone") })),
            React.createElement(FormControl, null,
                React.createElement(TextField, { id: "amount", label: "Dupa suma", style: { marginRight: "20px", marginTop: 8 }, value: amount, onChange: (e) => changeInput(e.target.value, "amount") })),
            React.createElement(SelectField, { id: "tx-type-select", onChange: (e, i, value) => changeInput((value === "any" ? "" : value), "status"), value: status || 'any', floatingLabelText: "Dupa status", style: {
                    width: 160
                } }, allStatus.map((item, index) => {
                return (React.createElement(MenuItem, { key: index, insetChildren: true, value: item, primaryText: item }));
            })),
            React.createElement(IconButton, { onClick: refresh },
                React.createElement(Refresh, null))));
    }
}
export default HeaderTable;
