import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject } from "../utils/DataHelpers";
import { appendBreadcrumb } from "../actions/Actions";
import { HttpClient } from "../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import UpgradeRequestProfile from "../components/UpgradeRequestsComponents/UpgradeRequestProfile";
import { isNullOrUndefined } from "util";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class WalletUpgradeRequestPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            upgradeRequestData: null,
            notFound: false,
            firstImage: "",
            secondImage: "",
            thirdImage: "",
            operatorImage: "",
        };
    }
    componentDidMount() {
        this.fetchData(true);
    }
    componentWillUnmount() {
        const { firstImage, secondImage, thirdImage, operatorImage } = this.state;
        if (firstImage) {
            window.URL.revokeObjectURL(firstImage);
        }
        if (secondImage) {
            window.URL.revokeObjectURL(secondImage);
        }
        if (thirdImage) {
            window.URL.revokeObjectURL(thirdImage);
        }
        if (operatorImage) {
            window.URL.revokeObjectURL(operatorImage);
        }
    }
    render() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(UpgradeRequestProfile, { request: this.state.upgradeRequestData, onAcceptButtonClick: this.acceptWalletUpgrade.bind(this), onRejectButtonClick: this.rejectWalletUpgrade.bind(this), onUpdateButtonClick: this.handleUpgradeRequestUpdate.bind(this), operatorImageUrl: this.state.operatorImage, firstImageUrl: this.state.firstImage, secondImageUrl: this.state.secondImage, thirdImageUrl: this.state.thirdImage, deleteOperatorImage: this.deleteOperatorImage.bind(this) }));
        }
    }
    fetchData(isFirstFetch) {
        HttpClient.getJSON(`${this.props.baseUrl}/upgrade/${this.props.match.params.id}`)
            .then((result) => {
            this.setState({
                loading: false,
                upgradeRequestData: result,
            });
            if (isFirstFetch) {
                this.props.dispatch(appendBreadcrumb([
                    getBreadcrumbObject(`${result.userName} ${result.userSurname}`, `/upgrade/${this.props.match.params.id}/info`),
                ]));
                this.prepareImagesUrls();
            }
        })
            .catch((err) => {
            switch (err.status) {
                case 404:
                    this.setState({ loading: false, notFound: true });
                    ReactToastify.toast.error("Can't load Upgrade request! Service Not Found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't load Upgrade request! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
    deleteOperatorImage() {
        const { fireId } = this.state.upgradeRequestData;
        const { id } = this.props.match.params;
        const query = `?fireId=${fireId}`;
        if (id) {
            HttpClient.delete(`${this.props.baseUrl}/upgrade/${id}/operator${query}`)
                .then(() => {
                this.setState({
                    operatorImage: null,
                });
                this.fetchImage(null, null);
                ReactToastify.toast.info("Operator image was deleted successfully!");
            })
                .catch((err) => {
                ReactToastify.toast.error("Could not delete operator image! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            });
        }
    }
    prepareImagesUrls() {
        const { images, operatorImage } = this.state.upgradeRequestData;
        const keys = ["firstImage", "secondImage", "thirdImage"];
        if (operatorImage) {
            this.fetchImage(operatorImage, "operatorImage");
        }
        for (let i = 0; i < 3; i++) {
            this.fetchImage(images[i], keys[i]);
        }
    }
    fetchImage(imagePath, key) {
        const query = `?image=${imagePath}`;
        const xhr = new XMLHttpRequest();
        const context = this;
        xhr.open("GET", `${this.props.baseUrl}/image${query}`, true);
        xhr.setRequestHeader("Authorization", this.props.token);
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
            if (this.status === 200) {
                const newBlob = new Blob([this.response]);
                const newState = Object.assign({}, context.state);
                newState[key] = window.URL.createObjectURL(newBlob);
                context.setState(newState);
            }
        };
        xhr.send();
    }
    acceptWalletUpgrade() {
        const query = `?wallet=${this.state.upgradeRequestData.walletPhone}`;
        HttpClient.post(`${this.props.baseUrl}/upgrade/accept${query}`)
            .then(() => {
            ReactToastify.toast.success("Wallet upgrade request was successfully accepted!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't update request info. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.responseText));
        });
    }
    rejectWalletUpgrade(notes, reason) {
        const query = `?wallet=${this.state.upgradeRequestData.walletPhone}&notes=${notes}&reason=${reason}`;
        HttpClient.post(`${this.props.baseUrl}/upgrade/reject${query}`)
            .then(() => {
            ReactToastify.toast.info("Wallet upgrade request was successfully rejected!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't update request info. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.responseText));
        });
    }
    handleUpgradeRequestUpdate(operatorImage, preview = "") {
        if (operatorImage.size / 1024 / 1024 > 1) {
            ReactToastify.toast.error("Too large image, please try with another image smaller than 1 MB");
            return;
        }
        if (!isNullOrUndefined(operatorImage)) {
            this.sendImage(operatorImage, preview);
        }
    }
    sendImage(file, preview) {
        const { token, baseUrl } = this.props;
        const { fireId } = this.state.upgradeRequestData;
        const { id } = this.props.match.params;
        const query = `?fireId=${fireId}`;
        const url = `${baseUrl}/upgrade/${id}/operator${query}`;
        const xhr = new XMLHttpRequest();
        const context = this;
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Authorization", token);
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (preview) {
                    context.setState({ operatorImage: preview });
                }
                ReactToastify.toast.info("Wallet upgrade request was successfully updated!");
            }
        };
        xhr.send(file);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default withRouter(connect(mapStateToProps)(WalletUpgradeRequestPage));
