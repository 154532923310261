import * as React from "react";
import Card, { CardActions, CardText, CardTitle } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import Divider from "material-ui/Divider";
import { connect } from "react-redux";
import * as lodash from "lodash";
import DeleteCommissionDialog from "../components/commissionComponents/DeleteCommissionDialog";
import { HttpClient } from "../utils/HttpClient";
import { getBreadcrumbObject, getCommissionPostData, transformCommissionResponse } from "../utils/DataHelpers";
import { setCommission, setHiCheckboxRow, setHiRowCommission, setHiThresholdRowCommission, setInitialState, setLoCheckboxRow, setLoRowCommission, setLoThresholdRowCommission, setValidFrom, setValidUntil, } from "../reducers/CommissionReducer";
import CommissionFormRow from "../components/commissionComponents/CommissionFormRow";
import CommissionFormThresholdOption from "../components/commissionComponents/CommissionFormThresholdOption";
import CommissionFormAdvancedOptions from "../components/commissionComponents/CommissionFormAdvancedOptions";
import { Navigate } from "react-router";
import { appendBreadcrumb } from "../actions/Actions";
const ReactToastify = require("react-toastify");
var Perspective;
(function (Perspective) {
    Perspective["WITHDRAWAL"] = "WITHDRAWAL";
    Perspective["PROVIDER"] = "PROVIDER";
})(Perspective || (Perspective = {}));
class Commission extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            deleteDialogOpened: false,
            isNew: this.props.match.params.id === "new",
            redirect: "",
            perspective: this.props.match.params.bussId === "digital" || this.props.match.params.bussId === "normal"
                ? Perspective.WITHDRAWAL
                : Perspective.PROVIDER,
        };
        this.fetchedData = {};
        this.toggleDeleteDialog = () => this.setState({ deleteDialogOpened: !this.state.deleteDialogOpened });
        this.state = this.initialState;
        const previousBreadcrumb = (this.state.perspective === Perspective.PROVIDER)
            ? getBreadcrumbObject("Commissions", `/business/${this.props.match.params.bussId}/info/commission`)
            : getBreadcrumbObject("Withdrawal Commissions", `/system/withdrawal`);
        this.props.dispatch(appendBreadcrumb([
            previousBreadcrumb,
            getBreadcrumbObject(this.getBreadcrumbText(), `/commission/${this.props.match.params.bussId}/${this.props.match.params.id}/info`),
        ]));
    }
    componentDidMount() {
        if (!this.state.isNew) {
            this.fetchData();
        }
        else {
            this.props.dispatch(setInitialState());
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ isNew: true });
        }
    }
    render() {
        const { redirect } = this.state;
        if (redirect) {
            const to = redirect === "business"
                ? `/business/${this.props.match.params.bussId}/info/commission`
                : `/system/withdrawal`;
            return (React.createElement(Navigate, { to: to }));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(DeleteCommissionDialog, { opened: this.state.deleteDialogOpened, toggleDialog: this.toggleDeleteDialog, onDeleteBtnClick: this.deleteCommission.bind(this) }),
                React.createElement("div", { className: "business-commissions" }, this.renderCommissionCard())));
        }
    }
    fetchData() {
        HttpClient.getText(`${this.props.baseUrl}/commission/${this.props.match.params.id}`).then((result) => {
            this.fetchedData = {};
            if (result) {
                this.fetchedData = transformCommissionResponse(JSON.parse(result));
            }
            this.props.dispatch(setCommission(this.fetchedData));
            this.setState({ loading: false });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load commissions! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    addCommission() {
        const postData = JSON.stringify(getCommissionPostData(this.props.commissionFormState));
        const { perspective } = this.state;
        const url = perspective === Perspective.PROVIDER
            ? `${this.props.baseUrl}/commission/business/${this.props.match.params.bussId}`
            : `${this.props.baseUrl}/commission/withdrawal/${this.props.match.params.bussId}`;
        const redirect = perspective === Perspective.PROVIDER
            ? "business"
            : "params";
        HttpClient.post(url, postData)
            .then(() => {
            ReactToastify.toast.success("Commission was successfully added!");
            this.setState({ redirect });
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't add commission! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    deleteCommission() {
        this.toggleDeleteDialog();
        HttpClient.delete(`${this.props.baseUrl}/commission/${this.props.match.params.id}`).then(() => {
            ReactToastify.toast.info("Commission was successfully deleted!");
            this.setState({ redirect: "business" });
        }).catch((err) => {
            ReactToastify.toast.error("Can't delete commission! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderCommissionCard() {
        const { loading } = this.state;
        const { hiRow, loRow, loThresholdRow, hiThresholdRow, loCheckboxRow, hiCheckboxRow, advancedOptions } = this.props.commissionFormState;
        const { dispatch } = this.props;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(Card, { className: "card-998" },
                React.createElement(CardTitle, { title: "High", subtitle: "Charged from client" }),
                React.createElement(Divider, null),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormRow, { state: hiRow, className: "commission-form-row", action: (action) => dispatch(setHiRowCommission(action)) })),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormThresholdOption, { state: hiCheckboxRow, action: (action) => dispatch(setHiCheckboxRow(action)) })),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormRow, { state: hiThresholdRow, className: "commission-form-row", action: (action) => dispatch(setHiThresholdRowCommission(action)) })),
                React.createElement(CardTitle, { title: "Low", subtitle: "Charged from provider" }),
                React.createElement(Divider, null),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormRow, { state: loRow, className: "commission-form-row", action: (action) => dispatch(setLoRowCommission(action)) })),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormThresholdOption, { state: loCheckboxRow, action: (action) => dispatch(setLoCheckboxRow(action)) })),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormRow, { state: loThresholdRow, className: "commission-form-row", action: (action) => dispatch(setLoThresholdRowCommission(action)) })),
                React.createElement(CardTitle, { title: "Advanced options" }),
                React.createElement(Divider, null),
                React.createElement(CardText, null,
                    React.createElement(CommissionFormAdvancedOptions, { validUntilAction: (date) => dispatch(setValidUntil(date)), validFromAction: (date) => dispatch(setValidFrom(date)), state: advancedOptions })),
                this.renderCardActions()));
        }
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "commission-form-cardactions" },
            React.createElement(RaisedButton, { className: "info-card-raisedbutton", label: "delete", secondary: true, disabled: this.handleDeleteBtnState(), onClick: this.toggleDeleteDialog }),
            React.createElement(RaisedButton, { className: "info-card-raisedbutton", label: "reset", primary: true, disabled: this.handleResetBtnState(), onClick: () => this.onResetBtnClick() }),
            React.createElement(RaisedButton, { className: "info-card-raisedbutton", label: "set", primary: true, disabled: this.handleSetBtnState(), onClick: () => this.addCommission() })));
    }
    onResetBtnClick() {
        this.props.dispatch(setCommission(this.fetchedData));
    }
    handleSetBtnState() {
        const { loThresholdRow, loCheckboxRow, loRow, hiThresholdRow, hiRow, hiCheckboxRow } = this.props.commissionFormState;
        if (loThresholdRow.percentUpValueErr || loThresholdRow.percentDownValueErr || loThresholdRow.percentValueErr
            || loThresholdRow.fixedValueErr || loCheckboxRow.thresholdErr || loRow.percentValueErr
            || loRow.percentUpValueErr || loRow.percentDownValueErr || loRow.fixedValueErr
            || hiThresholdRow.percentDownValueErr || hiThresholdRow.percentUpValueErr || hiThresholdRow.percentValueErr
            || hiThresholdRow.fixedValueErr || hiCheckboxRow.thresholdErr || hiCheckboxRow.thresholdErr
            || hiRow.percentValueErr || hiRow.percentUpValueErr || hiRow.percentDownValueErr || hiRow.fixedValueErr) {
            return true;
        }
        else {
            return this.handleResetBtnState();
        }
    }
    handleResetBtnState() {
        const postData = getCommissionPostData(this.props.commissionFormState);
        if (lodash.isEmpty(this.fetchedData)) {
            return this.checkEmptyCommission(postData);
        }
        else {
            return JSON.stringify(postData) === JSON.stringify(this.fetchedData);
        }
    }
    handleDeleteBtnState() {
        if (lodash.isEmpty(this.fetchedData)) {
            return true;
        }
        return this.checkEmptyCommission(this.fetchedData);
    }
    checkEmptyCommission(commission) {
        return !(!lodash.isEmpty(commission.hiAmountBefore)
            || !lodash.isEmpty(commission.loAmountAfter)
            || !lodash.isEmpty(commission.loAmountBefore)
            || !lodash.isEmpty(commission.loAmountAfter));
    }
    getBreadcrumbText() {
        if (this.state.isNew) {
            return "New";
        }
        else {
            return "Commission [" + this.props.match.params.id.slice(0, 7) + "]";
        }
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    commissionFormState: state.commissionFormState,
});
export default connect(mapStateToProps)(Commission);
