import * as React from "react";
import { formatPhone, formatTime } from "../../utils/DataHelpers";
import Card, { CardText, CardTitle } from "material-ui/Card";
import Table, { TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from "material-ui/Table";
import { Subheader } from "material-ui";
import { Link } from "react-router-dom";
class SellerProfile extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", null, this.renderSellerInfo()));
    }
    renderSellerInfo() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false, multiSelectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false },
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "ID: "),
                                this.props.data.id),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Created: "),
                                formatTime(Number(this.props.data.created)))),
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Phone: "),
                                formatPhone(this.props.data.phone)),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Accessed: "),
                                formatTime(Number(this.props.data.accessed))))))),
            React.createElement(CardTitle, { title: `Employers` }),
            this.props.data.employers.map((employer) => this.renderEmployer(employer))));
    }
    renderEmployer(employer) {
        return (React.createElement(CardText, { key: employer.businessId },
            React.createElement(Subheader, null, employer.businessTitle),
            React.createElement(Table, { selectable: false },
                React.createElement(TableHeader, { displaySelectAll: false },
                    React.createElement(TableRow, null,
                        React.createElement(TableHeaderColumn, null, "Point Of Sale"),
                        React.createElement(TableHeaderColumn, null, "When added"))),
                React.createElement(TableBody, { selectable: false, displayRowCheckbox: false }, employer.poses.map((pos) => this.renderSellerPosRow(pos, employer.businessId))))));
    }
    renderSellerPosRow(pos, businessId) {
        return (React.createElement(TableRow, { key: pos.externalId },
            React.createElement(TableRowColumn, null,
                React.createElement(Link, { to: `/business/${businessId}/pos_info/${pos.externalId}` }, pos.posTitle)),
            React.createElement(TableRowColumn, null, formatTime(Number(pos.added)))));
    }
}
export default SellerProfile;
