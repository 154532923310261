import * as React from "react";
import Profile from "../components/BusinessInfoComponents/Profile";
import { Tab, Tabs } from "material-ui/Tabs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addBusinessInfo, setBreadcrumb } from "../actions/Actions";
import { HttpClient } from "../utils/HttpClient";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { CircularProgress } from "material-ui";
import BusinessNotFound from "../components/BusinessInfoComponents/BusinessNotFound";
import BusinessCommissions from "../components/BusinessInfoComponents/BusinessCommissions";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
var TabNames;
(function (TabNames) {
    TabNames[TabNames["Profile"] = 0] = "Profile";
    TabNames[TabNames["Poses"] = 1] = "Poses";
    TabNames[TabNames["Sellers"] = 2] = "Sellers";
    TabNames[TabNames["Reports"] = 3] = "Reports";
    TabNames[TabNames["Commission"] = 4] = "Commission";
})(TabNames || (TabNames = {}));
class BusinessInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.getCurrentComponent = () => {
            const { fetchedBusinessData } = this.state;
            const { match: { params: { tab, id } } } = this.props;
            switch (tab) {
                case "commission":
                    return React.createElement(BusinessCommissions, Object.assign({}, { id }));
                default:
                    return React.createElement(Profile, Object.assign({}, { id }, { fetchBusinessInfoData: this.fetchBusinessInfoData.bind(this), fetchedBusinessData: fetchedBusinessData }));
            }
        };
        switch (location.pathname.split("/").pop()) {
            case "pos":
                this.state = {
                    selectedTab: TabNames.Poses,
                    loading: true,
                    fetchedBusinessData: null,
                    businessNotFound: false,
                };
                break;
            case "sellers":
                this.state = {
                    selectedTab: TabNames.Sellers,
                    loading: true,
                    fetchedBusinessData: null,
                    businessNotFound: false,
                };
                break;
            case "reports":
                this.state = {
                    selectedTab: TabNames.Reports,
                    loading: true,
                    fetchedBusinessData: null,
                    businessNotFound: false,
                };
                break;
            case "commission":
                this.state = {
                    selectedTab: TabNames.Commission,
                    loading: true,
                    fetchedBusinessData: null,
                    businessNotFound: false,
                };
                break;
            default:
                this.state = {
                    selectedTab: TabNames.Profile,
                    loading: true,
                    fetchedBusinessData: null,
                    businessNotFound: false,
                };
                break;
        }
    }
    render() {
        return (React.createElement("div", null,
            this.renderTabs(),
            this.renderChildComponents()));
    }
    componentDidMount() {
        this.fetchBusinessInfoData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url && this.props.match.url === `/business/${this.props.match.params.id}/info`) {
            this.props.dispatch(setBreadcrumb([
                getBreadcrumbObject("Businesses", "/business"),
                getBreadcrumbObject(this.state.fetchedBusinessData.business.title, `/business/${this.props.match.params.id}/info`),
            ]));
        }
    }
    fetchBusinessInfoData() {
        const { baseUrl, match: { params: { id } } } = this.props;
        HttpClient.getJSON(`${baseUrl}/business/${id}`).then((result) => {
            this.props.dispatch(addBusinessInfo(result));
            this.props.dispatch(setBreadcrumb([
                getBreadcrumbObject("Businesses", "/business"),
                getBreadcrumbObject(result.business.title ? result.business.title : id, `/business/${id}/info`),
            ]));
            this.setState({ loading: false, fetchedBusinessData: result });
        }).catch((err) => {
            if (err.response.status === 404) {
                this.setState({ businessNotFound: true, loading: false });
                ReactToastify.toast.error("Business not found!");
            }
            else {
                ReactToastify.toast.error("Can't load business data! Status: " +
                    JSON.stringify(err.response.status) +
                    " Error:" + JSON.stringify(err.response.statusText));
            }
        });
    }
    renderChildComponents() {
        const { loading, businessNotFound } = this.state;
        const { match: { params: { id } } } = this.props;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else if (businessNotFound) {
            return (React.createElement(BusinessNotFound, Object.assign({}, { id })));
        }
        else {
            return (React.createElement("div", null, this.getCurrentComponent()));
        }
    }
    renderTabs() {
        const { match: { params: { id } } } = this.props;
        const pathname = `/business/${id}/info`;
        return (React.createElement("div", { className: "tabs-wrapper" },
            React.createElement(Tabs, { value: this.state.selectedTab, className: "business-info-page-tabs", onChange: (value) => this.setState({ selectedTab: value }), tabItemContainerStyle: { width: "250px" } },
                React.createElement(Tab, { label: "Profile", value: TabNames.Profile, containerElement: React.createElement(Link, { to: `${pathname}` }) }),
                React.createElement(Tab, { label: "Commissions", value: TabNames.Commission, containerElement: React.createElement(Link, { to: `${pathname}/commission` }) }))));
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(BusinessInfoPage));
