import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { setBreadcrumb } from "../actions/Actions";
import { HttpClient } from "../utils/HttpClient";
import * as lodash from "lodash";
import Card from "material-ui/Card/Card";
import CircularProgress from "material-ui/CircularProgress";
import PendingPaymentsTable from "../components/PendingPaymentComponents/PendingPaymentsTable";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class PendingPayments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            payments: [],
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Pending payments", "/pendings")]));
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        return (React.createElement(Card, { className: "card-full-size" }, this.renderDataTable()));
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/pending`).then((result) => {
            this.setState({
                loading: false,
                payments: lodash.isEmpty(result) ? [] : result.payments,
            });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load pending payments! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
            this.setState({ loading: false });
        });
    }
    renderDataTable() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(PendingPaymentsTable, { data: this.state.payments, fetchData: this.fetchData.bind(this) }));
        }
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(PendingPayments));
