import * as React from "react";
import { Table, TableBody } from "material-ui/Table";
import { formatCurrency, formatTime, getInfoTableRow, } from "../../utils/DataHelpers";
import { Card, CardText } from "material-ui/Card";
import { status } from '../../interfaces/CashByCode';
class CBCTx extends React.Component {
    render() {
        const { txData: { id_, operationType_, status_, phone_, name_, amount_, commission_, createdAt_, executedAt_, place_, partner_, atmId_ }, showAll } = this.props;
        const created = new Date(createdAt_).getTime();
        const exucated = new Date(executedAt_).getTime();
        const allStatus = Object.values(status);
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("ID", id_),
                        getInfoTableRow("Tip operatie", operationType_),
                        getInfoTableRow("Status", (allStatus[status_])),
                        getInfoTableRow("Cont sursa", phone_),
                        getInfoTableRow("Nume destinatar", name_),
                        getInfoTableRow("Suma", formatCurrency(amount_)),
                        getInfoTableRow("Comission", commission_),
                        getInfoTableRow("Creat", formatTime(created)),
                        status_ ? getInfoTableRow("Executat", formatTime(exucated)) : null,
                        showAll && (React.createElement(React.Fragment, null,
                            getInfoTableRow("Partner", partner_),
                            getInfoTableRow("ATM ID", atmId_))),
                        getInfoTableRow("Loc", place_))))));
    }
}
export default CBCTx;
