import * as React from "react";
import { Table, TableBody } from "material-ui/Table";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, } from "../../utils/DataHelpers";
import { Card, CardText } from "material-ui/Card";
class SupplyTx extends React.Component {
    render() {
        const { txnId, buyer, amount, finished, id, supplyType } = this.props.txData;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("ID", id),
                        this.getBuyer("Buyer", buyer),
                        getInfoTableRow("Finished", formatTime(finished)),
                        getInfoTableRow("Amount", formatCurrency(amount)),
                        getInfoTableRow("Supply Type", supplyType),
                        getInfoTableRow("Txn ID", txnId))))));
    }
    getBuyer(keyText, keyValue) {
        return getInfoTableRowWithLink(keyText, formatPhone(keyValue), `/buyer/${keyValue}/info`);
    }
}
export default SupplyTx;
