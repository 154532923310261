import * as React from "react";
import { NavLink } from "react-router-dom";
import AppBar from "material-ui/AppBar";
import { connect } from "react-redux";
class ContentAppBar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(AppBar, { title: this.renderBreadcrumbs(), showMenuIconButton: false, className: this.props.className }));
    }
    renderBreadcrumbs() {
        return (React.createElement("div", null, this.props.breadcrumbs.map((crumb) => this.renderBreadCrumb(crumb.label, crumb.path))));
    }
    renderBreadCrumb(title, path) {
        return (React.createElement(NavLink, { to: path, className: "breadcrumb bread-1", key: path }, title));
    }
}
const mapStateToProps = (state) => ({
    breadcrumbs: state.currentBreadcrumbs,
});
export default connect(mapStateToProps)(ContentAppBar);
