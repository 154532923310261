import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import App from "./containers/AdminApp";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import reducer from "./reducers/Reducer";
import { setBaseUrl, setFirebaseConfiguration } from "./actions/Actions";
import { HttpClient } from "./utils/HttpClient";
import "normalize.css";
import getMuiTheme from "material-ui/styles/getMuiTheme";
const store = createStore(reducer);
HttpClient.getJSON("../config.json").then((result) => {
    store.dispatch(setBaseUrl(result.baseAdminUrl));
    store.dispatch(setFirebaseConfiguration(result.firebaseConfigurations));
});
const qiwiMuiTheme = getMuiTheme({
    palette: {
        primary1Color: "#FF8C00",
        primary2Color: "#FF9C00",
        accent1Color: "#031CCA",
    },
    datePicker: {
        headerColor: "#FF8C00",
    },
    timePicker: {
        headerColor: "#FF8C00",
    },
});
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(MuiThemeProvider, { muiTheme: qiwiMuiTheme },
        React.createElement(BrowserRouter, null,
            React.createElement(App, null)))), document.getElementById("root"));
