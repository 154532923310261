import * as React from "react";
import { HttpClient } from "../utils/HttpClient";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import CircularProgress from "material-ui/CircularProgress";
import SellerNotFount from "../components/SellerComponents/SellerNotFound";
import SellerProfile from "../components/SellerComponents/SellerProfile";
import { appendBreadcrumb } from "../actions/Actions";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class SellerInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            sellerNotFound: false,
        };
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject(this.props.match.params.id, `/seller/${this.props.match.params.id}/info`),
        ]));
    }
    componentDidMount() {
        this.fetchData();
    }
    render() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else if (this.state.sellerNotFound) {
            return (React.createElement(SellerNotFount, { phone: this.props.match.params.id }));
        }
        else {
            return (React.createElement(SellerProfile, { data: this.state.data }));
        }
    }
    fetchData() {
        HttpClient.getJSON(`${this.props.baseUrl}/seller/${this.props.match.params.id}`)
            .then((result) => {
            this.setState({ loading: false, data: result });
        })
            .catch((err) => {
            switch (err.response.status) {
                case 404:
                    ReactToastify.toast.error("Seller not found!");
                    this.setState({ loading: false, sellerNotFound: true });
                    break;
                default:
                    ReactToastify.toast.error("Can't load seller detailed data. Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(SellerInfoPage));
