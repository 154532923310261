import * as React from "react";
import Card, { CardActions, CardText, CardTitle } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
class OwnerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.owner.name,
            surname: this.props.owner.surname,
        };
    }
    render() {
        return this.renderInfoCard();
    }
    renderInfoCard() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardTitle, { title: this.props.owner.phone }),
            React.createElement(CardText, null, this.renderForm()),
            this.renderCardActions()));
    }
    renderForm() {
        return (React.createElement("div", null,
            React.createElement(TextField, { className: "owner-info-textfield", floatingLabelText: "Name", value: this.state.name, onChange: (e) => this.setState({ name: e.target.value }), errorText: this.state.name ? "" : "Name can not be empty" }),
            React.createElement(TextField, { className: "owner-info-textfield", floatingLabelText: "Surname", value: this.state.surname, onChange: (e) => this.setState({ surname: e.target.value }), errorText: this.state.surname ? "" : "Surname can not be empty" })));
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, label: "Reset", onClick: () => this.setState({
                    name: this.props.owner.name,
                    surname: this.props.owner.surname,
                }), disabled: this.getResetBtnState(), className: "info-card-raisedbutton" }),
            React.createElement(RaisedButton, { primary: true, disabled: this.getUpdateBtnState(), label: "Update", onClick: () => this.props.onUpdateBtnClick(this.state.name, this.state.surname), className: "info-card-raisedbutton" })));
    }
    getResetBtnState() {
        return !(this.state.name !== this.props.owner.name || this.state.surname !== this.props.owner.surname);
    }
    getUpdateBtnState() {
        if (this.state.surname !== "" && this.state.name !== "") {
            return this.getResetBtnState();
        }
        else {
            return true;
        }
    }
}
export default OwnerProfile;
