import * as React from "react";
import { getTableColumnAction, getTableColumnItem } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import More from "material-ui/svg-icons/navigation/more-vert";
import Delete from "material-ui/svg-icons/action/delete";
import Edit from "material-ui/svg-icons/editor/mode-edit";
import SmartDataTable from "../SmartDataTable";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import { Link } from "react-router-dom";
class MerchantsTable extends React.Component {
    constructor() {
        super(...arguments);
        this.merchantsTableColumns = [
            getTableColumnItem("logo", "Logo", (logoUrl) => React.createElement("img", { alt: "", style: { width: 40, height: 40 }, src: logoUrl })),
            getTableColumnItem("title", "Title", (name, merchantInfo) => React.createElement(Link, { to: "", onClick: (e) => (this.handleMerchantClick(e, merchantInfo)) }, name)),
            getTableColumnItem("alias", "Alias"),
            getTableColumnItem("serviceName", "Service", (name, all) => React.createElement(Link, { to: `/services/${all.serviceId}/info` }, name)),
            getTableColumnItem("active", "Status", (isActive) => (isActive ? "Active" : "Inactive")),
            getTableColumnAction("id", (name, merchantInfo) => this.renderIconMenu(merchantInfo)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.merchants, columns: this.merchantsTableColumns, toolbarIconRight: this.renderIcons }));
    }
    get renderIcons() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { primary: true, label: "New Merchant", onClick: () => this.props.updateMerchant(null) })),
            React.createElement(IconButton, { onClick: () => this.props.reloadData() },
                React.createElement(Refresh, null)),
        ];
    }
    renderIconMenu(merchant) {
        return (React.createElement(IconMenu, { iconButtonElement: React.createElement(IconButton, null,
                React.createElement(More, null)) },
            React.createElement(MenuItem, { leftIcon: React.createElement(Edit, null), primaryText: "Edit", onClick: () => this.props.updateMerchant(merchant) }),
            React.createElement(MenuItem, { leftIcon: React.createElement(Delete, null), primaryText: "Delete", onClick: () => this.props.deleteMerchant(merchant) })));
    }
    handleMerchantClick(e, merchant) {
        e.preventDefault();
        const readOnlyMerchant = Object.assign(Object.assign({}, merchant), { readOnly: true });
        this.props.updateMerchant(readOnlyMerchant);
    }
}
export default MerchantsTable;
