import * as React from "react";
import { connect } from "react-redux";
import { getBreadcrumbObject, mapStateToPropsBaseUrl } from "../utils/DataHelpers";
import { HttpClient } from "../utils/HttpClient";
import { appendBreadcrumb } from "../actions/Actions";
import CircularProgress from "material-ui/CircularProgress";
import PendingPaymentProfile from "../components/PendingPaymentComponents/PendingPaymentProfile";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
class PendingPaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            payment: null,
        };
    }
    componentDidMount() {
        this.fetchData(true);
    }
    render() {
        const { loading, payment } = this.state;
        if (loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(PendingPaymentProfile, { data: payment, finishPendingPayment: this.finishPayment.bind(this), rejectPendingPayment: this.rejectPayment.bind(this) }));
        }
    }
    fetchData(isFirst) {
        const { baseUrl, match } = this.props;
        HttpClient.getJSON(`${baseUrl}/pending/${match.params.id}`)
            .then((result) => {
            this.setState({
                loading: false,
                payment: result,
            });
            if (isFirst) {
                this.props.dispatch(appendBreadcrumb([
                    getBreadcrumbObject(`${result.walletPhone}`, `/pendings/${match.params.id}/info`),
                ]));
            }
        })
            .catch((err) => {
            switch (err.status) {
                case 404:
                    ReactToastify.toast.error("Can't load Pending payment! Request Not Found!");
                    break;
                default:
                    ReactToastify.toast.error("Can't load Pending payment! Status: " +
                        JSON.stringify(err.response.status) +
                        " Error:" + JSON.stringify(err.response.statusText));
                    break;
            }
        });
    }
    finishPayment() {
        const { baseUrl, match } = this.props;
        HttpClient.put(`${baseUrl}/pending/${match.params.id}/finish`)
            .then(() => {
            ReactToastify.toast.success("Payment was successfully accepted!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't finish payment. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
    rejectPayment() {
        const { baseUrl, match } = this.props;
        HttpClient.put(`${baseUrl}/pending/${match.params.id}/reject`)
            .then(() => {
            ReactToastify.toast.info("Payment was rejected!");
            this.fetchData(false);
        })
            .catch((err) => {
            ReactToastify.toast.error("Can't reject request. Status: " +
                JSON.stringify(err.response.status) + " Error:" + JSON.stringify(err.response.text));
        });
    }
}
export default withRouter(connect(mapStateToPropsBaseUrl)(PendingPaymentPage));
