import * as React from "react";
import { connect } from "react-redux";
import { downloadReport, getBreadcrumbObject, getEndOfDayMillis, getStartOfDayMillis } from "../../utils/DataHelpers";
import { appendBreadcrumb } from "../../actions/Actions";
import { HttpClient } from "../../utils/HttpClient";
import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import CardActions from "material-ui/Card/CardActions";
import { Card, CardHeader, CardText } from "material-ui/Card";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DateRangePicker from "../DateRangePicker";
const ReactToastify = require("react-toastify");
class AccountsPeriodPayments extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loading: false,
            generated: false,
            startDate: null,
            endDate: null,
            fileName: "",
            reportFormat: "xlsx"
        };
        this.renderMenuItems = () => {
            const reportFormats = [
                { name: 'MS Excel', fileExt: 'xlsx' },
                { name: 'PDF', fileExt: 'pdf' },
            ];
            return reportFormats.map((format) => React.createElement(MenuItem, { key: format.fileExt, insetChildren: true, value: format.fileExt, primaryText: format.name }));
        };
        this.state = this.initialState;
        this.props.dispatch(appendBreadcrumb([getBreadcrumbObject("Accounts Period Payments", "/reports/payments/info")]));
    }
    render() {
        return (React.createElement("div", null, this.renderActionCard()));
    }
    generatePeriodPaymentsReport() {
        this.setState({ loading: true, generated: false });
        const query = {
            start: getStartOfDayMillis(this.state.startDate),
            end: getEndOfDayMillis(this.state.endDate),
            reportFormat: this.state.reportFormat
        };
        HttpClient.getJSON(`${this.props.baseUrl}/reports/generate/payments`, query)
            .then((result) => {
            ReactToastify.toast.info("Report was successfully created.");
            this.setState({ loading: false, generated: true });
            this.openReport(result.fileName);
        })
            .catch((err) => {
            this.setState({ loading: false, generated: false });
            ReactToastify.toast.error("Could not create accounts period payments report. Status: " +
                JSON.stringify(err.response.status));
        });
    }
    openReport(fileName) {
        downloadReport(fileName, this.state.reportFormat, this.props.token, this.props.baseUrl);
    }
    renderCardActions() {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { label: "GENERATE", primary: true, className: "info-card-raisedbutton", onClick: () => this.generatePeriodPaymentsReport(), disabled: this.isButtonDisabled() || this.state.loading })));
    }
    renderActionCard() {
        return (React.createElement(Card, { className: "card-800" },
            React.createElement(CardHeader, { title: "Accounts Period Payments Report" }),
            React.createElement(CardText, null,
                "Generate accounts payments report for a inclusive period where will be displayed summary transaction data.",
                React.createElement(DateRangePicker, { startDate: this.state.startDate, endDate: this.state.endDate, disabled: this.state.loading, onStartDateChange: this.handleStartDateChange.bind(this), onEndDateChange: this.handleEndDateChange.bind(this) }),
                React.createElement(SelectField, { id: 'report-format', hintText: "Select report format", style: { position: 'absolute' }, onChange: (e, i, v) => this.handleReportFormatChange(e, i, v), value: this.state.reportFormat, disabled: this.state.loading, floatingLabelText: 'Report format' }, this.renderMenuItems())),
            this.renderLoading(),
            this.renderCardActions()));
    }
    handleStartDateChange(e, date) {
        this.setState({ startDate: date });
    }
    handleEndDateChange(e, date) {
        this.setState({ endDate: date });
    }
    handleReportFormatChange(event, index, value) {
        this.setState({ reportFormat: value, fileName: null });
    }
    renderLoading() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 30, thickness: 4, style: {
                    width: "30px",
                    margin: "auto",
                    display: "block",
                } }));
        }
    }
    isButtonDisabled() {
        const { startDate, endDate } = this.state;
        return !(startDate && endDate);
    }
}
const mapStateToProps = (state) => ({
    baseUrl: state.baseUrl,
    token: state.token,
});
export default connect(mapStateToProps)(AccountsPeriodPayments);
