import * as React from "react";
import { formatCurrency, formatPhone, formatTime, getInfoTableRow, getInfoTableRowWithLink, } from "../../utils/DataHelpers";
import Table, { TableBody } from "material-ui/Table";
import { Card, CardText } from "material-ui/Card";
class WithdrawalTx extends React.Component {
    render() {
        const { whenCreated, id, amount, balanceAfter, balanceBefore, commHi, operatorPhone, requestId, requestNumber, walletPhone } = this.props.txData;
        return (React.createElement(Card, { className: "card-700" },
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false, showRowHover: false },
                        getInfoTableRow("ID", id),
                        getInfoTableRowWithLink("Wallet", formatPhone(walletPhone), `/buyer/${walletPhone}/info`),
                        getInfoTableRow("Amount", formatCurrency(amount)),
                        getInfoTableRow("Charged amount", formatCurrency(amount + commHi)),
                        getInfoTableRow("Hi commission", formatCurrency(commHi)),
                        getInfoTableRow("Balance before", formatCurrency(balanceBefore)),
                        getInfoTableRow("Balance after", formatCurrency(balanceAfter)),
                        getInfoTableRow("Created", formatTime(whenCreated)),
                        getInfoTableRow("Operator", formatPhone(operatorPhone)),
                        getInfoTableRowWithLink("Withdrawal request", requestNumber, `/withdrawals/${requestId}/info`))))));
    }
}
export default WithdrawalTx;
