import * as React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import { formatCurrency } from "../../utils/DataHelpers";
class AcceptWithdrawalDialog extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.dialogActions = [
            React.createElement(FlatButton, { primary: true, label: "cancel", onClick: this.props.toggleDialog.bind(this) }),
            React.createElement(FlatButton, { primary: true, label: "accept", onClick: () => {
                    this.props.toggleDialog();
                    this.props.onAcceptBtnClick();
                } }),
        ];
    }
    render() {
        const { amount, opened, toggleDialog } = this.props;
        return (React.createElement(Dialog, { open: opened, onRequestClose: toggleDialog, title: "Accept withdrawal", modal: false, actions: this.dialogActions },
            "Are you sure you want to confirm Withdrawal in amount of ",
            React.createElement("b", null, formatCurrency(amount)),
            "?"));
    }
}
export default AcceptWithdrawalDialog;
