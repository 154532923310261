import * as React from "react";
import { formatCurrency, formatPhone, formatTime, getTableColumnItem, getTableColumnLogo, } from "../../utils/DataHelpers";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import IconButton from "material-ui/IconButton";
import { isNullOrUndefined } from "util";
import { Link } from "react-router-dom";
import Lock from "material-ui/svg-icons/action/lock";
import LockOpen from "material-ui/svg-icons/action/lock-open";
import { grey500 } from "material-ui/styles/colors";
import { BuyerType } from "../../interfaces/Buyer";
import Star from "material-ui/svg-icons/toggle/star";
import HalfStar from "material-ui/svg-icons/toggle/star-half";
const DataTable = require("material-ui-datatables");
class BuyersTable extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.tableColumns = [
            getTableColumnLogo("photoUrl"),
            getTableColumnItem("phone", "Phone", (phone, all) => this.getBuyerPhoneColumn(phone, all)),
            getTableColumnItem("blocked", "Status", (blocked) => blocked ? React.createElement(Lock, { className: "qiwi-secondary-color" }) : React.createElement(LockOpen, { color: grey500 })),
            getTableColumnItem("surname", "Name Surname", (surname, all) => this.getNameSurnameText(all.name, surname)),
            getTableColumnItem("idnp", "IDNP"),
            getTableColumnItem("balance", "Balance", (balance) => formatCurrency(balance)),
            getTableColumnItem("created", "Created", (created) => formatTime(created)),
            getTableColumnItem("accessed", "Accessed", (accessed) => formatTime(accessed)),
        ];
    }
    get renderIcons() {
        return (React.createElement(IconButton, { onClick: () => this.props.fetchData() },
            React.createElement(Refresh, null)));
    }
    render() {
        return (this.renderTable());
    }
    renderTable() {
        return (React.createElement(DataTable.DataTables, { tableStyle: { tableLayout: "auto" }, height: "auto", showRowHover: true, columns: this.tableColumns, data: this.props.tableState.data, showCheckboxes: false, showHeaderToolbar: true, selectable: false, rowSizeList: [5, 10, 20, 40], rowSize: this.props.tableState.rowSize, count: this.props.tableState.count, page: this.props.tableState.currentPage, onNextPageClick: this.handleNextPageClick.bind(this), onPreviousPageClick: this.handlePreviousPageClick.bind(this), onFilterValueChange: this.handleSearchValueChange.bind(this), onSortOrderChange: this.handleSortOrderChange.bind(this), onRowSizeChange: this.handleRowSizeChange.bind(this), toolbarIconRight: this.renderIcons }));
    }
    handleNextPageClick() {
        const page = this.props.tableState.currentPage + 1;
        this.props.fetchData(page, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order);
    }
    handlePreviousPageClick() {
        const page = this.props.tableState.currentPage - 1;
        this.props.fetchData(page, this.props.tableState.rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order);
    }
    handleSearchValueChange(search) {
        this.props.fetchData(1, this.props.tableState.rowSize, search, this.props.tableState.orderKey, this.props.tableState.order);
    }
    handleSortOrderChange(key, order) {
        let sortKey = key;
        if (sortKey === "accessed" || sortKey === "created") {
            sortKey += "_at";
        }
        this.props.fetchData(this.props.tableState.currentPage, this.props.tableState.rowSize, this.props.tableState.search, sortKey, order);
    }
    handleRowSizeChange(index, rowSize) {
        let page = this.props.tableState.currentPage;
        if ((page - 1) * rowSize > this.props.tableState.count) {
            page = 1;
        }
        this.props.fetchData(page, rowSize, this.props.tableState.search, this.props.tableState.orderKey, this.props.tableState.order);
    }
    getNameSurnameText(name, surname) {
        let result = "";
        if (!isNullOrUndefined(name)) {
            result += name + " ";
        }
        if (!isNullOrUndefined(surname)) {
            result += surname;
        }
        return result;
    }
    getBuyerPhoneColumn(phone, all) {
        return (React.createElement(Link, { to: `../buyer/${phone}/info` },
            all.type === BuyerType.NORMAL
                ? React.createElement(Star, { className: "normal-buyer-type-star" })
                : all.type === BuyerType.DIGITAL
                    ? React.createElement(HalfStar, { className: "normal-buyer-type-star" })
                    : null,
            formatPhone(phone)));
    }
}
export default BuyersTable;
