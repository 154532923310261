import * as React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import * as isEmail from "validator/lib/isEmail";
class AddEmailDialog extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            value: "",
            buttonDisabled: true,
            errorText: "",
        };
        this.state = this.initialState;
    }
    render() {
        return (this.renderAddEmailDialog());
    }
    emailValidation(value) {
        if (isEmail(value)) {
            this.setState({
                errorText: "",
                value,
                buttonDisabled: false,
            });
        }
        else {
            this.setState({
                errorText: "Input valid email",
                value,
                buttonDisabled: true,
            });
        }
    }
    renderAddEmailDialog() {
        return (React.createElement(Dialog, { title: "Add new Email", actions: this.renderDialogActions, modal: true, open: this.props.dialogOpened, onRequestClose: this.props.closeModal.bind(this) }, this.renderTextField()));
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "email-dialog-actions" },
                React.createElement(FlatButton, { label: "Cancel", primary: true, onClick: this.props.closeModal.bind(this) }),
                React.createElement(FlatButton, { label: "Accept", primary: true, disabled: this.state.buttonDisabled, onClick: !this.state.buttonDisabled ? () => {
                        this.props.addEmail(this.state.value);
                        this.setState(this.initialState);
                    } : () => false }))
        ];
    }
    renderTextField() {
        return (React.createElement(TextField, { autoFocus: true, floatingLabelText: "Input new email", fullWidth: true, value: this.state.value, errorText: this.state.errorText, onChange: (e) => this.emailValidation(e.target.value) }));
    }
}
export default AddEmailDialog;
