import * as React from "react";
import * as firebase from "firebase";
import { AuthService } from "../utils/AuthService";
import Header from "../components/AppComponents/Header";
import * as $ from "jquery";
import { faviconCode } from "../configs/faviconBase64";
import { connect } from "react-redux";
import { mapStateToPropsFirebase } from "../utils/DataHelpers";
import ContentAppBar from "../components/ContentAppBar";
import { setToken } from "../actions/Actions";
import * as jwtDecode from "jwt-decode";
import { RoutesApp } from "../routes/Routes";
import { withRouter } from '../utils/HOC/withRouter';
const ReactToastify = require("react-toastify");
const Favicon = require("react-favicon");
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            roles: [],
        };
    }
    get renderPage() {
        return this.state.isAuthenticated
            ? React.createElement("div", { className: "main" },
                React.createElement(RoutesApp, { roles: this.state.roles }))
            : React.createElement("div", { id: "firebaseui-auth-container" });
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Favicon, { url: faviconCode }),
            React.createElement(ReactToastify.ToastContainer, { position: "bottom-right" }),
            this.renderHeader(),
            this.renderContentAppbar(),
            this.renderPage));
    }
    componentDidUpdate(prevProps) {
        const { firebaseConfiguration, firebaseConfiguration: { fbUi, uiConfig } } = this.props;
        if (prevProps.firebaseConfiguration !== firebaseConfiguration) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    this.verifyPermission();
                }
                else {
                    fbUi.start("#firebaseui-auth-container", uiConfig);
                }
            });
        }
    }
    renderHeader() {
        const { isAuthenticated, roles } = this.state;
        if (isAuthenticated) {
            return (React.createElement(Header, { roles: roles, onLogoutClick: () => this.signOut() }));
        }
        else {
            return null;
        }
    }
    renderContentAppbar() {
        if (this.state.isAuthenticated) {
            return (React.createElement(ContentAppBar, Object.assign({}, this.props, { className: "info-page-appbar" })));
        }
        else {
            return null;
        }
    }
    signOut() {
        const { firebaseConfiguration: { fb } } = this.props;
        fb.auth().signOut().then(() => {
            this.setState({ isAuthenticated: false });
            history.pushState(null, null, "/");
        }, () => {
            ReactToastify.toast.error("Firebase signOut error, try again!");
        });
    }
    verifyPermission() {
        const { firebaseConfiguration: { fb } } = this.props;
        fb.auth().currentUser.getIdToken(true)
            .then((idToken) => {
            AuthService.getToken(`${this.props.baseUrl}/token`, (data) => {
                const response = JSON.parse(data);
                this.props.dispatch(setToken(response.token));
                $.ajaxSetup({ headers: { Authorization: response.token } });
                this.setState({ isAuthenticated: true, roles: jwtDecode(response.token).roles });
            }, (err) => {
                ReactToastify.toast.error("Server error :( ! Error:" + err.status);
                this.signOut();
            }, idToken);
        })
            .catch((err) => {
            ReactToastify.toast.error("Firebase server error :( ! \n" + err.message);
            this.signOut();
        });
    }
}
export default withRouter(connect(mapStateToPropsFirebase)(App));
