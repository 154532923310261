import * as React from "react";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import { isNullOrUndefined } from "util";
import RaisedButton from "material-ui/RaisedButton";
import POSValidTimeTextField from "./POSValidTimeTextField";
import POSTypeSelectField from "./POSTypeSelectField";
import { defaultLogoUrl, POSType } from "../../variables/Common";
import AddPOSLogoDialog from "./AddPOSLogoDialog";
import AddPhoto from "material-ui/svg-icons/image/add-a-photo";
export default class POSProfile extends React.Component {
    constructor(props) {
        super(props);
        this.fetchedData = {
            posType: this.getPosType(),
            validTime: this.getValidTime(),
            validTimeError: false,
            iconUrl: "",
            addLogoDialogOpened: false,
        };
        this.state = this.fetchedData;
    }
    render() {
        return (React.createElement("div", null,
            this.renderProfileCard(),
            this.renderPOSTypeEditCard(),
            React.createElement(AddPOSLogoDialog, { opened: this.state.addLogoDialogOpened, toggleDialog: this.toggleAddLogoDialog(), onOpenBtnClick: (iconUrl) => this.setState({
                    iconUrl,
                    addLogoDialogOpened: false,
                }) })));
    }
    componentWillReceiveProps(nextProps) {
        if (this.fetchedData.validTime !== nextProps.profileData.validTime
            || this.fetchedData.posType !== nextProps.profileData.type) {
            this.fetchedData.posType = nextProps.profileData.type;
            this.fetchedData.validTime = nextProps.profileData.validTime;
            this.setState(this.fetchedData);
        }
    }
    renderProfileCard() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardHeader, { title: this.props.profileData.title, avatar: React.createElement("img", { className: "table-image", src: this.props.profileData.iconUrl ? this.props.profileData.iconUrl : defaultLogoUrl, onError: (e) => e.target.src = defaultLogoUrl }) }),
            React.createElement(CardText, null,
                React.createElement(Table, { selectable: false, multiSelectable: false },
                    React.createElement(TableBody, { displayRowCheckbox: false },
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "Address: "),
                                this.props.profileData.address),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "MCC: "),
                                this.props.profileData.mcc)),
                        React.createElement(TableRow, null,
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "IDNO: "),
                                this.props.profileData.idno),
                            React.createElement(TableRowColumn, { className: "info-static-card-column" },
                                React.createElement("span", { className: "info-static-card-column-span" }, "External ID: "),
                                this.props.profileData.externalId))))),
            React.createElement(CardText, null,
                this.renderChangeLogoBtn(),
                this.renderChosenLogo()),
            this.renderCardActions(() => this.setState({ iconUrl: this.fetchedData.iconUrl }), this.getEditResetButtonState(), () => this.props.updatePos(this.state.iconUrl), this.getEditResetButtonState())));
    }
    renderPOSTypeEditCard() {
        return (React.createElement(Card, { className: "info-page-card" },
            React.createElement(CardText, null,
                React.createElement(POSTypeSelectField, { className: "pos-edit-card-select", type: this.state.posType, setPosType: (posType) => this.handlePOSTypeChange(posType) }),
                React.createElement(POSValidTimeTextField, { className: "pos-edit-card-time-input", posType: this.state.posType, setValidTime: (validTime) => this.setState({ validTime }), setError: (value) => this.setState({ validTimeError: value }), validTime: this.state.validTime })),
            this.renderCardActions(() => this.setState(this.fetchedData), this.getPOSTypeResetButtonState(), () => this.props.updatePosType(this.state.posType, this.state.validTime), this.getPOSTypeUpdateButtonState())));
    }
    renderCardActions(cancelBtnOnClick, cancelBtnDisabled, updBtnOnCLick, updBtnDisabled) {
        return (React.createElement(CardActions, { className: "info-page-cardactions" },
            React.createElement(RaisedButton, { primary: true, key: "pos-info-cancel-btn", label: "RESET", className: "info-card-raisedbutton", onClick: cancelBtnOnClick, disabled: cancelBtnDisabled }),
            React.createElement(RaisedButton, { primary: true, key: "pos-info-update-btn", label: "UPDATE", className: "info-card-raisedbutton", onClick: updBtnOnCLick, disabled: updBtnDisabled })));
    }
    renderChosenLogo() {
        if (this.state.iconUrl) {
            return React.createElement("img", { src: this.state.iconUrl, className: "pos-chosen-logo table-image" });
        }
        else {
            return null;
        }
    }
    renderChangeLogoBtn() {
        if (this.state.iconUrl) {
            return React.createElement("span", { className: "info-static-card-column-span info-static-card-column" }, "Chosen logo: ");
        }
        else {
            return (React.createElement(RaisedButton, { icon: React.createElement(AddPhoto, null), label: "CHANGE LOGO", onClick: this.toggleAddLogoDialog(), primary: true }));
        }
    }
    handlePOSTypeChange(posType) {
        posType === POSType.DEFAULT ? this.setState({ posType, validTime: "" }) : this.setState({
            posType,
            validTime: this.fetchedData.validTime,
        });
    }
    getPosType() {
        return isNullOrUndefined(this.props.profileData.type) ? POSType.DEFAULT : this.props.profileData.type;
    }
    getValidTime() {
        return isNullOrUndefined(this.props.profileData.validTime) ? "" : (Math.floor(Number(this.props.profileData.validTime) / 60)).toString();
    }
    getPOSTypeResetButtonState() {
        return !(this.state.posType !== this.fetchedData.posType
            || this.state.validTime !== this.fetchedData.validTime);
    }
    getPOSTypeUpdateButtonState() {
        return this.state.validTimeError ? true : this.getPOSTypeResetButtonState();
    }
    getEditResetButtonState() {
        return !(this.state.iconUrl !== this.fetchedData.iconUrl);
    }
    toggleAddLogoDialog() {
        return () => this.setState({ addLogoDialogOpened: !this.state.addLogoDialogOpened });
    }
}
