import * as React from "react";
import { formatPhone, formatTime, getTableColumnAction, getTableColumnItem, } from "../../utils/DataHelpers";
import IconButton from "material-ui/IconButton";
import Refresh from "material-ui/svg-icons/navigation/refresh";
import More from "material-ui/svg-icons/navigation/more-vert";
import Delete from "material-ui/svg-icons/action/delete";
import Edit from "material-ui/svg-icons/editor/mode-edit";
import SmartDataTable from "../SmartDataTable";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";
import { setChosenUser } from "../../actions/Actions";
import RaisedButton from "material-ui/RaisedButton";
class UsersTable extends React.Component {
    constructor() {
        super(...arguments);
        this.usersTableColumns = [
            getTableColumnItem("phone", "ID", (phone) => formatPhone(phone)),
            getTableColumnItem("roles", "Roles", (roles) => this.renderChips(roles)),
            getTableColumnItem("created", "Created", (created) => formatTime(created)),
            getTableColumnAction("phone", (phone, all) => this.renderIconMenu(phone, all)),
        ];
    }
    render() {
        return (React.createElement(SmartDataTable, { data: this.props.users, columns: this.usersTableColumns, toolbarIconRight: this.renderIcons }));
    }
    get renderIcons() {
        return [
            React.createElement("div", { className: "add-btn-wrapper" },
                React.createElement(RaisedButton, { primary: true, label: "ADD USER", onClick: this.props.addNewUser.bind(this) })),
            React.createElement(IconButton, { onClick: this.props.fetchData.bind(this) },
                React.createElement(Refresh, null)),
        ];
    }
    renderChips(roles) {
        return roles.map((role) => React.createElement("div", { className: "role-chip role-chip-" + role, key: role + role },
            React.createElement("p", null, role)));
    }
    renderIconMenu(phone, user) {
        return (React.createElement(IconMenu, { iconButtonElement: React.createElement(IconButton, { id: "more-button", onClick: () => {
                    this.props.dispatch(setChosenUser(user));
                } },
                React.createElement(More, null)) },
            React.createElement(MenuItem, { id: "edit-menu-item", leftIcon: React.createElement(Edit, null), primaryText: "Edit", onClick: this.props.editUser.bind(this) }),
            React.createElement(MenuItem, { id: "delete-menu-item", leftIcon: React.createElement(Delete, null), primaryText: "Delete", onClick: this.props.deleteUser.bind(this) })));
    }
}
export default connect()(UsersTable);
