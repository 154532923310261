import * as React from "react";
import Drawer from "material-ui/Drawer";
import { Link } from "react-router-dom";
import Divider from "material-ui/Divider";
import FontIcon from "material-ui/FontIcon";
import { List, ListItem, makeSelectable } from "material-ui/List";
import { Dialog, FlatButton } from "material-ui";
import { HttpClient } from "../../utils/HttpClient";
import { Typography } from "@material-ui/core";
import { mapStateToPropsBaseUrl } from "../../utils/DataHelpers";
import { connect } from "react-redux";
import { qwLogo } from "../../variables/Common";
import CBCSvg from '../SVG/CashByCode';
const pjson = require("../../../package.json");
const ReactToastify = require("react-toastify");
const SelectableList = makeSelectable(List);
class Sidebar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.hasRole = (arr) => {
            const { roles } = this.props;
            const contains = roles.filter(item => arr.includes(item)).length === arr.length;
            return contains;
        };
        this.state = {
            activeMenuItem: this.getActiveItemValue,
            showInfo: false,
            loading: false,
            backVersion: null,
        };
    }
    get getActiveItemValue() {
        switch (location.pathname.split("/")[1]) {
            case "buyer":
            case "buyers":
                return "/buyers";
            default:
                return "/" + window.location.pathname.split("/")[1];
        }
    }
    componentDidUpdate() {
        const { activeMenuItem } = this.state;
        if (activeMenuItem !== this.getActiveItemValue) {
            this.setState({ activeMenuItem: this.getActiveItemValue });
        }
    }
    render() {
        const { showInfo } = this.state;
        return (React.createElement("div", null,
            this.renderDrawer(),
            showInfo && this.showDialog()));
    }
    renderDrawer() {
        return (React.createElement(Drawer, { open: true, docked: true, containerStyle: { height: "calc(100% - 64px)", top: 64 } },
            React.createElement(SelectableList, { style: { paddingTop: "0px" }, value: this.state.activeMenuItem, onChange: (e, val) => this.setState({ activeMenuItem: val }), selectedItemStyle: { color: "#031CCA" } }, this.renderTabs())));
    }
    renderTabs() {
        const hasAdmin = this.hasRole(['Admin']);
        const hasSupportManager = this.hasRole(['Manager', 'Support']);
        const hasManager = this.hasRole(['Manager']);
        const hasSupport = this.hasRole(['Support']);
        const hasAccountant = this.hasRole(['Accountant']);
        const hasCashier = this.hasRole(['Cashier']);
        if (hasAdmin) {
            return this.getAdminTabs();
        }
        else if (hasSupportManager) {
            return this.getSupportManagerTabs();
        }
        else if (hasManager) {
            return this.getManagerTabs();
        }
        else if (hasSupport) {
            return this.getSupportTabs();
        }
        else if (hasAccountant) {
            return this.getAccountantTabs();
        }
        else if (hasCashier) {
            return this.getCashierTabs();
        }
    }
    getAccountantTabs() {
        return [
            this.renderListItem("/cash-in-requests", "CashIn requests", "attach_money"),
            this.renderListItem("/cbc", "CashByCode", "cbc"),
        ];
    }
    getCashierTabs() {
        return [
            this.renderListItem("/cash-in-requests", "CashIn requests", "attach_money"),
            this.renderListItem("/cbc", "CashByCode", "cbc"),
        ];
    }
    getAdminTabs() {
        return [
            this.renderListItem("/", "Dashboard", "dashboard"),
            this.renderListItem("/transactions", "Transactions", "compare_arrows"),
            this.renderListItem("/buyers", "Wallets", "account_balance_wallet"),
            this.renderListItem("/upgrades", "Upgrade requests", "schedule"),
            this.renderListItem("/cash-in-requests", "CashIn requests", "attach_money"),
            this.renderListItem("/processing", "Processing", "cached"),
            this.renderListItem("/merchants", "Merchants", "store"),
            this.renderListItem("/company", "Company", "location_city"),
            this.renderListItem("/archive-users", "Wallet archive", "inbox"),
            this.renderListItem("/notifications", "Notifications", "chat"),
            this.renderListItem("/cashout-company", "Cashout company", "business_center"),
            this.renderListItem("/withdrawals", "Withdrawal requests", "monetization_on"),
            this.renderListItem("/cbc", "CashByCode", "cbc"),
            this.renderListItem("/pendings", "Pending payments", "ac_unit"),
            this.renderListItem("/business", "Business", "business"),
            this.renderListItem("/commissions", "Commissions", "assignment"),
            this.renderListItem("/services", "Services", "category"),
            this.renderListItem("/system", "System Params", "settings"),
            React.createElement(Divider, { key: "first-divider" }),
            this.renderListItem("/reports", "Reports", "trending_up"),
            React.createElement(Divider, { key: "second-divider" }),
            this.renderListItem("/users", "Users", "group"),
            this.renderInfoItem("Info", "info"),
        ];
    }
    getManagerTabs() {
        return [
            this.renderListItem("/", "Dashboard", "dashboard"),
            this.renderListItem("/cashout-company", "Cashout company", "business_center"),
            React.createElement(Divider, { key: "first-divider" }),
            this.renderListItem("/reports", "Reports", "trending_up"),
            this.renderListItem("/cash-in-requests", "CashIn requests", "attach_money"),
            this.renderListItem("/cbc", "CashByCode", "cbc"),
            this.renderInfoItem("Info", "info"),
        ];
    }
    getSupportTabs() {
        return [
            this.renderListItem("/transactions", "Transactions", "compare_arrows"),
            this.renderListItem("/buyers", "Wallets", "account_balance_wallet"),
            this.renderListItem("/upgrades", "Upgrade requests", "schedule"),
            this.renderListItem("/withdrawals", "Withdrawal requests", "monetization_on"),
            this.renderListItem("/pendings", "Pending payments", "ac_unit"),
            this.renderListItem("/cashout-company", "Cashout company", "business_center"),
            this.renderListItem("/cbc", "CashByCode", "cbc"),
            this.renderInfoItem("Info", "info"),
        ];
    }
    getSupportManagerTabs() {
        return [
            this.renderListItem("/", "Dashboard", "dashboard"),
            this.renderListItem("/transactions", "Transactions", "compare_arrows"),
            this.renderListItem("/buyers", "Wallets", "account_balance_wallet"),
            this.renderListItem("/upgrades", "Upgrade requests", "schedule"),
            this.renderListItem("/withdrawals", "Withdrawal requests", "monetization_on"),
            this.renderListItem("/cbc", "CashByCode", "cbc"),
            this.renderListItem("/pendings", "Pending payments", "ac_unit"),
            this.renderListItem("/cashout-company", "Cashout company", "business_center"),
            React.createElement(Divider, { key: "first-divider" }),
            this.renderListItem("/reports", "Reports", "trending_up"),
            this.renderInfoItem("Info", "info"),
        ];
    }
    renderListItem(path, primaryText, icon) {
        return (React.createElement(ListItem, { value: path, primaryText: primaryText, leftIcon: this.getLeftIcon(path, icon), containerElement: React.createElement(Link, { to: path }), key: path }));
    }
    toggleDialog() {
        const { showInfo } = this.state;
        if (!showInfo) {
            this.fetchVersion();
        }
        this.setState(state => ({
            showInfo: !state.showInfo,
        }));
    }
    get renderDialogActions() {
        return [
            React.createElement("div", { id: "sidebar-actions" },
                React.createElement(FlatButton, { label: "Discard", primary: true, onClick: this.toggleDialog.bind(this) }))
        ];
    }
    fetchVersion() {
        HttpClient.getHeader(`${this.props.baseUrl}/params/limits`)
            .then((response) => {
            this.setState({
                backVersion: response,
            });
        })
            .catch((err) => {
            this.setState({ showInfo: false });
            ReactToastify.toast.error("Can't load App info! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    showDialog() {
        const { backVersion } = this.state;
        return (React.createElement(Dialog, { actions: this.renderDialogActions, modal: false, open: this.toggleDialog.bind(this), contentStyle: { width: 350 }, onRequestClose: this.toggleDialog.bind(this) },
            React.createElement("div", null,
                React.createElement("img", { src: qwLogo, alt: "logo", className: "logo-size" }),
                React.createElement(Typography, { style: { marginTop: 20 } },
                    " Frontend build: ",
                    pjson.version),
                React.createElement(Typography, null,
                    "Backend build: ",
                    backVersion))));
    }
    renderInfoItem(primaryText, icon) {
        return (React.createElement(ListItem, { onClick: this.toggleDialog.bind(this), primaryText: primaryText, leftIcon: this.getIcon(icon) }));
    }
    getIcon(icon) {
        return (React.createElement(FontIcon, { className: `material-icons` }, icon));
    }
    getLeftIcon(value, icon) {
        const { activeMenuItem } = this.state;
        if (icon === "cbc") {
            return React.createElement(CBCSvg, { className: `${(value === activeMenuItem) ? "selected-icon" : ""}` });
        }
        return (React.createElement(FontIcon, { className: `material-icons ${(value === activeMenuItem) ? "selected-icon" : ""}` }, icon));
    }
}
export default connect(mapStateToPropsBaseUrl)(Sidebar);
