import * as React from "react";
import { HttpClient } from "../utils/HttpClient";
import BuyersTable from "../components/BuyersComponents/BuyersTable";
import Card from "material-ui/Card/Card";
import CircularProgress from "material-ui/CircularProgress";
import { getBreadcrumbObject, setPageFromOffset } from "../utils/DataHelpers";
import { connect } from "react-redux";
import { setBreadcrumb, setBuyersTableState } from "../actions/Actions";
import { getOffset, getQuery } from "../utils/DataTablesHelepers";
const ReactToastify = require("react-toastify");
class Buyers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tableState: this.props.tableState || {
                rowSize: 20,
                count: 0,
                currentPage: 1,
                search: "",
                orderKey: "",
                order: "",
                data: []
            }
        };
        this.props.dispatch(setBreadcrumb([getBreadcrumbObject("Wallets", "/buyers")]));
    }
    render() {
        return (React.createElement("div", { className: "buyers-page" },
            React.createElement("div", { className: "buyers-main-wrapper" },
                React.createElement(Card, { className: "card-full-size" }, this.renderBuyersTable()))));
    }
    componentDidMount() {
        this.fetchBuyersData(this.state.tableState.currentPage, this.state.tableState.rowSize, "", this.state.tableState.orderKey, this.state.tableState.order);
    }
    fetchBuyersData(currentPage = this.state.tableState.currentPage, rowSize = this.state.tableState.rowSize, search = this.state.tableState.search, orderKey = this.state.tableState.orderKey, order = this.state.tableState.order) {
        const query = getQuery(getOffset(currentPage, rowSize), rowSize, search, orderKey, order);
        HttpClient.getJSON(`${this.props.baseUrl}/buyer/list`, query).then((result) => {
            const tableState = {
                count: result.pagination.total,
                currentPage: setPageFromOffset(result.pagination.offset, result.pagination.length),
                rowSize,
                search,
                orderKey,
                order,
                data: result.buyers,
            };
            this.props.dispatch(setBuyersTableState(tableState));
            this.setState({ loading: false, tableState: tableState });
        }).catch((err) => {
            ReactToastify.toast.error("Can't load data,reload page! Status: " +
                JSON.stringify(err.response.status) +
                " Error:" + JSON.stringify(err.response.statusText));
        });
    }
    renderBuyersTable() {
        if (this.state.loading) {
            return (React.createElement(CircularProgress, { size: 120, thickness: 10, style: {
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginLeft: "-60px",
                    marginTop: "-60px",
                } }));
        }
        else {
            return (React.createElement(BuyersTable, { tableState: this.props.tableState, fetchData: this.fetchBuyersData.bind(this) }));
        }
    }
}
const mapStateToProps = (state) => ({
    tableState: state.buyersTableState,
    baseUrl: state.baseUrl,
});
export default connect(mapStateToProps)(Buyers);
