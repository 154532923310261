import { BuyerType } from "../interfaces/Buyer";
import { SET_BUYER_INFO } from "../actions/Actions";
export function buyerProfileInfo(state = null, action) {
    if (action.type === SET_BUYER_INFO) {
        return Object.assign({}, {
            phone: action.phone,
            photoUrl: action.photoUrl,
            balance: action.balance,
            created: action.created,
            accessed: action.accessed,
            name: action.name,
            surname: action.surname,
            birthday: action.birthday,
            idnp: action.idnp,
            address: action.address,
            passportSeries: action.passportSeries,
            expDate: action.expDate,
            blocked: action.blocked,
            type: action.buyerType ? action.buyerType : BuyerType.ANONYMOUS,
            formNumber: action.formNumber,
            issueDate: action.issueDate,
            issuingAuthority: action.issuingAuthority,
            docType: action.docType,
        });
    }
    else {
        return state;
    }
}
