export const faviconCode = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/x" +
    "hBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAK7ElEQVRo3u1ZeXCV1RX/" +
    "3W97W17ekrxsJCYhIAmrSKkS0AZBtkYEJaIiWuxoi7gAto71Hx1pqaPFaat1qh0cK8VCmTGICogoi5Eom4JBIoEkhOzLy8vL277l3ts/spAvY" +
    "A2RdBvOzPvjve+7557fPef8zjn3AVfkilyR/2khg1lEa0slcuadZPgrskCj2Yh1uBELcChxBDZPGESuRPxVZ0nm9AYyapH6XwOAfbQqBU1Hb0K" +
    "kqRBacDKMcAphmh2UCuAMIAK4IDIIUgySrQWy80vYErdz76hdpOCPZwWHm/9HALD3lnrQ/s0ShOofQKwtjxgxGeADUs9FhUJxVcGe9CbiM9YLi" +
    "3bU/9sAUM4J2VyQD/+pZxDzF4Cq0mBijgOAIDEoriOIz1hDJi7fTsb/jA4pAFa2QcSRdfego3ottI40wvm3GzdAV3IAkO0BONKe447U34t3f6I" +
    "OCQBa+5lE9j/xOJoP/4roEdfFV4qci0oIoqUBsr0NWrAJksMNI+oFNzIIVd1g9KL6uSBF4cx4iWfe9Iw4e330sgNgb0y8D4GKl4gecl6wuShT" +
    "yM4yWD1vw+LdDXdWFdKu7+REiRHCFFTttkENZCFUPxVaxyJondcRqioXgpBVxKWtITe++DzJu12/bADYxqlT0Vq2iWgd6aYNCQHkuDrEZ73C3" +
    "SNeFxcWN35n8m+/142mL29DuO4JxNpHEc7MIGR7CJ7cnwv3Hd14WQCwHcsSUP3BZhJqmGFmGQJuT/oCCaNXCnfu2X+pm7DihWPQdPh3CNfPJn" +
    "3CigOA1VuO1OtuFRbtODUYAILpW33pYkRbCkzGEwJu8x1G/PAlgzEeAISFxSeQMHoZbInFnAjm01M7ctFWvpxxLn4vAGxbUQoizfcTqpsUcYun" +
    "HimTfiEsLT35fZJNKPqgkSdO+CWs3qO8j+MJp4DWsQTv3j3p+3mgvWIG9PAEc8JaOFw5LwuLduy7HJwt3rGrEu6c30KyhU0PtKAPrWXzBw2AhVs" +
    "kxALzwTTJ9NTmK0NGwQZcRiHZs96D4txu8gIzADUwl5auTRycB/asTIEWnGgqVoIMOFKLhekv1F5WAFPXxBA3bAtExUydWscI0nBw5KAA8M5zW" +
    "TBiaabwkaydiEvbjaGQhNzPIdtrTL9RNR4x/9hBASBqMAPcsJsD1lYHZ0bFkADIvbMRorXczLUGoIdyBhdCkjUdnJuLGhHruG9CYCjsF0bM1yD" +
    "INeYyxAFChvH+dgwIgCDGX9Aei1KMpE6iGCqx+xhIPwBqWLzUgUHoXhu7oKswNIKm40NmP9TAhROFbL1kNVK3snMXeZYKf7kTgH8giljZW3ac3" +
    "HA/Io1JADioVkvc1q2F29clN7d0FhkGgyILJNFrPfzQ0pwPUD4t2dyiCwDQKBDCLx2AZK8DEaOAbjtvkXYVgmczBwoAw+dSHHpuFvxf30LAwS" +
    "EAmvda6KG3TlZ2PBpVDTcAOO1ye5xCl80br5qnOiICFlfd4JLYnlhNRLnFlMNGOAGBM9cPWJHdo0Jx7QARAUZBmA7EQoUv/qhYt1rkMso4KOM" +
    "IhnTPl6f1ubB5S7jQp2sR5AiI9MXgAGTPruOyo8zMyxoQay1iR19xDVQZ9+QcgOLsPQjOtPSrldJJST5niSh05RjjHIHO6E0H6e1VRLI09y5WH" +
    "DXEd3X5oHJAmLQyxl4f/SGPtc0jtE+BjDTno+LtWwD8bSDKyOglp1F34BiirTO7DkEHOptnXjdK2FhzToqForoVBAh2qtnrDk5zb752/UGuRQo" +
    "JIagXxtUs31T08Oz7FlrBOUJho9qXYN2y9bUZDT36l67al98W0BZpOqWaSksWzMraeb73caS8j2DNClB9RK9BesjC28pWs3fvOiDc8vfK73Rn1" +
    "s1htuGHH3JBnkmY3sVrWnTKqvE7/7yzNL8iFNXHAUBMo1JpuXE98n0fIuwvhKwENlbdHN1VUv8EpYYIAgiEIM1nz61t6nwkPdlJAaDkSPM9tU2" +
    "R5ZxzOKzSgw67/HBvNyos/rgCimOjKS4BINoyEfUlv6E7700YkE/js/dDdgR6w8hQk8dJu0cn+ZyfC91hxDng90emvtcwo5yIlnpuTTuw8dhwU" +
    "dV10WAcBuXQdIZAUJ/z2NOHMgHg0adLU8JR40bdYDAYR0SlzvaglmUeaHzjNkCJP2EKC0ZBQg2Lydk9f2BbF6V9J4Bh+V9DcZ3XwQ0gVDc9L80" +
    "osciiDg6AcKianvNsSX4SfCPeP2zM3VbXysf2LwxRzbiqqS2aDwBffRMYF44Y2T01z6qIAYdN3m8CIBTtPoO4YWu5ZA2ZSd4g6Ky/G/Ulm9mmG" +
    "bNYxbuWbw2jHzwWhCX+I07E3gILLTZ5xYQ9HfEOS2+9oYwpjQFxDvLveubXB+eo0aiWYU4oQNeZ2NAcnQ4A7UF1ekxn9p56G2eXTuRP8h0XLmC" +
    "SsQ9sQVz6y1y0mCZwwilBuGkamg9vwSdPvsn+MXs++2h1Cqs/JF2AwpF6EJIl0m0HuKGlTHPuGe312A72dA+cA8GQPm3ZK9McVQ36DTGNij3G" +
    "9fQEjHMEguqU1c9+ltsaUPMp7TJJFAji7NLHax6/tv2CzcXJj+l871PP8zNbU3nHmXsJVcn5Q+GA2hEPrfMOHjx7K1qOVaJy2zds/ZizUEPNsN" +
    "g94CwTLcdzCdUsfcADseYZY9LptsoasUg1uowNR41hVTWhOztCej7rrspOh6zZLGK02R9zAUBEpVknzwTv6QwbeT0etShCKDnRureitxL3p8OC" +
    "te1s75Orcbo4gGD1g6Cqzewm1sVQeigPEZJHCOmCF+FdIC92ixcNXPPg+AN/3XVkYq2q00wQwKBMOtcYeri9U/P0vOaNV054XJbDre3qAwwcqs" +
    "5sZ2o6H4qqhqvLnYDDLp8aM9J7vAT9byX6xnLBc36MKHySe0at4orrbN/bhD5h2m0wAzgF4ezixgOAoXqnez8ZleixHevjUlTXhZJCEV0GAUSR" +
    "IDnBdsDjtmxRFCEC3hVGp891ejSDdc0uAmC3SXtfXZvfhn8FoAvEuhj5ybHXkDi2EI6Uv3DZ3goiYDDCmQFJa5g+PpMflUShFyXrzvNuZtGTEqw" +
    "fJ3qshxRZKD//zvlDkUUh6rBLu8ytxL+qroRwYcmnZRg+5yH4JhRyZ/oL3OI6yiVriAsy7/JMN7+DdP9HIIFLtiiX7QHenbUEAKL+MT+95lhAVq" +
    "SGi+3ljlPOjc5xHdnyckHA7bSUCqRfi8+BOLtcNTHXe7xvFFySsKq9BEee9yLcmAeqjgfVRsKIemBLTEHM3wpR8UOQK2H1fsW1jmzSfupPhGpdd" +
    "6OChJjvxpdGvrYir7YxOLN/UzZ2hHvTsR0LlhJCjAnz3rmtvLJjk2pQ+byxBOnJ9ldrSoqWk+62W7rk7i+7gANoA1DS/QHtGgMVALpISC/90rem" +
    "pUNQqkG1q7vrCaxqxeRxGfTT+iYykzGOnsSUFYG5ncpOQogBAFOu8R1vaIk0NvuNjJ5ztigCTU+27yN9ZobBBXR/6iWEi4SofY0HAPLjNxpgT9" +
    "4P2dkO2dkKKa4Nhu5bPPZ0lcthrYizSW1Oq9QaZ5PafC7LyfRUx4GetU+tmFCT7LXuc9rkdqet650kr+Xk+FHuQ/2JZEiFFd+ehFi7j/RkqiD" +
    "yE8aUxpXv35BADaP32t3rVtQF8zKrly4cafT8tuSRvSkNTdEE3s1siR5L7LZ5WdV3LcihuCJX5Ir8f8g/AdH+1Npu2y5dAAAAJXRFWHRkYXR" +
    "lOmNyZWF0ZQAyMDE4LTAxLTMwVDEzOjQ4OjAzKzAxOjAwTd1hJQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wMS0zMFQxMzo0ODowMyswMT" +
    "owMDyA2ZkAAAAASUVORK5CYII=";
